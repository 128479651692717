import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Input, Select, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Flex, Spacer } from "~/components/shared/global";
import { GlobalContext } from "~/context/global.context";
import { IQuizz } from "~/model/quizz.model";
import { getQuizzList } from "~/services";
import { searchFilterOptions } from "~/utils/helpers";

const QuizzList = () => {
  const [quizzList, setQuizzList] = useState<IQuizz[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<{
    room?: string;
    eventName?: string;
  } | null>(null);

  const { roomList } = useContext(GlobalContext);

  useEffect(() => {
    async function fetchQuizzList() {
      setIsLoading(true);
      const response = await getQuizzList({
        room: filterParams?.room,
        eventName: filterParams?.eventName,
      });
      if (response) setQuizzList(response);
      setIsLoading(false);
    }

    fetchQuizzList();
  }, [filterParams]);

  const columns: ColumnsType<IQuizz> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <Link to={`${record.id}`}>{text}</Link>,
    },
    {
      title: "Subtitle",
      dataIndex: "subtitle",
      key: "subtitle",
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
    },
    {
      title: "Display At",
      dataIndex: "displayAt",
      key: "displayAt",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "End Display At",
      dataIndex: "endDisplayAt",
      key: "endDisplayAt",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
  ];

  return (
    <div className='basic-layout'>
      <Flex align='center' justify='space-between'>
        <h1 style={{ margin: 0 }}>Quizz List</h1>

        <Link to='create'>
          <Button type='primary' icon={<PlusOutlined />}>
            {"Create Quizz"}
          </Button>
        </Link>
      </Flex>
      <Spacer />
      <Card>
        <Flex align='center' gap={8}>
          <Tooltip title='⚠️ The search is an EXACT-TERM SEARCH, the query has to be exactly the same as the searched event name.'>
            <Input.Search
              allowClear
              placeholder='Search for Event Name'
              style={{ width: "100%" }}
              onSearch={(value) =>
                setFilterParams((prev) => ({ ...prev, eventName: value }))
              }
            />
          </Tooltip>
          <Select
            showSearch
            placeholder='Filter by room'
            disabled={!roomList}
            loading={!roomList}
            style={{ width: 300 }}
            options={roomList?.map((room) => ({
              label: room.name,
              value: room.id,
            }))}
            filterOption={searchFilterOptions}
            onChange={(value) =>
              setFilterParams((prev) => ({ ...prev, room: value }))
            }
          />
        </Flex>
        <Spacer height={8} />
        <Table
          loading={!quizzList || isLoading}
          columns={columns}
          dataSource={quizzList.map((quizz) => ({
            key: quizz.id,
            ...quizz,
          }))}
          scroll={{
            x: "max-content",
          }}
        />
      </Card>
    </div>
  );
};

export default QuizzList;
