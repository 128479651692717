import {
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Image,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import SelectTagsField from "~/components/SelectTagsField";
import { Flex, Spacer } from "~/components/shared/global";
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
} from "~/config/dropzone";
import { GlobalContext } from "~/context/global.context";
import { SupportedLanguage } from "~/model/enum";
import { IImage } from "~/model/image.model";
import { IPlaylist } from "~/model/playlist.model";
import { ITag } from "~/model/tag.model";
import { IUser } from "~/model/user.model";
import {
  createPlaylist,
  getCertifiedUsers,
  updatePlaylist,
  uploadPlaylistImage,
} from "~/services";
import {
  getRequestErrorMessage,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import { triggerAutosaveMessage } from "~/utils/ui/messages";

const TIMEOUT_AUTOSAVE = 5000;

type PlaylistEditionContentFormProps = {
  form: FormInstance;
  playlist?: IPlaylist;
  setPlaylist: (playlist: IPlaylist) => void;
};

export default function PlaylistEditionContentForm({
  form,
  playlist,
  setPlaylist,
}: PlaylistEditionContentFormProps) {
  const navigate = useNavigate();
  const { id: playlistId } = useParams();
  const { tagList, companyList } = useContext(GlobalContext);

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [certifiedUsers, setCertifiedUsers] = useState<IUser[] | null>(null);
  const [innerImages, setInnerImages] = useState<string[]>([]);
  const [innerPublisher, setInnerPublisher] = useState<{
    key: string | null;
    value: string | null;
  }>({
    key: playlist?.user ? "user" : "company",
    value: playlist?.user ? playlist.user : playlist?.company?._id || "",
  });

  // Ref timeout autosave
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Watch form values
  const watchLanguage: SupportedLanguage = Form.useWatch("language", form);

  const initialValues = {
    ...playlist,
    publicationDate: playlist?.publicationDate
      ? moment(playlist.publicationDate)
      : undefined,
    medical_specialties: playlist?.medical_specialties?.map(
      (spe) => (spe as ITag)._id
    ),
    tags: playlist?.tags?.map((tag) => (tag as ITag)._id),
    owner: playlist?.owner?._id,
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  async function handleFinish() {
    setIsLoading(true);
    try {
      const values = {
        ...form.getFieldsValue(true),
        user: innerPublisher.key === "user" ? innerPublisher.value : null,
        company: innerPublisher.key === "company" ? innerPublisher.value : null,
      };

      if (playlistId) {
        const newPlaylist = await updatePlaylist(playlistId, values);

        setPlaylist(newPlaylist);

        if (acceptedFiles.length) {
          const playlist = await uploadPlaylistImage(
            playlistId,
            acceptedFiles[0]
          );
          if (playlist.images.length) {
            setInnerImages(playlist.images.map((image: IImage) => image.url));
          }
        }

        notification.success({
          message: "Playlist updated",
          description: "The playlist was successfully updated.",
          placement: "bottomRight",
        });
      } else {
        const createdPlaylist = await createPlaylist(values);

        if (createdPlaylist) {
          navigate(`/content-management/playlist/${createdPlaylist._id}`);
        }

        notification.success({
          message: "Playlist created",
          description: "The playlist was successfully created.",
          placement: "bottomRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "An error occurred while saving the playlist",
        description: getRequestErrorMessage(error),
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleFinishFailed() {
    const errorFields = form.getFieldsError();

    errorFields.forEach((error) => {
      notification.error({
        message: "Validation Error",
        description: error.errors[0],
        placement: "bottomRight",
      });
    });
  }

  async function handleAutoSave() {
    if (!playlistId || playlist?.meta?.status !== "draft") return;

    try {
      const values = {
        ...form.getFieldsValue(),
        user: innerPublisher.key === "user" ? innerPublisher.value : null,
        company: innerPublisher.key === "company" ? innerPublisher.value : null,
      };

      const newPlaylist = await updatePlaylist(playlistId, values);

      setPlaylist(newPlaylist);

      if (acceptedFiles.length) {
        const playlist = await uploadPlaylistImage(
          playlistId,
          acceptedFiles[0]
        );
        if (playlist.images.length) {
          setInnerImages(playlist.images.map((image: IImage) => image.url));
        }
      }

      triggerAutosaveMessage();
    } catch (error) {
      triggerAutosaveMessage(error);
    }
  }

  async function handleFieldsChange() {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      timeoutRef.current = null;
      await handleAutoSave();
    }, TIMEOUT_AUTOSAVE);
  }

  useEffect(() => {
    async function fetchCertifiedUsers() {
      const users = await getCertifiedUsers();
      setCertifiedUsers(users);
    }

    fetchCertifiedUsers();
  }, []);

  useEffect(() => {
    if (playlist?.images.length) {
      setInnerImages(playlist.images.map((image: IImage) => image.url));
    }
  }, [playlist]);

  // Prevent double save with autosave
  useEffect(() => {
    if (!isLoading && timeoutRef.current) clearTimeout(timeoutRef.current);
  }, [isLoading]);

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        onFieldsChange={handleFieldsChange}
      >
        <Row
          style={{
            paddingBottom: "20px",
            borderBottom: "1px solid #d9d9d9",
          }}
          justify='space-between'
          gutter={48}
        >
          <Col span={12}>
            <Form.Item name='isPublic' label='Accessibility' required>
              <Select placeholder='Accessibility'>
                <Select.Option value={true}>{"✅ Public"}</Select.Option>
                <Select.Option value={false}>{"🔐 Private"}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name='language' label='Language' required>
              <Select placeholder='Language' options={languageOptions} />
            </Form.Item>

            <Form.Item name='sponsored' label='Sponsored' required>
              <Select
                placeholder='Sponsored'
                options={[
                  { label: "👍 Yes", value: true },
                  { label: "👎 No", value: false },
                ]}
              />
            </Form.Item>

            <Form.Item name='title' label='Title' required>
              <Input placeholder='Title' />
            </Form.Item>

            <Form.Item name='publicationDate' label='Publication date' required>
              <DatePicker
                style={{ width: "75%" }}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
              />
            </Form.Item>

            <Form.Item name='rank' label='Rank' required>
              <Input
                placeholder='Rank'
                type='number'
                min={0}
                style={{ width: 75 }}
              />
            </Form.Item>

            <Form.Item
              name='medical_specialties'
              label='Medical specialties'
              required
            >
              <Select
                mode='tags'
                placeholder='Select medical specialties'
                disabled={!tagList.length}
                loading={!tagList.length}
                options={tagList
                  .filter((tag) => !tag.parent)
                  .map((tag) => ({
                    label: tag.translations.en,
                    value: tag._id,
                  }))}
                filterOption={searchFilterOptions}
              />
            </Form.Item>

            <Form.Item name='tags' label='Tags'>
              <SelectTagsField form={form} />
            </Form.Item>

            {playlistId ? (
              <Form.Item key='image' label='Image' name='image'>
                <Flex gap={8} align='center'>
                  <div
                    {...getRootProps({
                      className: "dropzone",
                      maxFiles: 1,
                    })}
                  >
                    <input {...getInputProps({ style })} />
                  </div>
                  {innerImages ? (
                    <Image
                      key={innerImages[innerImages.length - 1]}
                      style={{
                        maxHeight: "72px",
                      }}
                      src={innerImages[innerImages.length - 1]}
                      alt={innerImages[innerImages.length - 1]}
                    />
                  ) : null}
                </Flex>
              </Form.Item>
            ) : null}
          </Col>

          <Col span={12}>
            <Divider>Company / KOL *</Divider>
            <Select
              style={{ width: "100%" }}
              placeholder='Publisher'
              defaultValue={
                playlistId ? (playlist?.user ? "user" : "company") : undefined
              }
              onChange={(publisher: string) =>
                setInnerPublisher({
                  key: publisher,
                  value: null,
                })
              }
            >
              <Select.Option value='company'>Company</Select.Option>
              <Select.Option value='user'>KOL User</Select.Option>
            </Select>

            <Spacer />

            <Select
              style={{ width: "100%" }}
              notFoundContent='Please select a language first'
              placeholder={
                innerPublisher.key === "company"
                  ? "Select a company"
                  : "Select a certified user"
              }
              showSearch
              filterOption={searchFilterOptions}
              disabled={!innerPublisher}
              value={innerPublisher.value}
              options={
                innerPublisher.key === "company"
                  ? companyList
                      ?.filter((company) => company.language === watchLanguage)
                      .map((company) => ({
                        value: company._id,
                        label: company.name + ` (${company.language})`,
                      }))
                  : certifiedUsers?.map((user: IUser) => ({
                      value: user._id,
                      label: user.firstname + " " + user.lastname,
                    }))
              }
              onChange={(value: string) =>
                setInnerPublisher({
                  ...innerPublisher,
                  value,
                })
              }
            />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
