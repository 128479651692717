import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
  notification,
} from "antd";

import {
  getMe,
  listUsers,
  getUserCsv,
  searchUsers,
  getProfessionList,
  updateUserRoles,
  createAppAnnouncement,
} from "../../services";
import { userMapping } from "../../config/userMapping";
import { countryList } from "../../config/countries";
import { useNavigate } from "react-router-dom";
import { GlobalContext, IGlobalContext } from "../../context/global.context";
import { Link } from "react-router-dom";
import {
  CheckCircleOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { languageLabel, searchFilterOptions } from "../../utils/helpers";
import UserRoomsAssociator from "~/pages/users/UserRoomsAssociator";
import UserOrganisationAssociator from "./UserOrganisationAssociator";
import FiltersDrawer, {
  FiltersDrawerValuesModel,
} from "~/components/shared/FiltersDrawer";
import UsersListActions from "./UsersListActions";
import { IProfession } from "~/model/profession.model";
import { IUser } from "~/model/user.model";
import { IQuery } from "~/model/query.model";
import { SupportedLanguage } from "~/model/enum";
import { ITag } from "~/model/tag.model";
import { IJournal } from "~/model/journal.model";

const USER_ENUM = {
  juisci: "Administrateur Juisci",
  admin_org: "Administrateur organisation",
  user: "Utilisateur",
};

export const UserList: React.FC = () => {
  const navigate = useNavigate();
  const { organisationList, tagList } = useContext(
    GlobalContext
  ) as IGlobalContext;
  const [innerUserList, setInnerUserList] = useState<IUser[] | null>(null);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [blobUrl, setBlobUrl] = useState<string | null>("");
  const [isBlobLoading, setBlobLoading] = useState<boolean>(false);
  const [queryMeta, setQueryMeta] = useState<{
    total: number;
    offset: number;
    limit: number;
  }>({
    total: 0,
    offset: 0,
    limit: 100,
  });

  const [innerProfessionList, setInnerProfessionList] = useState<IProfession[]>(
    []
  );

  const [fetchParams, setFetchParams] = useState<FiltersDrawerValuesModel>();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);

  const [selectedUser, setSelectedUser] = useState<IUser>();

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);
  const [isRolesConfirmOpen, setIsRolesConfirmOpen] = useState(false);
  const [showAdModal, setShowAdModal] = useState(false);
  const [form] = Form.useForm();

  const fetchUsers = async (limit?: number, offset?: number) => {
    const query: IQuery = {
      ...fetchParams,
      limit: limit || 100,
      offset: offset || 0,
    };

    if (fetchParams?.search) {
      const users = await searchUsers(fetchParams?.search, query);

      setInnerUserList(users);
      setPage(1);
    } else {
      const { docs: userList, meta } = await listUsers(query);

      setInnerUserList(userList);
      setQueryMeta(meta);
    }

    if (!innerProfessionList.length) {
      const { docs: professionList } = await getProfessionList({ limit: 100 });
      setInnerProfessionList(professionList);
    }
  };

  const fetch = async () => {
    if (!currentUser) {
      try {
        const loggedUser = await getMe();
        setCurrentUser(loggedUser);
      } catch {
        navigate("/");
      }
    }
  };

  const showRolesModal = (user: IUser) => {
    setIsRolesModalOpen(true);
    setSelectedRoles(user.roles);
    setSelectedUser(user);
  };

  const handleRolesCancel = () => {
    setIsRolesModalOpen(false);
    setSelectedRoles([]);
  };

  const handleRolesConfirmOk = async () => {
    setIsRolesConfirmOpen(false);
    setIsRolesModalOpen(false);
    if (selectedUser && selectedRoles.length > 0) {
      const updatedUser = await updateUserRoles(
        selectedUser._id,
        selectedRoles
      );
      if (updatedUser) {
        await fetchUsers(
          pageSize,
          (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
        );
      }
      setSelectedRoles([]);
    }
  };

  const handleRolesOk = async () => {
    setIsRolesConfirmOpen(true);
  };

  const handleRolesConfirmCancel = () => {
    setIsRolesConfirmOpen(false);
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchUsers(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
      setPageSize(pageSize);
    }
    setPage(page);
  };

  const handleRoleChange = (value: string[]) => {
    setSelectedRoles(value);
  };

  const handleDownloadCSV = async () => {
    delete fetchParams?.search;

    const query = {
      ...fetchParams,
      limit: pageSize || 100,
      offset: (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize || 0,
    };

    if (blobUrl) return;

    setBlobLoading(true);

    const userCsv = await getUserCsv(query);
    if (userCsv) {
      const blobConfig = new Blob([userCsv], {
        type: "text/csv;charset=utf-8",
      });
      const url = URL.createObjectURL(blobConfig);
      setBlobUrl(url);

      const links = document.createElement("a");
      document.body.appendChild(links);
      links.download = "users.csv";
      links.href = url;
      links.click();
      document.body.removeChild(links);
      setBlobUrl(null);
    }

    setBlobLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParams]);

  const handleCreateAnnouncement = async () => {
    const contents: {
      title: string;
      content: string;
      cta: string;
      language: SupportedLanguage;
    }[] = [];
    const inputData = form.getFieldsValue();

    Object.values(SupportedLanguage).forEach((language) => {
      const title = inputData[`title-${language}`];
      const content = inputData[`content-${language}`];
      const cta = inputData[`cta-${language}`];

      if (title && content) {
        contents.push({
          title,
          content,
          cta,
          language,
        });
      }
    });

    await createAppAnnouncement({
      contents,
      organisation: inputData["organisation"],
    });

    setShowAdModal(false);
    form.resetFields();

    notification.success({
      message: "✅ Announcement created successfully",
    });
  };

  const columns: ColumnsType<IUser> = [
    {
      title: "",
      dataIndex: ["profilePicture", "url"],
      key: "profilePicture",
      width: 64,
      align: "center",
      render: (url: string) => <Avatar src={url} icon={<UserOutlined />} />,
    },
    ...Object.keys(userMapping)
      .filter((key) => !["preferredFormats", "origin", "device"].includes(key))
      .map((key: string) => {
        if (["main_specialty", "profession"].includes(key))
          return {
            title: userMapping[key],
            dataIndex: key,
            key: key,
            width: 150,
            render: (value: ITag | IProfession) => {
              return value ? value?.translations?.en : "";
            },
          };
        if (key === "country")
          return {
            title: userMapping[key],
            dataIndex: key,
            key: key,
            width: 120,
            render: (value: string) => {
              return value ? countryList[value] : "";
            },
          };

        if (key === "journal") {
          return {
            title: "Journals",
            dataIndex: "journals",
            key: "journals",
            width: 240,
            render: (journal: IJournal[]) => {
              return journal?.length
                ? journal.map((j) => j?.name).join(", ") || "N/A"
                : "N/A";
            },
          };
        }
        if (key === "birthdate")
          return {
            title: userMapping[key],
            dataIndex: key,
            key: key,
            width: 100,
            render: (birthdate: Date) => {
              return birthdate ? new Date(birthdate).toLocaleDateString() : "";
            },
          };
        return {
          title: userMapping[key],
          dataIndex: key,
          key,
          width: 100,
        };
      }),

    {
      title: "Organisation",
      render: (_text: string, record: IUser) => (
        <UserOrganisationAssociator user={record} revalidate={fetchUsers} />
      ),
    },
    {
      title: "Origin",
      dataIndex: "origin",
      key: "origin",
      width: 100,
      render: (origin: string) => origin || "N/A",
    },
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      width: 100,
      render: (device: string) => device || "N/A",
    },
    {
      title: "Signup date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 100,
    },
    {
      title: "Last login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      width: 100,
    },

    {
      title: "RPPS",
      dataIndex: "rpps",
      key: "rpps",
      width: 100,
      render: (rpps: string) => rpps || "N/A",
    },
    {
      title: "Roles",
      width: 200,
      render: (_text: string, record: IUser) => (
        <div
          style={{
            cursor: "pointer",
            color: "#1890ff",
          }}
          onClick={() => showRolesModal(record)}
        >
          {record.roles
            .map((role: string) => USER_ENUM[role as keyof typeof USER_ENUM])
            .join(", ")}
        </div>
      ),
    },
    {
      title: "Email Validated",
      dataIndex: "emailValidated",
      key: "emailValidated",
      width: 120,
      render: (verified: boolean) =>
        verified ? (
          <Tag color='green'>{"✅ Validated"}</Tag>
        ) : (
          <Tag color='red'>{"❌ Not validated"}</Tag>
        ),
    },
    {
      title: "Action",
      align: "center",
      key: "action",
      render: (_text: string, user: IUser) => (
        <UsersListActions user={user} fetchUsers={fetchUsers} />
      ),
    },
    {
      title: "Rooms",
      dataIndex: "rooms",
      fixed: "right",
      width: 100,
      render: (_, user: IUser) => <UserRoomsAssociator user={user} />,
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Modal
          open={showAdModal}
          title='Make an announcement'
          onOk={handleCreateAnnouncement}
          onCancel={() => setShowAdModal(false)}
        >
          <Form layout='vertical' form={form}>
            <Form.Item label='Organisation' name='organisation'>
              <Select
                placeholder='Organisation'
                options={organisationList.map((org) => ({
                  label: org.name,
                  value: org._id,
                }))}
                allowClear
              />
            </Form.Item>

            <Tabs
              items={Object.values(SupportedLanguage).map((lng) => ({
                key: lng,
                label: languageLabel[lng],
                children: (
                  <div>
                    <Form.Item label='Title' name={"title-" + lng}>
                      <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item label='Content' name={"content-" + lng}>
                      <Input.TextArea
                        placeholder='Your message here...'
                        autoSize={{ minRows: 3, maxRows: 9 }}
                      />
                    </Form.Item>
                    <Form.Item label='CTA' name={"cta-" + lng}>
                      <Input placeholder='CTA' />
                    </Form.Item>
                  </div>
                ),
              }))}
            />
          </Form>
        </Modal>
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Users</h1>
          <div>
            <Button
              onClick={() => setShowAdModal(true)}
              icon={<SoundOutlined />}
            >
              Announcement
            </Button>
            <Divider type='vertical' />
            <Link to='/sensitive-data/users/lemon-check-requests'>
              <Button icon={<CheckCircleOutlined />}>
                LemonCertified Requests
              </Button>
            </Link>
            <Divider type='vertical' />
            <Button
              type='primary'
              download='users.csv'
              loading={isBlobLoading}
              onClick={handleDownloadCSV}
            >
              Download as CSV
            </Button>
          </div>
        </Row>
        <FiltersDrawer
          fields={["languages", "search", "rooms"]}
          onChange={(filters: FiltersDrawerValuesModel) =>
            setFetchParams(filters)
          }
        >
          <Form.Item name='mainSpecialties'>
            <Select
              allowClear
              mode='multiple'
              placeholder='Main specialties'
              filterOption={searchFilterOptions}
              options={tagList
                .filter((s) => !s.parent)
                .map((s) => ({
                  label: s.translations.en,
                  value: s._id,
                }))}
            />
          </Form.Item>
          <Form.Item name='organisations'>
            <Select
              allowClear
              mode='multiple'
              placeholder='Organisations'
              filterOption={searchFilterOptions}
              options={organisationList.map((org) => ({
                label: org.name,
                value: org._id,
              }))}
            />
          </Form.Item>
          <Form.Item name='professions'>
            <Select
              allowClear
              mode='multiple'
              placeholder='Professions'
              filterOption={searchFilterOptions}
              options={innerProfessionList.map((p) => ({
                label: p.translations.en,
                value: p._id,
              }))}
            />
          </Form.Item>
          <Form.Item name='countries'>
            <Select
              mode='multiple'
              allowClear
              placeholder='Countries'
              filterOption={searchFilterOptions}
              options={Object.entries(countryList).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
            />
          </Form.Item>

          <Form.Item name='device'>
            <Select
              showSearch
              allowClear
              placeholder='Device'
              filterOption={searchFilterOptions}
              options={[
                { label: "Android", value: "android" },
                { label: "iOS", value: "ios" },
              ]}
            />
          </Form.Item>

          <Form.Item name='origin'>
            <Select
              showSearch
              allowClear
              placeholder='Origin'
              filterOption={searchFilterOptions}
              options={[
                { label: "website", value: "Website" },
                { label: "app", value: "App" },
              ]}
            />
          </Form.Item>
        </FiltersDrawer>
        <div style={{ height: 16 }} />
        <Spin spinning={!innerUserList} tip='Loading...'>
          <Modal
            title='Confirmation'
            open={isRolesConfirmOpen}
            onOk={handleRolesConfirmOk}
            onCancel={handleRolesConfirmCancel}
          >
            <p>Are you sure you want to update this user's roles?</p>
          </Modal>
          <Modal
            title='Select roles'
            open={isRolesModalOpen}
            onOk={handleRolesOk}
            onCancel={handleRolesCancel}
          >
            <p>{`Select roles for user ${selectedUser?.firstname} ${selectedUser?.lastname}`}</p>
            <Select
              value={selectedRoles}
              onChange={handleRoleChange}
              showSearch
              size='middle'
              mode='multiple'
              placeholder='Please select'
              style={{ width: "100%" }}
            >
              {(selectedUser?.organisations?.[0]?.uid === "juisci" ||
              !selectedUser?.organisations?.length
                ? ["juisci", "user"]
                : ["juisci", "admin_org", "user"]
              )?.map((role: string) => (
                <Select.Option key={role} value={role}>
                  {USER_ENUM[role as keyof typeof USER_ENUM]}
                </Select.Option>
              ))}
            </Select>
          </Modal>

          <Table
            size='small'
            columns={columns}
            dataSource={
              innerUserList
                ? innerUserList.map((user: IUser, index: number) => ({
                    key: user.uid + "--key" + index,
                    ...user,
                    creationDate: new Date(
                      user?.meta?.creationDate
                    ).toLocaleDateString(),
                    lastLogin: new Date(
                      user?.meta?.lastLogin
                    ).toLocaleDateString(),
                  }))
                : []
            }
            pagination={false}
            scroll={{
              x: "max-content",
              y: "calc(100vh - 360px)",
            }}
            sticky
          />
        </Spin>
        <Pagination
          style={{ marginTop: "10px" }}
          onChange={handlePaginationChange}
          total={queryMeta.total}
          current={page}
          defaultCurrent={1}
          defaultPageSize={100}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </Layout.Content>
    </Layout>
  );
};
