import { Card } from "antd";
import React from "react";
import { Flex } from "../shared/global";
import styled from "styled-components";

type InnerProps = {
  title: string;
  icon: React.ReactNode;
  color: string;
  data: { label: string; value: string | number }[];
};

const TopContentCard = ({ title, icon, data, color }: InnerProps) => {
  const hexToRgb = (hex: string, opacity: number) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) return "rgba(0, 0, 0, 0)";

    return `rgba(${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16
    )}, ${parseInt(result[3], 16)}, ${opacity})`;
  };

  return (
    <Card
      title={
        <Flex gap={8} align='center'>
          {icon}
          <span>{title}</span>
        </Flex>
      }
    >
      {data?.map(({ label, value }, index) => (
        <StyledTopCard
          key={label + index}
          style={{ background: hexToRgb(color, 1 - index / data.length) }}
        >
          <div>{label}</div>
          <div>{value}</div>
        </StyledTopCard>
      ))}
    </Card>
  );
};

export default TopContentCard;

const StyledTopCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin: 4px 0;
  border-radius: 5px;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #212121;
`;
