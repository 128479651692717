import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, notification, Row, Tabs } from "antd";
import { red } from "@ant-design/colors";
import {
  addContentToContent,
  addInfographicArticle,
  getInfographic,
  removeContentFromContent,
  removeInfographicArticle,
  searchArticlesV2,
  searchVideos,
} from "../../services";

import { useLocation, useParams } from "react-router-dom";

import LoadingLayout from "../../components/shared/LoadingLayout";
import RoomsAssociator from "../../components/shared/RoomsAssociator";
import { FiltersDrawerValuesModel } from "../../components/shared/FiltersDrawer";
import InfographicEditionContentForm from "./InfographicEditionContentForm";
import EditionPageHeader, {
  PageHeaderSetContent,
} from "~/components/EditionPageHeader.tsx";
import { IInfographic } from "~/model/infographic.model";
import { ContentFormatsEnum, SupportedLanguage } from "~/model/enum";
import { IArticle } from "~/model/article.model";
import { IVideo } from "~/model/video.model";
import { IContent } from "~/model/content.model";

type TabsType = "details" | "rooms" | "articles" | "videos";

export const InfographicEdition: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const infographicId = params.id;
  const search = new URLSearchParams(location.search);
  const [innerInfographic, setInnerInfographic] = useState<IInfographic>();

  const [language, setLanguage] = useState<SupportedLanguage>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );

  const [selectedTab, setSelectedTab] = useState<TabsType>("details");

  const [searchValue, setSearchValue] = useState<string>();
  const [searchArticleList, setSearchArticleList] = useState<IArticle[]>([]);
  const [searchVideoList, setSearchVideoList] = useState<IVideo[]>([]);

  const [form] = Form.useForm<IInfographic>();
  const languageField = Form.useWatch("language", form);
  const { TabPane } = Tabs;

  const fetchInfographic = async () => {
    if (infographicId && !innerInfographic) {
      const infographic = await getInfographic(infographicId);

      if (infographic) {
        setLanguage(infographic.language);
        setInnerInfographic(infographic);
      }
    }
  };

  const handleSearchChange = (
    value: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {
    event?.preventDefault();

    if (!value.length) {
      setSearchArticleList([]);
      setSearchVideoList([]);
    }
    setSearchValue(value);
  };

  const handleAssociatedArticleAdd = async (articleId: string) => {
    if (innerInfographic) {
      const updatedInfographic = await addInfographicArticle(
        innerInfographic._id,
        articleId
      );
      if (updatedInfographic) {
        setInnerInfographic({
          ...innerInfographic,
          associatedArticles: updatedInfographic.associatedArticles,
        });
      }
    }
  };

  const handleAssociatedArticleRemove = async (articleId: string) => {
    if (innerInfographic) {
      const updatedInfographic = await removeInfographicArticle(
        innerInfographic._id,
        articleId
      );
      if (updatedInfographic) {
        setInnerInfographic({
          ...innerInfographic,
          associatedArticles: updatedInfographic.associatedArticles,
        });
      }
    }
  };

  const handleAssociatedVideoAdd = async (videoId: string) => {
    if (!innerInfographic) return;

    try {
      const { associatedContent } = await addContentToContent(
        innerInfographic._id,
        videoId
      );

      setInnerInfographic({
        ...innerInfographic,
        associatedContent,
      });
      notification.success({
        message: "Success",
        description: "Video added to infographic",
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.response.data.message,
      });
    }
  };

  const handleAssociatedVideoRemove = async (videoId: string) => {
    if (!innerInfographic) return;

    try {
      const { associatedContent } = await removeContentFromContent(
        innerInfographic._id,
        videoId
      );

      setInnerInfographic({
        ...innerInfographic,
        associatedContent,
      });
      notification.success({
        message: "Success",
        description: "Video removed from infographic",
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.response.data.message,
      });
    }
  };

  useEffect(() => {
    const fetchArticles = async () => {
      const query = {
        limit: 10,
        offset: 0,
        language: language,
      };

      let articleList;

      if (searchValue) {
        articleList = await searchArticlesV2({
          ...query,
          status: "published",
          search: searchValue,
        });

        if (articleList.hits.hits) {
          setSearchArticleList(
            articleList.hits.hits.map(
              (article: { _source: { core: IArticle; id: string } }) => {
                return { ...article._source.core, _id: article._source.id };
              }
            )
          );
        }
      }
    };

    const fetchVideos = async () => {
      const query = {
        limit: 10,
        offset: 0,
        status: "published" as FiltersDrawerValuesModel["status"],
        language,
      };

      if (searchValue) {
        const videoList = await searchVideos(searchValue, query);

        if (videoList.hits) {
          setSearchVideoList(
            videoList.hits.map(
              (video: { _source: { core: IVideo; id: string } }) => {
                return { ...video._source.core, _id: video._source.id };
              }
            )
          );
        }
      }
    };

    if (selectedTab === "articles") fetchArticles();
    if (selectedTab === "videos") fetchVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, searchValue, selectedTab]);

  useEffect(() => {
    fetchInfographic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageField]);

  if (!innerInfographic && !location.pathname.includes("/create"))
    return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <EditionPageHeader
        form={form}
        format={ContentFormatsEnum.INFOGRAPHIC}
        content={innerInfographic}
        setContent={setInnerInfographic as PageHeaderSetContent}
      />

      <div className='site-layout-content'>
        <Tabs
          defaultActiveKey='1'
          onChange={(tab) => setSelectedTab(tab as TabsType)}
        >
          <TabPane tab='Details' key='details'>
            <InfographicEditionContentForm
              form={form}
              infographic={innerInfographic}
              setInfographic={setInnerInfographic}
            />
          </TabPane>
          <TabPane tab='Rooms' key='rooms'>
            {innerInfographic && (
              <RoomsAssociator
                contentId={innerInfographic._id}
                language={innerInfographic.language}
                type={ContentFormatsEnum.INFOGRAPHIC}
                isPublic={innerInfographic.isPublic}
                ownerId={innerInfographic.owner?._id}
                form={form}
              />
            )}
          </TabPane>

          <TabPane
            tab='Articles'
            key='articles'
            disabled={!innerInfographic?._id}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <Col span={11}>
                <h3 style={{ marginBottom: "20px" }}>Current articles</h3>
                {innerInfographic?.associatedArticles?.map(
                  (article: IArticle) => (
                    <Card key={article.slug} style={{ marginBottom: "20px" }}>
                      <p>{article.title}</p>
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: red.primary,
                          float: "right",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleAssociatedArticleRemove(article._id);
                        }}
                      >
                        Remove
                      </Button>
                    </Card>
                  )
                )}
              </Col>
              <Col span={11}>
                <h3 style={{ marginBottom: "20px" }}>Article list</h3>
                <Input.Search
                  placeholder='Search article'
                  allowClear
                  style={{
                    marginBottom: "20px",
                  }}
                  onSearch={handleSearchChange}
                />
                {searchArticleList?.map((article: IArticle) => (
                  <Card key={article.slug} style={{ marginBottom: "20px" }}>
                    <p>{article.title}</p>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='info-form-button'
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleAssociatedArticleAdd(article._id);
                      }}
                      disabled={innerInfographic?.associatedArticles.some(
                        (associatedArticle: IArticle) =>
                          associatedArticle._id === article._id
                      )}
                    >
                      Add new article
                    </Button>
                  </Card>
                ))}
              </Col>
            </Row>
          </TabPane>

          <TabPane tab='Videos' key='videos' disabled={!innerInfographic?._id}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <Col span={11}>
                <h3 style={{ marginBottom: "20px" }}>Current videos</h3>
                {innerInfographic?.associatedContent?.map((video: IContent) => (
                  <Card key={video._id} style={{ marginBottom: "20px" }}>
                    <p>{video.title}</p>
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: red.primary,
                        float: "right",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleAssociatedVideoRemove(video._id);
                      }}
                    >
                      Remove
                    </Button>
                  </Card>
                ))}
              </Col>
              <Col span={11}>
                <h3 style={{ marginBottom: "20px" }}>Videos list</h3>
                <Input.Search
                  placeholder='Search video'
                  allowClear
                  style={{
                    marginBottom: "20px",
                  }}
                  onSearch={handleSearchChange}
                />
                {searchVideoList?.map((video: IVideo) => (
                  <Card key={video._id} style={{ marginBottom: "20px" }}>
                    <p>{video.title}</p>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='info-form-button'
                      style={{ float: "right" }}
                      onClick={() => handleAssociatedVideoAdd(video._id)}
                      disabled={innerInfographic?.associatedContent.some(
                        (associatedArticle: IContent) =>
                          associatedArticle._id === video._id
                      )}
                    >
                      Add new video
                    </Button>
                  </Card>
                ))}
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
