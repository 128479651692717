import { useContext, useEffect, useRef } from "react";
import { Button, Card, Carousel, Form, Input, Row, Select, Tag } from "antd";
import { languageOptions, searchFilterOptions } from "~/utils/helpers";
import { DownloadOutlined } from "@ant-design/icons";
import { GlobalContext } from "~/context/global.context";
import { CarouselRef } from "antd/lib/carousel";
import { FormInstance } from "antd/es/form/Form";
import { VideoRequestInformationsContext } from "~/context/video-request.context";
import { Flex } from "~/components/shared/global";
import SelectTagsField from "~/components/SelectTagsField";
import { VideoRequestVersionColor } from "~/model/video.model";
import { ITag } from "~/model/tag.model";

type InnerProps = {
  formInstance: FormInstance;
  hasFile: boolean;
  loading: boolean;
};

export default function VideoRequestInformations({
  formInstance,
  hasFile,
  loading,
}: InnerProps) {
  const carouselRef = useRef<CarouselRef>(null);
  const { request, video } = useContext(VideoRequestInformationsContext);

  useEffect(() => {
    if (hasFile) {
      carouselRef.current?.goTo(1);
    } else {
      carouselRef.current?.goTo(0);
    }
  }, [hasFile]);

  if (!video || !request) return null;

  return (
    <Card
      style={{ height: "auto" }}
      title={
        <Flex align='center' gap={12}>
          <div>{"Video Informations"}</div>
          <Tag
            color={VideoRequestVersionColor[video.versionNumber || -1]}
          >{`v${[video.versionNumber]}`}</Tag>
        </Flex>
      }
    >
      <Carousel ref={carouselRef} adaptiveHeight>
        <DisplayRequestInformations />
        <FormRequest form={formInstance} loading={loading} />
      </Carousel>
    </Card>
  );
}

const DisplayRequestInformations = () => {
  const { video, request } = useContext(VideoRequestInformationsContext);

  if (!video || !request) return null;

  return (
    <Flex flexDirection='column' gap={16}>
      <div>
        <b>{"Language: "}</b>
        {languageOptions.find((lng) => lng.value === video.language)?.label ||
          "N/A"}
      </div>
      <div>
        <b>{"Title: "}</b>
        {video.title || "N/A"}
      </div>
      <div>
        <b>{"DOI: "}</b>
        {video.doi ? (
          <a href={video.doi} target='_blank'>
            {video.doi}
          </a>
        ) : (
          "N/A"
        )}
      </div>
      <Button
        icon={<DownloadOutlined />}
        type='link'
        href={request.pdf.url}
        target='_blank'
        style={{ padding: 0 }}
      >
        {"Download PDF"}
      </Button>
      <Flex gap={8}>
        <b style={{ whiteSpace: "nowrap" }}>{"Medical specialties: "}</b>
        <Row gutter={[8, 8]}>
          {video?.medicalSpecialties?.map((ms) => (
            <Tag>{(ms as ITag).translations.en}</Tag>
          ))}
        </Row>
      </Flex>
      <Flex gap={8}>
        <b>{"Tags: "}</b>
        <Row gutter={[8, 8]}>
          {video?.tags?.map((tag) => (
            <Tag>
              {(tag as ITag).translations.en}
              <small>{` (${
                (
                  video.medicalSpecialties?.find(
                    (ms) =>
                      (ms as ITag)._id ===
                      ((tag as ITag).parent as unknown as string)
                  ) as ITag
                )?.translations?.en
              })`}</small>
            </Tag>
          ))}
        </Row>
      </Flex>
      <Flex gap={8}>
        <b>{"Keywords: "}</b>
        <Row gutter={[8, 8]}>
          {video?.keywords?.map((kw) => (
            <Tag>{kw}</Tag>
          ))}
        </Row>
      </Flex>
    </Flex>
  );
};

const FormRequest = ({
  form,
  loading,
}: {
  form: FormInstance;
  loading: boolean;
}) => {
  const { tagList } = useContext(GlobalContext);
  const { request, video } = useContext(VideoRequestInformationsContext);

  if (!video || !request) return null;

  return (
    <Form
      form={form}
      initialValues={{
        ...video,
        medicalSpecialties: video.medicalSpecialties?.map(
          (spe) => (spe as ITag)._id
        ),
        tags: video.tags?.map((tag) => (tag as ITag)._id),
      }}
      disabled={loading}
    >
      <Form.Item name='title' label='Title' required>
        <Input placeholder='Video title' />
      </Form.Item>
      <Form.Item name='doi' label='DOI' required>
        <Input placeholder='https://doi.example.com' />
      </Form.Item>
      <Form.Item name='medicalSpecialties' label='Medical specialties' required>
        <Select
          allowClear
          mode='multiple'
          placeholder='Select medical specialties'
          options={tagList
            .filter((t) => !t.parent)
            .map((tag) => ({
              label: tag.translations.en,
              value: tag._id,
            }))}
          filterOption={searchFilterOptions}
        />
      </Form.Item>
      <Form.Item name='tags' label='Tags' required>
        <SelectTagsField form={form} medicalSpecialtyKey='medicalSpecialties' />
      </Form.Item>
      <Form.Item name='keywords' label='Keywords' required>
        <Select
          allowClear
          mode='tags'
          placeholder='Enter keywords'
          filterOption={searchFilterOptions}
          tokenSeparators={[","]}
          notFoundContent={null}
        />
      </Form.Item>
    </Form>
  );
};
