import { createContext, ReactNode, useState } from "react";
import { IVideoRequest, VideoRequestVideoModel } from "~/model/video.model";

type VideoRequestInformationContextType = {
  video: VideoRequestVideoModel | null;
  request: IVideoRequest | null;
  setVideo: (video: VideoRequestVideoModel | null) => void;
  setRequest: (request: IVideoRequest | null) => void;
};

export const VideoRequestInformationsContext =
  createContext<VideoRequestInformationContextType>({
    video: null,
    request: null,
    setVideo: () => {},
    setRequest: () => {},
  });

export const VideoRequestInformationsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [video, setVideo] = useState<VideoRequestVideoModel | null>(null);
  const [request, setRequest] = useState<IVideoRequest | null>(null);

  return (
    <VideoRequestInformationsContext.Provider
      value={{ video, request, setVideo, setRequest }}
    >
      {children}
    </VideoRequestInformationsContext.Provider>
  );
};
