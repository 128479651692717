import { createContext } from "react";
import { ICompany } from "~/model/company.model";
import { IJournal } from "~/model/journal.model";
import { MonitoringReducerModel } from "~/model/monitoring.model";
import { INectarHighlight } from "~/model/nectar.model";
import { IOrganisation } from "~/model/organisation.model";
import { IRoom } from "~/model/room.model";
import { ITag } from "~/model/tag.model";
import { IUserAdmin } from "~/model/user.model";

export interface IGlobalContext {
  user: IUserAdmin | null;
  updateUser: (logout?: boolean) => void;
  companyList: ICompany[];
  journalList: IJournal[];
  tagList: ITag[];
  organisationList: IOrganisation[];
  highlightNectars: INectarHighlight[] | null;
  roomList: IRoom[] | null;
  monitoring: MonitoringReducerModel | null;
}

const defaultGlobalContext: IGlobalContext = {
  user: null,
  updateUser: () => {
    return;
  },
  companyList: [],
  journalList: [],
  tagList: [],
  organisationList: [],
  highlightNectars: null,
  roomList: null,
  monitoring: null,
};

export const GlobalContext =
  createContext<IGlobalContext>(defaultGlobalContext);
