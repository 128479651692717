import { Tag, Tooltip } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { VideoRequestStatus } from "~/model/enum";

const StatusTag = ({ status }: { status: VideoRequestStatus }) => {
  switch (status) {
    case VideoRequestStatus.WAITING:
      return (
        <Tag color='orange' icon={<SyncOutlined spin />}>
          {"In progress"}
        </Tag>
      );

    case VideoRequestStatus.WAITING_VALIDATION:
      return (
        <Tag color='blue' icon={<WarningOutlined />}>
          {"Need validation"}
        </Tag>
      );

    case VideoRequestStatus.REJECTED:
      return (
        <Tag color='red' icon={<CloseOutlined />}>
          {"Rejected"}
        </Tag>
      );

    case VideoRequestStatus.CANCELED:
      return (
        <Tag color='purple' icon={<StopOutlined />}>
          {"Canceled"}
        </Tag>
      );

    case VideoRequestStatus.DONE:
      return (
        <Tag color='green' icon={<CheckOutlined />}>
          {"Done"}
        </Tag>
      );

    default:
      break;
  }
};

type InnerProps = {
  status: VideoRequestStatus;
  showDescription?: boolean;
  showTootltip?: boolean;
};
const VideoRequestStatusTag = ({
  status,
  showDescription = false,
  showTootltip = false,
}: InnerProps) => {
  let description = "The video has been reviewed and is ready to be used";

  switch (status) {
    case VideoRequestStatus.WAITING:
      description = "The request is waiting for a process by our content team";
      break;

    case VideoRequestStatus.WAITING_VALIDATION:
      description = "Waiting for validation from the client";
      break;

    case VideoRequestStatus.REJECTED:
      description = "The video request has been rejected, see the details.";
      break;

    case VideoRequestStatus.CANCELED:
      description = "The video request has been canceled.";
      break;

    default:
      description = "The video has been reviewed and is ready to be used";
      break;
  }

  if (showDescription)
    return (
      <div>
        <StatusTag status={status} />
        <p
          style={{
            marginTop: 8,
            fontWeight: 400,
            fontSize: 12,
            marginBottom: 0,
          }}
        >
          {description}
        </p>
      </div>
    );

  if (showTootltip)
    return (
      <Tooltip title={description}>
        <div>
          <StatusTag status={status} />
        </div>
      </Tooltip>
    );

  return <StatusTag status={status} />;
};
export default VideoRequestStatusTag;
