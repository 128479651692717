import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, notification, Tag, Tooltip } from "antd";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex } from "~/components/shared/global";
import { VideoRequestInformationsContext } from "~/context/video-request.context";
import { VideoRequestStatus } from "~/model/enum";
import { rejectVideoRequest, validateVideoRequest } from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";

export default function VideoRequestReviewHeader() {
  const navigate = useNavigate();
  const { id: requestId } = useParams();
  const { request, video, setRequest } = useContext(
    VideoRequestInformationsContext
  );
  const [form] = Form.useForm();

  async function handleValidateRequest() {
    if (!requestId || !request) return;

    Modal.confirm({
      title: "Send request to validation",
      content: "Are you sure you want to send this request to validation?",
      onOk: async () => {
        try {
          const res = await validateVideoRequest(requestId);
          notification.success({
            message: "Request validated",
            description: "The request has been validated successfully",
          });
          setRequest({
            ...request,
            status: res.status,
          });
        } catch (error) {
          notification.error({
            message: "An error occured",
            description: getRequestErrorMessage(error),
          });

          return error;
        }
      },
    });
  }

  async function handleCancelRequest() {
    try {
      if (!requestId || !video) throw new Error("Invalid request or video");

      await rejectVideoRequest(requestId, form.getFieldValue("reason"));

      navigate("/content-management/video/requests");

      notification.success({
        message: "Request canceled",
        description: "The request has been canceled successfully",
      });
    } catch (error) {
      notification.error({
        message: "Cancel request failed",
        description: getRequestErrorMessage(error),
      });
    }
  }

  function triggerCancelModal() {
    Modal.confirm({
      title: "Are you sure you want to cancel this request?",
      content: (
        <Form form={form} layout='vertical' onFinish={handleCancelRequest}>
          <Form.Item name='reason' label='Reject cause'>
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 10 }}
              placeholder='Please provide a reason why the request has to be rejected.'
            />
          </Form.Item>
        </Form>
      ),
      onOk: () => form.submit(),
      okText: "Reject",
      okButtonProps: {
        danger: true,
        type: "primary",
      },
    });
  }

  if (!request || !video) return null;

  const canValidateRequest = !request.videos.some((v) => !v.videoId);

  return (
    <div>
      {request?.status === VideoRequestStatus.WAITING ? (
        <Flex align='center' gap={8} justify='end'>
          {canValidateRequest && (
            <Tooltip title='Send the request to be validated from the Client'>
              <Button
                style={{
                  background: "rgb(22, 163, 74)",
                  borderColor: "rgb(22, 163, 74)",
                  color: "white",
                }}
                icon={<CheckOutlined />}
                onClick={handleValidateRequest}
              >
                {"Send to validation"}
              </Button>
            </Tooltip>
          )}
          <Button
            danger
            type='primary'
            icon={<CloseOutlined />}
            onClick={triggerCancelModal}
          >
            {"Reject request"}
          </Button>
        </Flex>
      ) : request?.status === VideoRequestStatus.WAITING_VALIDATION ? (
        <Tag icon={<LoadingOutlined spin />} color='blue'>
          {"Need Validation from Client"}
        </Tag>
      ) : (
        <Tag color='red'>{`Declined (${request?.status})`}</Tag>
      )}
    </div>
  );
}
