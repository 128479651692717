import { useContext, useState } from "react";
import { notification, Select, Spin } from "antd";
import { GlobalContext } from "~/context/global.context";

import { associateUserToRoom, dissociateUserFromRoom } from "~/services";
import { IUser } from "~/model/user.model";

type InnerProps = {
  user: IUser;
};
const UserRoomsAssociator = ({ user }: InnerProps) => {
  const { roomList } = useContext(GlobalContext);
  const [innerRooms, setInnerRooms] = useState<string[]>(
    user?.rooms as string[]
  );
  const [loading, setLoading] = useState(false);

  const fetchAssociateToRoom = async (roomId: string) => {
    setLoading(true);

    try {
      if (!user) throw new Error("User is required");
      if (!roomId) throw new Error("Room is required");

      await associateUserToRoom({
        roomId: roomId,
        userId: user._id,
      });

      notification.success({
        message: "Success",
        description: "User has been associated to the room",
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: (error as Error)?.message || "An error occurred",
      });
    }
    setLoading(false);
  };

  const fetchDissociateFromRoom = async (roomId: string) => {
    setLoading(true);

    try {
      if (!user) throw new Error("User is required");

      await dissociateUserFromRoom({
        roomId,
        userId: user._id,
      });

      notification.success({
        message: "Success",
        description: "User has been dissociated from the room",
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: (error as Error)?.message || "An error occurred",
      });
    }
    setLoading(false);
  };

  const handleChange = (values: string[]) => {
    if (!innerRooms) {
      fetchAssociateToRoom(values[0]);
    } else if (values?.length > innerRooms?.length) {
      const addedTag = values.find((tag) => !innerRooms.includes(tag));
      if (addedTag) fetchAssociateToRoom(addedTag);
    } else {
      const removedTag = innerRooms?.find((tag) => !values.includes(tag));
      if (removedTag) fetchDissociateFromRoom(removedTag);
    }
    setInnerRooms(values);
  };

  if (!roomList) return <Spin style={{ width: "100%" }} />;

  return (
    <Select
      mode='tags'
      loading={loading}
      disabled={loading}
      options={roomList.map((room) => ({ value: room.id, label: room.name }))}
      placeholder='Select rooms'
      onChange={handleChange}
      defaultValue={innerRooms}
      style={{ width: 200 }}
    />
  );
};

export default UserRoomsAssociator;
