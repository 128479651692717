import { IMapping } from ".";

export const createJournalMapping: { [key: string]: IMapping } = {
  name: {
    label: "Name",
    type: "text",
    required: true,
  },
  description: {
    label: "Description",
    type: "textarea",
    required: true,
  },
  impact_factor: {
    label: "Impact factor",
    type: "text",
    required: true,
  },
  impact_score: {
    label: "Impact score",
    type: "text",
    required: false,
  },
  h_index: {
    label: "H-index",
    type: "text",
    required: false,
  },
  sjr: {
    label: "SJR",
    type: "text",
    required: false,
  },
};
