import { Column, ColumnConfig } from "@ant-design/plots";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
import { Flex } from "~/components/shared/global";

type InnerProps = {
  title: string;
  icon: React.ReactNode;
  data: { label: string; value: number }[];
  color?: string;
  link?: string;
};

const CustomColumnsPlot = ({
  title,
  icon,
  data = [],
  color = "#FFAA00",
  link = undefined,
}: InnerProps) => {
  function groupWordsByLength(words: string[], maxLength: number) {
    const result = [];
    let currentGroup: string[] = [];
    let currentLength = 0;

    words?.forEach((word) => {
      const wordLength = word.length;

      if (currentLength + wordLength > maxLength) {
        result.push(currentGroup);
        currentGroup = [word];
        currentLength = wordLength;
      } else {
        currentGroup.push(word);
        currentLength += wordLength;
      }
    });

    if (currentGroup.length > 0) {
      result.push(currentGroup);
    }

    return result.map((group) => group.join(" "));
  }

  const config: ColumnConfig = {
    data,
    xField: "label",
    yField: "value",
    colorField: color,
    axis: {
      x: {
        labelFormatter: (v: string) => {
          const words = v?.split(" ");

          const grouped = groupWordsByLength(words, 20)
            .join("\n")
            .replace(",", " ");

          return grouped;
        },
        labelFontSize: 8,
        labelAutoHide: true,
        labelAutoEllipsis: true,
      },
    },
    width: data.length * 100,
  };

  return (
    <Card
      title={
        <Flex align='center' justify='space-between'>
          <Flex gap={8} align='center'>
            {icon}
            <div>{title}</div>
          </Flex>
          {link && (
            <Link to={link}>
              <Button type='link'>{"View all"}</Button>
            </Link>
          )}
        </Flex>
      }
    >
      <div
        style={{
          width: "100%",
          overflow: "scroll",
        }}
      >
        <Column {...config} />
      </div>
    </Card>
  );
};

export default CustomColumnsPlot;
