import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Layout,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";

import { listCongress } from "../services";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/global.context";
import { ColumnsType } from "antd/lib/table";
import { languageOptions } from "../utils/helpers";
import { ICongress } from "~/model/congress.mode";
import { SupportedLanguage } from "~/model/enum";
import { IQuery } from "~/model/query.model";

export const CongressList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const { organisationList } = useContext(GlobalContext);
  const [innerCongressList, setInnerCongressList] = useState<ICongress[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [language, setLanguage] = useState<SupportedLanguage>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [organisation, setOrganisation] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchCongressList = async (limit?: number, offset?: number) => {
    setIsLoading(true);
    const query: IQuery = {
      limit: limit || 10,
      offset: offset || 0,
      language,
      organisation,
    };
    const congressList = await listCongress(query);
    setInnerCongressList(congressList.docs);
    setCount(congressList.meta.total);
    setIsLoading(false);
  };

  const handleLanguageChange = (language: SupportedLanguage) => {
    navigate(`/reference-data/congress?language=${language}`);
    setLanguage(language);
  };

  const handleClick = () => {
    navigate(`/reference-data/congress/create/?language=${language}`);
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchCongressList(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
    }
    setPage(page);
  };

  useEffect(() => {
    fetchCongressList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, organisation]);

  const columns: ColumnsType<ICongress> = [
    {
      title: "Name",
      dataIndex: "_id",
      key: "_id",
      render: (text: string) => {
        // const congress = _.find(innerCongressList, { _id: text });
        const congress = innerCongressList.find((v) => v._id === text);

        if (congress) {
          return (
            <Link to={`/reference-data/congress/${congress._id}`}>
              {congress.name}
            </Link>
          );
        }
      },
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row justify='space-between' style={{ margin: "20px 0" }}>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Congresses</h1>
          <div>
            <Select
              defaultValue={language}
              style={{ marginRight: 10 }}
              onSelect={handleLanguageChange}
              options={languageOptions}
            />
            <Button type='primary' onClick={handleClick}>
              New congress
            </Button>
          </div>
        </Row>
        <Row gutter={8}>
          <Col span={20}>
            <Input.Search
              placeholder='Search congress'
              allowClear
              onSearch={setSearchValue}
            />
          </Col>
          <Col span={4}>
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder='Select organisation'
              options={organisationList.map((organisation) => ({
                value: organisation._id,
                label: organisation.name,
              }))}
              onChange={setOrganisation}
            />
          </Col>
        </Row>
        <div style={{ height: 16 }} />
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={innerCongressList.filter((v) =>
            v.name.toLowerCase().includes(searchValue?.toLowerCase() ?? "")
          )}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          sticky
          pagination={false}
        />
        <Pagination
          style={{ marginTop: "10px" }}
          onChange={handlePaginationChange}
          total={count}
          current={page}
          defaultCurrent={1}
          defaultPageSize={10}
          pageSizeOptions={["10", "20"]}
        />
      </Layout.Content>
    </Layout>
  );
};
