import styled from "styled-components";

type InnerProps = {
  title: string | number;
  subtitle?: string | number | React.ReactNode;
  size?: "small" | "large";
  icon?: React.ReactNode;
  label?: React.ReactNode;
  backgroundColor?: string;
  align?: "center" | "left" | "right";
};
export default function MetricCard({
  icon,
  title,
  subtitle,
  label,
  backgroundColor,
  size = "small",
  align = "left",
}: InnerProps) {
  return (
    <StyledWrapper
      className={`align-${align}`}
      style={{
        background: backgroundColor || "#FFFFFF",
        border: backgroundColor ? "none" : "1px solid #90A4AE",
      }}
    >
      <div className='card-icon'>{icon}</div>
      <div className='label'>{label}</div>
      <div className={`title ${size}`}>{title}</div>
      {subtitle && <div className='subtitle'>{subtitle}</div>}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  padding: 26px;
  border-radius: 16px;
  height: 100%;
  width: 100%;

  .card-icon {
    display: flex;

    svg {
      font-size: 24px;
      color: #212;
    }
  }

  a {
    color: #ce0868;
    text-decoration: underline;
  }

  .title {
    font-family: "Poppins";
    font-weight: 600;
    line-height: 140%;
    text-align: left;
    color: #212121;

    &.small {
      font-size: 38px;
      margin-top: 4px;
    }
    &.large {
      font-size: 64px;
    }
  }

  .subtitle {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    color: #212121;
  }

  .label {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    color: #212121;
    white-space: pre-line;

    span {
      color: #ce0868;
    }
  }

  &.align-right {
    text-align: right;
    justify-content: end;

    .title,
    .subtitle,
    .label {
      text-align: right;
    }

    .card-icon {
      justify-content: end;
    }
  }
`;
