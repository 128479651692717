import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Table,
  notification,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext, useState } from "react";
import { GlobalContext } from "~/context/global.context";

import { Flex } from "./shared/global";
import { updateOrganisationFeature } from "~/services";
import axios from "axios";
import { fetchData } from "~/store/actions";
import { FETCH_ORGANISATIONS } from "~/store/types";
import { useDispatch } from "react-redux";
import { FeatureEnum } from "~/model/enum";
import { IOrganisation } from "~/model/organisation.model";

const LIMITED_FEATURES = [FeatureEnum.Videos, FeatureEnum.JuisciGPT];

const FeaturesManager = () => {
  const dispatch = useDispatch();
  const { organisationList } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [selectedOrganisation, setSelectedOrganisation] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    setIsSubmitting(true);

    const body = Object.keys(FeatureEnum)
      .map((feature) => ({
        feature: feature as FeatureEnum,
        enabled: values[`${feature.toLowerCase()}-checked`],
        limit: values[`${feature.toLowerCase()}-limit`],
      }))
      .filter((f) => f.enabled !== undefined || f.limit !== undefined)
      .map((f) => ({
        feature: f.feature,
        enabled:
          f.enabled !== undefined
            ? f.enabled
            : organisationList
                .find((o) => o._id === selectedOrganisation)
                ?.organisationSettings.features.find(
                  (f2) => f2.name === f.feature
                )?.enable,
        limit:
          f.limit !== undefined
            ? f.limit
            : organisationList
                .find((o) => o._id === selectedOrganisation)
                ?.organisationSettings.features.find(
                  (f2) => f2.name === f.feature
                )?.limit,
      }))
      .map((f) => ({
        feature: f.feature as FeatureEnum,
        enabled: f.enabled as boolean,
        limit: (f.limit?.length ? f.limit : undefined) as number | undefined,
      }));

    let isError = false;

    for (const feat of body) {
      try {
        await updateOrganisationFeature(selectedOrganisation, feat);
      } catch (error) {
        if (axios.isAxiosError(error))
          notification.error({
            message: "An error occurred while updating organisation features",
            description: error?.response?.data.message,
          });

        isError = true;
      }
    }

    if (!isError && body.length > 0)
      notification.success({
        message: "Organisation features updated successfully",
      });

    dispatch(fetchData(FETCH_ORGANISATIONS));

    setIsSubmitting(false);
    form.resetFields();
    setSelectedOrganisation("");
  };

  const columns: ColumnsType<IOrganisation> = [
    {
      title: "Organisation",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    ...Object.keys(FeatureEnum).map((feature) => ({
      title: feature,
      dataIndex: feature,
      key: feature,
      width: 100,
      align: "center" as unknown as "center",
      render: (_: unknown, org: IOrganisation) => (
        <Checkbox
          style={{ pointerEvents: "none" }}
          checked={
            org.organisationSettings.features.find((f) => f.name === feature)
              ?.enable
          }
        />
      ),
    })),
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 150,

      render: (_: unknown, org: IOrganisation) => (
        <Button
          type='ghost'
          icon={<EditOutlined />}
          onClick={() => setSelectedOrganisation(org._id)}
          style={{ width: "100%" }}
        >
          {"Edit access"}
        </Button>
      ),
    },
  ];

  return (
    <div className='basic-layout'>
      <h1>Features Manager</h1>
      <Table
        bordered
        columns={columns}
        dataSource={organisationList.map((org) => ({
          ...org,
          key: org._id,
        }))}
        scroll={{ y: "calc(100dvh - 250px)" }}
        pagination={{ pageSize: 50 }}
      />
      <Modal
        title={
          "Edit access for " +
          organisationList.find((o) => o._id === selectedOrganisation)?.name
        }
        open={!!selectedOrganisation}
        onCancel={() => setSelectedOrganisation("")}
        footer={null}
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            ...Object.keys(FeatureEnum).map((feature) => ({
              [`${feature.toLowerCase()}-checked`]: organisationList
                .find((o) => o._id === selectedOrganisation)
                ?.organisationSettings.features.find((f) => f.name === feature)
                ?.enable,
              [`${feature.toLowerCase()}-limit`]: organisationList
                .find((o) => o._id === selectedOrganisation)
                ?.organisationSettings.features.find((f) => f.name === feature)
                ?.limit,
            })),
          }}
        >
          {Object.keys(FeatureEnum).map((feature) => (
            <Flex
              justify='space-between'
              align='center'
              key={feature + "modal-form-item"}
            >
              <Form.Item name={`${feature.toLowerCase()}-checked`}>
                <Checkbox
                  defaultChecked={
                    organisationList
                      .find((o) => o._id === selectedOrganisation)
                      ?.organisationSettings.features.find(
                        (f) => f.name === feature
                      )?.enable
                  }
                  onChange={(e) => {
                    form.setFieldsValue({
                      [`${feature.toLowerCase()}-checked`]: e.target.checked,
                    });
                  }}
                >
                  {feature}
                </Checkbox>
              </Form.Item>
              {LIMITED_FEATURES.includes(feature as FeatureEnum) ? (
                <Form.Item name={`${feature.toLowerCase()}-limit`}>
                  <Input
                    placeholder='Limit'
                    type='number'
                    min={1}
                    max={100}
                    style={{ width: 100 }}
                    defaultValue={
                      organisationList
                        .find((o) => o._id === selectedOrganisation)
                        ?.organisationSettings.features.find(
                          (f) => f.name === feature
                        )?.limit
                    }
                  />
                </Form.Item>
              ) : (
                <div />
              )}
            </Flex>
          ))}
          <Divider />
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              block
              loading={isSubmitting}
            >
              {"Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FeaturesManager;
