import moment from "moment";
import VideoRequestStatusTag from "~/components/VideoRequestStatusTag";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { languageLabel } from "~/utils/helpers";
import { Tag } from "antd";
import { Flex } from "~/components/shared/global";
import { IVideoRequest, VideoRequestVersionColor } from "~/model/video.model";

const columns: ColumnsType<IVideoRequest> = [
  {
    title: "Request name",
    dataIndex: "name",
    key: "name",
    width: 400,
    render: (text, record) => (
      <Link to={`/content-management/video/requests/${record._id}`}>
        {text}
      </Link>
    ),
  },
  {
    title: "Organisation",
    dataIndex: ["organisation", "name"],
    key: "organisation",
    width: 200,
  },
  {
    title: "Language",
    key: "language",
    width: 120,
    render: (_, record) => (
      <Flex flexDirection='column' align='center' gap={8}>
        {record.videos.map((video) => (
          <Tag>{languageLabel[video.language]}</Tag>
        ))}
      </Flex>
    ),
  },
  {
    title: "Creation Date",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    render: (text) => moment(new Date(text)).format("MM/DD/YYYY"),
  },
  {
    title: "Version",
    key: "version",
    width: 100,
    render: (_, request) => (
      <Flex flexDirection='column' align='center' gap={8}>
        {request.videos.map((video) => (
          <Tag
            key={request._id + video.language}
            color={VideoRequestVersionColor[Number(video.versionNumber)]}
          >{`v${video.versionNumber} (${languageLabel[video.language]})`}</Tag>
        ))}
      </Flex>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (text) => (
      <VideoRequestStatusTag
        status={text}
        showDescription={false}
        showTootltip={true}
      />
    ),
  },
  {
    title: "PDF File",
    dataIndex: "pdf",
    key: "pdf",
    render: (_, record) => (
      <a href={record.pdf.url} target='_blank' download={record._id + ".pdf"}>
        Download PDF
      </a>
    ),
  },
];

export default columns;
