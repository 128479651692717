import { CloseOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Input, Modal, notification, Tag, Tooltip } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "~/components/shared/global";
import { VideoRequestInformationsContext } from "~/context/video-request.context";
import {
  FeedbackStatusEnum,
  SupportedLanguage,
  VideoRequestStatus,
} from "~/model/enum";
import { IVideoRequestFeedback } from "~/model/video.model";
import { deleteFeedbackVideoRequest, feedbackVideoRequest } from "~/services";
import { getRequestErrorMessage, secondsToMMSS } from "~/utils/helpers";

type VideoRequestReviewCommentsProps = {
  videoTimer: number;
};

export default function VideoRequestReviewComments({
  videoTimer,
}: VideoRequestReviewCommentsProps) {
  const { id: requestId } = useParams();
  const { video, request, setVideo, setRequest } = useContext(
    VideoRequestInformationsContext
  );
  const [commentList, setCommentList] = useState<IVideoRequestFeedback[]>(
    video?.feedbacks || []
  );
  const [currentComment, setCurrentComment] = useState<string>("");
  const [showDone, setShowDone] = useState(false);

  const totalDone = video?.feedbacks
    ? [...video.feedbacks]?.filter((c) => c.status === FeedbackStatusEnum.DONE)
        ?.length
    : 0;

  const totalCancel = video?.feedbacks
    ? [...video.feedbacks]?.filter(
        (c) => c.status === FeedbackStatusEnum.CANCEL
      )?.length
    : 0;

  async function handleSendFeedback() {
    if (!currentComment) return;

    const feedback = {
      message: currentComment || "",
      timer: secondsToMMSS(videoTimer),
      language: video?.language as SupportedLanguage,
      requestId: requestId || "",
    };

    const _feedback = {
      ...feedback,
      status: FeedbackStatusEnum.NEW,
    } as unknown as IVideoRequestFeedback;

    setCommentList([...commentList, _feedback]);

    const newRequest = await feedbackVideoRequest(feedback);
    notification.success({
      message: "Feedback sent!",
      description: "Your feedback has been sent successfully",
    });

    setVideo(
      newRequest.videos.find((v) => v.language === video?.language) || null
    );
    setRequest(newRequest);
    setCurrentComment("");
  }

  async function handleDeleteFeedback(feedbackId: string) {
    if (!requestId) return;

    Modal.confirm({
      title: "Are you sure you want to delete this comment?",
      onOk: async () => {
        try {
          const newRequest = await deleteFeedbackVideoRequest({
            requestId,
            feedbackId,
          });

          setRequest(newRequest);

          notification.success({
            message: "Success",
            description: "Comment deleted successfully",
          });
        } catch (error) {
          notification.error({
            message: "Error",
            description:
              getRequestErrorMessage(error) ||
              "An error occurred while deleting the comment",
          });
        }
      },
    });
  }

  useEffect(() => {
    setCommentList(video?.feedbacks || []);
  }, [video]);
  return (
    <div>
      <div onClick={() => console.log(video)}>debug</div>
      <StyledCard
        title={
          <Flex align='center' justify='space-between'>
            <div>{"Comments"}</div>
            <Button
              size='small'
              type='link'
              onClick={() => setShowDone(!showDone)}
            >
              {showDone
                ? "Hide done comments"
                : `Show ${totalDone} done comments`}
            </Button>
          </Flex>
        }
      >
        {commentList
          ? commentList
              ?.filter((c) => c.status !== FeedbackStatusEnum.CANCEL)
              ?.filter((c) =>
                showDone ? true : c.status !== FeedbackStatusEnum.DONE
              )
              .map((feedback) => (
                <div
                  key={feedback._id}
                  className={`comment ${
                    feedback.authorRole === "juisci" ? "isJuisci" : "isClient"
                  } ${feedback.status.toLowerCase()}  
                `}
                >
                  <div style={{ width: "100%" }}>
                    <Flex
                      align='center'
                      justify='space-between'
                      className='header'
                    >
                      <div>
                        <Tag color='volcano'>{feedback.timer || "00:00"}</Tag>
                        <Tooltip
                          title={moment(feedback.createdAt).format(
                            "YYYY/MM/DD [at] HH:mm"
                          )}
                        >
                          <span>{moment(feedback.createdAt).fromNow()}</span>
                        </Tooltip>
                      </div>
                      {feedback.authorRole === "juisci" && (
                        <Button
                          shape='circle'
                          icon={<CloseOutlined />}
                          size='small'
                          onClick={() => handleDeleteFeedback(feedback._id)}
                        />
                      )}
                    </Flex>
                    <p className='msg-content'>{feedback.message}</p>
                    <small>
                      {feedback.status === FeedbackStatusEnum.DONE
                        ? "✅ Done"
                        : "⏳ In progress"}
                    </small>
                  </div>
                </div>
              ))
          : "No comments yet"}
        {totalDone + totalCancel === video?.feedbacks?.length && !showDone && (
          <p className='no-comment-text'>
            {"No pending comments for the moment..."}
          </p>
        )}
      </StyledCard>
      <Card
        style={{ borderTop: 0 }}
        bodyStyle={{
          padding: 16,
          display:
            request &&
            ![
              VideoRequestStatus.CANCELED,
              VideoRequestStatus.REJECTED,
            ].includes(request.status)
              ? "block"
              : "none",
        }}
      >
        <Input.TextArea
          placeholder='Add a comment'
          onChange={(e) => setCurrentComment(e.target.value)}
          value={currentComment}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
        <Button
          icon={<SendOutlined />}
          type='primary'
          style={{ marginTop: 8, float: "right" }}
          onClick={handleSendFeedback}
        >
          {"Send comment"}
        </Button>
      </Card>
    </div>
  );
}

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .comment {
    border: 1px solid #f0f0f0;
    padding: 16px;
    margin: 8px 0;
    width: 90%;

    &.isJuisci {
      background: #fff3ce;
      border-radius: 8px 8px 0 8px;
      align-self: flex-end;
    }

    &.isClient {
      background: #f0f0f0;
      border-radius: 8px 8px 8px 0;
    }

    &.done {
      opacity: 0.5;
    }

    .header {
      font-size: 11px;
      color: #667;
      padding-bottom: 12px;
    }

    .msg-content {
      margin: 8px 0;
      white-space: pre-wrap;
    }
    .no-comment-text {
      margin: 8px;
      text-align: center;
      font-style: italic;
      opacity: 0.5;
    }
  }
`;
