import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import EditionPageHeader, {
  PageHeaderSetContent,
} from "~/components/EditionPageHeader.tsx";
import { GlobalContext } from "~/context/global.context";
import { ContentFormatsEnum } from "~/model/enum";
import { INectar } from "~/model/nectar.model";
import { addNectarHightlights, deleteNectarHightlights } from "~/services";
import { fetchData } from "~/store/actions";
import { FETCH_HIGHLIGHT_NECTARS } from "~/store/types";
import { getRequestErrorMessage } from "~/utils/helpers";

type NectarEditionHeaderProps = {
  form: FormInstance;
  nectar: INectar | null;
  setNectar: (nectar: INectar) => void;
};

export default function NectarEditionHeader({
  form,
  nectar,
  setNectar,
}: NectarEditionHeaderProps) {
  const dispatch = useDispatch();

  const { highlightNectars } = useContext(GlobalContext);

  const isHighlighted = highlightNectars?.find((hlt) =>
    hlt.highlight.find((ntr) => ntr.nectarId === nectar?._id)
  );

  async function handlePinHighlight() {
    if (!nectar) return;

    try {
      if (isHighlighted) {
        await deleteNectarHightlights(nectar._id);
        dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));

        notification.success({
          message: "Nectar un-highlighted",
          description: "The nectar has been un-highlighted successfully",
        });
      } else {
        await addNectarHightlights(nectar._id);
        dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));

        notification.success({
          message: "Nectar highlighted",
          description: "The nectar has been highlighted successfully",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error highlighting nectar",
        description: getRequestErrorMessage(error),
      });
    }
  }

  return (
    <EditionPageHeader
      form={form}
      format={ContentFormatsEnum.NECTAR}
      content={nectar || undefined}
      setContent={setNectar as PageHeaderSetContent}
    >
      <Button
        type={isHighlighted ? "primary" : "default"}
        icon={isHighlighted ? <StarFilled /> : <StarOutlined />}
        onClick={handlePinHighlight}
      >
        {isHighlighted ? "Un-highlight" : "Highlight"}
      </Button>
    </EditionPageHeader>
  );
}
