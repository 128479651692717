import { useContext, useEffect, useState } from "react";
import { Card, Form, Tabs } from "antd";
import { getNectarById } from "../../../services";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/global.context";
import LoadingLayout from "../../../components/shared/LoadingLayout";
import { useDispatch } from "react-redux";
import { fetchData } from "../../../store/actions";
import { FETCH_TAGS } from "../../../store/types";
import RoomsAssociator from "../../../components/shared/RoomsAssociator";
import NectarEditionHeader from "./NectarEditionHeader";
import NectarEditionContentForm from "./NectarEditionContentForm";
import { INectar } from "~/model/nectar.model";
import { ContentFormatsEnum } from "~/model/enum";

const NectarEdition = () => {
  const { id: nectarId } = useParams();
  const dispatch = useDispatch();
  const { tagList } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [nectar, setNectar] = useState<INectar | null>(null);

  useEffect(() => {
    const fetchNectar = async () => {
      if (!nectarId || nectar) return;

      const res = await getNectarById(nectarId);
      setNectar({
        ...res,
        associatedArticles: res.associatedArticles.filter(
          (article, index, self) =>
            index === self.findIndex((t) => t._id === article._id)
        ),
      });
    };

    if (!tagList.length) dispatch(fetchData(FETCH_TAGS));
    fetchNectar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nectar]);

  if ((!nectar && nectarId) || !tagList.length) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <NectarEditionHeader
        form={form}
        nectar={nectar ? { ...nectar, title: nectar.question } : null}
        setNectar={setNectar}
      />

      <Card>
        <Tabs>
          <Tabs.TabPane tab='Details' key='1'>
            <NectarEditionContentForm
              form={form}
              nectar={nectar}
              setNectar={setNectar}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Rooms' key='2'>
            {nectar && (
              <RoomsAssociator
                contentId={nectar._id}
                language={nectar.language}
                type={ContentFormatsEnum.NECTAR}
                isPublic={nectar?.isPublic}
                ownerId={nectar?.owner?._id}
                form={form}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default NectarEdition;
