import React, { useCallback, useContext, useEffect, useState } from "react";
import "antd/dist/antd.less";
import { Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "~/context/global.context";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import JuisciLogo from "~/assets/logo-base.svg";
import LoadingLayout from "../shared/LoadingLayout";
import AppMenuItems from "./AppMenuItems";
import { LocalStorageKeys } from "~/utils/constants";

const AppLayout = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();

  const { user, updateUser } = useContext(GlobalContext);

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem(LocalStorageKeys.SIDER_COLLAPSED) === "true" || false
  );

  const fetchUser = useCallback(async () => {
    if (!user) {
      try {
        updateUser();
      } catch {
        navigate("/");
      }
    }
  }, [navigate, updateUser, user]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    localStorage.setItem(
      LocalStorageKeys.SIDER_COLLAPSED,
      collapsed.toString()
    );
  }, [collapsed]);

  if (!user && window.location.pathname !== "/") return <LoadingLayout />;
  if (!user) return children;

  return (
    <StyledLayout className='app-layout'>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className='sider-menu'
        width={256}
      >
        <img
          src={JuisciLogo}
          alt='Juisci'
          className={`juisci-logo ${collapsed ? "collapsed" : ""}`}
        />
        <AppMenuItems collapsed={collapsed} />
        <Button
          className='collapse-button'
          shape='circle'
          size='small'
          type='primary'
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      </Layout.Sider>
      <Layout className='site-layout'>
        <Layout.Content
          style={{
            height: "100vh",
            overflow: "auto",
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </StyledLayout>
  );
};

export default AppLayout;

const StyledLayout = styled(Layout)`
  .juisci-logo {
    height: 24px;
    margin: 24px auto;
    width: 256px;
    transition: all 0.2s;

    &.collapsed {
      width: 80px;
      padding: 0 16px;
    }
  }

  .sider-menu {
    position: relative;
    display: block;
    width: 256px;
    background-color: #29374b;

    a.active {
      color: #212121 !important;
    }

    .sider-menu-menu {
      background-color: #29374b;
      height: calc(100vh - 160px) !important;
      overflow-y: hidden;
    }

    .logout-button {
      position: absolute;
      bottom: 24px;
      background: transparent !important;
      color: red !important;

      .ant-menu-title-content,
      * {
        color: red !important;
      }
    }

    .collapse-button {
      position: absolute;
      bottom: 128px;
      right: -12px;
    }
  }
`;
