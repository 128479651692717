import { EnterOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { capitalize } from "lodash";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectTagsField from "~/components/SelectTagsField";
import { Flex, Spacer } from "~/components/shared/global";
import ImageUploader from "~/components/shared/ImageUploader";
import { GlobalContext } from "~/context/global.context";
import { ICongress } from "~/model/congress.mode";
import { SupportedLanguage, SupportedPublisherEnum } from "~/model/enum";
import { IInfographic } from "~/model/infographic.model";
import { ITag } from "~/model/tag.model";
import { IUser } from "~/model/user.model";
import {
  createInfographic,
  listCongress,
  searchUsers,
  updateInfographic,
  uploadInfographicImage,
  uploadInfographiThumbnail,
} from "~/services";
import {
  getRequestErrorMessage,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import { triggerAutosaveMessage } from "~/utils/ui/messages";

const TIMEOUT_AUTOSAVE = 5000;

type InfographicEditionContentFormProps = {
  form: FormInstance;
  infographic?: IInfographic;
  setInfographic: (infographic: IInfographic) => void;
};
export default function InfographicEditionContentForm({
  form,
  infographic,
  setInfographic,
}: InfographicEditionContentFormProps) {
  const navigate = useNavigate();
  const { journalList, companyList, tagList } = useContext(GlobalContext);

  // Timeout ref for autosave
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // States values
  const [isLoading, setIsLoading] = useState(false);
  const [congressList, setCongressList] = useState<ICongress[] | null>(null);
  const [searchUserList, setSearchUserList] = useState<IUser[]>([]);
  const [infographicImageFile, setInfographicImageFile] = useState<{
    image: File | null;
    thumbnail: File | null;
  }>({
    image: null,
    thumbnail: null,
  });

  // Watch form values
  const watchLanguage: SupportedLanguage = Form.useWatch("language", form);
  const watchPublisher = Form.useWatch("publisher", form);

  // Initial values
  const initialValues = {
    ...infographic,
    company: infographic?.company?._id,
    owner: infographic?.owner?._id,
    congress: infographic?.congress?._id,
    medical_specialties: infographic?.medical_specialties.map(
      (tag) => (tag as ITag)?._id
    ),
    tags: infographic?.tags.map((tag) => (tag as ITag)._id),
    publication_date: infographic?.publication_date
      ? moment(infographic?.publication_date)
      : null,
  };

  function renderPublisherInput() {
    let options;

    switch (watchPublisher) {
      case "congress":
        options = congressList?.map((congress) => ({
          label: congress.name,
          value: congress._id,
        }));
        break;

      case "company":
        options = companyList
          .filter((company) =>
            watchLanguage ? company?.language === watchLanguage : true
          )
          .map((company) => ({
            label: `${company.name} (${company.language})`,
            value: company._id,
          }));
        break;

      case "journal":
        options = journalList.map((journal) => ({
          label: journal.name,
          value: journal._id,
        }));
        break;

      case "user":
        options = searchUserList.map((user) => ({
          label: user.fullname,
          value: user._id,
        }));
        break;

      default:
        break;
    }

    const isLoading = !options || !congressList;

    return (
      <Select
        style={{ width: "100%" }}
        options={options}
        loading={isLoading}
        disabled={isLoading}
        showSearch={watchPublisher === "user"}
        autoClearSearchValue={watchPublisher === "user"}
        onSearch={watchPublisher === "user" ? handleSearchUsers : undefined}
        filterOption={watchPublisher === "user" ? false : undefined}
        placeholder={`Select ${watchPublisher}`}
      />
    );
  }

  async function handleSearchUsers(value: string) {
    const users = await searchUsers(value, { limit: 10 });
    setSearchUserList(users);
  }

  async function handleFinish() {
    const values = form.getFieldsValue(true);

    setIsLoading(true);

    if (infographic?._id) {
      try {
        const res = await updateInfographic(infographic._id, values);

        notification.success({
          message: "Infographic updated successfully",
          description: "The infographic was updated successfully",
        });

        setInfographic(res);
      } catch (error) {
        notification.error({
          message: "An error occurred while updating the infographic",
          description: getRequestErrorMessage(error),
        });
      }
    } else {
      try {
        const createdInfographic = await createInfographic(values);

        if (!createdInfographic) throw new Error("Infographic not created");

        if (infographicImageFile.image)
          await uploadInfographicImage(
            createdInfographic._id,
            infographicImageFile.image
          );

        if (infographicImageFile.thumbnail)
          await uploadInfographiThumbnail(
            createdInfographic._id,
            infographicImageFile.thumbnail
          );

        navigate(`/content-management/infographic/${createdInfographic._id}`);

        notification.success({
          message: "Infographic created successfully",
          description: `The infographic ${createdInfographic.title} was created successfully`,
        });
      } catch (error) {
        notification.error({
          message: "An error occurred while creating the infographic",
          description: getRequestErrorMessage(error),
        });
      } finally {
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  }

  async function handleImageUpload(file: File) {
    if (infographic?._id) {
      setIsLoading(true);
      try {
        const res = await uploadInfographicImage(infographic._id, file);

        if (res)
          setInfographic({
            ...infographic,
            image: res.image,
          });

        notification.success({
          message: "Success",
          description:
            file.type === "application/pdf"
              ? "PDF document uploaded successfully"
              : "Image uploaded successfully",
        });
      } catch (error) {
        notification.error({
          message: "An error occurred while uploading the image",
          description: getRequestErrorMessage(error),
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setInfographicImageFile({
        ...infographicImageFile,
        image: file,
      });

      if (infographic)
        setInfographic({
          ...infographic,
          image: { ...infographic.image, url: URL.createObjectURL(file) },
        });
    }
  }

  const handleThumbnailUpload = async (file: File) => {
    if (infographic?._id) {
      setIsLoading(true);
      const res = await uploadInfographiThumbnail(infographic._id, file);

      if (res) {
        setInfographic({
          ...infographic,
          thumbnail: res.thumbnail,
        });
      }

      setIsLoading(false);
    } else {
      setInfographicImageFile({
        ...infographicImageFile,
        thumbnail: file,
      });

      if (infographic)
        setInfographic({
          ...infographic,
          thumbnail: {
            ...infographic.thumbnail,
            url: URL.createObjectURL(file),
          },
        });
    }
  };

  function handleFinishFailed() {
    const errorFields = form.getFieldsError();

    errorFields.forEach((error) => {
      notification.error({
        message: "Validation Error",
        description: error.errors[0],
        placement: "topRight",
      });
    });
  }

  async function handleAutoSave() {
    if (!infographic || infographic.meta.status !== "draft") return;

    try {
      const values = form.getFieldsValue();

      const res = await updateInfographic(infographic._id, values);

      triggerAutosaveMessage();

      setInfographic(res);
    } catch (error) {
      triggerAutosaveMessage();
    }
  }

  async function handleFieldsChange() {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      timeoutRef.current = null;
      await handleAutoSave();
    }, TIMEOUT_AUTOSAVE);
  }

  useEffect(() => {
    if (!watchLanguage) return;
    async function fetchCongressList() {
      const query = {
        limit: 20,
        offset: 0,
        language: watchLanguage,
      };
      const congressList = await listCongress(query);
      setCongressList(congressList.docs);
    }

    fetchCongressList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLanguage]);

  // Prevent double save with autosave
  useEffect(() => {
    if (!isLoading && timeoutRef.current) clearTimeout(timeoutRef.current);
  }, [isLoading]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={48}>
        <Col span={12}>
          <Form
            layout='vertical'
            form={form}
            initialValues={initialValues}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            onFieldsChange={handleFieldsChange}
          >
            <Form.Item name='language' label='Language' required>
              <Select options={languageOptions} placeholder='Language' />
            </Form.Item>
            <Form.Item name='title' label='Title' required>
              <Input placeholder='Title' />
            </Form.Item>
            <Form.Item
              name='publication_date'
              label='Publication Date'
              required
            >
              <DatePicker
                placeholder='Publication date'
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item name='publisher' label='Publisher' required>
              <Select
                placeholder='Select a publisher'
                onChange={() => {
                  form.setFieldsValue({ [watchPublisher]: undefined });
                  setSearchUserList([]);
                }}
                options={
                  Object.values(SupportedPublisherEnum).map((value) => ({
                    label: capitalize(value),
                    value,
                  })) || []
                }
              />
            </Form.Item>
            {watchPublisher && (
              <Flex
                gap={16}
                align='start'
                style={{ width: "100%", paddingLeft: 16 }}
              >
                <EnterOutlined
                  style={{ transform: "scaleX(-1)", fontSize: 24 }}
                />
                <Form.Item
                  name={watchPublisher}
                  style={{ width: "100%" }}
                  required
                >
                  {renderPublisherInput()}
                </Form.Item>
              </Flex>
            )}

            <Form.Item
              name='medical_specialties'
              label='Medical specialties'
              required
            >
              <Select
                mode='tags'
                placeholder='Select medical specialties'
                disabled={!tagList.length}
                loading={!tagList.length}
                options={tagList
                  .filter((tag) => !tag.parent)
                  .map((tag) => ({
                    label: tag.translations.en,
                    value: tag._id,
                  }))}
                filterOption={searchFilterOptions}
              />
            </Form.Item>

            <Form.Item name='tags' label='Tags'>
              <SelectTagsField form={form} />
            </Form.Item>
          </Form>
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: "16px" }}>Image:</p>
          <ImageUploader
            images={
              infographic?.image && !infographic?.image?.path?.includes(".pdf")
                ? [infographic.image]
                : []
            }
            inputLegendImage={null}
            onChange={(file) => handleImageUpload(file)}
          />
          <Spacer height={36} />
          <p style={{ marginBottom: "16px" }}>Thumbnail:</p>
          <ImageUploader
            images={infographic?.thumbnail ? [infographic.thumbnail] : []}
            inputLegendImage={null}
            onChange={(file) => handleThumbnailUpload(file)}
          />
        </Col>
      </Row>
    </Spin>
  );
}
