import styled from "styled-components";
import { Card } from "antd";
import { Flex } from "~/components/shared/global";

type InnerProps = {
  title: string;
  icon: React.ReactNode;
  value: number;
};

const MetricsCard = ({ title, value, icon }: InnerProps) => {
  return (
    <StyledCard
      size='small'
      title={
        <Flex align='center' gap={8}>
          {icon}
          <div>{title}</div>
        </Flex>
      }
    >
      <div className='metric-value'>{value}</div>
    </StyledCard>
  );
};

export default MetricsCard;

const StyledCard = styled(Card)`
  .metric-value {
    font-size: 36px;
    font-weight: 500;
  }
`;
