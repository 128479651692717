export const europePMCJournalList = {
    "ACI Open": "ACI Open",
    "ACR Open Rheumatology": "ACR Open Rheumatology",
    "ACS Chemical Biology": "ACS Chemical Biology",
    "ACS Chemical Neuroscience": "ACS Chemical Neuroscience",
    "ACS Nano": "ACS Nano",
    "ACS Synthetic Biology": "ACS Synthetic Biology",
    "ACTA Otorhinolaryngologica Italica": "ACTA Otorhinolaryngologica Italica",
    "AI Magazine": "AI Magazine",
    "AIDS Patient Care and STDs": "AIDS Patient Care and STDs",
    "AIDS Research and Human Retroviruses": "AIDS Research and Human Retroviruses",
    "AIDS Research and Therapy": "AIDS Research and Therapy",
    "AIDS Research and Treatment": "AIDS Research and Treatment",
    "ASAIO Journal": "ASAIO Journal",
    "Abdominal Imaging": "Abdominal Imaging",
    "Abdominal Radiology": "Abdominal Radiology",
    "Academic Emergency Medicine": "Academic Emergency Medicine",
    "Academic Pathology": "Academic Pathology",
    "Academic Psychiatry": "Academic Psychiatry",
    "Academic Radiology": "Academic Radiology",
    "Acta Anaesthesiologica Scandinavica": "Acta Anaesthesiologica Scandinavica",
    "Acta Bioethica": "Acta Bioethica",
    "Acta Chirurgiae Orthopaedicae et Traumatologiae Cechoslovaca": "Acta Chirurgiae Orthopaedicae et Traumatologiae Cechoslovaca",
    "Acta Clinica Belgica": "Acta Clinica Belgica",
    "Acta Cytologica": "Acta Cytologica",
    "Acta Diabetologica": "Acta Diabetologica",
    "Acta Gastro-Enterologica Belgica": "Acta Gastro-Enterologica Belgica",
    "Acta Haematologica": "Acta Haematologica",
    "Acta Neurochirurgica": "Acta Neurochirurgica",
    "Acta Neurologica Scandinavica": "Acta Neurologica Scandinavica",
    "Acta Neuropathologica": "Acta Neuropathologica",
    "Acta Neuropsychiatrica": "Acta Neuropsychiatrica",
    "Acta Obstetricia et Gynecologica Scandinavica": "Acta Obstetricia et Gynecologica Scandinavica",
    "Acta Oncologica": "Acta Oncologica",
    "Acta Orthopaedica": "Acta Orthopaedica",
    "Acta Orthopaedica et Traumatologica Turcica": "Acta Orthopaedica et Traumatologica Turcica",
    "Acta Paediatrica": "Acta Paediatrica",
    "Acta Pharmacologica Sinica": "Acta Pharmacologica Sinica",
    "Acta Physiologica Scandinavica": "Acta Physiologica Scandinavica",
    "Acta Psychiatrica Scandinavica": "Acta Psychiatrica Scandinavica",
    "Acta Radiologica": "Acta Radiologica",
    "Acta Reumatologica Portuguesa": "Acta Reumatologica Portuguesa",
    "Acta Virologica": "Acta Virologica",
    "Acupuncture & Electro-Therapeutics Research": "Acupuncture & Electro-Therapeutics Research",
    "Addiction": "Addiction",
    "Addiction Biology": "Addiction Biology",
    "Addiction Science & Clinical Practice": "Addiction Science & Clinical Practice",
    "Addictive Behaviors": "Addictive Behaviors",
    "Adipocyte": "Adipocyte",
    "Adolescent Psychiatry": "Adolescent Psychiatry",
    "Advanced Drug Delivery Reviews": "Advanced Drug Delivery Reviews",
    "Advances In Health Sciences Education": "Advances In Health Sciences Education",
    "Advances in Alzheimer's Disease": "Advances in Alzheimer's Disease",
    "Advances in Anatomic Pathology": "Advances in Anatomic Pathology",
    "Advances in Applied Probability": "Advances in Applied Probability",
    "Advances in Bioethics": "Advances in Bioethics",
    "Advances in Chronic Kidney Disease ": "Advances in Chronic Kidney Disease ",
    "Advances in Clinical Chemistry": "Advances in Clinical Chemistry",
    "Advances in Hematology": "Advances in Hematology",
    "Advances in Immunology": "Advances in Immunology",
    "Advances in Neonatal Care": "Advances in Neonatal Care",
    "Advances in Neurology": "Advances in Neurology",
    "Advances in Nutrition": "Advances in Nutrition",
    "Advances in Physiology Education": "Advances in Physiology Education",
    "Advances in Protein Chemistry and Structural Biology": "Advances in Protein Chemistry and Structural Biology",
    "Advances in Renal Replacement Therapy": "Advances in Renal Replacement Therapy",
    "Advances in Skin & Wound Care": "Advances in Skin & Wound Care",
    "Advances in Therapy": "Advances in Therapy",
    "Advances in Urology": "Advances in Urology",
    "Advances in Virus Research": "Advances in Virus Research",
    "Aesthetic Surgery Journal": "Aesthetic Surgery Journal",
    "Age and Ageing": "Age and Ageing",
    "Ageing Research Reviews": "Ageing Research Reviews",
    "Aging Cell": "Aging Cell",
    "Aging Medicine ": "Aging Medicine ",
    "Aging and Disease": "Aging and Disease",
    "Aids": "Aids",
    "Aids Reviews": "Aids Reviews",
    "Alcohol": "Alcohol",
    "Alcohol Research & Health": "Alcohol Research & Health",
    "Allergologia et Immunopathologia": "Allergologia et Immunopathologia",
    "Allergology International": "Allergology International",
    "Allergy": "Allergy",
    "Alzheimer Disease and Associated Disorders": "Alzheimer Disease and Associated Disorders",
    "Alzheimer's Research & Therapy": "Alzheimer's Research & Therapy",
    "Ambulatory Surgery": "Ambulatory Surgery",
    "American Heart Journal": "American Heart Journal",
    "American Journal of Audiology": "American Journal of Audiology",
    "American Journal of Clinical Dermatology": "American Journal of Clinical Dermatology",
    "American Journal of Clinical Pathology": "American Journal of Clinical Pathology",
    "American Journal of Electroneurodiagnostic Technology": "American Journal of Electroneurodiagnostic Technology",
    "American Journal of Epidemiology": "American Journal of Epidemiology",
    "American Journal of Health Behavior": "American Journal of Health Behavior",
    "American Journal of Human Genetics": "American Journal of Human Genetics",
    "American Journal of Infection Control": "American Journal of Infection Control",
    "American Journal of Kidney Diseases": "American Journal of Kidney Diseases",
    "American Journal of Medical Quality": "American Journal of Medical Quality",
    "American Journal of Obstetrics and Gynecology": "American Journal of Obstetrics and Gynecology",
    "American Journal of Ophthalmology": "American Journal of Ophthalmology",
    "American Journal of Otolaryngology": "American Journal of Otolaryngology",
    "American Journal of Perinatology": "American Journal of Perinatology",
    "American Journal of Pharmaceutical Education": "American Journal of Pharmaceutical Education",
    "American Journal of Preventive Cardiology": "American Journal of Preventive Cardiology",
    "American Journal of Public Health": "American Journal of Public Health",
    "American Journal of Reproductive Immunology": "American Journal of Reproductive Immunology",
    "American Journal of Respiratory Cell and Molecular Biology": "American Journal of Respiratory Cell and Molecular Biology",
    "American Journal of Respiratory and Critical Care Medicine": "American Journal of Respiratory and Critical Care Medicine",
    "American Journal of Rhinology": "American Journal of Rhinology",
    "American Journal of Robotic Surgery": "American Journal of Robotic Surgery",
    "American Journal of Surgery": "American Journal of Surgery",
    "American Journal of Translational Research": "American Journal of Translational Research",
    "Analytical Biochemistry": "Analytical Biochemistry",
    "Anatomical Science International": "Anatomical Science International",
    "Anatomical Sciences Education": "Anatomical Sciences Education",
    "Andrology": "Andrology",
    "Anesthesiology and Pain Medicine": "Anesthesiology and Pain Medicine",
    "Animal Reproduction Science": "Animal Reproduction Science",
    "Annales d'Endocrinologie": "Annales d'Endocrinologie",
    "Annales de Biologie Clinique": "Annales de Biologie Clinique",
    "Annales de Chirurgie Plastique": "Annales de Chirurgie Plastique",
    "Annales de Gastroenterologie et d'Hepatologie": "Annales de Gastroenterologie et d'Hepatologie",
    "Annals of Anatomy": "Annals of Anatomy",
    "Annals of Clinical Biochemistry": "Annals of Clinical Biochemistry",
    "Annals of Clinical Psychiatry": "Annals of Clinical Psychiatry",
    "Annals of Dermatology": "Annals of Dermatology",
    "Annals of Diagnostic Pathology": "Annals of Diagnostic Pathology",
    "Annals of Family Medicine": "Annals of Family Medicine",
    "Annals of Global Health": "Annals of Global Health",
    "Annals of Hematology": "Annals of Hematology",
    "Annals of Hepatology": "Annals of Hepatology",
    "Annals of Human Genetics": "Annals of Human Genetics",
    "Annals of Intensive Care": "Annals of Intensive Care",
    "Annals of Internal Medicine": "Annals of Internal Medicine",
    "Annals of Laboratory Medicine": "Annals of Laboratory Medicine",
    "Annals of Medicine": "Annals of Medicine",
    "Annals of Medicine and Surgery": "Annals of Medicine and Surgery",
    "Annals of Noninvasive Electrocardiology": "Annals of Noninvasive Electrocardiology",
    "Annals of Nuclear Medicine": "Annals of Nuclear Medicine",
    "Annals of Oncology": "Annals of Oncology",
    "Annals of Physical and Rehabilitation Medicine": "Annals of Physical and Rehabilitation Medicine",
    "Annals of Plastic Surgery": "Annals of Plastic Surgery",
    "Annals of Statistics": "Annals of Statistics",
    "Annals of Surgery": "Annals of Surgery",
    "Annals of Surgical Oncology": "Annals of Surgical Oncology",
    "Annals of Transplantation": "Annals of Transplantation",
    "Annals of the Rheumatic Diseases": "Annals of the Rheumatic Diseases",
    "Annual Review of Biomedical Data Science": "Annual Review of Biomedical Data Science",
    "Annual Review of Cell and Developmental Biology": "Annual Review of Cell and Developmental Biology",
    "Annual Review of Genetics": "Annual Review of Genetics",
    "Annual Review of Immunology": "Annual Review of Immunology",
    "Annual Review of Linguistics": "Annual Review of Linguistics",
    "Annual Review of Microbiology": "Annual Review of Microbiology",
    "Annual Review of Neuroscience": "Annual Review of Neuroscience",
    "Annual Review of Nutrition": "Annual Review of Nutrition",
    "Annual Review of Pathology": "Annual Review of Pathology",
    "Annual Review of Pharmacology and Toxicology": "Annual Review of Pharmacology and Toxicology",
    "Annual Review of Physiology": "Annual Review of Physiology",
    "Annual Review of Public Health": "Annual Review of Public Health",
    "Annual Review of Statistics and its Application": "Annual Review of Statistics and its Application",
    "Annual Review of Virology": "Annual Review of Virology",
    "Annual Review of Vision Science": "Annual Review of Vision Science",
    "Antibiotics": "Antibiotics",
    "Antibody Therapeutics": "Antibody Therapeutics",
    "Antiviral Research": "Antiviral Research",
    "Aphasiology": "Aphasiology",
    "Applied Artificial Intelligence": "Applied Artificial Intelligence",
    "Applied Health Economics and Health Policy": "Applied Health Economics and Health Policy",
    "Applied Radiation and Isotopes": "Applied Radiation and Isotopes",
    "Applied and Environmental Microbiology": "Applied and Environmental Microbiology",
    "Applied intelligence": "Applied intelligence",
    "Arab Journal of Gastroenterology": "Arab Journal of Gastroenterology",
    "Arab Journal of Urology": "Arab Journal of Urology",
    "Archives of Biochemistry and Biophysics": "Archives of Biochemistry and Biophysics",
    "Archives of Dermatology and Syphilology": "Archives of Dermatology and Syphilology",
    "Archives of Endocrinology and Metabolism": "Archives of Endocrinology and Metabolism",
    "Archives of Family Medicine": "Archives of Family Medicine",
    "Archives of Gerontology and Geriatrics": "Archives of Gerontology and Geriatrics",
    "Archives of Neurology": "Archives of Neurology",
    "Archives of Oral Biology": "Archives of Oral Biology",
    "Archives of Osteoporosis": "Archives of Osteoporosis",
    "Archives of Physiology and Biochemistry": "Archives of Physiology and Biochemistry",
    "Archives of Psychiatric Nursing": "Archives of Psychiatric Nursing",
    "Archives of Rheumatology": "Archives of Rheumatology",
    "Archives of Virology": "Archives of Virology",
    "Archivos de Bronconeumologia": "Archivos de Bronconeumologia",
    "Archivum Immunologiae et Therapiae Experimentalis": "Archivum Immunologiae et Therapiae Experimentalis",
    "Arthritis Research": "Arthritis Research",
    "Arthritis Research & Therapy": "Arthritis Research & Therapy",
    "Artificial Intelligence in Medicine": "Artificial Intelligence in Medicine",
    "AsiaIntervention": "AsiaIntervention",
    "Asian Journal of Psychiatry": "Asian Journal of Psychiatry",
    "Asian Journal of Surgery": "Asian Journal of Surgery",
    "Asian Journal of Urology": "Asian Journal of Urology",
    "Atherosclerosis": "Atherosclerosis",
    "Auris Nasus Larynx": "Auris Nasus Larynx",
    "Australasian Radiology": "Australasian Radiology",
    "Australian Journal of Dermatology": "Australian Journal of Dermatology",
    "Australian Journal of Primary Health": "Australian Journal of Primary Health",
    "Australian Prescriber": "Australian Prescriber",
    "Australian and New Zealand Journal of Medicine": "Australian and New Zealand Journal of Medicine",
    "Autism Research and Treatment": "Autism Research and Treatment",
    "Autonomic Neuroscience": "Autonomic Neuroscience",
    "Autophagy": "Autophagy",
    "BJGP Open ": "BJGP Open ",
    "BJPsych Open": "BJPsych Open",
    "BJU International": "BJU International",
    "BMC Anesthesiology": "BMC Anesthesiology",
    "BMC Bioinformatics": "BMC Bioinformatics",
    "BMC Biology": "BMC Biology",
    "BMC Cancer": "BMC Cancer",
    "BMC Cardiovascular Disorders": "BMC Cardiovascular Disorders",
    "BMC Cell Biology": "BMC Cell Biology",
    "BMC Complementary Medicine and Therapies": "BMC Complementary Medicine and Therapies",
    "BMC Emergency Medicine": "BMC Emergency Medicine",
    "BMC Endocrine Disorders": "BMC Endocrine Disorders",
    "BMC Gastroenterology": "BMC Gastroenterology",
    "BMC Genetics": "BMC Genetics",
    "BMC Genomic Data": "BMC Genomic Data",
    "BMC Genomics": "BMC Genomics",
    "BMC Geriatrics": "BMC Geriatrics",
    "BMC Health Services Research": "BMC Health Services Research",
    "BMC Infectious Diseases": "BMC Infectious Diseases",
    "BMC Medical Imaging": "BMC Medical Imaging",
    "BMC Musculoskeletal Disorders": "BMC Musculoskeletal Disorders",
    "BMC Nephrology": "BMC Nephrology",
    "BMC Ophthalmology": "BMC Ophthalmology",
    "BMC Palliative care": "BMC Palliative care",
    "BMC Pediatrics": "BMC Pediatrics",
    "BMC Pregnancy and Childbirth": "BMC Pregnancy and Childbirth",
    "BMC Primary Care": "BMC Primary Care",
    "BMC Psychiatry": "BMC Psychiatry",
    "BMC Public Health": "BMC Public Health",
    "BMC Pulmonary Medicine": "BMC Pulmonary Medicine",
    "BMC Sports Science, Medicine and Rehabilitation": "BMC Sports Science, Medicine and Rehabilitation",
    "BMC Surgery": "BMC Surgery",
    "BMC Urology": "BMC Urology",
    "BMC Womens Health": "BMC Womens Health",
    "BMJ": "BMJ",
    "BMJ Open": "BMJ Open",
    "BMJ Open Diabetes Research & Care": "BMJ Open Diabetes Research & Care",
    "BMJ Open Respiratory Research": "BMJ Open Respiratory Research",
    "BMJ Quality & Safety": "BMJ Quality & Safety",
    "Basic Research in Cardiology": "Basic Research in Cardiology",
    "Behavioral Medicine": "Behavioral Medicine",
    "Behavioral Sleep Medicine": "Behavioral Sleep Medicine",
    "Behavioural Pharmacology": "Behavioural Pharmacology",
    "BioDrugs": "BioDrugs",
    "Biochemia medica": "Biochemia medica",
    "Biochemical Pharmacology": "Biochemical Pharmacology",
    "Biochemical and Biophysical Research Communications": "Biochemical and Biophysical Research Communications",
    "Biochemistry": "Biochemistry",
    "Biochemistry and Molecular Biology Education": "Biochemistry and Molecular Biology Education",
    "Bioconjugate Chemistry": "Bioconjugate Chemistry",
    "Bioelectromagnetics": "Bioelectromagnetics",
    "Biological Chemistry": "Biological Chemistry",
    "Biological Cybernetics": "Biological Cybernetics",
    "Biological Psychiatry": "Biological Psychiatry",
    "Biological Research for Nursing": "Biological Research for Nursing",
    "Biology Direct": "Biology Direct",
    "Biology Letters": "Biology Letters",
    "Biology Open": "Biology Open",
    "Biology of Reproduction": "Biology of Reproduction",
    "Biology of the Cell": "Biology of the Cell",
    "Biomarker research": "Biomarker research",
    "Biomedical Engineering and Computational Biology": "Biomedical Engineering and Computational Biology",
    "Biomedical Signal processing and control": "Biomedical Signal processing and control",
    "Biomedical and Environmental Sciences": "Biomedical and Environmental Sciences",
    "Biomedicine & Pharmacotherapy": "Biomedicine & Pharmacotherapy",
    "Biomedicines": "Biomedicines",
    "Biometrika": "Biometrika",
    "Biorheology": "Biorheology",
    "Biotechnology Letters": "Biotechnology Letters",
    "Birth Defects Research": "Birth Defects Research",
    "Blood": "Blood",
    "Blood Advances": "Blood Advances",
    "Blood Reviews": "Blood Reviews",
    "Brain Research Bulletin": "Brain Research Bulletin",
    "Brain Research Reviews": "Brain Research Reviews",
    "Brain Sciences": "Brain Sciences",
    "Brain and Behavior": "Brain and Behavior",
    "Brazilian Journal of Psychiatry": "Brazilian Journal of Psychiatry",
    "Breast Cancer (Auckl)": "Breast Cancer (Auckl)",
    "Breast Cancer Research": "Breast Cancer Research",
    "Breast Cancer Research and Treatment": "Breast Cancer Research and Treatment",
    "Briefings in Bioinformatics": "Briefings in Bioinformatics",
    "British Journal of Audiology": "British Journal of Audiology",
    "British Journal of Haematology": "British Journal of Haematology",
    "British Journal of Hospital Medicine": "British Journal of Hospital Medicine",
    "British Journal of Pharmacology": "British Journal of Pharmacology",
    "British Medical Bulletin": "British Medical Bulletin",
    "Bulletin on the Rheumatic Diseases": "Bulletin on the Rheumatic Diseases",
    "CJC Open": "CJC Open",
    "CNS Drug Reviews": "CNS Drug Reviews",
    "CNS Neuroscience & Therapeutics": "CNS Neuroscience & Therapeutics",
    "CNS Spectrums": "CNS Spectrums",
    "Canadian Family Physician": "Canadian Family Physician",
    "Canadian Journal of Diabetes": "Canadian Journal of Diabetes",
    "Canadian Journal of Kidney Health and Disease": "Canadian Journal of Kidney Health and Disease",
    "Canadian Journal of Public Health": "Canadian Journal of Public Health",
    "Canadian Journal of Surgery": "Canadian Journal of Surgery",
    "Canadian Medical Association Journal": "Canadian Medical Association Journal",
    "Canadian Respiratory Journal": "Canadian Respiratory Journal",
    "Canadian Urological Association Journal": "Canadian Urological Association Journal",
    "Canadian geriatrics journal": "Canadian geriatrics journal",
    "Cancer Cell": "Cancer Cell",
    "Cancer Cell International": "Cancer Cell International",
    "Cancer Chemotherapy and Pharmacology": "Cancer Chemotherapy and Pharmacology",
    "Cancer Cytopathology": "Cancer Cytopathology",
    "Cancer Discovery": "Cancer Discovery",
    "Cancer Immunology Research": "Cancer Immunology Research",
    "Cancer Informatics": "Cancer Informatics",
    "Cancer Nursing": "Cancer Nursing",
    "Cancers (Basel)": "Cancers (Basel)",
    "Cardiology Journal": "Cardiology Journal",
    "Cardiology in the Young": "Cardiology in the Young",
    "Cardiorenal Medicine": "Cardiorenal Medicine",
    "Cardiovascular Diabetology": "Cardiovascular Diabetology",
    "Cardiovascular Digital Health Journal": "Cardiovascular Digital Health Journal",
    "Cardiovascular Drugs and Therapy": "Cardiovascular Drugs and Therapy",
    "Cardiovascular Pathology": "Cardiovascular Pathology",
    "Cardiovascular Research": "Cardiovascular Research",
    "Cardiovascular Toxicology": "Cardiovascular Toxicology",
    "Cardiovascular and Interventional Radiology": "Cardiovascular and Interventional Radiology",
    "Case Studies on Transport Policy": "Case Studies on Transport Policy",
    "Catheterization and Cardiovascular Interventions": "Catheterization and Cardiovascular Interventions",
    "Cell": "Cell",
    "Cell Biology International": "Cell Biology International",
    "Cell Biology and Toxicology": "Cell Biology and Toxicology",
    "Cell Chemical Biology": "Cell Chemical Biology",
    "Cell Discovery": "Cell Discovery",
    "Cell Host Microbe": "Cell Host Microbe",
    "Cells": "Cells",
    "Cellular and Molecular Gastroenterology and Hepatology": "Cellular and Molecular Gastroenterology and Hepatology",
    "Central European Neurosurgery": "Central European Neurosurgery",
    "ChemBioChem": "ChemBioChem",
    "Chemical Immunology": "Chemical Immunology",
    "Chemical Research in Toxicology": "Chemical Research in Toxicology",
    "Chemical Reviews": "Chemical Reviews",
    "Chemical Senses": "Chemical Senses",
    "Chemico-Biological Interactions": "Chemico-Biological Interactions",
    "Chemosensory Perception": "Chemosensory Perception",
    "Chest": "Chest",
    "Child Neuropsychology": "Child Neuropsychology",
    "Child and Adolescent Mental Health": "Child and Adolescent Mental Health",
    "Child and Adolescent Psychiatric Clinics of North America": "Child and Adolescent Psychiatric Clinics of North America",
    "Child and Adolescent Psychiatry and Mental Health": "Child and Adolescent Psychiatry and Mental Health",
    "Children (Basel)": "Children (Basel)",
    "Chiropractic & Manual Therapies": "Chiropractic & Manual Therapies",
    "Chromosome Research": "Chromosome Research",
    "Chronobiology International": "Chronobiology International",
    "Circulation": "Circulation",
    "Circulation Journal": "Circulation Journal",
    "Circulation Research": "Circulation Research",
    "Circulation: Arrhythmia and Electrophysiology": "Circulation: Arrhythmia and Electrophysiology",
    "Circulation: Cardiovascular Quality and Outcomes": "Circulation: Cardiovascular Quality and Outcomes",
    "Cleveland Clinic Journal of Medicine": "Cleveland Clinic Journal of Medicine",
    "Clinical Biochemistry": "Clinical Biochemistry",
    "Clinical Cancer Research": "Clinical Cancer Research",
    "Clinical Cardiology": "Clinical Cardiology",
    "Clinical Chemistry": "Clinical Chemistry",
    "Clinical Chemistry and Laboratory Medicine": "Clinical Chemistry and Laboratory Medicine",
    "Clinical Child Psychology and Psychiatry": "Clinical Child Psychology and Psychiatry",
    "Clinical Colorectal Cancer": "Clinical Colorectal Cancer",
    "Clinical Dysmorphology": "Clinical Dysmorphology",
    "Clinical EEG and Neuroscience": "Clinical EEG and Neuroscience",
    "Clinical Endocrinology": "Clinical Endocrinology",
    "Clinical Endoscopy": "Clinical Endoscopy",
    "Clinical Epidemiology": "Clinical Epidemiology",
    "Clinical Epigenetics": "Clinical Epigenetics",
    "Clinical Ethics": "Clinical Ethics",
    "Clinical Gastroenterology and Hepatology": "Clinical Gastroenterology and Hepatology",
    "Clinical Genitourinary Cancer": "Clinical Genitourinary Cancer",
    "Clinical Gerontologist": "Clinical Gerontologist",
    "Clinical Hematology International": "Clinical Hematology International",
    "Clinical Hypertension": "Clinical Hypertension",
    "Clinical Imaging": "Clinical Imaging",
    "Clinical Immunology": "Clinical Immunology",
    "Clinical Infectious Diseases": "Clinical Infectious Diseases",
    "Clinical Interventions in Aging": "Clinical Interventions in Aging",
    "Clinical Journal of Gastroenterology": "Clinical Journal of Gastroenterology",
    "Clinical Journal of Sport Medicine": "Clinical Journal of Sport Medicine",
    "Clinical Medicine": "Clinical Medicine",
    "Clinical Neuropathology": "Clinical Neuropathology",
    "Clinical Neuropharmacology": "Clinical Neuropharmacology",
    "Clinical Neuropsychiatry": "Clinical Neuropsychiatry",
    "Clinical Neuroradiology": "Clinical Neuroradiology",
    "Clinical Neuroscience Research": "Clinical Neuroscience Research",
    "Clinical Nuclear Medicine": "Clinical Nuclear Medicine",
    "Clinical Nursing Research": "Clinical Nursing Research",
    "Clinical Nutrition ESPEN": "Clinical Nutrition ESPEN",
    "Clinical Obstetrics and Gynecology": "Clinical Obstetrics and Gynecology",
    "Clinical Orthopaedics and Related Research": "Clinical Orthopaedics and Related Research",
    "Clinical Otolaryngology": "Clinical Otolaryngology",
    "Clinical Otolaryngology and Allied Sciences": "Clinical Otolaryngology and Allied Sciences",
    "Clinical Pharmacokinetics": "Clinical Pharmacokinetics",
    "Clinical Physiology and Functional Imaging": "Clinical Physiology and Functional Imaging",
    "Clinical Proteomics": "Clinical Proteomics",
    "Clinical Psychological Science": "Clinical Psychological Science",
    "Clinical Radiology": "Clinical Radiology",
    "Clinical Research in Cardiology": "Clinical Research in Cardiology",
    "Clinical Reviews in Allergy & Immunology": "Clinical Reviews in Allergy & Immunology",
    "Clinical Rheumatology": "Clinical Rheumatology",
    "Clinical Simulation in Nursing": "Clinical Simulation in Nursing",
    "Clinical Spine Surgery": "Clinical Spine Surgery",
    "Clinical Toxicology": "Clinical Toxicology",
    "Clinical and Developmental Immunology": "Clinical and Developmental Immunology",
    "Clinical and Diagnostic Laboratory Immunology": "Clinical and Diagnostic Laboratory Immunology",
    "Clinical and Experimental Allergy": "Clinical and Experimental Allergy",
    "Clinical and Experimental Dermatology": "Clinical and Experimental Dermatology",
    "Clinical and Experimental Gastroenterology": "Clinical and Experimental Gastroenterology",
    "Clinical and Experimental Immunology": "Clinical and Experimental Immunology",
    "Clinical and Experimental Nephrology": "Clinical and Experimental Nephrology",
    "Clinical and Experimental Otorhinolaryngology": "Clinical and Experimental Otorhinolaryngology",
    "Clinical and Experimental Rheumatology": "Clinical and Experimental Rheumatology",
    "Clinical and Laboratory Haematology": "Clinical and Laboratory Haematology",
    "Clinical and Molecular Hepatology": "Clinical and Molecular Hepatology",
    "Clinical and Translational Gastroenterology": "Clinical and Translational Gastroenterology",
    "Clinical and Translational Imaging": "Clinical and Translational Imaging",
    "Clinical and Translational Medicine": "Clinical and Translational Medicine",
    "Clinical and Translational Radiation Oncology": "Clinical and Translational Radiation Oncology",
    "Clinical and Vaccine Immunology": "Clinical and Vaccine Immunology",
    "Clinical case reports": "Clinical case reports",
    "Clinical, Cosmetic and Investigational Dermatology": "Clinical, Cosmetic and Investigational Dermatology",
    "Clinics and Research in Hepatology and Gastroenterology": "Clinics and Research in Hepatology and Gastroenterology",
    "Clinics in Chest Medicine": "Clinics in Chest Medicine",
    "Clinics in Colon and Rectal Surgery": "Clinics in Colon and Rectal Surgery",
    "Clinics in Dermatology": "Clinics in Dermatology",
    "Cognitive Neuropsychiatry": "Cognitive Neuropsychiatry",
    "Cognitive and Behavioral Neurology": "Cognitive and Behavioral Neurology",
    "Cold Spring Harbor Perspectives in Biology": "Cold Spring Harbor Perspectives in Biology",
    "Colorectal Disease": "Colorectal Disease",
    "Communications Biology": "Communications Biology",
    "Community Dentistry and Oral Epidemiology": "Community Dentistry and Oral Epidemiology",
    "Comprehensive Physiology": "Comprehensive Physiology",
    "Comprehensive Psychiatry": "Comprehensive Psychiatry",
    "Comptes Rendus Biologies": "Comptes Rendus Biologies",
    "Comptes Rendus Mathematique": "Comptes Rendus Mathematique",
    "Computational Intelligence and Neuroscience": "Computational Intelligence and Neuroscience",
    "Computational Statistics": "Computational Statistics",
    "Computational and Mathematical Methods in Medicine": "Computational and Mathematical Methods in Medicine",
    "Computational and Structural Biotechnology Journal": "Computational and Structural Biotechnology Journal",
    "Computerized Medical Imaging and Graphics": "Computerized Medical Imaging and Graphics",
    "Computers in Biology and Medicine": "Computers in Biology and Medicine",
    "Connective Tissue Research": "Connective Tissue Research",
    "Contrast Media & Molecular Imaging": "Contrast Media & Molecular Imaging",
    "Contributions to Nephrology": "Contributions to Nephrology",
    "Critical Care": "Critical Care",
    "Critical Care Medicine": "Critical Care Medicine",
    "Critical Reviews in Biochemistry and Molecular Biology": "Critical Reviews in Biochemistry and Molecular Biology",
    "Critical Reviews in Clinical Laboratory Sciences": "Critical Reviews in Clinical Laboratory Sciences",
    "Critical Reviews in Diagnostic Imaging": "Critical Reviews in Diagnostic Imaging",
    "Critical Reviews in Immunology": "Critical Reviews in Immunology",
    "Critical Reviews in Microbiology": "Critical Reviews in Microbiology",
    "Critical Reviews in Neurobiology": "Critical Reviews in Neurobiology",
    "Critical Reviews in Toxicology": "Critical Reviews in Toxicology",
    "Crohns Colitis 360": "Crohns Colitis 360",
    "Cryobiology": "Cryobiology",
    "Cureus": "Cureus",
    "Current Allergy and Asthma Reports": "Current Allergy and Asthma Reports",
    "Current Alzheimer Research": "Current Alzheimer Research",
    "Current Bioinformatics": "Current Bioinformatics",
    "Current Biology": "Current Biology",
    "Current Computer-aided Drug Design": "Current Computer-aided Drug Design",
    "Current Dermatology Reports": "Current Dermatology Reports",
    "Current Diabetes Reports": "Current Diabetes Reports",
    "Current Drug Metabolism": "Current Drug Metabolism",
    "Current Drug Targets": "Current Drug Targets",
    "Current Eye Research": "Current Eye Research",
    "Current Fungal Infection Reports": "Current Fungal Infection Reports",
    "Current Gene Therapy": "Current Gene Therapy",
    "Current Genomics": "Current Genomics",
    "Current HIV Research": "Current HIV Research",
    "Current HIV/AIDS Reports": "Current HIV/AIDS Reports",
    "Current Issues in Molecular Biology": "Current Issues in Molecular Biology",
    "Current Medical Imaging": "Current Medical Imaging",
    "Current Molecular Pharmacology": "Current Molecular Pharmacology",
    "Current Neuropharmacology": "Current Neuropharmacology",
    "Current Neurovascular Research": "Current Neurovascular Research",
    "Current Obesity Reports": "Current Obesity Reports",
    "Current Oncology": "Current Oncology",
    "Current Oncology Reports": "Current Oncology Reports",
    "Current Opinion in Allergy and Clinical Immunology": "Current Opinion in Allergy and Clinical Immunology",
    "Current Opinion in Behavioral Sciences": "Current Opinion in Behavioral Sciences",
    "Current Opinion in Biotechnology": "Current Opinion in Biotechnology",
    "Current Opinion in Cardiology": "Current Opinion in Cardiology",
    "Current Opinion in Cell Biology": "Current Opinion in Cell Biology",
    "Current Opinion in Clinical Nutrition and Metabolic Care": "Current Opinion in Clinical Nutrition and Metabolic Care",
    "Current Opinion in Critical Care": "Current Opinion in Critical Care",
    "Current Opinion in Gastroenterology": "Current Opinion in Gastroenterology",
    "Current Opinion in Hematology": "Current Opinion in Hematology",
    "Current Opinion in Immunology": "Current Opinion in Immunology",
    "Current Opinion in Infectious Diseases": "Current Opinion in Infectious Diseases",
    "Current Opinion in Lipidology": "Current Opinion in Lipidology",
    "Current Opinion in Microbiology": "Current Opinion in Microbiology",
    "Current Opinion in Nephrology and Hypertension": "Current Opinion in Nephrology and Hypertension",
    "Current Opinion in Neurobiology": "Current Opinion in Neurobiology",
    "Current Opinion in Neurology": "Current Opinion in Neurology",
    "Current Opinion in Ophthalmology": "Current Opinion in Ophthalmology",
    "Current Opinion in Organ Transplantation": "Current Opinion in Organ Transplantation",
    "Current Opinion in Pharmacology": "Current Opinion in Pharmacology",
    "Current Opinion in Psychiatry": "Current Opinion in Psychiatry",
    "Current Opinion in Pulmonary Medicine": "Current Opinion in Pulmonary Medicine",
    "Current Opinion in Rheumatology": "Current Opinion in Rheumatology",
    "Current Opinion in Supportive and Palliative Care": "Current Opinion in Supportive and Palliative Care",
    "Current Opinion in Urology": "Current Opinion in Urology",
    "Current Opinion in Virology": "Current Opinion in Virology",
    "Current Pain and Headache Reports": "Current Pain and Headache Reports",
    "Current Problems in Dermatology": "Current Problems in Dermatology",
    "Current Problems in Pediatric and Adolescent Health Care": "Current Problems in Pediatric and Adolescent Health Care",
    "Current Protein & Peptide Science": "Current Protein & Peptide Science",
    "Current Psychiatry Reports": "Current Psychiatry Reports",
    "Current Radiopharmaceuticals": "Current Radiopharmaceuticals",
    "Current Rheumatology Reports": "Current Rheumatology Reports",
    "Current Rheumatology Reviews": "Current Rheumatology Reviews",
    "Current Stem Cell Research & Therapy": "Current Stem Cell Research & Therapy",
    "Current Topics in Developmental Biology": "Current Topics in Developmental Biology",
    "Current Topics in Microbiology and Immunology": "Current Topics in Microbiology and Immunology",
    "Current Transplantation Reports": "Current Transplantation Reports",
    "Current Treatment Options in Neurology": "Current Treatment Options in Neurology",
    "Current Treatment Options in Rheumatology": "Current Treatment Options in Rheumatology",
    "Current Urology Reports": "Current Urology Reports",
    "Current Vascular Pharmacology": "Current Vascular Pharmacology",
    "Cytopathology": "Cytopathology",
    "Cytotherapy": "Cytotherapy",
    "DNA and Cell Biology": "DNA and Cell Biology",
    "Danish Medical Bulletin": "Danish Medical Bulletin",
    "Danish Medical Journal": "Danish Medical Journal",
    "Dental Traumatology": "Dental Traumatology",
    "Dermatologic Clinics": "Dermatologic Clinics",
    "Dermatology Online Journal": "Dermatology Online Journal",
    "Dermatology Reports": "Dermatology Reports",
    "Dermatology Research and Practice": "Dermatology Research and Practice",
    "Dermatology and Therapy": "Dermatology and Therapy",
    "Developing World Bioethics": "Developing World Bioethics",
    "Development": "Development",
    "Development Genes and Evolution": "Development Genes and Evolution",
    "Developmental Biology": "Developmental Biology",
    "Developmental Cell": "Developmental Cell",
    "Developmental Disabilities Research Reviews": "Developmental Disabilities Research Reviews",
    "Developmental Dynamics": "Developmental Dynamics",
    "Developmental Neurobiology": "Developmental Neurobiology",
    "Developmental Neurorehabilitation": "Developmental Neurorehabilitation",
    "Developmental Neuroscience": "Developmental Neuroscience",
    "Developmental Psychobiology": "Developmental Psychobiology",
    "Diabetes": "Diabetes",
    "Diabetes Care": "Diabetes Care",
    "Diabetes Research and Clinical Practice": "Diabetes Research and Clinical Practice",
    "Diabetologia": "Diabetologia",
    "Diagnostic Cytopathology": "Diagnostic Cytopathology",
    "Diagnostic Molecular Pathology": "Diagnostic Molecular Pathology",
    "Diagnostic Pathology": "Diagnostic Pathology",
    "Diagnostic and Interventional Imaging": "Diagnostic and Interventional Imaging",
    "Diagnostic and Interventional Radiology": "Diagnostic and Interventional Radiology",
    "Diagnostic and Therapeutic Endoscopy": "Diagnostic and Therapeutic Endoscopy",
    "Dialogues in Clinical Neuroscience": "Dialogues in Clinical Neuroscience",
    "Digestive Endoscopy": "Digestive Endoscopy",
    "Digestive Surgery": "Digestive Surgery",
    "Disease Models & Mechanisms": "Disease Models & Mechanisms",
    "Disease-a-Month": "Disease-a-Month",
    "Drug Alcohol Depend": "Drug Alcohol Depend",
    "Drug Alcohol Rev": "Drug Alcohol Rev",
    "Drug Delivery and Translational Research": "Drug Delivery and Translational Research",
    "Drug Discovery Today": "Drug Discovery Today",
    "Drug and Chemical Toxicology": "Drug and Chemical Toxicology",
    "Drug delivery": "Drug delivery",
    "Drug development and Industrial Pharmacy": "Drug development and Industrial Pharmacy",
    "EBioMedicine": "EBioMedicine",
    "EJNMMI Physics": "EJNMMI Physics",
    "EJNMMI Radiopharmacy and Chemistry": "EJNMMI Radiopharmacy and Chemistry",
    "EJNMMI Research": "EJNMMI Research",
    "EMBO Molecular Medicine": "EMBO Molecular Medicine",
    "EMBO Reports": "EMBO Reports",
    "ESMO Open": "ESMO Open",
    "Early Intervention in Psychiatry": "Early Intervention in Psychiatry",
    "Echocardiography": "Echocardiography",
    "Econometrica": "Econometrica",
    "Economics and Human Biology": "Economics and Human Biology",
    "Ecotoxicology and Environmental Safety": "Ecotoxicology and Environmental Safety",
    "Education for Primary Care": "Education for Primary Care",
    "Educational and Psychological Measurement": "Educational and Psychological Measurement",
    "Elife": "Elife",
    "Emergency Medicine Clinics of North America": "Emergency Medicine Clinics of North America",
    "Emerging Microbes & Infections": "Emerging Microbes & Infections",
    "Endocrine Connections": "Endocrine Connections",
    "Endocrine Pathology": "Endocrine Pathology",
    "Endocrine Practice": "Endocrine Practice",
    "Endocrine Research": "Endocrine Research",
    "Endocrine Reviews": "Endocrine Reviews",
    "Endocrinology and Metabolism Clinics of North America": "Endocrinology and Metabolism Clinics of North America",
    "Endokrynologia Polska": "Endokrynologia Polska",
    "Endoscopy International Open": "Endoscopy International Open",
    "Environmental Health Perspectives": "Environmental Health Perspectives",
    "Epidemics": "Epidemics",
    "Epidemiologic Reviews": "Epidemiologic Reviews",
    "Epilepsia": "Epilepsia",
    "Epilepsia Open": "Epilepsia Open",
    "Epilepsy Research": "Epilepsy Research",
    "Epileptic Disorders": "Epileptic Disorders",
    "Essays in Biochemistry": "Essays in Biochemistry",
    "EuroIntervention": "EuroIntervention",
    "Euroasian Journal of Hepato-Gastroenterology": "Euroasian Journal of Hepato-Gastroenterology",
    "European Addiction Research": "European Addiction Research",
    "European Archives of Psychiatry and Clinical Neuroscience": "European Archives of Psychiatry and Clinical Neuroscience",
    "European Cytokine Network": "European Cytokine Network",
    "European Geriatric Medicine": "European Geriatric Medicine",
    "European Heart Journal": "European Heart Journal",
    "European Heart Journal - Digital Health": "European Heart Journal - Digital Health",
    "European Heart Journal Cardiovascular Imaging": "European Heart Journal Cardiovascular Imaging",
    "European Journal Of Breast Health": "European Journal Of Breast Health",
    "European Journal for Philosophy of Science": "European Journal for Philosophy of Science",
    "European Journal of Anaesthesiology": "European Journal of Anaesthesiology",
    "European Journal of Applied Physiology": "European Journal of Applied Physiology",
    "European Journal of Cancer": "European Journal of Cancer",
    "European Journal of Cancer Care": "European Journal of Cancer Care",
    "European Journal of Cardiovascular Nursing": "European Journal of Cardiovascular Nursing",
    "European Journal of Cell Biology": "European Journal of Cell Biology",
    "European Journal of Clinical Investigation": "European Journal of Clinical Investigation",
    "European Journal of Clinical Nutrition": "European Journal of Clinical Nutrition",
    "European Journal of Clinical Pharmacology": "European Journal of Clinical Pharmacology",
    "European Journal of Dental Education": "European Journal of Dental Education",
    "European Journal of Dermatology": "European Journal of Dermatology",
    "European Journal of Drug Metabolism and Pharmacokinetics": "European Journal of Drug Metabolism and Pharmacokinetics",
    "European Journal of Emergency Medicine": "European Journal of Emergency Medicine",
    "European Journal of Endocrinology": "European Journal of Endocrinology",
    "European Journal of Epidemiology": "European Journal of Epidemiology",
    "European Journal of Haematology": "European Journal of Haematology",
    "European Journal of Health Law": "European Journal of Health Law",
    "European Journal of Heart Failure": "European Journal of Heart Failure",
    "European Journal of Human Genetics": "European Journal of Human Genetics",
    "European Journal of Hybrid Imaging": "European Journal of Hybrid Imaging",
    "European Journal of Immunology": "European Journal of Immunology",
    "European Journal of Inflammation": "European Journal of Inflammation",
    "European Journal of Internal Medicine": "European Journal of Internal Medicine",
    "European Journal of Medical Genetics": "European Journal of Medical Genetics",
    "European Journal of Neurology": "European Journal of Neurology",
    "European Journal of Oncology Nursing": "European Journal of Oncology Nursing",
    "European Journal of Ophthalmology": "European Journal of Ophthalmology",
    "European Journal of Orthodontics": "European Journal of Orthodontics",
    "European Journal of Paediatric Neurology": "European Journal of Paediatric Neurology",
    "European Journal of Pediatric Surgery": "European Journal of Pediatric Surgery",
    "European Journal of Pediatrics": "European Journal of Pediatrics",
    "European Journal of Pharmaceutics and Biopharmaceutics": "European Journal of Pharmaceutics and Biopharmaceutics",
    "European Journal of Pharmacology": "European Journal of Pharmacology",
    "European Journal of Physical and Rehabilitation Medicine": "European Journal of Physical and Rehabilitation Medicine",
    "European Journal of Preventive Cardiology": "European Journal of Preventive Cardiology",
    "European Journal of Public Health": "European Journal of Public Health",
    "European Journal of Radiology": "European Journal of Radiology",
    "European Journal of Surgical Oncology": "European Journal of Surgical Oncology",
    "European Journal of Trauma and Emergency Surgery": "European Journal of Trauma and Emergency Surgery",
    "European Journal of Vascular and Endovascular Surgery": "European Journal of Vascular and Endovascular Surgery",
    "European Neurology": "European Neurology",
    "European Neuropsychopharmacology": "European Neuropsychopharmacology",
    "European Psychiatry": "European Psychiatry",
    "European Radiology": "European Radiology",
    "European Respiratory Review": "European Respiratory Review",
    "European Review for Medical and Pharmacological Sciences": "European Review for Medical and Pharmacological Sciences",
    "European Review of Aging and Physical Activity": "European Review of Aging and Physical Activity",
    "European Spine Journal": "European Spine Journal",
    "European Thyroid Journal": "European Thyroid Journal",
    "European Urology": "European Urology",
    "European Urology Focus": "European Urology Focus",
    "European Urology Oncology": "European Urology Oncology",
    "Eurosurveillance": "Eurosurveillance",
    "Exercise Immunology Review": "Exercise Immunology Review",
    "Experimental Aging Research": "Experimental Aging Research",
    "Experimental Brain Research": "Experimental Brain Research",
    "Experimental Cell Research": "Experimental Cell Research",
    "Experimental Dermatology": "Experimental Dermatology",
    "Experimental Eye Research": "Experimental Eye Research",
    "Experimental Gerontology": "Experimental Gerontology",
    "Experimental Hematology": "Experimental Hematology",
    "Experimental Lung Research": "Experimental Lung Research",
    "Experimental Nephrology": "Experimental Nephrology",
    "Experimental Parasitology": "Experimental Parasitology",
    "Experimental Physiology": "Experimental Physiology",
    "Experimental and Clinical Endocrinology & Diabetes": "Experimental and Clinical Endocrinology & Diabetes",
    "Experimental and Clinical Immunogenetics": "Experimental and Clinical Immunogenetics",
    "Experimental and Clinical Psychopharmacology": "Experimental and Clinical Psychopharmacology",
    "Experimental and Molecular Pathology": "Experimental and Molecular Pathology",
    "Experimental and Toxicologic Pathology": "Experimental and Toxicologic Pathology",
    "Expert Opinion on Drug Delivery": "Expert Opinion on Drug Delivery",
    "Expert Opinion on Drug Discovery": "Expert Opinion on Drug Discovery",
    "Expert Opinion on Drug Metabolism & Toxicology": "Expert Opinion on Drug Metabolism & Toxicology",
    "Expert Opinion on Drug Safety": "Expert Opinion on Drug Safety",
    "Expert Opinion on Investigational Drugs": "Expert Opinion on Investigational Drugs",
    "Expert Opinion on Therapeutic Targets": "Expert Opinion on Therapeutic Targets",
    "Expert Review of Clinical Immunology": "Expert Review of Clinical Immunology",
    "Expert Review of Clinical Pharmacology": "Expert Review of Clinical Pharmacology",
    "Expert Review of Dermatology": "Expert Review of Dermatology",
    "Expert Review of Hematology": "Expert Review of Hematology",
    "Expert Review of Neurotherapeutics": "Expert Review of Neurotherapeutics",
    "Expert Review of Ophthalmology": "Expert Review of Ophthalmology",
    "Expert Review of Pharmacoeconomics & Outcomes Research": "Expert Review of Pharmacoeconomics & Outcomes Research",
    "Expert Review of Respiratory Medicine": "Expert Review of Respiratory Medicine",
    "Expert Review of Vaccines": "Expert Review of Vaccines",
    "F&S Reports": "F&S Reports",
    "FEBS Open Bio": "FEBS Open Bio",
    "FEMS Yeast Research": "FEMS Yeast Research",
    "Female Pelvic Medicine & Reconstructive Surgery": "Female Pelvic Medicine & Reconstructive Surgery",
    "Fertility and Sterility": "Fertility and Sterility",
    "Fetal Diagnosis and Therapy": "Fetal Diagnosis and Therapy",
    "Fetal and Pediatric Pathology": "Fetal and Pediatric Pathology",
    "Folia Neuropathologica": "Folia Neuropathologica",
    "Folia Phoniatrica et Logopaedica": "Folia Phoniatrica et Logopaedica",
    "Food and Chemical Toxicology": "Food and Chemical Toxicology",
    "Food and Environmental Virology": "Food and Environmental Virology",
    "Forensic Science International": "Forensic Science International",
    "Forensic Sciences Research": "Forensic Sciences Research",
    "Free Radical Research": "Free Radical Research",
    "Frontiers in Bioengineering and Biotechnology": "Frontiers in Bioengineering and Biotechnology",
    "Frontiers in Bioscience": "Frontiers in Bioscience",
    "Frontiers in Cardiovascular Medicine": "Frontiers in Cardiovascular Medicine",
    "Frontiers in Cell and Developmental Biology": "Frontiers in Cell and Developmental Biology",
    "Frontiers in Cellular Neuroscience": "Frontiers in Cellular Neuroscience",
    "Frontiers in Endocrinology": "Frontiers in Endocrinology",
    "Frontiers in Genetics": "Frontiers in Genetics",
    "Frontiers in Global Women's Health": "Frontiers in Global Women's Health",
    "Frontiers in Human Neuroscience": "Frontiers in Human Neuroscience",
    "Frontiers in Immunology": "Frontiers in Immunology",
    "Frontiers in Medicine": "Frontiers in Medicine",
    "Frontiers in Molecular Neuroscience": "Frontiers in Molecular Neuroscience",
    "Frontiers in Neural Circuits": "Frontiers in Neural Circuits",
    "Frontiers in Neuroanatomy": "Frontiers in Neuroanatomy",
    "Frontiers in Neuroendocrinology": "Frontiers in Neuroendocrinology",
    "Frontiers in Neuroinformatics": "Frontiers in Neuroinformatics",
    "Frontiers in Neurology": "Frontiers in Neurology",
    "Frontiers in Neurorobotics": "Frontiers in Neurorobotics",
    "Frontiers in Neuroscience": "Frontiers in Neuroscience",
    "Frontiers in Oncology": "Frontiers in Oncology",
    "Frontiers in Pediatrics": "Frontiers in Pediatrics",
    "Frontiers in Pharmacology": "Frontiers in Pharmacology",
    "Frontiers in Physiology": "Frontiers in Physiology",
    "Frontiers in Psychiatry": "Frontiers in Psychiatry",
    "Frontiers in Public Health": "Frontiers in Public Health",
    "Frontiers in Surgery": "Frontiers in Surgery",
    "Frontiers in Systems Neuroscience": "Frontiers in Systems Neuroscience",
    "Frontiers in aging neuroscience": "Frontiers in aging neuroscience",
    "Frontiers in nutrition": "Frontiers in nutrition",
    "Fungal Biology": "Fungal Biology",
    "Fungal Biology Reviews": "Fungal Biology Reviews",
    "Fungal Biology and Biotechnology": "Fungal Biology and Biotechnology",
    "Fungal Ecology": "Fungal Ecology",
    "Fungal Genetics and Biology": "Fungal Genetics and Biology",
    "Future Microbiology": "Future Microbiology",
    "Future Virology": "Future Virology",
    "Gastroenterologie Clinique et Biologique": "Gastroenterologie Clinique et Biologique",
    "Gastroenterology": "Gastroenterology",
    "Gastroenterology Nursing": "Gastroenterology Nursing",
    "Gastroenterology Report": "Gastroenterology Report",
    "Gastroenterology Research and Practice": "Gastroenterology Research and Practice",
    "Gastrointestinal Endoscopy": "Gastrointestinal Endoscopy",
    "Gastrointestinal Endoscopy Clinics of North America": "Gastrointestinal Endoscopy Clinics of North America",
    "Gene": "Gene",
    "General Hospital Psychiatry": "General Hospital Psychiatry",
    "General and Comparative Endocrinology": "General and Comparative Endocrinology",
    "Genes Chromosomes Cancer": "Genes Chromosomes Cancer",
    "Genes and Development": "Genes and Development",
    "Genes and Environment": "Genes and Environment",
    "Genetic Epidemiology": "Genetic Epidemiology",
    "Genome Biology and Evolution": "Genome Biology and Evolution",
    "Genome Medicine": "Genome Medicine",
    "Genome Research": "Genome Research",
    "Geriatric Orthopaedic Surgery & Rehabilitation": "Geriatric Orthopaedic Surgery & Rehabilitation",
    "Geriatrics (Basel)": "Geriatrics (Basel)",
    "Geriatrie et Psychologie Neuropsychiatrie du Vieillissement": "Geriatrie et Psychologie Neuropsychiatrie du Vieillissement",
    "GeroScience": "GeroScience",
    "Gerodontology": "Gerodontology",
    "GigaScience": "GigaScience",
    "Global Dermatology": "Global Dermatology",
    "Global Health Action": "Global Health Action",
    "Global Health Promotion": "Global Health Promotion",
    "Global Mental Health": "Global Mental Health",
    "Global Public Health": "Global Public Health",
    "Global Spine Journal": "Global Spine Journal",
    "Glycobiology": "Glycobiology",
    "Graefe's Archive for Clinical and Experimental Ophthalmology": "Graefe's Archive for Clinical and Experimental Ophthalmology",
    "Gut": "Gut",
    "Gut Pathogens": "Gut Pathogens",
    "Gynecological Endocrinology": "Gynecological Endocrinology",
    "HGG Advances": "HGG Advances",
    "Haematologica": "Haematologica",
    "Hand Clinics": "Hand Clinics",
    "Harm Reduction Journal": "Harm Reduction Journal",
    "Harvard Review of Psychiatry": "Harvard Review of Psychiatry",
    "Health Affairs": "Health Affairs",
    "Health Behavior and Policy Review": "Health Behavior and Policy Review",
    "Health Education Research": "Health Education Research",
    "Health Informatics Journal": "Health Informatics Journal",
    "Health Information Science and Systems": "Health Information Science and Systems",
    "Health Promotion International": "Health Promotion International",
    "Health and Quality of Life Outcomes": "Health and Quality of Life Outcomes",
    "Health and Technology": "Health and Technology",
    "Heart": "Heart",
    "Heart Failure Reviews": "Heart Failure Reviews",
    "Heart Rhythm": "Heart Rhythm",
    "Heart Rhythm O2": "Heart Rhythm O2",
    "Heliyon": "Heliyon",
    "Hellenic Journal of Nuclear Medicine": "Hellenic Journal of Nuclear Medicine",
    "Hematology": "Hematology",
    "Hepatobiliary Surgery and Nutrition": "Hepatobiliary Surgery and Nutrition",
    "Hepatology": "Hepatology",
    "Hepatology International": "Hepatology International",
    "Hepatology Research": "Hepatology Research",
    "Histochemistry and Cell Biology": "Histochemistry and Cell Biology",
    "Hormone Research in Paediatrics": "Hormone Research in Paediatrics",
    "Hormone and Metabolic Research": "Hormone and Metabolic Research",
    "Human Fertility": "Human Fertility",
    "Human Gene Therapy Methods": "Human Gene Therapy Methods",
    "Human Molecular Genetics": "Human Molecular Genetics",
    "Human Movement Science": "Human Movement Science",
    "Human Psychopharmacology": "Human Psychopharmacology",
    "Human Reproduction Open": "Human Reproduction Open",
    "Human Reproduction Update": "Human Reproduction Update",
    "Hypertension Research": "Hypertension Research",
    "IDCases": "IDCases",
    "IEEE Access": "IEEE Access",
    "IEEE Journal of Biomedical and Health Informatics": "IEEE Journal of Biomedical and Health Informatics",
    "IEEE Journal of Translational Engineering in Health and Medicine": "IEEE Journal of Translational Engineering in Health and Medicine",
    "IEEE Transactions on Image Processing": "IEEE Transactions on Image Processing",
    "IEEE Transactions on Information Technology in Biomedicine": "IEEE Transactions on Information Technology in Biomedicine",
    "IEEE Transactions on Neural Networks and Learning Systems": "IEEE Transactions on Neural Networks and Learning Systems",
    "IEEE Transactions on Pattern Analysis and Machine Intelligence": "IEEE Transactions on Pattern Analysis and Machine Intelligence",
    "IEEE/ACM Transactions on Computational Biology and Bioinformatics": "IEEE/ACM Transactions on Computational Biology and Bioinformatics",
    "IET Systems Biology": "IET Systems Biology",
    "IMA Fungus": "IMA Fungus",
    "ISRN Dermatology": "ISRN Dermatology",
    "ISRN Pathology": "ISRN Pathology",
    "Immunity": "Immunity",
    "Immunogenetics": "Immunogenetics",
    "Immunologic Research": "Immunologic Research",
    "Immunological Investigations": "Immunological Investigations",
    "Immunological Reviews": "Immunological Reviews",
    "Immunology Letters": "Immunology Letters",
    "Immunology Today": "Immunology Today",
    "Immunopharmacology and Immunotoxicology": "Immunopharmacology and Immunotoxicology",
    "Immunotechnology": "Immunotechnology",
    "Indian Dermatology Online Journal": "Indian Dermatology Online Journal",
    "Indian Heart Journal": "Indian Heart Journal",
    "Indian Journal of Dermatology": "Indian Journal of Dermatology",
    "Indian Journal of Dermatology and Venereology": "Indian Journal of Dermatology and Venereology",
    "Indian Journal of Ophthalmology": "Indian Journal of Ophthalmology",
    "Indian Journal of Orthopaedics": "Indian Journal of Orthopaedics",
    "Indian Journal of Otolaryngology": "Indian Journal of Otolaryngology",
    "Indian Journal of Surgical Oncology": "Indian Journal of Surgical Oncology",
    "Infant Mental Health Journal": "Infant Mental Health Journal",
    "Infection and Immunity": "Infection and Immunity",
    "Infectious Disease Clinics of North America": "Infectious Disease Clinics of North America",
    "Inflammation Research": "Inflammation Research",
    "Inflammatory Bowel Diseases": "Inflammatory Bowel Diseases",
    "Inflammopharmacology": "Inflammopharmacology",
    "Influenza and Other Respiratory Viruses": "Influenza and Other Respiratory Viruses",
    "Innovation in Aging": "Innovation in Aging",
    "Innovative Higher Education": "Innovative Higher Education",
    "Insights Imaging": "Insights Imaging",
    "Integrative Medicine Research": "Integrative Medicine Research",
    "Intelligence-based Medicine": "Intelligence-based Medicine",
    "Intensive Care Medicine": "Intensive Care Medicine",
    "Interactive Cardiovascular and Thoracic Surgery": "Interactive Cardiovascular and Thoracic Surgery",
    "Interdisciplinary Perspectives on Infectious Diseases": "Interdisciplinary Perspectives on Infectious Diseases",
    "Interface Focus": "Interface Focus",
    "Internal Medicine Journal": "Internal Medicine Journal",
    "Internal and Emergency Medicine": "Internal and Emergency Medicine",
    "International Advances in Surgical Oncology": "International Advances in Surgical Oncology",
    "International Archives of Allergy and Immunology": "International Archives of Allergy and Immunology",
    "International Archives of Occupational and Environmental Health": "International Archives of Occupational and Environmental Health",
    "International Breastfeeding Journal": "International Breastfeeding Journal",
    "International Clinical Psychopharmacology": "International Clinical Psychopharmacology",
    "International Emergency Nursing": "International Emergency Nursing",
    "International Gambling Studies": "International Gambling Studies",
    "International Immunology": "International Immunology",
    "International Immunopharmacology": "International Immunopharmacology",
    "International Journal Of Medical Education": "International Journal Of Medical Education",
    "International Journal of Antimicrobial Agents": "International Journal of Antimicrobial Agents",
    "International Journal of Approximate Reasoning": "International Journal of Approximate Reasoning",
    "International Journal of Audiology": "International Journal of Audiology",
    "International Journal of Bioinformatics Research and Applications": "International Journal of Bioinformatics Research and Applications",
    "International Journal of Cancer": "International Journal of Cancer",
    "International Journal of Cardiac Imaging": "International Journal of Cardiac Imaging",
    "International Journal of Cardiology": "International Journal of Cardiology",
    "International Journal of Chronic Obstructive Pulmonary Disease": "International Journal of Chronic Obstructive Pulmonary Disease",
    "International Journal of Circumpolar Health": "International Journal of Circumpolar Health",
    "International Journal of Clinical Practice": "International Journal of Clinical Practice",
    "International Journal of Computer Assisted Radiology and Surgery": "International Journal of Computer Assisted Radiology and Surgery",
    "International Journal of Dermatology": "International Journal of Dermatology",
    "International Journal of Dermatology and Venereology": "International Journal of Dermatology and Venereology",
    "International Journal of Environmental Research and Public Health": "International Journal of Environmental Research and Public Health",
    "International Journal of Epidemiology": "International Journal of Epidemiology",
    "International Journal of Experimental Pathology": "International Journal of Experimental Pathology",
    "International Journal of Food Microbiology": "International Journal of Food Microbiology",
    "International Journal of General Medicine": "International Journal of General Medicine",
    "International Journal of Geriatric Psychiatry": "International Journal of Geriatric Psychiatry",
    "International Journal of Gynecological Cancer": "International Journal of Gynecological Cancer",
    "International Journal of Gynecological Pathology": "International Journal of Gynecological Pathology",
    "International Journal of Health Economics and Management": "International Journal of Health Economics and Management",
    "International Journal of Health Geographics": "International Journal of Health Geographics",
    "International Journal of Health Services": "International Journal of Health Services",
    "International Journal of Hematology": "International Journal of Hematology",
    "International Journal of Hygiene and Environmental Health": "International Journal of Hygiene and Environmental Health",
    "International Journal of Hyperthermia": "International Journal of Hyperthermia",
    "International Journal of Immunogenetics": "International Journal of Immunogenetics",
    "International Journal of Immunopathology and Pharmacology": "International Journal of Immunopathology and Pharmacology",
    "International Journal of Immunopharmacology": "International Journal of Immunopharmacology",
    "International Journal of Infectious Diseases": "International Journal of Infectious Diseases",
    "International Journal of Laboratory Hematology": "International Journal of Laboratory Hematology",
    "International Journal of Law and Psychiatry": "International Journal of Law and Psychiatry",
    "International Journal of Legal Medicine": "International Journal of Legal Medicine",
    "International Journal of Medical Engineering and Informatics": "International Journal of Medical Engineering and Informatics",
    "International Journal of Medical Informatics": "International Journal of Medical Informatics",
    "International Journal of Medical Microbiology": "International Journal of Medical Microbiology",
    "International Journal of Medical Sciences": "International Journal of Medical Sciences",
    "International Journal of Mental Health Nursing": "International Journal of Mental Health Nursing",
    "International Journal of Mental Health and Addiction": "International Journal of Mental Health and Addiction",
    "International Journal of Methods in Psychiatric Research": "International Journal of Methods in Psychiatric Research",
    "International Journal of Molecular Sciences": "International Journal of Molecular Sciences",
    "International Journal of Neonatal Screening": "International Journal of Neonatal Screening",
    "International Journal of Neuropsychopharmacology": "International Journal of Neuropsychopharmacology",
    "International Journal of Nursing Knowledge": "International Journal of Nursing Knowledge",
    "International Journal of Nursing Practice": "International Journal of Nursing Practice",
    "International Journal of Nursing Studies": "International Journal of Nursing Studies",
    "International Journal of Obesity": "International Journal of Obesity",
    "International Journal of Obstetric Anesthesia": "International Journal of Obstetric Anesthesia",
    "International Journal of Older People Nursing": "International Journal of Older People Nursing",
    "International Journal of Ophthalmology": "International Journal of Ophthalmology",
    "International Journal of Oral Science": "International Journal of Oral Science",
    "International Journal of Paleopathology": "International Journal of Paleopathology",
    "International Journal of Palliative Nursing": "International Journal of Palliative Nursing",
    "International Journal of Pediatric Obesity": "International Journal of Pediatric Obesity",
    "International Journal of Pharmaceutics": "International Journal of Pharmaceutics",
    "International Journal of Psychiatry in Clinical Practice": "International Journal of Psychiatry in Clinical Practice",
    "International Journal of Psychophysiology": "International Journal of Psychophysiology",
    "International Journal of Public Health": "International Journal of Public Health",
    "International Journal of Qualitative Studies on Health and Well-being": "International Journal of Qualitative Studies on Health and Well-being",
    "International Journal of Radiation Biology": "International Journal of Radiation Biology",
    "International Journal of Reproductive BioMedicine": "International Journal of Reproductive BioMedicine",
    "International Journal of Rheumatic Diseases": "International Journal of Rheumatic Diseases",
    "International Journal of Sports Physiology and Performance": "International Journal of Sports Physiology and Performance",
    "International Journal of Stroke": "International Journal of Stroke",
    "International Journal of Surgery Protocols": "International Journal of Surgery Protocols",
    "International Journal of Surgical Oncology": "International Journal of Surgical Oncology",
    "International Journal of Surgical Pathology": "International Journal of Surgical Pathology",
    "International Journal of Systematic and Evolutionary Microbiology": "International Journal of Systematic and Evolutionary Microbiology",
    "International Journal of Technology Assessment in Health Care": "International Journal of Technology Assessment in Health Care",
    "International Journal of Urology": "International Journal of Urology",
    "International Journal of Vascular Medicine": "International Journal of Vascular Medicine",
    "International Nursing Review": "International Nursing Review",
    "International Ophthalmology": "International Ophthalmology",
    "International Orthopaedics": "International Orthopaedics",
    "International Statistical Review": "International Statistical Review",
    "International Surgery": "International Surgery",
    "International Urogynecology Journal": "International Urogynecology Journal",
    "International Urology and Nephrology": "International Urology and Nephrology",
    "International journal of Telemedicine and Applications": "International journal of Telemedicine and Applications",
    "International journal of endocrinology": "International journal of endocrinology",
    "Internet Interventions": "Internet Interventions",
    "Investigational New Drugs": "Investigational New Drugs",
    "Investigative Radiology": "Investigative Radiology",
    "Investigative and Clinical Urology": "Investigative and Clinical Urology",
    "Iranian Journal of Kidney Diseases": "Iranian Journal of Kidney Diseases",
    "Iranian Journal of Medical Sciences": "Iranian Journal of Medical Sciences",
    "Irish Journal of Medical Science": "Irish Journal of Medical Science",
    "Italian General Review of Dermatology": "Italian General Review of Dermatology",
    "Italian Journal of Pediatrics": "Italian Journal of Pediatrics",
    "JA Clinical Reports": "JA Clinical Reports",
    "JACC Case Reports": "JACC Case Reports",
    "JAMA": "JAMA",
    "JAMA Cardiology": "JAMA Cardiology",
    "JAMA Dermatology": "JAMA Dermatology",
    "JAMA Health Forum": "JAMA Health Forum",
    "JAMA Internal Medicine": "JAMA Internal Medicine",
    "JAMA Network Open": "JAMA Network Open",
    "JAMA Neurology": "JAMA Neurology",
    "JAMA Oncology": "JAMA Oncology",
    "JAMA Ophthalmology": "JAMA Ophthalmology",
    "JAMA Pediatrics": "JAMA Pediatrics",
    "JAMA Psychiatry": "JAMA Psychiatry",
    "JAMA Surgery": "JAMA Surgery",
    "JAMIA Open": "JAMIA Open",
    "JCO Precision Oncology": "JCO Precision Oncology",
    "JCO clinical cancer informatics": "JCO clinical cancer informatics",
    "JMA Journal": "JMA Journal",
    "JMIR Formative Research": "JMIR Formative Research",
    "JMIR Mental Health": "JMIR Mental Health",
    "JMIR Perioperative Medicine": "JMIR Perioperative Medicine",
    "JMIR Research Protocols": "JMIR Research Protocols",
    "JMIR Serious Games": "JMIR Serious Games",
    "JMIR mHealth and uHealth": "JMIR mHealth and uHealth",
    "JNCI Cancer Spectrum": "JNCI Cancer Spectrum",
    "Japanese Journal of Radiology": "Japanese Journal of Radiology",
    "Joint Bone Spine": "Joint Bone Spine",
    "Joint Diseases and Related Surgery": "Joint Diseases and Related Surgery",
    "Journal Francais d'Ophtalmologie": "Journal Francais d'Ophtalmologie",
    "Journal de Physiologie": "Journal de Physiologie",
    "Journal for Healthcare Quality": "Journal for Healthcare Quality",
    "Journal for Immunotherapy of Cancer": "Journal for Immunotherapy of Cancer",
    "Journal of AHIMA": "Journal of AHIMA",
    "Journal of AOAC INTERNATIONAL": "Journal of AOAC INTERNATIONAL",
    "Journal of Academic Ethics": "Journal of Academic Ethics",
    "Journal of Advanced Nursing": "Journal of Advanced Nursing",
    "Journal of Affective Disorders": "Journal of Affective Disorders",
    "Journal of Aging and Physical Activity": "Journal of Aging and Physical Activity",
    "Journal of Analytical Toxicology": "Journal of Analytical Toxicology",
    "Journal of Andrology": "Journal of Andrology",
    "Journal of Anesthesia": "Journal of Anesthesia",
    "Journal of Antimicrobial Chemotherapy": "Journal of Antimicrobial Chemotherapy",
    "Journal of Applied Clinical Medical Physics": "Journal of Applied Clinical Medical Physics",
    "Journal of Applied Microbiology": "Journal of Applied Microbiology",
    "Journal of Applied Oral Science": "Journal of Applied Oral Science",
    "Journal of Applied Probability": "Journal of Applied Probability",
    "Journal of Applied Statistics": "Journal of Applied Statistics",
    "Journal of Applied Toxicology": "Journal of Applied Toxicology",
    "Journal of Arrhythmia": "Journal of Arrhythmia",
    "Journal of Assisted Reproduction and Genetics": "Journal of Assisted Reproduction and Genetics",
    "Journal of Attention Disorders": "Journal of Attention Disorders",
    "Journal of Autoimmunity": "Journal of Autoimmunity",
    "Journal of Autonomic Pharmacology": "Journal of Autonomic Pharmacology",
    "Journal of Bacteriology": "Journal of Bacteriology",
    "Journal of Behavioral Addictions": "Journal of Behavioral Addictions",
    "Journal of Biochemical and Molecular Toxicology": "Journal of Biochemical and Molecular Toxicology",
    "Journal of Biochemistry": "Journal of Biochemistry",
    "Journal of Bioethical Inquiry": "Journal of Bioethical Inquiry",
    "Journal of Bioinformatics and Computational Biology": "Journal of Bioinformatics and Computational Biology",
    "Journal of Biological Engineering": "Journal of Biological Engineering",
    "Journal of Biological Inorganic Chemistry": "Journal of Biological Inorganic Chemistry",
    "Journal of Biological Rhythms": "Journal of Biological Rhythms",
    "Journal of Biological Systems": "Journal of Biological Systems",
    "Journal of Biomedical Informatics": "Journal of Biomedical Informatics",
    "Journal of Biomedical Optics": "Journal of Biomedical Optics",
    "Journal of Biomedical Semantics": "Journal of Biomedical Semantics",
    "Journal of Biomolecular NMR": "Journal of Biomolecular NMR",
    "Journal of Biopharmaceutical Statistics": "Journal of Biopharmaceutical Statistics",
    "Journal of Biophotonics": "Journal of Biophotonics",
    "Journal of Bone Oncology": "Journal of Bone Oncology",
    "Journal of Bone and Mineral Metabolism": "Journal of Bone and Mineral Metabolism",
    "Journal of Bone and Mineral Research": "Journal of Bone and Mineral Research",
    "Journal of Burn Care & Research": "Journal of Burn Care & Research",
    "Journal of Cancer Survivorship": "Journal of Cancer Survivorship",
    "Journal of Cardiac Failure": "Journal of Cardiac Failure",
    "Journal of Cardiac Surgery": "Journal of Cardiac Surgery",
    "Journal of Cardiology": "Journal of Cardiology",
    "Journal of Cardiothoracic Surgery": "Journal of Cardiothoracic Surgery",
    "Journal of Cardiothoracic and Vascular Anesthesia": "Journal of Cardiothoracic and Vascular Anesthesia",
    "Journal of Cardiovascular Computed Tomography": "Journal of Cardiovascular Computed Tomography",
    "Journal of Cardiovascular Development and Disease": "Journal of Cardiovascular Development and Disease",
    "Journal of Cardiovascular Electrophysiology": "Journal of Cardiovascular Electrophysiology",
    "Journal of Cardiovascular Magnetic Resonance": "Journal of Cardiovascular Magnetic Resonance",
    "Journal of Cardiovascular Pharmacology and Therapeutics": "Journal of Cardiovascular Pharmacology and Therapeutics",
    "Journal of Causal Inference": "Journal of Causal Inference",
    "Journal of Cell Science": "Journal of Cell Science",
    "Journal of Cellular Biochemistry": "Journal of Cellular Biochemistry",
    "Journal of Cellular Physiology": "Journal of Cellular Physiology",
    "Journal of Cerebral Blood Flow & Metabolism": "Journal of Cerebral Blood Flow & Metabolism",
    "Journal of Chemical Neuroanatomy": "Journal of Chemical Neuroanatomy",
    "Journal of Child & Adolescent Trauma": "Journal of Child & Adolescent Trauma",
    "Journal of Child Health Care": "Journal of Child Health Care",
    "Journal of Child Neurology": "Journal of Child Neurology",
    "Journal of Child and Family Studies": "Journal of Child and Family Studies",
    "Journal of Clinical & Translational Endocrinology": "Journal of Clinical & Translational Endocrinology",
    "Journal of Clinical Anesthesia": "Journal of Clinical Anesthesia",
    "Journal of Clinical Apheresis": "Journal of Clinical Apheresis",
    "Journal of Clinical Epidemiology": "Journal of Clinical Epidemiology",
    "Journal of Clinical Gastroenterology": "Journal of Clinical Gastroenterology",
    "Journal of Clinical Gastroenterology and Hepatology": "Journal of Clinical Gastroenterology and Hepatology",
    "Journal of Clinical Hypertension": "Journal of Clinical Hypertension",
    "Journal of Clinical Immunology": "Journal of Clinical Immunology",
    "Journal of Clinical Lipidology": "Journal of Clinical Lipidology",
    "Journal of Clinical Medicine": "Journal of Clinical Medicine",
    "Journal of Clinical Microbiology": "Journal of Clinical Microbiology",
    "Journal of Clinical Neurophysiology": "Journal of Clinical Neurophysiology",
    "Journal of Clinical Neuroscience": "Journal of Clinical Neuroscience",
    "Journal of Clinical Nursing": "Journal of Clinical Nursing",
    "Journal of Clinical Oncology": "Journal of Clinical Oncology",
    "Journal of Clinical Pathology": "Journal of Clinical Pathology",
    "Journal of Clinical Pharmacology": "Journal of Clinical Pharmacology",
    "Journal of Clinical Rheumatology": "Journal of Clinical Rheumatology",
    "Journal of Clinical Ultrasound": "Journal of Clinical Ultrasound",
    "Journal of Clinical Urology": "Journal of Clinical Urology",
    "Journal of Clinical Virology": "Journal of Clinical Virology",
    "Journal of Cognitive Neuroscience": "Journal of Cognitive Neuroscience",
    "Journal of Community Health": "Journal of Community Health",
    "Journal of Comparative Pathology": "Journal of Comparative Pathology",
    "Journal of Computational Biology": "Journal of Computational Biology",
    "Journal of Computer Assisted Tomography": "Journal of Computer Assisted Tomography",
    "Journal of Computer-Aided Molecular Design": "Journal of Computer-Aided Molecular Design",
    "Journal of Continuing Education in Nursing": "Journal of Continuing Education in Nursing",
    "Journal of Cosmetic Dermatology": "Journal of Cosmetic Dermatology",
    "Journal of Critical Care": "Journal of Critical Care",
    "Journal of Current Ophthalmology": "Journal of Current Ophthalmology",
    "Journal of Cutaneous Pathology": "Journal of Cutaneous Pathology",
    "Journal of Cystic Fibrosis": "Journal of Cystic Fibrosis",
    "Journal of Dental Education": "Journal of Dental Education",
    "Journal of Developmental and Behavioral Pediatrics": "Journal of Developmental and Behavioral Pediatrics",
    "Journal of Diabetes": "Journal of Diabetes",
    "Journal of Diabetes Research": "Journal of Diabetes Research",
    "Journal of Digestive Diseases": "Journal of Digestive Diseases",
    "Journal of Digestive Endoscopy": "Journal of Digestive Endoscopy",
    "Journal of Digital Imaging": "Journal of Digital Imaging",
    "Journal of Drug Delivery Science and Technology": "Journal of Drug Delivery Science and Technology",
    "Journal of Dual Diagnosis": "Journal of Dual Diagnosis",
    "Journal of Electrocardiology": "Journal of Electrocardiology",
    "Journal of Electromyography and Kinesiology": "Journal of Electromyography and Kinesiology",
    "Journal of Emergency Nursing": "Journal of Emergency Nursing",
    "Journal of Empirical Research on Human Research Ethics": "Journal of Empirical Research on Human Research Ethics",
    "Journal of Endotoxin Research": "Journal of Endotoxin Research",
    "Journal of Endourology": "Journal of Endourology",
    "Journal of Epidemiology": "Journal of Epidemiology",
    "Journal of Epidemiology and Community Health": "Journal of Epidemiology and Community Health",
    "Journal of Epidemiology and Global Health": "Journal of Epidemiology and Global Health",
    "Journal of Ethnicity in Substance Abuse": "Journal of Ethnicity in Substance Abuse",
    "Journal of Ethnopharmacology": "Journal of Ethnopharmacology",
    "Journal of Evaluation in Clinical Practice": "Journal of Evaluation in Clinical Practice",
    "Journal of Experimental Pharmacology": "Journal of Experimental Pharmacology",
    "Journal of Extracellular Vesicles": "Journal of Extracellular Vesicles",
    "Journal of Family Nursing": "Journal of Family Nursing",
    "Journal of Fluorescence": "Journal of Fluorescence",
    "Journal of Food and Drug Analysis": "Journal of Food and Drug Analysis",
    "Journal of Foot and Ankle Research": "Journal of Foot and Ankle Research",
    "Journal of Forensic Sciences": "Journal of Forensic Sciences",
    "Journal of Forensic and Legal Medicine": "Journal of Forensic and Legal Medicine",
    "Journal of Fungi": "Journal of Fungi",
    "Journal of Gastric Cancer": "Journal of Gastric Cancer",
    "Journal of Gastroenterology": "Journal of Gastroenterology",
    "Journal of Gastroenterology and Hepatology": "Journal of Gastroenterology and Hepatology",
    "Journal of Gastrointestinal Oncology": "Journal of Gastrointestinal Oncology",
    "Journal of Gastrointestinal Surgery": "Journal of Gastrointestinal Surgery",
    "Journal of General Internal Medicine": "Journal of General Internal Medicine",
    "Journal of Genetic Counseling": "Journal of Genetic Counseling",
    "Journal of Genetics and Genomics": "Journal of Genetics and Genomics",
    "Journal of Geriatric Oncology": "Journal of Geriatric Oncology",
    "Journal of Geriatric Psychiatry and Neurology": "Journal of Geriatric Psychiatry and Neurology",
    "Journal of Glaucoma": "Journal of Glaucoma",
    "Journal of Global Antimicrobial Resistance": "Journal of Global Antimicrobial Resistance",
    "Journal of Gynecology Obstetrics and Human Reproduction": "Journal of Gynecology Obstetrics and Human Reproduction",
    "Journal of Hand Surgery Global Online": "Journal of Hand Surgery Global Online",
    "Journal of Hand Therapy": "Journal of Hand Therapy",
    "Journal of Health Economics": "Journal of Health Economics",
    "Journal of Health informatics": "Journal of Health informatics",
    "Journal of Healthcare Engineering": "Journal of Healthcare Engineering",
    "Journal of Healthcare Informatics Research": "Journal of Healthcare Informatics Research",
    "Journal of Healthcare Information Management": "Journal of Healthcare Information Management",
    "Journal of Hematology and Oncology": "Journal of Hematology and Oncology",
    "Journal of Hepato-Biliary-Pancreatic Sciences": "Journal of Hepato-Biliary-Pancreatic Sciences",
    "Journal of Hepatology": "Journal of Hepatology",
    "Journal of Hospital Medicine": "Journal of Hospital Medicine",
    "Journal of Human Genetics": "Journal of Human Genetics",
    "Journal of Human Lactation": "Journal of Human Lactation",
    "Journal of Hypertension": "Journal of Hypertension",
    "Journal of Imaging": "Journal of Imaging",
    "Journal of Immunoassay": "Journal of Immunoassay",
    "Journal of Immunoassay & Immunochemistry": "Journal of Immunoassay & Immunochemistry",
    "Journal of Immunological Methods": "Journal of Immunological Methods",
    "Journal of Immunology Research": "Journal of Immunology Research",
    "Journal of Infection and Chemotherapy": "Journal of Infection and Chemotherapy",
    "Journal of Infection and Public Health": "Journal of Infection and Public Health",
    "Journal of Inflammation": "Journal of Inflammation",
    "Journal of Inflammation Research": "Journal of Inflammation Research",
    "Journal of Inorganic Biochemistry": "Journal of Inorganic Biochemistry",
    "Journal of Integrative Neuroscience": "Journal of Integrative Neuroscience",
    "Journal of Intensive Care": "Journal of Intensive Care",
    "Journal of Interferon & Cytokine Research": "Journal of Interferon & Cytokine Research",
    "Journal of Interventional Cardiac Electrophysiology": "Journal of Interventional Cardiac Electrophysiology",
    "Journal of Investigative Medicine": "Journal of Investigative Medicine",
    "Journal of Leukocyte Biology": "Journal of Leukocyte Biology",
    "Journal of Lipid Research": "Journal of Lipid Research",
    "Journal of Mass Spectrometry": "Journal of Mass Spectrometry",
    "Journal of Mathematical Biology": "Journal of Mathematical Biology",
    "Journal of Mathematical Neuroscience": "Journal of Mathematical Neuroscience",
    "Journal of Medical Case Reports": "Journal of Medical Case Reports",
    "Journal of Medical Ethics": "Journal of Medical Ethics",
    "Journal of Medical Genetics": "Journal of Medical Genetics",
    "Journal of Medical Imaging and Health Informatics": "Journal of Medical Imaging and Health Informatics",
    "Journal of Medical Imaging and Radiation Oncology": "Journal of Medical Imaging and Radiation Oncology",
    "Journal of Medical Internet Research": "Journal of Medical Internet Research",
    "Journal of Medical Microbiology": "Journal of Medical Microbiology",
    "Journal of Medical Signals and Sensors": "Journal of Medical Signals and Sensors",
    "Journal of Medical Systems": "Journal of Medical Systems",
    "Journal of Medical Ultrasound": "Journal of Medical Ultrasound",
    "Journal of Medical Virology": "Journal of Medical Virology",
    "Journal of Midwifery & Women's Health": "Journal of Midwifery & Women's Health",
    "Journal of Minimal Access Surgery": "Journal of Minimal Access Surgery",
    "Journal of Molecular Endocrinology": "Journal of Molecular Endocrinology",
    "Journal of Molecular Medicine": "Journal of Molecular Medicine",
    "Journal of Molecular Neuroscience": "Journal of Molecular Neuroscience",
    "Journal of Molecular and Cellular Cardiology": "Journal of Molecular and Cellular Cardiology",
    "Journal of Morphology": "Journal of Morphology",
    "Journal of Motor Behavior": "Journal of Motor Behavior",
    "Journal of Multidisciplinary Healthcare": "Journal of Multidisciplinary Healthcare",
    "Journal of Musculoskeletal Pain": "Journal of Musculoskeletal Pain",
    "Journal of Nanobiotechnology": "Journal of Nanobiotechnology",
    "Journal of Natural Products": "Journal of Natural Products",
    "Journal of Nephrology": "Journal of Nephrology",
    "Journal of Neural Engineering": "Journal of Neural Engineering",
    "Journal of Neural Transmission": "Journal of Neural Transmission",
    "Journal of Neuro-Ophthalmology": "Journal of Neuro-Ophthalmology",
    "Journal of Neurochemistry": "Journal of Neurochemistry",
    "Journal of Neurogenetics": "Journal of Neurogenetics",
    "Journal of Neuroimaging": "Journal of Neuroimaging",
    "Journal of Neuroimmune Pharmacology": "Journal of Neuroimmune Pharmacology",
    "Journal of Neuroimmunology": "Journal of Neuroimmunology",
    "Journal of Neuroinflammation": "Journal of Neuroinflammation",
    "Journal of Neurolinguistics": "Journal of Neurolinguistics",
    "Journal of Neurologic Physical Therapy": "Journal of Neurologic Physical Therapy",
    "Journal of Neurology": "Journal of Neurology",
    "Journal of Neuroradiology": "Journal of Neuroradiology",
    "Journal of Neuroscience Methods": "Journal of Neuroscience Methods",
    "Journal of Neuroscience Research": "Journal of Neuroscience Research",
    "Journal of Neurosurgery": "Journal of Neurosurgery",
    "Journal of Neurosurgical Anesthesiology": "Journal of Neurosurgical Anesthesiology",
    "Journal of Neurosurgical Sciences": "Journal of Neurosurgical Sciences",
    "Journal of Neurotrauma": "Journal of Neurotrauma",
    "Journal of Neurovirology": "Journal of Neurovirology",
    "Journal of Nuclear Medicine": "Journal of Nuclear Medicine",
    "Journal of Nuclear Medicine Technology": "Journal of Nuclear Medicine Technology",
    "Journal of Nursing Care Quality": "Journal of Nursing Care Quality",
    "Journal of Nursing Scholarship": "Journal of Nursing Scholarship",
    "Journal of Obstetrics and Gynaecology": "Journal of Obstetrics and Gynaecology",
    "Journal of Occupational Health": "Journal of Occupational Health",
    "Journal of Occupational and Environmental Medicine": "Journal of Occupational and Environmental Medicine",
    "Journal of Ocular Pharmacology and Therapeutics": "Journal of Ocular Pharmacology and Therapeutics",
    "Journal of Ophthalmic Inflammation and Infection": "Journal of Ophthalmic Inflammation and Infection",
    "Journal of Ophthalmology": "Journal of Ophthalmology",
    "Journal of Oral Pathology & Medicine": "Journal of Oral Pathology & Medicine",
    "Journal of Orthopaedic Research": "Journal of Orthopaedic Research",
    "Journal of Orthopaedic Science": "Journal of Orthopaedic Science",
    "Journal of Orthopaedic Surgery and Research": "Journal of Orthopaedic Surgery and Research",
    "Journal of Orthopaedic Translation": "Journal of Orthopaedic Translation",
    "Journal of Ovarian Research": "Journal of Ovarian Research",
    "Journal of Paediatrics and Child Health": "Journal of Paediatrics and Child Health",
    "Journal of Pain and Symptom Management": "Journal of Pain and Symptom Management",
    "Journal of Palliative Care": "Journal of Palliative Care",
    "Journal of Palliative Medicine": "Journal of Palliative Medicine",
    "Journal of Parkinson's Disease": "Journal of Parkinson's Disease",
    "Journal of Pathology Informatics": "Journal of Pathology Informatics",
    "Journal of Patient Safety": "Journal of Patient Safety",
    "Journal of Patient-Reported Outcomes": "Journal of Patient-Reported Outcomes",
    "Journal of Pediatric Gastroenterology and Nutrition": "Journal of Pediatric Gastroenterology and Nutrition",
    "Journal of Pediatric Health Care": "Journal of Pediatric Health Care",
    "Journal of Pediatric Infectious Diseases": "Journal of Pediatric Infectious Diseases",
    "Journal of Pediatric Nursing": "Journal of Pediatric Nursing",
    "Journal of Pediatric Surgery": "Journal of Pediatric Surgery",
    "Journal of Pediatric Urology": "Journal of Pediatric Urology",
    "Journal of Pediatric and Adolescent Gynecology": "Journal of Pediatric and Adolescent Gynecology",
    "Journal of Peptide Science": "Journal of Peptide Science",
    "Journal of Perinatal Medicine": "Journal of Perinatal Medicine",
    "Journal of Perinatology": "Journal of Perinatology",
    "Journal of Personalized Medicine": "Journal of Personalized Medicine",
    "Journal of Pharmaceutical Policy and Practice ": "Journal of Pharmaceutical Policy and Practice ",
    "Journal of Pharmaceutical Sciences": "Journal of Pharmaceutical Sciences",
    "Journal of Pharmacokinetics and Pharmacodynamics": "Journal of Pharmacokinetics and Pharmacodynamics",
    "Journal of Pharmacological Sciences": "Journal of Pharmacological Sciences",
    "Journal of Pharmacological and Toxicological Methods": "Journal of Pharmacological and Toxicological Methods",
    "Journal of Physiology and Biochemistry": "Journal of Physiology and Biochemistry",
    "Journal of Pineal Research": "Journal of Pineal Research",
    "Journal of Plastic Surgery and Hand Surgery": "Journal of Plastic Surgery and Hand Surgery",
    "Journal of Postgraduate Medicine": "Journal of Postgraduate Medicine",
    "Journal of Primary Care & Community Health": "Journal of Primary Care & Community Health",
    "Journal of Professional Nursing": "Journal of Professional Nursing",
    "Journal of Proteome Research": "Journal of Proteome Research",
    "Journal of Proteomics": "Journal of Proteomics",
    "Journal of Psychiatric Practice": "Journal of Psychiatric Practice",
    "Journal of Psychiatric Research": "Journal of Psychiatric Research",
    "Journal of Psychiatric and Mental Health Nursing": "Journal of Psychiatric and Mental Health Nursing",
    "Journal of Psychoactive Drugs": "Journal of Psychoactive Drugs",
    "Journal of Psychophysiology": "Journal of Psychophysiology",
    "Journal of Psychosomatic Obstetrics and Gynaecology": "Journal of Psychosomatic Obstetrics and Gynaecology",
    "Journal of Psychosomatic Research": "Journal of Psychosomatic Research",
    "Journal of Public Health": "Journal of Public Health",
    "Journal of Public Health Dentistry": "Journal of Public Health Dentistry",
    "Journal of Public Health Policy": "Journal of Public Health Policy",
    "Journal of Racial and Ethnic Health Disparities": "Journal of Racial and Ethnic Health Disparities",
    "Journal of Radiological Protection": "Journal of Radiological Protection",
    "Journal of Reconstructive Microsurgery": "Journal of Reconstructive Microsurgery",
    "Journal of Renal Care": "Journal of Renal Care",
    "Journal of Renal Nutrition": "Journal of Renal Nutrition",
    "Journal of Reproductive Immunology": "Journal of Reproductive Immunology",
    "Journal of Reproductive Medicine": "Journal of Reproductive Medicine",
    "Journal of Robotic Surgery": "Journal of Robotic Surgery",
    "Journal of Safety Research": "Journal of Safety Research",
    "Journal of Shoulder and Elbow Surgery": "Journal of Shoulder and Elbow Surgery",
    "Journal of Sleep Research": "Journal of Sleep Research",
    "Journal of Social Work in End-of-Life & Palliative Care": "Journal of Social Work in End-of-Life & Palliative Care",
    "Journal of Spinal Disorders & Techniques": "Journal of Spinal Disorders & Techniques",
    "Journal of Stroke and Cerebrovascular Diseases": "Journal of Stroke and Cerebrovascular Diseases",
    "Journal of Structural Biology": "Journal of Structural Biology",
    "Journal of Substance Abuse Treatment": "Journal of Substance Abuse Treatment",
    "Journal of Substance Use": "Journal of Substance Use",
    "Journal of Surgical Education": "Journal of Surgical Education",
    "Journal of Surgical Oncology": "Journal of Surgical Oncology",
    "Journal of Synchrotron Radiation": "Journal of Synchrotron Radiation",
    "Journal of Telemedicine and Telecare": "Journal of Telemedicine and Telecare",
    "Journal of Theoretical Biology": "Journal of Theoretical Biology",
    "Journal of Theoretical Probability": "Journal of Theoretical Probability",
    "Journal of Thoracic Disease": "Journal of Thoracic Disease",
    "Journal of Thoracic Oncology": "Journal of Thoracic Oncology",
    "Journal of Thrombosis and Thrombolysis": "Journal of Thrombosis and Thrombolysis",
    "Journal of Time Series Analysis": "Journal of Time Series Analysis",
    "Journal of Tissue Viability": "Journal of Tissue Viability",
    "Journal of Toxicologic Pathology": "Journal of Toxicologic Pathology",
    "Journal of Trace Elements in Medicine and Biology": "Journal of Trace Elements in Medicine and Biology",
    "Journal of Translational Medicine": "Journal of Translational Medicine",
    "Journal of Trauma Nursing": "Journal of Trauma Nursing",
    "Journal of Urban Health": "Journal of Urban Health",
    "Journal of Vascular Surgery": "Journal of Vascular Surgery",
    "Journal of Vascular Surgery Cases and Innovative Techniques": "Journal of Vascular Surgery Cases and Innovative Techniques",
    "Journal of Vestibular Research": "Journal of Vestibular Research",
    "Journal of Virological Methods": "Journal of Virological Methods",
    "Journal of Virology": "Journal of Virology",
    "Journal of Virus Eradication": "Journal of Virus Eradication",
    "Journal of Visceral Surgery": "Journal of Visceral Surgery",
    "Journal of Vision": "Journal of Vision",
    "Journal of Voice": "Journal of Voice",
    "Journal of Women's Health": "Journal of Women's Health",
    "Journal of global health": "Journal of global health",
    "Journal of hematopathology": "Journal of hematopathology",
    "Journal of neurological surgery. Part B, Skull base": "Journal of neurological surgery. Part B, Skull base",
    "Journal of the American Academy of Audiology": "Journal of the American Academy of Audiology",
    "Journal of the American Academy of Child and Adolescent Psychiatry": "Journal of the American Academy of Child and Adolescent Psychiatry",
    "Journal of the American Academy of Dermatology": "Journal of the American Academy of Dermatology",
    "Journal of the American College of Cardiology": "Journal of the American College of Cardiology",
    "Journal of the American Heart Association": "Journal of the American Heart Association",
    "Journal of the American Medical Directors Association": "Journal of the American Medical Directors Association",
    "Journal of the American Medical Informatics Association": "Journal of the American Medical Informatics Association",
    "Journal of the American Psychiatric Nurses Association": "Journal of the American Psychiatric Nurses Association",
    "Journal of the American Society of Echocardiography": "Journal of the American Society of Echocardiography",
    "Journal of the Belgian Society of Radiology": "Journal of the Belgian Society of Radiology",
    "Journal of the Canadian Association of Gastroenterology": "Journal of the Canadian Association of Gastroenterology",
    "Journal of the Endocrine Society": "Journal of the Endocrine Society",
    "Journal of the European Academy of Dermatology and Venereology": "Journal of the European Academy of Dermatology and Venereology",
    "Journal of the History of the Neurosciences": "Journal of the History of the Neurosciences",
    "Journal of the National Cancer Institute": "Journal of the National Cancer Institute",
    "Journal of the National Medical Association": "Journal of the National Medical Association",
    "Journal of the Royal Society of Medicine": "Journal of the Royal Society of Medicine",
    "Kidney International": "Kidney International",
    "Kidney International Reports": "Kidney International Reports",
    "Kidney360": "Kidney360",
    "Korean Journal of Pathology": "Korean Journal of Pathology",
    "Korean Journal of Radiology": "Korean Journal of Radiology",
    "Laryngoscope Investigative Otolaryngology": "Laryngoscope Investigative Otolaryngology",
    "Leprosy Review": "Leprosy Review",
    "Letters in Applied Microbiology": "Letters in Applied Microbiology",
    "Life (Basel)": "Life (Basel)",
    "Life Science Alliance": "Life Science Alliance",
    "Lipids in Health and Disease": "Lipids in Health and Disease",
    "Liver International": "Liver International",
    "Local and Regional Anesthesia": "Local and Regional Anesthesia",
    "Macromolecular Bioscience": "Macromolecular Bioscience",
    "Magnetic Resonance in Medical Sciences": "Magnetic Resonance in Medical Sciences",
    "Malaria Journal": "Malaria Journal",
    "Mathematical Biosciences": "Mathematical Biosciences",
    "Mathematical Population Studies": "Mathematical Population Studies",
    "Mathematical Social Sciences": "Mathematical Social Sciences",
    "Maturitas": "Maturitas",
    "Mayo Clinic Proceedings": "Mayo Clinic Proceedings",
    "Mayo Clinic Proceedings: Innovations, Quality & Outcomes": "Mayo Clinic Proceedings: Innovations, Quality & Outcomes",
    "Mechanisms of Development": "Mechanisms of Development",
    "MedComm": "MedComm",
    "MedEdPORTAL": "MedEdPORTAL",
    "Medecine Sciences": "Medecine Sciences",
    "Medecine et Maladies Infectieuses": "Medecine et Maladies Infectieuses",
    "Medical Decision Making": "Medical Decision Making",
    "Medical Gas Research": "Medical Gas Research",
    "Medical Hypotheses": "Medical Hypotheses",
    "Medical Microbiology and Immunology": "Medical Microbiology and Immunology",
    "Medical Molecular Morphology": "Medical Molecular Morphology",
    "Medical Mycology": "Medical Mycology",
    "Medical Teacher": "Medical Teacher",
    "Medical science educator": "Medical science educator",
    "Medicina (Kaunas)": "Medicina (Kaunas)",
    "Medicinal Research Reviews": "Medicinal Research Reviews",
    "Metabolism: Clinical and Experimental": "Metabolism: Clinical and Experimental",
    "Methodist DeBakey Cardiovascular Journal": "Methodist DeBakey Cardiovascular Journal",
    "Methods of Information in Medicine": "Methods of Information in Medicine",
    "Microbial Biotechnology": "Microbial Biotechnology",
    "Microbiological Research": "Microbiological Research",
    "Microbiology Spectrum": "Microbiology Spectrum",
    "Microbiology and Immunology": "Microbiology and Immunology",
    "Microbiology and Molecular Biology Reviews": "Microbiology and Molecular Biology Reviews",
    "Middle East African Journal of Ophthalmology": "Middle East African Journal of Ophthalmology",
    "Minerva Anestesiologica": "Minerva Anestesiologica",
    "Minerva Endocrinologica": "Minerva Endocrinologica",
    "Minerva Urologica e Nefrologica": "Minerva Urologica e Nefrologica",
    "Modern Rheumatology": "Modern Rheumatology",
    "Molecular Autism": "Molecular Autism",
    "Molecular BioSystems": "Molecular BioSystems",
    "Molecular Biology Reports": "Molecular Biology Reports",
    "Molecular Biology of the Cell": "Molecular Biology of the Cell",
    "Molecular Biotechnology": "Molecular Biotechnology",
    "Molecular Brain": "Molecular Brain",
    "Molecular Cancer": "Molecular Cancer",
    "Molecular Cancer Therapeutics": "Molecular Cancer Therapeutics",
    "Molecular Diversity": "Molecular Diversity",
    "Molecular Genetics and Metabolism Reports": "Molecular Genetics and Metabolism Reports",
    "Molecular Human Reproduction": "Molecular Human Reproduction",
    "Molecular Imaging": "Molecular Imaging",
    "Molecular Medicine": "Molecular Medicine",
    "Molecular Membrane Biology": "Molecular Membrane Biology",
    "Molecular Metabolism": "Molecular Metabolism",
    "Molecular Microbiology": "Molecular Microbiology",
    "Molecular Neurobiology": "Molecular Neurobiology",
    "Molecular Neurodegeneration": "Molecular Neurodegeneration",
    "Molecular Omics": "Molecular Omics",
    "Molecular Oncology": "Molecular Oncology",
    "Molecular Pain": "Molecular Pain",
    "Molecular Pathology": "Molecular Pathology",
    "Molecular Pharmaceutics": "Molecular Pharmaceutics",
    "Molecular Psychiatry": "Molecular Psychiatry",
    "Molecular Reproduction and Development": "Molecular Reproduction and Development",
    "Molecular Systems Biology": "Molecular Systems Biology",
    "Molecular Therapy": "Molecular Therapy",
    "Molecular and Cellular Biochemistry": "Molecular and Cellular Biochemistry",
    "Molecular and Cellular Endocrinology": "Molecular and Cellular Endocrinology",
    "Molecular and Clinical Oncology": "Molecular and Clinical Oncology",
    "Molecular biomedicine": "Molecular biomedicine",
    "Movement Disorders Clinical Practice": "Movement Disorders Clinical Practice",
    "Multidisciplinary Respiratory Medicine": "Multidisciplinary Respiratory Medicine",
    "Multiple Sclerosis and Related Disorders": "Multiple Sclerosis and Related Disorders",
    "Multisensory Research": "Multisensory Research",
    "Mutation Research": "Mutation Research",
    "MycoKeys": "MycoKeys",
    "Mycobiology": "Mycobiology",
    "Mycologia": "Mycologia",
    "Mycological Progress": "Mycological Progress",
    "Mycopathologia": "Mycopathologia",
    "Mycoscience": "Mycoscience",
    "Mycotoxin Research": "Mycotoxin Research",
    "NMR in Biomedicine": "NMR in Biomedicine",
    "NPJ Digital Medicine": "NPJ Digital Medicine",
    "NPJ Primary Care Respiratory Medicine": "NPJ Primary Care Respiratory Medicine",
    "NPJ Schizophrenia": "NPJ Schizophrenia",
    "NPJ Vaccines": "NPJ Vaccines",
    "Nanoethics": "Nanoethics",
    "Nanotoxicology": "Nanotoxicology",
    "National Journal of Maxillofacial Surgery": "National Journal of Maxillofacial Surgery",
    "Nature": "Nature",
    "Nature Biomedical Engineering": "Nature Biomedical Engineering",
    "Nature Biotechnology": "Nature Biotechnology",
    "Nature Cancer": "Nature Cancer",
    "Nature Cell Biology": "Nature Cell Biology",
    "Nature Communications": "Nature Communications",
    "Nature Immunology": "Nature Immunology",
    "Nature Medicine": "Nature Medicine",
    "Nature Methods": "Nature Methods",
    "Nature Microbiology": "Nature Microbiology",
    "Nature Nanotechnology": "Nature Nanotechnology",
    "Nature Neuroscience": "Nature Neuroscience",
    "Nature Photonics": "Nature Photonics",
    "Nature Protocols": "Nature Protocols",
    "Nefrologia": "Nefrologia",
    "Nephrology Dialysis Transplantation": "Nephrology Dialysis Transplantation",
    "Nephrology Nursing Journal": "Nephrology Nursing Journal",
    "Neural Regeneration Research": "Neural Regeneration Research",
    "Neuro Endocrinology Letters": "Neuro Endocrinology Letters",
    "Neuro-Oncology": "Neuro-Oncology",
    "NeuroRehabilitation": "NeuroRehabilitation",
    "Neurobiology of Aging": "Neurobiology of Aging",
    "Neurobiology of Stress": "Neurobiology of Stress",
    "Neurocase": "Neurocase",
    "Neurochemistry International": "Neurochemistry International",
    "Neurocritical Care": "Neurocritical Care",
    "Neuroendocrinology": "Neuroendocrinology",
    "Neuroepidemiology": "Neuroepidemiology",
    "Neuroimaging Clinics of North America": "Neuroimaging Clinics of North America",
    "Neuroimmunomodulation": "Neuroimmunomodulation",
    "Neurologic Clinics": "Neurologic Clinics",
    "Neurological Research": "Neurological Research",
    "Neurological Sciences": "Neurological Sciences",
    "Neuromodulation": "Neuromodulation",
    "Neuromolecular Medicine": "Neuromolecular Medicine",
    "Neuromuscular Disorders": "Neuromuscular Disorders",
    "Neuron Glia Biology": "Neuron Glia Biology",
    "Neuropathology and Applied Neurobiology": "Neuropathology and Applied Neurobiology",
    "Neuropediatrics": "Neuropediatrics",
    "Neurophysiology": "Neurophysiology",
    "Neuropsychiatric Disease and Treatment": "Neuropsychiatric Disease and Treatment",
    "Neuropsychiatrie de l'Enfance et de l'Adolescence": "Neuropsychiatrie de l'Enfance et de l'Adolescence",
    "Neuropsychobiology": "Neuropsychobiology",
    "Neuropsychologia": "Neuropsychologia",
    "Neuropsychology Review": "Neuropsychology Review",
    "Neuropsychopharmacology": "Neuropsychopharmacology",
    "Neuroquantology": "Neuroquantology",
    "Neurorehabilitation and Neural Repair": "Neurorehabilitation and Neural Repair",
    "Neuroreport": "Neuroreport",
    "Neuroscience Letters": "Neuroscience Letters",
    "Neuroscience and Biobehavioral Reviews": "Neuroscience and Biobehavioral Reviews",
    "Neuroscience of Consciousness": "Neuroscience of Consciousness",
    "Neurosurgery Clinics of North America": "Neurosurgery Clinics of North America",
    "Neurosurgical Focus": "Neurosurgical Focus",
    "Neurosurgical Review": "Neurosurgical Review",
    "Neurotherapeutics": "Neurotherapeutics",
    "Neurotoxicity Research": "Neurotoxicity Research",
    "Neurotoxicology": "Neurotoxicology",
    "Neurotoxicology and Teratology": "Neurotoxicology and Teratology",
    "Neurourology and Urodynamics": "Neurourology and Urodynamics",
    "Nitric Oxide": "Nitric Oxide",
    "Nordic Journal of Psychiatry": "Nordic Journal of Psychiatry",
    "Nuclear Medicine and Biology": "Nuclear Medicine and Biology",
    "Nucleic Acids Research": "Nucleic Acids Research",
    "Nuklearmedizin": "Nuklearmedizin",
    "Nurse Education Today": "Nurse Education Today",
    "Nurse Education in Practice": "Nurse Education in Practice",
    "Nursing Ethics": "Nursing Ethics",
    "Nursing Open": "Nursing Open",
    "Nursing Outlook": "Nursing Outlook",
    "Nursing Reports": "Nursing Reports",
    "Nursing Research": "Nursing Research",
    "Nutrients": "Nutrients",
    "Nutrition Bulletin": "Nutrition Bulletin",
    "Nutrition Journal": "Nutrition Journal",
    "Nutrition Research Reviews": "Nutrition Research Reviews",
    "Nutrition Research and Practice": "Nutrition Research and Practice",
    "Nutrition Reviews": "Nutrition Reviews",
    "Nutrition and Metabolism": "Nutrition and Metabolism",
    "Nutritional Neuroscience": "Nutritional Neuroscience",
    "Obesity Reviews": "Obesity Reviews",
    "Obesity Science & Practice": "Obesity Science & Practice",
    "Obstetric Medicine": "Obstetric Medicine",
    "Ocular Immunology and Inflammation": "Ocular Immunology and Inflammation",
    "Oncoimmunology": "Oncoimmunology",
    "Oncology Nursing Forum": "Oncology Nursing Forum",
    "Open Biology": "Open Biology",
    "Open Forum Infectious Diseases": "Open Forum Infectious Diseases",
    "Open Health Data": "Open Health Data",
    "Open Heart": "Open Heart",
    "Open Medicine": "Open Medicine",
    "Ophthalmic Epidemiology": "Ophthalmic Epidemiology",
    "Ophthalmic Genetics": "Ophthalmic Genetics",
    "Ophthalmic Research": "Ophthalmic Research",
    "Ophthalmologica": "Ophthalmologica",
    "Ophthalmology and Therapy": "Ophthalmology and Therapy",
    "Optometry and Vision Science": "Optometry and Vision Science",
    "Orphanet Journal of Rare Diseases": "Orphanet Journal of Rare Diseases",
    "Orthopaedic Journal of Sports Medicine": "Orthopaedic Journal of Sports Medicine",
    "Osteoarthritis and Cartilage Open": "Osteoarthritis and Cartilage Open",
    "Oxford Medical Case Reports": "Oxford Medical Case Reports",
    "PLOS Pathogens": "PLOS Pathogens",
    "PLoS Biology": "PLoS Biology",
    "PLoS Computational Biology": "PLoS Computational Biology",
    "PLoS Genetics": "PLoS Genetics",
    "PLoS Neglected Tropical Diseases": "PLoS Neglected Tropical Diseases",
    "PNAS": "PNAS",
    "Paediatric Anaesthesia": "Paediatric Anaesthesia",
    "Paediatric Respiratory Reviews": "Paediatric Respiratory Reviews",
    "Paediatric and Perinatal Epidemiology": "Paediatric and Perinatal Epidemiology",
    "Paediatrics and International Child Health": "Paediatrics and International Child Health",
    "Pain Physician": "Pain Physician",
    "Pain Practice": "Pain Practice",
    "Palliative Medicine": "Palliative Medicine",
    "Pancreatology": "Pancreatology",
    "Parasite Immunology": "Parasite Immunology",
    "Parasitology International": "Parasitology International",
    "Particle and Fibre Toxicology": "Particle and Fibre Toxicology",
    "Pathogens": "Pathogens",
    "Pathogens and Global Health": "Pathogens and Global Health",
    "Pathology International": "Pathology International",
    "Pathology and Laboratory Medicine International": "Pathology and Laboratory Medicine International",
    "Patient Preference and Adherence": "Patient Preference and Adherence",
    "Pediatric Allergy and Immunology": "Pediatric Allergy and Immunology",
    "Pediatric Annals": "Pediatric Annals",
    "Pediatric Critical Care Medicine": "Pediatric Critical Care Medicine",
    "Pediatric Dental Journal": "Pediatric Dental Journal",
    "Pediatric Dermatology": "Pediatric Dermatology",
    "Pediatric Exercise Science": "Pediatric Exercise Science",
    "Pediatric Nephrology": "Pediatric Nephrology",
    "Pediatric Neurology": "Pediatric Neurology",
    "Pediatric Neurosurgery": "Pediatric Neurosurgery",
    "Pediatric Pathology & Molecular Medicine": "Pediatric Pathology & Molecular Medicine",
    "Pediatric Pulmonology": "Pediatric Pulmonology",
    "Pediatric Quality & Safety": "Pediatric Quality & Safety",
    "Pediatric Research": "Pediatric Research",
    "Pediatric Rheumatology Online Journal": "Pediatric Rheumatology Online Journal",
    "Pediatric Surgery International": "Pediatric Surgery International",
    "Pediatric Transplantation": "Pediatric Transplantation",
    "Pediatric and Developmental Pathology": "Pediatric and Developmental Pathology",
    "Perioperative Medicine": "Perioperative Medicine",
    "Perspectives in Psychiatric Care": "Perspectives in Psychiatric Care",
    "Perspectives in Public Health": "Perspectives in Public Health",
    "Perspectives on Medical Education": "Perspectives on Medical Education",
    "Pharmaceutical Biology": "Pharmaceutical Biology",
    "Pharmaceutical Statistics": "Pharmaceutical Statistics",
    "Pharmaceuticals (Basel)": "Pharmaceuticals (Basel)",
    "Pharmaceutics": "Pharmaceutics",
    "Pharmacological Reports": "Pharmacological Reports",
    "Pharmacological Research": "Pharmacological Research",
    "Pharmacological Reviews": "Pharmacological Reviews",
    "Pharmacopsychiatry": "Pharmacopsychiatry",
    "Philosophy of Science": "Philosophy of Science",
    "Photochemistry and Photobiology": "Photochemistry and Photobiology",
    "Physics in Medicine and Biology": "Physics in Medicine and Biology",
    "Physiological Genomics": "Physiological Genomics",
    "Physiological Reviews": "Physiological Reviews",
    "Phytotherapy Research": "Phytotherapy Research",
    "Plastic and Reconstructive Surgery": "Plastic and Reconstructive Surgery",
    "PloS Medicine": "PloS Medicine",
    "Plos One": "Plos One",
    "Polish journal of radiology": "Polish journal of radiology",
    "Postgraduate Medical Journal": "Postgraduate Medical Journal",
    "Postgraduate Medicine": "Postgraduate Medicine",
    "Practical Dermatology": "Practical Dermatology",
    "Practical Radiation Oncology": "Practical Radiation Oncology",
    "Prehospital Emergency Care": "Prehospital Emergency Care",
    "Prehospital and Disaster Medicine": "Prehospital and Disaster Medicine",
    "Prenatal Diagnosis": "Prenatal Diagnosis",
    "Preventive Medicine": "Preventive Medicine",
    "Preventive medicine reports": "Preventive medicine reports",
    "Primary Care Diabetes": "Primary Care Diabetes",
    "Proceedings of the American Thoracic Society": "Proceedings of the American Thoracic Society",
    "Proceedings of the National Academy of Sciences of the United States of America": "Proceedings of the National Academy of Sciences of the United States of America",
    "Progress in Brain Research": "Progress in Brain Research",
    "Progress in Cardiovascular Diseases": "Progress in Cardiovascular Diseases",
    "Progress in Neurobiology": "Progress in Neurobiology",
    "Progress in Retinal and Eye Research": "Progress in Retinal and Eye Research",
    "Protein Science": "Protein Science",
    "Psychiatric Genetics": "Psychiatric Genetics",
    "Psychiatry": "Psychiatry",
    "Psychiatry Investigation": "Psychiatry Investigation",
    "Psychiatry Research": "Psychiatry Research",
    "Psychiatry and Clinical Neurosciences": "Psychiatry and Clinical Neurosciences",
    "Psychogeriatrics": "Psychogeriatrics",
    "Psychological Medicine": "Psychological Medicine",
    "Psychology of Addictive Behaviors": "Psychology of Addictive Behaviors",
    "Psychometrika": "Psychometrika",
    "Psychoneuroendocrinology": "Psychoneuroendocrinology",
    "Psychophysiology": "Psychophysiology",
    "Psychosomatics": "Psychosomatics",
    "Psychotherapy and Psychosomatics": "Psychotherapy and Psychosomatics",
    "Public Health Reports": "Public Health Reports",
    "Pulmonary Medicine": "Pulmonary Medicine",
    "QJM": "QJM",
    "Quality of Life Research": "Quality of Life Research",
    "Quantitative Imaging in Medicine and Surgery": "Quantitative Imaging in Medicine and Surgery",
    "RNA Biology": "RNA Biology",
    "Radiation Protection Dosimetry": "Radiation Protection Dosimetry",
    "Radiation Research": "Radiation Research",
    "Radiation and Environmental Biophysics": "Radiation and Environmental Biophysics",
    "Radiographics": "Radiographics",
    "Rapid Communications in Mass Spectrometry": "Rapid Communications in Mass Spectrometry",
    "Redox Biology": "Redox Biology",
    "Regulatory Peptides": "Regulatory Peptides",
    "Renal Failure": "Renal Failure",
    "Reproductive Biology and Endocrinology": "Reproductive Biology and Endocrinology",
    "Reproductive Biomedicine Online": "Reproductive Biomedicine Online",
    "Reproductive Health": "Reproductive Health",
    "Research Communications in Molecular Pathology and Pharmacology": "Research Communications in Molecular Pathology and Pharmacology",
    "Research and Reports in Urology": "Research and Reports in Urology",
    "Research in Autism Spectrum Disorders": "Research in Autism Spectrum Disorders",
    "Research in Developmental Disabilities": "Research in Developmental Disabilities",
    "Research in Pharmaceutical Sciences": "Research in Pharmaceutical Sciences",
    "Respiratory Medicine": "Respiratory Medicine",
    "Respiratory Medicine and Research": "Respiratory Medicine and Research",
    "Respiratory Research": "Respiratory Research",
    "Respirology": "Respirology",
    "Respirology Case Reports": "Respirology Case Reports",
    "Restorative Neurology and Neuroscience": "Restorative Neurology and Neuroscience",
    "Resuscitation": "Resuscitation",
    "Retrovirology": "Retrovirology",
    "Reviews in Medical Virology": "Reviews in Medical Virology",
    "Reviews of Environmental Contamination and Toxicology": "Reviews of Environmental Contamination and Toxicology",
    "Revista Brasileira de Reumatologia": "Revista Brasileira de Reumatologia",
    "Revista Espanola de Cardiologia": "Revista Espanola de Cardiologia",
    "Revista Internacional de Andrologia": "Revista Internacional de Andrologia",
    "Revue Neurologique": "Revue Neurologique",
    "Revue d'Epidemiologie et de Sante Publique": "Revue d'Epidemiologie et de Sante Publique",
    "Revue de Pneumologie Clinique": "Revue de Pneumologie Clinique",
    "Revue des Maladies Respiratoires": "Revue des Maladies Respiratoires",
    "Revue du Rhumatisme": "Revue du Rhumatisme",
    "Rheumatology Advances in Practice": "Rheumatology Advances in Practice",
    "Rheumatology International": "Rheumatology International",
    "Risk Management and Healthcare Policy": "Risk Management and Healthcare Policy",
    "Romanian Journal of Morphology and Embryology": "Romanian Journal of Morphology and Embryology",
    "Sante Publique": "Sante Publique",
    "Saudi Journal of Ophthalmology": "Saudi Journal of Ophthalmology",
    "Scandinavian Audiology": "Scandinavian Audiology",
    "Scandinavian Journal of Gastroenterology": "Scandinavian Journal of Gastroenterology",
    "Scandinavian Journal of Infectious Diseases": "Scandinavian Journal of Infectious Diseases",
    "Scandinavian Journal of Pain": "Scandinavian Journal of Pain",
    "Scandinavian Journal of Primary Health Care": "Scandinavian Journal of Primary Health Care",
    "Scandinavian Journal of Public Health": "Scandinavian Journal of Public Health",
    "Scandinavian Journal of Rheumatology": "Scandinavian Journal of Rheumatology",
    "Scandinavian Journal of Urology": "Scandinavian Journal of Urology",
    "Scandinavian Journal of Urology and Nephrology": "Scandinavian Journal of Urology and Nephrology",
    "Scars, Burns & Healing": "Scars, Burns & Healing",
    "Schizophrenia Bulletin": "Schizophrenia Bulletin",
    "Schizophrenia Research": "Schizophrenia Research",
    "Schizophrenia Research: Cognition": "Schizophrenia Research: Cognition",
    "Science": "Science",
    "Science Immunology": "Science Immunology",
    "Science Translational Medicine": "Science Translational Medicine",
    "Science and Engineering Ethics": "Science and Engineering Ethics",
    "Scientific Reports": "Scientific Reports",
    "Scottish Medical Journal": "Scottish Medical Journal",
    "Seminars in Diagnostic Pathology": "Seminars in Diagnostic Pathology",
    "Seminars in Dialysis": "Seminars in Dialysis",
    "Seminars in Hematology": "Seminars in Hematology",
    "Seminars in Immunology": "Seminars in Immunology",
    "Seminars in Immunopathology": "Seminars in Immunopathology",
    "Seminars in Interventional Radiology": "Seminars in Interventional Radiology",
    "Seminars in Liver Disease": "Seminars in Liver Disease",
    "Seminars in Musculoskeletal Radiology": "Seminars in Musculoskeletal Radiology",
    "Seminars in Nephrology": "Seminars in Nephrology",
    "Seminars in Neurology": "Seminars in Neurology",
    "Seminars in Nuclear Medicine": "Seminars in Nuclear Medicine",
    "Seminars in Oncology": "Seminars in Oncology",
    "Seminars in Ophthalmology": "Seminars in Ophthalmology",
    "Seminars in Pediatric Neurology": "Seminars in Pediatric Neurology",
    "Seminars in Pediatric Surgery": "Seminars in Pediatric Surgery",
    "Seminars in Perinatology": "Seminars in Perinatology",
    "Seminars in Radiation Oncology": "Seminars in Radiation Oncology",
    "Seminars in Reproductive Medicine": "Seminars in Reproductive Medicine",
    "Seminars in Roentgenology": "Seminars in Roentgenology",
    "Seminars in Surgical Oncology": "Seminars in Surgical Oncology",
    "Seminars in Thoracic and Cardiovascular Surgery": "Seminars in Thoracic and Cardiovascular Surgery",
    "Seminars in Thrombosis and Hemostasis": "Seminars in Thrombosis and Hemostasis",
    "Seminars in Vascular Surgery": "Seminars in Vascular Surgery",
    "Sensors international": "Sensors international",
    "Sexual Development": "Sexual Development",
    "Skeletal Radiology": "Skeletal Radiology",
    "Skin Pharmacology and Physiology": "Skin Pharmacology and Physiology",
    "Sleep": "Sleep",
    "Sleep Medicine Reviews": "Sleep Medicine Reviews",
    "Sleep and Biological Rhythms": "Sleep and Biological Rhythms",
    "Social Neuroscience": "Social Neuroscience",
    "Social Psychiatry and Psychiatric Epidemiology": "Social Psychiatry and Psychiatric Epidemiology",
    "Social Studies of Science": "Social Studies of Science",
    "Social Work in Public Health": "Social Work in Public Health",
    "Somnologie": "Somnologie",
    "Southern Medical Journal": "Southern Medical Journal",
    "Spinal Cord": "Spinal Cord",
    "Springer Seminars in Immunopathology": "Springer Seminars in Immunopathology",
    "Statistical Analysis and Data Mining": "Statistical Analysis and Data Mining",
    "Statistical Methods in Medical Research": "Statistical Methods in Medical Research",
    "Statistical Science": "Statistical Science",
    "Statistics and Computing": "Statistics and Computing",
    "Stem Cell Research & Therapy": "Stem Cell Research & Therapy",
    "Stem Cells Translational Medicine": "Stem Cells Translational Medicine",
    "Stereotactic and Functional Neurosurgery": "Stereotactic and Functional Neurosurgery",
    "Strahlentherapie und Onkologie": "Strahlentherapie und Onkologie",
    "Stress and Health": "Stress and Health",
    "Stroke": "Stroke",
    "Stroke and Vascular Neurology": "Stroke and Vascular Neurology",
    "Studies in History and Philosophy of Science": "Studies in History and Philosophy of Science",
    "Studies in Mycology": "Studies in Mycology",
    "Surgery Today": "Surgery Today",
    "Surgery for Obesity and Related Diseases": "Surgery for Obesity and Related Diseases",
    "Surgical Endoscopy": "Surgical Endoscopy",
    "Surgical Neurology": "Surgical Neurology",
    "Survey of Ophthalmology": "Survey of Ophthalmology",
    "Swiss Medical Weekly": "Swiss Medical Weekly",
    "Systematic and Applied Microbiology": "Systematic and Applied Microbiology",
    "Targeted Oncology": "Targeted Oncology",
    "Teaching and Learning In Medicine": "Teaching and Learning In Medicine",
    "Techniques in Coloproctology": "Techniques in Coloproctology",
    "Techniques in Gastrointestinal Endoscopy": "Techniques in Gastrointestinal Endoscopy",
    "Technometrics": "Technometrics",
    "Telemedicine Journal and e-Health": "Telemedicine Journal and e-Health",
    "The American statistician": "The American statistician",
    "The Journal of Ambulatory Care Management": "The Journal of Ambulatory Care Management",
    "The Journal of Frailty & Aging": "The Journal of Frailty & Aging",
    "The Journal of Infectious Diseases": "The Journal of Infectious Diseases",
    "The Journal of Innovations in Cardiac Rhythm Management": "The Journal of Innovations in Cardiac Rhythm Management",
    "The Journal of Nervous and Mental Disease": "The Journal of Nervous and Mental Disease",
    "The Journal of Nutrition": "The Journal of Nutrition",
    "The Journal of Physical Chemistry Letters": "The Journal of Physical Chemistry Letters",
    "The Journal of Urology": "The Journal of Urology",
    "The Lancet Child & Adolescent Health": "The Lancet Child & Adolescent Health",
    "The Lancet Global Health": "The Lancet Global Health",
    "The Lancet HIV": "The Lancet HIV",
    "The Lancet Infectious Diseases": "The Lancet Infectious Diseases",
    "The Lancet Microbe": "The Lancet Microbe",
    "The Lancet Oncology": "The Lancet Oncology",
    "The Lancet Regional health - Europe": "The Lancet Regional health - Europe",
    "The Lancet Rheumatology": "The Lancet Rheumatology",
    "The New England Journal of Medicine": "The New England Journal of Medicine",
    "The Pan African Medical Journal": "The Pan African Medical Journal",
    "Theoretical Population Biology": "Theoretical Population Biology",
    "Therapeutic Advances in Chronic Disease": "Therapeutic Advances in Chronic Disease",
    "Therapeutic Advances in Drug Safety": "Therapeutic Advances in Drug Safety",
    "Therapeutic Advances in Endocrinology and Metabolism": "Therapeutic Advances in Endocrinology and Metabolism",
    "Therapeutic Advances in Gastroenterology": "Therapeutic Advances in Gastroenterology",
    "Therapeutic Advances in Hematology": "Therapeutic Advances in Hematology",
    "Therapeutic Advances in Ophthalmology": "Therapeutic Advances in Ophthalmology",
    "Therapeutic Advances in Psychopharmacology": "Therapeutic Advances in Psychopharmacology",
    "Therapeutic Advances in Urology": "Therapeutic Advances in Urology",
    "Therapeutic Hypothermia and Temperature Management": "Therapeutic Hypothermia and Temperature Management",
    "Therapeutics and Clinical Risk Management": "Therapeutics and Clinical Risk Management",
    "Theriogenology": "Theriogenology",
    "Thoracic Surgery Clinics": "Thoracic Surgery Clinics",
    "Thorax": "Thorax",
    "Thrombosis Journal": "Thrombosis Journal",
    "Thrombosis and Haemostasis": "Thrombosis and Haemostasis",
    "Tissue Antigens": "Tissue Antigens",
    "Toxicological Sciences": "Toxicological Sciences",
    "Toxicology In Vitro": "Toxicology In Vitro",
    "Toxicology Letters": "Toxicology Letters",
    "Toxicology and Applied Pharmacology": "Toxicology and Applied Pharmacology",
    "Transfusion Clinique et Biologique": "Transfusion Clinique et Biologique",
    "Transfusion Medicine Reviews": "Transfusion Medicine Reviews",
    "Transfusion Medicine and Hemotherapy": "Transfusion Medicine and Hemotherapy",
    "Translational Andrology and Urology": "Translational Andrology and Urology",
    "Translational Lung Cancer Research": "Translational Lung Cancer Research",
    "Translational Neurodegeneration": "Translational Neurodegeneration",
    "Translational Neuroscience": "Translational Neuroscience",
    "Translational Psychiatry": "Translational Psychiatry",
    "Translational Stroke Research": "Translational Stroke Research",
    "Transplant Immunology": "Transplant Immunology",
    "Transplant Infectious Disease": "Transplant Infectious Disease",
    "Transplant International": "Transplant International",
    "Transplantation Direct": "Transplantation Direct",
    "Transplantation Proceedings": "Transplantation Proceedings",
    "Transplantation Reviews": "Transplantation Reviews",
    "Travel Medicine and Infectious Disease": "Travel Medicine and Infectious Disease",
    "Trends in Amplification": "Trends in Amplification",
    "Trends in Biotechnology": "Trends in Biotechnology",
    "Trends in Cardiovascular Medicine": "Trends in Cardiovascular Medicine",
    "Trends in Cell Biology": "Trends in Cell Biology",
    "Trends in Genetics": "Trends in Genetics",
    "Trends in Hearing": "Trends in Hearing",
    "Trends in Immunology": "Trends in Immunology",
    "Trends in Microbiology": "Trends in Microbiology",
    "Trends in Parasitology": "Trends in Parasitology",
    "Trends in Pharmacological Sciences": "Trends in Pharmacological Sciences",
    "Trials": "Trials",
    "Tropical Medicine and Infectious Disease": "Tropical Medicine and Infectious Disease",
    "Turkish Journal of Emergency Medicine": "Turkish Journal of Emergency Medicine",
    "Turkish Journal of urology": "Turkish Journal of urology",
    "Twin Research and Human Genetics": "Twin Research and Human Genetics",
    "Ultrastructural Pathology": "Ultrastructural Pathology",
    "United European Gastroenterology Journal": "United European Gastroenterology Journal",
    "Urologia": "Urologia",
    "Urologic Oncology": "Urologic Oncology",
    "Urology": "Urology",
    "Urology Annals": "Urology Annals",
    "Urology Practice": "Urology Practice",
    "Vaccines": "Vaccines",
    "Value in Health": "Value in Health",
    "Vascular Pharmacology": "Vascular Pharmacology",
    "Vascular and Endovascular Surgery": "Vascular and Endovascular Surgery",
    "Veterinary Pathology": "Veterinary Pathology",
    "Veterinary and Comparative Orthopaedics and Traumatology": "Veterinary and Comparative Orthopaedics and Traumatology",
    "Viral Immunology": "Viral Immunology",
    "Virology": "Virology",
    "Virology Journal": "Virology Journal",
    "Viruses": "Viruses",
    "Visceral Medicine": "Visceral Medicine",
    "Vitamins and Hormones": "Vitamins and Hormones",
    "Vox Sanguinis": "Vox Sanguinis",
    "Wellcome Open Research": "Wellcome Open Research",
    "Western Journal of Emergency Medicine": "Western Journal of Emergency Medicine",
    "Western Journal of Medicine": "Western Journal of Medicine",
    "Western Journal of Nursing Research": "Western Journal of Nursing Research",
    "Women's Health Reports": "Women's Health Reports",
    "World Journal of Clinical Cases": "World Journal of Clinical Cases",
    "World Journal of Colorectal Surgery": "World Journal of Colorectal Surgery",
    "World Journal of Gastroenterology": "World Journal of Gastroenterology",
    "World Journal of Nuclear Medicine": "World Journal of Nuclear Medicine",
    "World Journal of Urology": "World Journal of Urology",
    "Yonago Acta Medica": "Yonago Acta Medica",
    "biomolecules": "biomolecules",
    "eNeuro": "eNeuro",
    "eNeurologicalSci": "eNeurologicalSci",
    "i-Perception": "i-Perception",
    "iScience": "iScience",
    "medRxiv": "medRxiv",
    "molecules": "molecules",
    "AAPS Journal": "The AAPS Journal",
    "AIDS Reader": "The AIDS Reader",
    "AJNR American Journal of Neuroradiology": "AJNR. American Journal of Neuroradiology",
    "AJR American Journal of Roentgenology": "AJR. American Journal of Roentgenology",
    "ANS Advances in Nursing Science": "ANS. Advances in nursing science",
    "Academic Pediatry": "Academic pediatrics",
    "Acta Otolaryngolica": "Acta oto-laryngologica",
    "Acta Pharmaceutica Sinica B": "Acta pharmaceutica Sinica. B",
    "Acta Psychiatrica Scandinavia": "Acta psychiatrica Scandinavica",
    "Actas Urol\u00c3\u00b3gicas Espa\u00c3\u00b1olas": "Actas urologicas espanolas",
    "Addiction Research Theory": "Addiction research  theory",
    "Advances and Applications in Bioinformatics and Chemistry": "Advances and applications in bioinformatics and chemistry : AABC",
    "Advancesin Applied Microbiology": "Advances in applied microbiology",
    "Aging and Mental Health": "Aging & mental health",
    "Alcoholism Clinical and Experimental Research": "Alcoholism, clinical and experimental research",
    "Alimentary Pharmacology and Therapeutics": "Alimentary pharmacology & therapeutics",
    "Allergy Asthma and Immunology Research": "Allergy, asthma & immunology research",
    "American Journal of Alzheimer's Disease and Other Dementia": "American journal of Alzheimers disease and other dementias",
    "American Journal of Clinical Nutrition": "The American journal of clinical nutrition",
    "American Journal of Drug Alcohol Abuse": "The American journal of drug and alcohol abuse",
    "American Journal of Gastroenterology": "The American journal of gastroenterology",
    "American Journal of Hospice and Palliative Care": "The American journal of hospice & palliative care",
    "American Journal of Managed Care": "The American journal of managed care",
    "American Journal of Medicine": "The American journal of medicine",
    "American Journal of Physiology Cell Physiology": "American journal of physiology. Cell physiology",
    "American Journal of Physiology Endocrinology and Metabolism": "American journal of physiology. Endocrinology and metabolism",
    "American Journal of Physiology Gastrointestinal and Liver Physiology": "American journal of physiology. Gastrointestinal and liver physiology",
    "American Journal of Physiology Heart and Circulatory Physiology": "American journal of physiology. Heart and circulatory physiology",
    "American Journal of Physiology Lung Cellular and Molecular Physiology": "American journal of physiology. Lung cellular and molecular physiology",
    "American Journal of Physiology Regulatory Integrative and Comparative Physiology": "American journal of physiology. Regulatory, integrative and comparative physiology",
    "American Journal of Physiology Renal Physiology": "American journal of physiology. Renal physiology",
    "American Journal of Speech and Language Pathology": "American journal of speech-language pathology",
    "American Journal of Tropical Medicine and Hygiene": "The American journal of tropical medicine and hygiene",
    "American Journal on Addictions": "The American journal on addictions",
    "Angle Orthodontist": "The Angle orthodontist",
    "Annales de Dermatologie et V\u00c3\u00a9n\u00c3\u00a9rologie": "Annales de dermatologie et de venereologie",
    "Annales of Epidemiology": "Annals of epidemiology",
    "Annals of Allergy Asthma and Immunology": "Annals of Indian Academy of Neurology",
    "Annals of Applied Statistics": "The annals of applied statistics",
    "Annals of Otology Rhinology and Laryngology": "The Annals of otology, rhinology, and laryngology",
    "Annals of Thoracic Cardiovascular Surgery": "Seminars in thoracic and cardiovascular surgery",
    "Annals of Thoracic Surgery": "The Annals of thoracic surgery",
    "Annals of Work Exposure and Health": "Annals of work exposures and health",
    "Archives of Diseases in Childhood": "Archives of disease in childhood",
    "Archives of Diseases in Childhood Education and Practice Edition": "Archives of disease in childhood. Education and practice edition",
    "Archives of Environmental and Occupational Health": "Archives of environmental & occupational health",
    "Archives of Gynecology and Obstettrics": "Archives of gynecology and obstetrics",
    "Archives of Orthopaedics and Trauma Surgery": "Archives of orthopaedic and trauma surgery",
    "Archives of Pediatric and Adolescent Medicine": "Archives of pediatrics & adolescent medicine",
    "Archives of Womens Mental Health": "Archives of womens mental health",
    "Arteriosclerosis Thrombosis and Vascular Biology": "Arteriosclerosis, thrombosis, and vascular biology",
    "Arthritis Care Research": "Arthritis care & research",
    "Asian Cardiovascular and Thoracic Annals": "Asian cardiovascular & thoracic annals",
    "Attention Perception Psychophysics": "Attention, perception & psychophysics",
    "Australasian Journal of Dermatology": "The Australasian journal of dermatology",
    "Australian and New Zealand Journal of Obstetrics and Gynaecology": "The Australian & New Zealand journal of obstetrics & gynaecology",
    "Autoimmuninty Reviews": "Autoimmunity reviews",
    "BMC Acta Neuropathologica Communications": "Acta neuropathologica communications",
    "BMC Pharmacology and Toxicology": "EC pharmacology and toxicology",
    "BMJ Open Ophtalmology": "BMJ open ophthalmology",
    "Behavioral Genetics": "Behavior genetics",
    "Behavioral and Brain Research": "Behavioural brain research",
    "Behavioral and Brain Science": "The Behavioral and brain sciences",
    "Biochimica et Biophysica Acta Bioenergetics": "Biochimica et biophysica acta. Bioenergetics",
    "Biochimica et Biophysica Acta Biomembrane": "Biochimica et biophysica acta. Biomembranes",
    "Biochimica et Biophysica Acta Gene Regulatory Mechanisms": "Biochimica et biophysica acta. Gene regulatory mechanisms",
    "Biochimica et Biophysica Acta Mol Cell Biology of Lipids": "Biochimica et biophysica acta. Molecular and cell biology of lipids",
    "Biochimica et Biophysica Acta Molecular Basis of Diseases": "Biochimica et biophysica acta. Molecular basis of disease",
    "Biochimica et Biophysica Acta Molecular Cell Research": "Biochimica et biophysica acta. Molecular cell research",
    "Biology and Philosophy": "Biology & philosophy",
    "Biology of Blood and Marrow Transplant": "Ecology of food and nutrition",
    "Biometrical Journal": "Biomedical journal",
    "Bioorganic and Medicinal Chemistry": "Bioorganic & medicinal chemistry",
    "Blood Cells Molecular Diseases": "Blood cells, molecules & diseases",
    "Brain Behavior and Evolution": "Brain, behavior and evolution",
    "Brain Research Cognitive Brain Research": "Brain research. Molecular brain research",
    "Brain Research Developmental Brain Research": "Brain research. Developmental brain research",
    "Brazilan Journal of Otorhinolaryngology": "Brazilian journal of otorhinolaryngology",
    "British Journal for the Philosophy of Science": "The British journal for the philosophy of science",
    "British Journal of Nutrition": "The British journal of nutrition",
    "British Journal of Oral and Maxillofacial Surgery": "The British journal of oral & maxillofacial surgery",
    "Bulletin de la Societe de Pathologies Exotiques": "Bulletin de la Societe de pathologie exotique (1990)",
    "Bulletin of the World Health Organisation": "Bulletin of the World Health Organization",
    "CNS and Neurologic Disorders Drug Targets": "CNS & neurological disorders drug targets",
    "CPT Pharmacometrics & Systems pharmacology": "CPT: pharmacometrics & systems pharmacology",
    "Canadian Journal of Cardiology": "The Canadian journal of cardiology",
    "Canadian Journal of Neurol Sci Psychiatry": "Journal of Neurology and Psychiatry",
    "Cancer Biotherapy and Radiopharmaceuticals": "Cancer biotherapy & radiopharmaceuticals",
    "Cancer Immunology Immunotherapy": "Cancer immunology, immunotherapy : CII",
    "Cardiovascular Diabetology Journal": "Cardiovascular diabetology",
    "Cardiovascular Drugs Therapy": "Cardiovascular drugs and therapy",
    "Cardiovascular Imaging": "JACC. Cardiovascular imaging",
    "Cell Death & Differentiation": "Cell death and differentiation",
    "Cerebrovascular Diseases": "Cardiovascular diseases",
    "Chronic Respiratory Disease journal": "Chronic respiratory disease",
    "Circulation Arrhythmia and Electrophysiology": "Circulation. Arrhythmia and electrophysiology",
    "Circulation Cardiovascular Imaging": "Circulation. Cardiovascular imaging",
    "Circulation Cardiovascular Interventions": "Circulation. Cardiovascular interventions",
    "Circulation Genomics and Precision Medicine": "Circulation. Genomic and precision medicine",
    "Circulation Heart Failure": "Circulation. Heart failure",
    "Circulation: Cardiovascular Imaging": "Circulation. Cardiovascular imaging",
    "Circulation: Heart Failure": "Circulation. Heart failure",
    "Clinical Journal of Pain": "The Clinical journal of pain",
    "Clinical Journal of the American Society of Nephrology": "Clinical journal of the American Society of Nephrology : CJASN",
    "Clinical Lymphoma Myeloma & Leukemia": "Clinical lymphoma, myeloma & leukemia",
    "Clinical Neurology Neurosurgery": "Clinical neurology and neurosurgery",
    "Clinical Neuropsychology": "Clinical neuropathology",
    "Clinical Pediatric Dermatology": "Pediatric dermatology",
    "Clinical Pharmacology and Therapetics": "Clinical pharmacology and therapeutics",
    "Clinical Respiratory Journal": "The clinical respiratory journal",
    "Clinical Transplantion": "Clinical transplantation",
    "Clinical and Experimental Ophthalmology": "Clinical & experimental ophthalmology",
    "Clinical and Experimental Optometry": "Clinical & experimental optometry",
    "Clinical and Experimental Pharmacology and Physiology": "Clinical and experimental pharmacology & physiology",
    "Clinical and Laboratory Medicine": "Clinics in laboratory medicine",
    "Clinical and Translational Immunology": "Clinical & translational immunology",
    "Clinincal Neurophysiology": "Clinical neuropathology",
    "Cochrane Database Systematic Reviews": "The Cochrane database of systematic reviews",
    "Cognitive Affective Behavioral Neuroscience": "Cognitive, affective & behavioral neuroscience",
    "Communication in Statitics Theory & Methods": "Communications in statistics: theory and methods",
    "Comparative Biochemistry Physiology Part D Genomics Proteomics": "Comparative biochemistry and physiology. Part D, Genomics & proteomics",
    "Comparative Biochemistry and Physiology Part A Molecular and Integrative Physiology": "Comparative biochemistry and physiology. Part A, Molecular & integrative physiology",
    "Comparative Biochemistry and Physiology Part B Biochemistry and Molecular Biology": "Comparative biochemistry and physiology. Part B, Biochemistry & molecular biology",
    "Comparative Biochemistry and Physiology Part C Tocicology and Pharmacology": "Comparative biochemistry and physiology. Toxicology & pharmacology : CBP",
    "Computational Biology and Chemistry.": "Computational biology and chemistry",
    "Computers Informatics Nursing": "Computers, informatics, nursing : CIN",
    "Concepts in Magnetic Resonance Part A Bridging Education and Research": "Concepts in magnetic resonance. Part A, Bridging education and research",
    "Critical Reviews in Oncology and Hematology": "Critical reviews in oncology/hematology",
    "Current Hematology and Malignancy Reports": "Current hematologic malignancy reports",
    "Current Hypertension Report": "Current hypertension reports",
    "Current Infectious Diseases Reports": "Current infectious disease reports",
    "Current Medical Chemistry": "Current medicinal chemistry",
    "Current Medical Research Opinion": "Current medical research and opinion",
    "Current Neurology Neuroscience Report": "Current neurology and neuroscience reports",
    "Current Opinion in Anesthesiology": "Current opinion in anaesthesiology",
    "Current Opinion in Endocrinology Diabetes and Obesity": "Current opinion in endocrinology, diabetes, and obesity",
    "Current Opinion in Genetics and Development": "Current opinion in genetics & development",
    "Current Opinion in HIV AIDS": "Current opinion in HIV and AIDS",
    "Current Opinion in Obstettrics and Gynecology": "Current opinion in obstetrics & gynecology",
    "Current Opinion in Otolaryngology and Head and Neck Surgery": "Current opinion in otolaryngology & head and neck surgery",
    "Cytogenetics and Genome Research": "Cytogenetic and genome research",
    "Cytometry Part B Clinical Cytometry": "Cytometry. Part B, Clinical cytometry",
    "Dev elopmental Cognitive Neurosciencve": "Developmental cognitive neuroscience",
    "Develomental Medicine and Child Neurology": "Developmental medicine and child neurology",
    "Development Growth and Differentiation": "Development, growth & differentiation",
    "Developmental Comparative Immunology": "Developmental and comparative immunology",
    "Diabetes Metabolism Research & Reviews": "Diabetes/metabolism research and reviews",
    "Diabetes Obesity and Metabolism": "Diabetes, obesity & metabolism",
    "Diabetes and Vascular Disease Research": "Diabetes & vascular disease research",
    "Diagnostic Microbiology & Infectious Diseases": "Diagnostic microbiology and infectious disease",
    "Digestive Diseases and Science": "Digestive diseases and sciences",
    "Disaster Medicine and Public Health Preparadness": "Disaster medicine and public health preparedness",
    "Drug Metabolism Review": "Drug metabolism reviews",
    "EClinical Medicine": "EClinicalMedicine",
    "ESC Heart Failure Journal": "ESC heart failure",
    "Ear Nose Throat Journal": "Ear, nose, & throat journal",
    "Eat Weight Disorders": "Eating disorders",
    "Emergency Medical Australasia": "Emergency medicine Australasia : EMA",
    "Emergency Medicine Journal": "Emergency medicine journal : EMJ",
    "Emerging Microbes & Infection": "Emerging microbes & infections",
    "Endocrine Metabolic and Immune Disorders Drug Targets": "Endocrine, metabolic & immune disorders drug targets",
    "Endocrine Related Cancer": "Endocrine-related cancer",
    "Environmental Health and Prevention Medicine": "Environmental health and preventive medicine",
    "Epidemioliology and Infection": "Epidemiology and infection",
    "Epidemiology and Psychiatric Science": "Epidemiology and psychiatric sciences",
    "Eurolntervention": "Neurointervention",
    "European Annals of Otorhinolaryngology Head and Neck Diseases": "European annals of otorhinolaryngology, head and neck diseases",
    "European Child and Adolescent Psychiatry": "European child & adolescent psychiatry",
    "European Heart Journal - Case Reports": "European heart journal. Case reports",
    "European Heart Journal Acute Cardiovascular Care": "European Heart Journal. Acute Cardiovascular Care",
    "European Heart Journal Cardiovascular Pharmacotherapy": "European heart journal. Cardiovascular pharmacotherapy",
    "European Journal of Cardiothoracic Surgery": "Journal of cardiothoracic surgery",
    "European Journal of Clinical Microbiology and Infectious Diseases": "Mediterranean journal of hematology and infectious diseases",
    "European Journal of Contraception and Reproductive Health Care": "The journal of family planning and reproductive health care",
    "European Journal of Gastroenterology and Hepatology": "European journal of gastroenterology & hepatology",
    "European Journal of General Practice": "The European journal of general practice",
    "European Journal of Obstetrics & Gynecology and Reproductive Biology": "European journal of obstetrics, gynecology, and reproductive biology",
    "European Journal of Obstetrics Gynecology and Reproductive Biology": "European journal of obstetrics, gynecology, and reproductive biology",
    "European Journal of Oral Science": "European journal of oral sciences",
    "European Journal of Pediatry": "European journal of pediatrics",
    "European Respiratory Journal": "The European respiratory journal",
    "European Respiratory Journal Open Research": "BMJ open respiratory research",
    "Evaluation and the Health Professions": "Evaluation & the health professions",
    "Evolution Psychiatrique": "L Evolution psychiatrique",
    "Experimental Hematology and Oncology": "Experimental hematology & oncology",
    "Expert Opinion onTherapeutic Patents": "Expert opinion on therapeutic patents",
    "Expert Review Proteomics": "Expert review of proteomics",
    "Expert Review of Anti Infective Therapy": "Expert review of anti-infective therapy",
    "Expert Review of Gastroenterology and Hepatology": "Expert review of gastroenterology & hepatology",
    "Expert Review of Molecular Diagnostic": "Expert review of molecular diagnostics",
    "Expert Review of Molecular Medicine": "Expert reviews in molecular medicine",
    "FEBS Letter": "FEBS letters",
    "FEMS Microbiology Letter": "FEMS microbiology letters",
    "FEMS Microbiology Review": "FEMS microbiology reviews",
    "Facial Plastic Surgery Clinics of North American": "Facial plastic surgery clinics of North America",
    "Forensic Science International Genetics": "Forensic science international. Genetics",
    "Forensic Science Medicine and Pathology": "Forensic science, medicine, and pathology",
    "Free Radical Biology and Medicine": "Free radical biology & medicine",
    "Frontiers in Cellular Infection and Microbiology": "Frontiers in cellular and infection microbiology",
    "Frontiers in Computanional Neuroscience": "Frontiers in computational neuroscience",
    "Fundamental and Clinical Pharmacology": "Fundamental & clinical pharmacology",
    "Gait and Posture": "Gait & posture",
    "Genes Brain and Behavior": "Genes, brain, and behavior",
    "Geriatrics and Gerontology International": "Geriatrics & gerontology international",
    "Global Health Science Practice": "Global health, science and practice",
    "Gynecologic Obstetric Investigation": "Gynecologic and obstetric investigation",
    "Gynecologie Obstetrique Fertililt\u00c3\u00a9 & Senologie": "Gynecologie, obstetrique, fertilite & senologie",
    "Health Care Women Internationa": "Health care for women international",
    "Health Expectations": "Health communication",
    "Health Information Management Journal": "Health information and libraries journal",
    "Health Service Research": "Health services research",
    "Health and Place": "Health & place",
    "Health and Social Care in the Community": "Health & social care in the community",
    "Hematology Oncology": "Hematological oncology",
    "Human Gene Therapy Clinical Development": "Human gene therapy. Clinical development",
    "Human Vaccines and Immunotherapeutics": "Human vaccines & immunotherapeutics",
    "IEEE International Symposium on Computer-Based Medical Systems": "Proceedings. IEEE International Symposium on Computer-Based Medical Systems",
    "IEEE Transactions in Medical Imaging": "IEEE transactions on medical imaging",
    "IEEE Transactions on Cognitive Development Systems": "IEEE transactions on computational social systems",
    "IEEE Transactions on Visualization and Computer gGaphics": "IEEE transactions on visualization and computer graphics",
    "Immunity Inflammation and Disease": "Immunity, inflammation and disease",
    "Immunology Allergy Clinics of North America": "Immunology and allergy clinics of North America",
    "In Vitro Cellular and Developmental Biology Animal": "In vitro cellular & developmental biology. Animal",
    "Indian Journal of Nuclear Medicine": "World journal of nuclear medicine",
    "Indian Journal of Otolaryngology Head and Neck Surgery": "World journal of otorhinolaryngology - head and neck surgery",
    "Infection Genetics & Evolution": "Infection prevention in practice",
    "Intensive and Critical Care Nursing": "Intensive & critical care nursing",
    "International Emergency Medicine": "Internal and emergency medicine",
    "International Forum for Allergy & Rhinology": "International forum of allergy & rhinology",
    "International Journal of Artificial Organs": "The International journal of artificial organs",
    "International Journal of Behavioral Nutrition and Physical Activity": "The international journal of behavioral nutrition and physical activity",
    "International Journal of Biochemistry and Cell Biology": "International journal of biochemistry and molecular biology",
    "International Journal of Biological Science": "International journal of biological sciences",
    "International Journal of Biostatitics": "International journal of pediatrics",
    "International Journal of Bipolar Disorder": "International journal of bipolar disorders",
    "International Journal of Cardiology Heart and Vasculature": "International journal of cardiology. Heart & vasculature",
    "International Journal of Cardiovascular Imaging": "The international journal of cardiovascular imaging",
    "International Journal of Colorectal Diseases": "International journal of colorectal disease",
    "International Journal of Developmental Biology": "The International journal of developmental biology",
    "International Journal of Environmental and Health Research": "International journal of environmental health research",
    "International Journal of Language and Communication Disorders": "International journal of language & communication disorders",
    "International Journal of Molecular Science": "International journal of molecular sciences",
    "International Journal of Oral Maxillofacial Implants": "The International journal of oral & maxillofacial implants",
    "International Journal of Oral Maxillofacial Surgery": "International journal of oral and maxillofacial surgery",
    "International Journal of Quality Health Care": "International journal of public health",
    "International Journal of Radiation Oncology Biology Physics": "International journal of radiation oncology, biology, physics",
    "International Journal of STD and AIDS": "International journal of STD & AIDS",
    "International Journal of Womens Dermatology": "International journal of womens dermatology",
    "International Psychogeriatry": "International psychogeriatrics",
    "International Reviews in Immunology": "International reviews of immunology",
    "International journal of Healthcare Information Systems and Informatics": "International journal of health care finance and economics",
    "Invertebrate Reproduction and Development": "Molecular reproduction and development",
    "Investigative Ophthalmology and Visual Science": "Investigative ophthalmology & visual science",
    "JACC Basic to Translational Science": "JACC. Basic to translational science",
    "JACC Cardiovascular Imaging": "JACC. Cardiovascular imaging",
    "JACC Cardiovascular Interventions": "JACC. Cardiovascular interventions",
    "JACC Clinical Electrophisiology": "JACC. Clinical electrophysiology",
    "JACC Heart Failure": "JACC. Heart failure",
    "JAMA Facial and Plastic Surgery": "JAMA facial plastic surgery",
    "JAMA Otolaryngoly and Head and Neck Surgery": "JAMA otolaryngology-- head & neck surgery",
    "Japanese Journal of Surgery": "The Japanese journal of surgery",
    "Joint Bone and Spine": "Joint bone spine",
    "Journal of Addiction Nursing": "Journal of addictions nursing",
    "Journal of Allergy and Clinical Immunology": "The Journal of allergy and clinical immunology",
    "Journal of Allergy and Clinical Immunology Practice": "The journal of allergy and clinical immunology. In practice",
    "Journal of American College of Cardiology": "Journal of the American College of Cardiology",
    "Journal of Anattomy": "Journal of anatomy",
    "Journal of Antibiotics": "Journal of xenobiotics",
    "Journal of Bioenergetics Biomembranes": "Journal of bioenergetics and biomembranes",
    "Journal of Biological Chemistry": "The Journal of biological chemistry",
    "Journal of Bone and Joint Surgery American Volume": "The Journal of bone and joint surgery. American volume",
    "Journal of Cell Communication and Signalling": "Journal of cell communication and signaling",
    "Journal of Child Orthopedics": "Journal of childrens orthopaedics",
    "Journal of Child and Adolesc Psychopharmacology": "Journal of child and adolescent psychopharmacology",
    "Journal of Child and Adolescent Substance Abuse": "Journal of child & adolescent substance abuse",
    "Journal of Chromatography A": "Journal of chromatography. A",
    "Journal of Chromatography B Analytical Technologies in the Biomedical and Life Sciences": "Journal of chromatography. B, Analytical technologies in the biomedical and life sciences",
    "Journal of Clinical Ethics": "Journal of medical ethics",
    "Journal of Comparative Physiology A Neuroethology Sensory Neural and Behavioral Physiology": "Journal of comparative physiology. A, Neuroethology, sensory, neural, and behavioral physiology",
    "Journal of Computatonal Neuroscience": "Journal of computational neuroscience",
    "Journal of Craniofacial Surgery": "The Journal of craniofacial surgery",
    "Journal of Craniomaxillofacial Surgery": "Journal of oral & maxillofacial research",
    "Journal of Diabetes Complications": "Journal of diabetes investigation",
    "Journal of Emergency Medicine": "The Journal of emergency medicine",
    "Journal of Endocrinology Investigation": "Journal of endocrinological investigation",
    "Journal of Experimental & Clinical Cancer Research": "Journal of experimental & clinical cancer research : CR",
    "Journal of Experimental Medicine": "The Journal of experimental medicine",
    "Journal of Experimental Zoology A Ecological Genetics and Physiology": "Journal of experimental zoology. Part A, Ecological genetics and physiology",
    "Journal of Experimental Zoology B Molecular and Developmental Evolution": "Journal of experimental zoology. Part B, Molecular and developmental evolution",
    "Journal of Gerontology B Psychological Sciences & Social Science": "The journals of gerontology. Series B, Psychological sciences and social sciences",
    "Journal of Gerontology Nursing": "Journal of gerontological nursing",
    "Journal of Heart Valve Diseases": "The Journal of heart valve disease",
    "Journal of Hospital Infection": "The Journal of hospital infection",
    "Journal of Innate Immuninty": "Journal of innate immunity",
    "Journal of Innovative Optical Health Science": "Journal of innovative optical health sciences",
    "Journal of International Advanced Otology": "The journal of international advanced otology",
    "Journal of Investigative Dermatology": "The Journal of investigative dermatology",
    "Journal of Investigative Dermatology Symposium Proceedings": "The journal of investigative dermatology. Symposium proceedings",
    "Journal of Laryngology and Otology": "The Journal of laryngology and otology",
    "Journal of Law Medicine & Ethics": "Journal of medical ethics",
    "Journal of Lipoosome Research": "Journal of liposome research",
    "Journal of Maternal Fetal & Neonatal Medicine": "Journal of neonatal-perinatal medicine",
    "Journal of Medical Ultrasonics": "Journal of medical ultrasound",
    "Journal of Medicine and Philosophy": "The Journal of medicine and philosophy",
    "Journal of Membrane Biology": "The Journal of membrane biology",
    "Journal of Microbiology": "Journal of neurobiology",
    "Journal of Microbiology Immunology & Infection": "Journal of microbiology & biology education",
    "Journal of Neurodevelopment Disorders": "Journal of neurodevelopmental disorders",
    "Journal of Neuroengineering Rehabilitation": "Journal of neuroengineering and rehabilitation",
    "Journal of Neurology Neurosurgery & Psychiatry": "Journal of neurology, neurosurgery, and psychiatry",
    "Journal of Neurooncology": "Journal of neuro-oncology",
    "Journal of Neuropathology & Experimental Neurology": "Journal of neuropathology and experimental neurology",
    "Journal of Neurosurgery Pediatrics": "Journal of neurosurgery. Pediatrics",
    "Journal of Neurosurgery Spine": "Journal of neurosurgery. Spine",
    "Journal of Obsessive and Compulsive Related Disorders": "Journal of obsessive-compulsive and related disorders",
    "Journal of Obstetric Gynecologic and Neonatal Nursing": "Journal of obstetric, gynecologic, and neonatal nursing : JOGNN",
    "Journal of Obstetrics and Gynaecology Research": "The journal of obstetrics and gynaecology research",
    "Journal of Pain Palliative Care & Pharmacotherapy": "Journal of pain & palliative care pharmacotherapy",
    "Journal of Pathology and Clinical Research": "The journal of pathology. Clinical research",
    "Journal of Pediatric Endocrinology and Metabolism": "Annals of pediatric endocrinology & metabolism",
    "Journal of Pediatric Hematology & Oncology": "Journal of pediatric hematology/oncology",
    "Journal of Pediatric Ophthalmology & Strabismus": "Journal of pediatric ophthalmology and strabismus",
    "Journal of Pediatric Orthopaedic": "Journal of pediatric orthopedics",
    "Journal of Pediatric Orthopaedic Part B": "Journal of pediatric orthopedics. Part B",
    "Journal of Perinatal and Neonatal Nursing": "The Journal of perinatal & neonatal nursing",
    "Journal of Pharmacy and Pharmacology": "The Journal of pharmacy and pharmacology",
    "Journal of Photochemistry Photobiology Part A Chemistry": "Journal of photochemistry and photobiology. A, Chemistry",
    "Journal of Photochemistry Photobiology Part B Biology": "Journal of photochemistry and photobiology. B, Biology",
    "Journal of Physical Activity and Health": "Journal of physical activity & health",
    "Journal of Plastic Reconstructive Aesthetic Surgery": "Journal of plastic, reconstructive  aesthetic surgery : JPRAS",
    "Journal of Prevention of the Alzheimer's Disease": "The journal of prevention of Alzheimers disease",
    "Journal of Public Health Management & Practice": "Journal of public health management and practice : JPHMP",
    "Journal of Reproduction and Development": "The Journal of reproduction and development",
    "Journal of Soeech Language and Hear Research": "Journal of speech and hearing research",
    "Journal of Statistical Computation & Simulation": "Journal of statistical computation and simulation",
    "Journal of Steroid Biochemistry and Molecular Biology": "The Journal of steroid biochemistry and molecular biology",
    "Journal of Stomatology & Oral and maxillofacial Surgery": "International journal of oral and maxillofacial surgery",
    "Journal of Thorac Imaging": "Journal of thoracic imaging",
    "Journal of Thoracic Diseases": "Journal of thoracic disease",
    "Journal of Thoracic and Cardiovascular Surgery": "The Journal of thoracic and cardiovascular surgery",
    "Journal of Toxicology and Environmental Health Part B Critical Reviews": "Journal of toxicology and environmental health. Part B, Critical reviews",
    "Journal of Vascular Surgery Venous and Lymphatic Disorders": "Journal of vascular surgery. Venous and lymphatic disorders",
    "Journal of Visual Impairment and Blindness": "Journal of visual impairment & blindness",
    "Journal of Women's Health and Gender-Based Medicine": "Journal of womens health & gender-based medicine",
    "Journal of acquired immune deficiency syndromes : JAIDS": "Journal of acquired immune deficiency syndromes (1999)",
    "Journal of the American Board of Family Practice": "The Journal of the American Board of Family Practice",
    "Journal of the American Geriatric Society": "Journal of the American Geriatrics Society",
    "Journal of the American Society of Mass Spectrometry": "Journal of the American Society for Mass Spectrometry",
    "Journal of the American Statistics Association": "Journal of the American Statistical Association",
    "Journal of the Association for Research in Otolaryngology": "Journal of the Association for Research in Otolaryngology : JARO",
    "Journal of the Association of Nurses in AIDS Care": "The Journal of the Association of Nurses in AIDS Care : JANAC",
    "Journal of the Oklahoma State Medical Association": "The Journal of the Oklahoma State Medical Association",
    "Journal of the Royal Statistical Society Series A Statistics in Society": "Journal of the Royal Statistical Society. Series A, (Statistics in Society)",
    "Journal of the Royal Statistical Society Series B Statistical Methodology": "Journal of the Royal Statistical Society. Series B, Statistical methodology",
    "Journal of the Royal Statistical Society Series C Applied Statistics": "Journal of the Royal Statistical Society. Series C, Applied statistics",
    "Kidney and Blood Pressure Research": "Kidney & blood pressure research",
    "Knee Surgery Sports Traumatology Arthroscopy": "Knee surgery & related research",
    "La Revue des Maladies Respiratoires": "Revue des maladies respiratoires",
    "Langenbecks Archives of Surgery": "Langenbecks archives of surgery",
    "Magnetic Resonance Medicine": "Magnetic resonance in medicine",
    "Medical Care Research & Review": "Medicinal research reviews",
    "Medicine Clinics of North America": "Pediatric clinics of North America",
    "Medicine Science and the Law": "Medicine, science, and the law",
    "Medicine and Science in Sports and Exercice": "Medicine and science in sports and exercise",
    "Mental Retardation and Development Disability Reseach and Reviews": "Mental retardation and developmental disabilities research reviews",
    "Molecular Imaging Biology": "Molecular imaging and biology",
    "Molecular Pharmacol": "Molecular pharmacology",
    "Molecular and Cellular Neuroscience": "Molecular and cellular neurosciences",
    "Mutation Research Reviews in Mutation Research": "Mutation research. Reviews in mutation research",
    "Nature Reviews Cardiology": "Nature reviews. Cardiology",
    "Nature Reviews Clinical Oncology": "Nature reviews. Clinical oncology",
    "Nature Reviews Drug Discovery": "Nature reviews. Drug discovery",
    "Nature Reviews Endocrinology": "Nature reviews. Endocrinology",
    "Nature Reviews Gastroenterology & Hepatology": "Nature reviews. Gastroenterology & hepatology",
    "Nature Reviews Genetics": "Nature reviews. Genetics",
    "Nature Reviews Microbiology": "Nature reviews. Microbiology",
    "Nature Reviews Molecular Cell Biology": "Nature reviews. Molecular cell biology",
    "Nature Reviews Nephrology": "Nature reviews. Nephrology",
    "Nature Reviews Neurology": "Nature reviews. Neurology",
    "Nature Reviews Neuroscience": "Nature reviews. Neuroscience",
    "Nature Reviews Rheumatology": "Nature reviews. Rheumatology",
    "Nature Reviews Urology": "Nature reviews. Urology",
    "Nauny-Schmiedbergs Archives of Pharmacology": "Naunyn-Schmiedebergs archives of pharmacology",
    "Nephron Clinical Practice": "Nephron. Clinical practice",
    "Nephron Experimental Nephrology": "Nephron. Experimental nephrology",
    "Neurobiology of Diseases": "Neurobiology of disease",
    "Neurobiology of Learning & Memory": "Neurobiology of learning and memory",
    "Neurochemistry Research": "Neurochemical research",
    "Neurogastroenterology & Motility": "Gastroenterology & hepatology",
    "Neuroimage Clinical": "NeuroImage. Clinical",
    "Neurolmage": "NeuroImage",
    "Neurology Clinical Practice": "Neurology: Clinical Practice",
    "Neurology Genetics": "Neurology. Genetics",
    "Neurology Neuroimmunology & Neuroinflammation": "Neurology(R) neuroimmunology & neuroinflammation",
    "Neurology Neuroimmunology Neuroinflammation": "Neurology(R) neuroimmunology & neuroinflammation",
    "Neurology Psychiatry and Brain Research": "Neurology, psychiatry, and brain research",
    "Neuropsychiatry Neuropsychology and Behavioral Neurology": "Health psychology and behavioral medicine",
    "Nursing Inquiries": "Nursing inquiry",
    "Nutrition Clinique et M\u00c3\u00a9tabolisme": "Nutrition Clinique et Me\u00ef\u00bf\u00bdtabolisme",
    "ORL Journal of Otorhinolaryngology and Related Specialities": "ORL; journal for oto-rhino-laryngology and its related specialties",
    "Obstetrical and Gynecological Survey": "Obstetrical & gynecological survey",
    "Oral Surgery Oral Medicine Oral Pathology Oral Radiology": "Oral surgery, oral medicine, oral pathology and oral radiology",
    "Orthodontics and Craniofacial Research": "Orthodontics & craniofacial research",
    "Orthopaedic Nursing": "Orthopedic nursing",
    "Orthopedic Clinics of North America": "The Orthopedic clinics of North America",
    "Otolaryngology Clinics of North America": "Otolaryngologic clinics of North America",
    "Otolaryngology and Head & Neck Surgery": "JAMA otolaryngology-- head & neck surgery",
    "Oxididative Medicine and Cellular Longevity": "Oxidative medicine and cellular longevity",
    "Pace-Pacing and Clinical Electrophisiology": "Pacing and clinical electrophysiology : PACE",
    "Pain Research and Management": "Pain research & management",
    "Palliative and Supportive Care": "Palliative & supportive care",
    "Parkinsonism Related Disorders": "Parkinsonism & related disorders",
    "Pathologie Biologie": "Pathologie-biologie",
    "Pediatric Allergy Immunology Pulmonology": "Pediatric allergy, immunology, and pulmonology",
    "Pediatric Anesthesia": "Paediatric anaesthesia",
    "Pediatric Blood Cancer": "Pediatric blood & cancer",
    "Pediatric Hematology & Oncology": "Pediatric Hematology Oncology Journal",
    "Pediatric Infectious Disease Journal": "The Pediatric infectious disease journal",
    "Pediatric Rheumatology": "Pediatric dermatology",
    "Pediatrics reports": "Pediatric reports",
    "Pharmacogenomics Journal": "The pharmacogenomics journal",
    "Pharmacology Biochemistry and Behavior": "Pharmacology, biochemistry, and behavior",
    "Photodermatology Photoimmunology Photomedicine": "Photodermatology, photoimmunology & photomedicine",
    "Pigment Cell and Melanoma Research": "Pigment cell & melanoma research",
    "Polish Journal of Pathology": "Polish journal of radiology",
    "Proceedings of the Nutrition Society": "The Proceedings of the Nutrition Society",
    "Progress in Molecular Biology & Translational Science": "Progress in molecular biology and translational science",
    "Progress in Neuropsychopharmacology & Biological Psychiatry": "Progress in neuro-psychopharmacology & biological psychiatry",
    "Progress in Transplantation": "Journal of transplantation",
    "Prostate Cancer Prostatic Disease": "Prostate cancer and prostatic diseases",
    "Proteomics Clinical Applications": "Proteomics. Clinical applications",
    "Psychiatry Research Neuroimaging": "Psychiatry research. Neuroimaging",
    "Quaterly Review of Biology": "The Quarterly review of biology",
    "Radiology Clinics of North America": "Radiologic clinics of North America",
    "Regional Anesthia and Pain Medicine": "Regional anesthesia and pain medicine",
    "Reproduction Fertility Development": "Reproduction, fertility, and development",
    "Reproduction and Fertility": "Reproduction & fertility",
    "Reviews in Endocrine and Metabolic Disorders": "Reviews in endocrine & metabolic disorders",
    "Reviews in Environmental Health": "Reviews on environmental health",
    "Reviews in Neuroscience": "Reviews in the neurosciences",
    "Revista Latino-America de Enfermagem": "Revista latino-americana de enfermagem",
    "Revista Portuguesa de Cardiologia (English edition)": "Revista espanola de cardiologia (English ed.)",
    "Revue de M\u00c3\u00a9decine Interne": "La Revue de medecine interne",
    "Revue de M\u00c3\u00a9decine L\u00c3\u00a9gale": "La Revue de medecine interne",
    "Scandinavian Journal of Caring Science": "Scandinavian journal of caring sciences",
    "Scandinavian Journal of Trauma Research and Emergency Medicine": "Scandinavian journal of trauma, resuscitation and emergency medicine",
    "Science of the Total Environment": "The Science of the total environment",
    "Sciences Advances": "Science advances",
    "Seminars in Colon and Rectal Surgery": "Seminars in colon & rectal surgery",
    "Seminars in Fetal and Neonatal Medicine": "Seminars in fetal & neonatal medicine",
    "Seminars in Respiriratory and Critical Care Medicine": "Seminars in respiratory and critical care medicine",
    "Seminars in Ultrasound CT & MRI": "Seminars in ultrasound, CT, and MR",
    "Semininars in Arthritis and Rheumatism": "Seminars in arthritis and rheumatism",
    "Semininars in Cell and Developmental Biology": "Seminars in cell & developmental biology",
    "Social Cognitive Affective Neuroscience": "Social cognitive and affective neuroscience",
    "Sociology of Health and Illness": "Sociology of health & illness",
    "Somatosensory and Motor Research": "Somatosensory & motor research",
    "Substance Abuse Treatment Prevention & Policy": "Substance abuse treatment, prevention, and policy",
    "Substance Use and Misuse": "Substance use & misuse",
    "Surgical Laparoscopy Endoscopy and Percutaneous Techniques": "Surgical laparoscopy, endoscopy & percutaneous techniques",
    "Surgical and Radiological Anatomy": "Surgical and radiologic anatomy : SRA",
    "Systems Biology and Reproductive Medicine": "Systems biology in reproductive medicine",
    "The Lancet": "Lancet (London, England)",
    "The Lancet Digital Health": "The Lancet. Digital health",
    "The Lancet Gastroenterology & Hepatology": "The lancet. Gastroenterology & hepatology",
    "The Lancet Haematology": "The Lancet. Haematology",
    "The Lancet Neurology": "The Lancet. Neurology",
    "The Lancet Planetary Health": "The Lancet. Planetary health",
    "The Lancet Psychiatry": "The lancet. Psychiatry",
    "The Lancet Public Health": "The Lancet. Public health",
    "The Lancet Respiratory Medicine": "The Lancet. Respiratory medicine",
    "Theoretical Biology and Medical Modelling": "Theoretical biology & medical modelling",
    "Therapeutic Advances Neurologic Disorders": "Therapeutic advances in neurological disorders",
    "Therapeutic Advances in Musculoskeletal Diseases": "Therapeutic advances in musculoskeletal disease",
    "Therapeutic Advances in Respiratory Diseases": "Therapeutic advances in respiratory disease",
    "Tissue Engineering Part A": "Tissue engineering. Part A",
    "Tissue Engineering Part C Methods": "Tissue engineering. Part C, Methods",
    "Toxicology and Pathology": "Toxicologic pathology",
    "Translational Vision Science and Technology": "Translational vision science & technology",
    "Travel Medicine and Infectious Diseases": "Travel medicine and infectious disease",
    "Trends in Biochemical Science": "Trends in biochemical sciences",
    "Trends in Cognitive Science": "Trends in cognitive sciences",
    "Trends in Endocrinology & Metabolism": "Trends in endocrinology and metabolism: TEM",
    "Turkish Journal of Ophtalmology": "Turkish journal of ophthalmology",
    "Ultrasound Quaterly": "Ultrasound quarterly",
    "Ultrasound in Medicine and Biology": "Ultrasound in medicine & biology",
    "Ultrasound in Obstetric and Gynecology": "Case reports in obstetrics and gynecology",
    "Upsala Journal of Medical Science": "Upsala journal of medical sciences",
    "Wiley Interdisciplinary Reviews Developmental Biology": "Wiley interdisciplinary reviews. Developmental biology",
    "Wiley Interdisciplinary Reviews RNA": "Wiley interdisciplinary reviews. RNA",
    "Wiley Interdisciplinary Reviews Systems Biology and Medicine.": "Wiley interdisciplinary reviews. Systems biology and medicine",
    "World Allergy Organization Journal": "The World Allergy Organization journal",
    "World Journal of Biological Psychiatry": "World journal of biological chemistry"
}