import { SaveOutlined } from "@ant-design/icons";
import { message } from "antd";
import { getRequestErrorMessage } from "../helpers";

export function triggerAutosaveMessage(error?: unknown) {
  //   message.config({ top: window.innerHeight - 100 });

  if (error)
    return message.error({
      icon: <SaveOutlined />,
      content: `Auto-save error: ${getRequestErrorMessage(error)}`,
      duration: 1,
    });

  return message.success({
    icon: <SaveOutlined />,
    content: `Content has been saved automatically`,
  });
}
