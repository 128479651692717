import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Layout,
  Row,
  Select,
  Spin,
} from "antd";

import { red } from "@ant-design/colors";
import { LeftOutlined } from "@ant-design/icons";

import {
  getJournal,
  createJournal,
  deleteJournal,
  updateJournal,
  uploadJournalImage,
} from "../services";
import { ICreateJournalDto } from "../model/dto//journal-dto";
import { createJournalMapping } from "../config/createJournalMapping";
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from "../config/dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../store/actions";
import { FETCH_JOURNALS } from "../store/types";
import { IJournal } from "~/model/journal.model";
import { IImage } from "~/model/image.model";

export const JournalEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const journalId = params.id;
  const [innerJournal, setInnerJournal] = useState<IJournal>();
  const [innerImage, setInnerImage] = useState<string>("");
  const [journalSaving, setJournalSaving] = useState<boolean>(false);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchJournal = async () => {
    if (journalId && !innerJournal) {
      const journal = await getJournal(journalId);
      setInnerJournal(journal);
      if (journal.image && (journal.image as IImage)?.url) {
        setInnerImage((journal.image as IImage)?.url);
      }
    }
  };

  useEffect(() => {
    fetchJournal();
  });

  const handleReturn = () => {
    navigate("/reference-data/journal/");
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Journal saved",
      description: "The journal was succesfully saved.",
    });
  };

  const handleEdition = async (values: IJournal) => {
    setJournalSaving(true);
    if (innerJournal) {
      const updateValues = { ...innerJournal, ...values };
      if (!updateValues?.h_index?.length) delete updateValues.h_index;

      const updatedJournal = await updateJournal(
        innerJournal._id,
        updateValues
      );
      if (acceptedFiles.length) {
        const journal = await uploadJournalImage(
          innerJournal._id,
          acceptedFiles[0]
        );
        if (journal.image && (journal.image as IImage)?.url) {
          setInnerImage((journal.image as IImage)?.url);
        }
      }
      setInnerJournal(updatedJournal);
    } else {
      try {
        const createdJournal = await createJournal(values);
        if (createdJournal) {
          navigate(`/reference-data/journal/${createdJournal._id}`);
        }
      } catch (error) {
        setJournalSaving(false);
        return;
      }
    }
    dispatch(fetchData(FETCH_JOURNALS));
    setJournalSaving(false);
    openNotificationSuccess();
  };

  const handleDeletion = async () => {
    if (innerJournal) {
      const deletedJournal = await deleteJournal(innerJournal._id);
      if (deletedJournal) {
        navigate("/reference-data/journal/");
      }
    }
  };

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Row>
          <div>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
          </div>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
            {journalId ? "Edit journal" : "Create journal"}
          </h1>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={journalSaving} tip='Journal saving...'>
            <Form
              name='journal_edition'
              layout='vertical'
              fields={
                innerJournal &&
                Object.keys(innerJournal).map((key: string) => ({
                  name: [key],
                  value: innerJournal[key as keyof ICreateJournalDto],
                }))
              }
              onFinish={handleEdition}
            >
              <Row
                style={{
                  paddingBottom: "20px",
                  borderBottom: "1px solid #d9d9d9",
                }}
                justify='space-between'
              >
                <Col span={12}>
                  {Object.keys(createJournalMapping).map((key: string) => (
                    <Form.Item
                      key={key}
                      label={createJournalMapping[key].label}
                      name={key}
                      required={createJournalMapping[key].required}
                    >
                      {createJournalMapping[key].type === "text" ? (
                        <Input
                          placeholder={createJournalMapping[key].label}
                          required={createJournalMapping[key].required}
                        />
                      ) : createJournalMapping[key].type === "textarea" ? (
                        <Input.TextArea
                          style={{ minHeight: "180px" }}
                          placeholder={createJournalMapping[key].label}
                        />
                      ) : createJournalMapping[key].type === "date" ? (
                        <>
                          <DatePicker />
                        </>
                      ) : createJournalMapping[key].type === "select" ? (
                        <Select>
                          {(createJournalMapping[key].enum as string[])?.map(
                            (option: string) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      ) : null}
                    </Form.Item>
                  ))}
                  {journalId ? (
                    <Form.Item key='image' label='Image' name='image'>
                      <div style={{ display: "flex" }}>
                        <div
                          {...getRootProps({
                            className: "dropzone",
                            maxFiles: 1,
                          })}
                        >
                          <input {...getInputProps({ style })} />
                        </div>
                        {innerImage ? (
                          <img
                            style={{ maxHeight: "72px", marginLeft: "10px" }}
                            src={innerImage}
                            alt={innerImage}
                          />
                        ) : null}
                      </div>
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
              <Row justify='end'>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='journal-form-button'
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    {journalId ? "Save journal" : "Create journal"}
                  </Button>
                  {journalId ? (
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: red.primary,
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                    >
                      Delete journal
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
