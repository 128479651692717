/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Spin,
  Tabs,
} from "antd";

import {
  SoundOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  postArticleSynthetize,
  searchVideos,
  postAssociateVideoToArticle,
  postDissociateVideoToArticle,
  listInfographics,
  associateInfographicToArticle,
  dissociateInfographicFromArticle,
  getArticleById,
} from "../../../services";
import { GlobalContext, IGlobalContext } from "../../../context/global.context";
import { useLocation, useParams } from "react-router-dom";
import LoadingLayout from "../../../components/shared/LoadingLayout";
import { Flex, Spacer } from "../../../components/shared/global";
import { isAxiosError } from "axios";
import ApiVideoPlayer from "@api.video/react-player";
import UploadSpeechAudio from "../../../components/UploadSpeechAudio";
import RoomsAssociator from "../../../components/shared/RoomsAssociator";
import Fuse from "fuse.js";
import ArticleEditionContent from "./ArticleEditionContentForm";
import ArticleEditionHeader from "./ArticleEditionHeader";
import { IArticle } from "~/model/article.model";
import { ContentFormatsEnum, SupportedLanguage } from "~/model/enum";
import { IInfographic } from "~/model/infographic.model";
import { IVideo } from "~/model/video.model";
import { useArticleEditionContext } from "~/utils/hooks";
import { ArticleEditionProvider } from "~/context/article.context";
import moment from "moment";
import { ITag } from "~/model/tag.model";

const ArticleEdition: React.FC = () => {
  // form instance
  const [form] = Form.useForm();

  const params = useParams();
  const location = useLocation();
  const { tagList } = useContext(GlobalContext) as IGlobalContext;
  const articleId = params.id;
  const search = new URLSearchParams(location.search);
  const { article, setArticle } = useArticleEditionContext();

  const [articleSynthesize, setArticleSynthesize] = useState<boolean>(false);
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    [SupportedLanguage.EN, SupportedLanguage.FR, SupportedLanguage.IT].includes(
      (search.get("language") as SupportedLanguage) || SupportedLanguage.EN
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );

  // Search video & infographics
  const [searchValue, setSearchValue] = useState<
    { videos: string; infographics: string } | undefined
  >();
  // Video
  const [innerVideos, setInnerVideos] = useState<IVideo[] | undefined>();
  const [innerInfographics, setInnerInfographics] = useState<
    IInfographic[] | undefined
  >();
  const [tmpInfographics, setTmpInfographics] = useState<
    IInfographic[] | undefined
  >(); // TODO: remove with infographic search

  const { TabPane } = Tabs;

  const fetchArticle = async () => {
    if (!articleId) return;
    const _article = await getArticleById(articleId);

    setLanguage(_article.language);
    setArticle(_article);

    // Set form values on language change
    const initialValues = {
      ..._article,
      publication_date: moment(_article?.publication_date),
      journal: _article?.journal?._id,
      keywords: Array.from(
        new Set(_article?.keywords?.filter((el) => el?.length > 0))
      ),
      medical_specialties: (_article?.medical_specialties as ITag[])?.map(
        (tag: ITag) => tag._id
      ),
      tags: (_article?.tags as ITag[])?.map((tag: ITag) => tag._id),
    };

    form.setFieldsValue(initialValues);
  };

  const handleSynthesize = async () => {
    if (!article) return;

    setArticleSynthesize(true);

    try {
      notification.info({
        message: "Article synthetize in progress...",
        description:
          "Please wait a few seconds, the article is being synthetized...",
        placement: "bottomRight",
      });
      const newArticle = await postArticleSynthetize(article._id);
      notification.success({
        message: "Article synthetized",
        description:
          "The article is being synthetized, it'll be available soon.",
        placement: "bottomRight",
      });

      setArticle({
        ...article,
        speech: newArticle.speech,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occured while synthetizing the article.",
        placement: "bottomRight",
      });
    }
    setArticleSynthesize(false);
  };

  const handleAssociateVideo = async (videoId: string) => {
    if (!article) return;

    try {
      const newArticle = await postAssociateVideoToArticle(
        article._id,
        videoId
      );

      setArticle({
        ...article,
        video: newArticle.video,
      });

      notification.success({
        message: "Video associated",
        description: "The video has been associated to the article.",
        placement: "bottomRight",
      });
    } catch (error) {
      let description = "An error occured while associating the video.";
      if (isAxiosError(error))
        description = error.response?.data?.message || message;

      notification.error({
        message: "Error",
        description,
        placement: "bottomRight",
      });
    }
  };

  const handleDissociateVideo = async () => {
    if (!article) return;

    Modal.confirm({
      title: "Dissociate video",
      content: "Are you sure to dissociate this video?",
      onOk: async () => {
        try {
          const newArticle = await postDissociateVideoToArticle(article._id);

          setArticle({
            ...article,
            video: newArticle.video,
          });

          notification.success({
            message: "Video dissociated",
            description: "The video has been dissociated from the article.",
            placement: "bottomRight",
          });
        } catch (error) {
          let description = "An error occured while dissociating the video.";
          if (isAxiosError(error))
            description = error.response?.data?.message || message;

          notification.error({
            message: "Error",
            description,
            placement: "bottomRight",
          });
        }
      },
    });
  };

  const handleAssociateInfographic = async (infographicId: string) => {
    if (!article) return;

    try {
      const newArticle = await associateInfographicToArticle({
        articleId: article._id,
        infographicId,
      });

      notification.success({
        message: "Infographic associated",
        description: "The infographic has been associated to the article.",
        placement: "bottomRight",
      });

      setArticle({
        ...article,
        associatedContent: newArticle.associatedContent,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occured while associating the infographic.",
        placement: "bottomRight",
      });
      throw error;
    }
  };

  const handleDissociateInfographic = async (infographicId: string) => {
    if (!article) return;

    try {
      const newArticle = await dissociateInfographicFromArticle({
        articleId: article._id,
        infographicId,
      });

      notification.success({
        message: "Infographic dissociated",
        description: "The infographic has been dissociated from the article.",
        placement: "bottomRight",
      });

      setArticle({
        ...article,
        associatedContent: newArticle.associatedContent,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occured while dissociating the infographic.",
        placement: "bottomRight",
      });
      throw error;
    }
  };

  const temporaryGetInfographics = async () => {
    const { docs: infographics } = await listInfographics({
      limit: 1000,
      offset: 0,
    });

    setTmpInfographics(infographics);
  };

  useEffect(() => {
    temporaryGetInfographics();
  }, []);

  useEffect(() => {
    setArticle(null);
    fetchArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const handleSearchVideo = async () => {
      if (!searchValue?.videos.length) {
        setInnerVideos([]);
        return;
      }

      const { hits } = await searchVideos(searchValue.videos, {
        language,
      });

      const videos = hits.map((hit: any) => ({
        ...hit._source.view,
        id: hit._source.id,
      }));

      setInnerVideos(videos);
    };

    // TODO: implement search infographics
    const handleSearchInfographics = () => {
      if (!tmpInfographics || !searchValue?.infographics?.length) return;

      const fuse = new Fuse(tmpInfographics, {
        keys: ["title"],
        threshold: 0.3,
      });

      const results = fuse.search(searchValue?.infographics);

      setInnerInfographics(results.map((result) => result.item));
    };

    if (searchValue?.videos) handleSearchVideo();
    else if (searchValue?.infographics) handleSearchInfographics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, language]);

  if ((!article && "id"! in params) || !tagList.length)
    return <LoadingLayout />;

  const associatedInfographics = article?.associatedContent?.filter(
    (content) => content.content_format === ContentFormatsEnum.INFOGRAPHIC
  );

  return (
    <div className='basic-layout'>
      <ArticleEditionHeader form={form} />

      <div className='site-layout-content'>
        <Tabs defaultActiveKey='1' destroyInactiveTabPane={false}>
          <TabPane tab='Card' key='1' destroyInactiveTabPane={false}>
            <ArticleEditionContent section='card' form={form} />
          </TabPane>

          <TabPane tab='Publication' key='2' destroyInactiveTabPane={false}>
            <ArticleEditionContent form={form} section='publication' />
          </TabPane>

          <TabPane tab='Rooms' key='3'>
            {article ? (
              <RoomsAssociator
                form={form}
                language={article.language}
                type={ContentFormatsEnum.ARTICLE}
                isPublic={article.isPublic}
                ownerId={article?.owner?._id}
                contentId={article?._id}
              />
            ) : (
              <Spin />
            )}
            <Divider />
          </TabPane>

          <TabPane tab='Videos' key='4'>
            <Row gutter={16}>
              <Col span={12}>
                <h3>Associated Videos</h3>
                <Spacer height={8} />
                {article?.video ? (
                  <Card style={{ width: "100%" }}>
                    <ApiVideoPlayer
                      video={{
                        id: article?.video?.apiVideo?.videoId || "",
                      }}
                      style={{
                        width: "100%",
                        height: 300,
                      }}
                    />
                    <Divider />
                    <p
                      style={{ fontWeight: 600 }}
                      onClick={() => console.log(article.video)}
                    >
                      {article.video.title}
                    </p>
                    <Button
                      block
                      type='dashed'
                      icon={<MinusCircleOutlined />}
                      onClick={handleDissociateVideo}
                    >
                      Dissociate this video
                    </Button>
                  </Card>
                ) : (
                  <div />
                )}
              </Col>
              <Col span={12}>
                <h3>Add videos to this article</h3>
                <Spacer height={8} />
                <Input.Search
                  placeholder='Enter the video URL'
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchValue({
                      infographics: searchValue?.infographics || "",
                      videos: e.target.value,
                    });
                  }}
                />
                {searchValue && <Divider />}
                <Flex flexDirection='column' gap={8}>
                  {innerVideos?.map((video) => (
                    <Card key={video.id + "--card"} style={{ width: "100%" }}>
                      <a
                        style={{ fontWeight: 600 }}
                        onClick={() => console.log(video)}
                        href={video.apiVideo?.player}
                        target='_blank'
                      >
                        {video.title}
                      </a>
                      <Spacer height={8} />
                      <Button
                        block
                        icon={<PlusCircleOutlined />}
                        onClick={() => handleAssociateVideo(video.id)}
                      >
                        Add this video
                      </Button>
                    </Card>
                  ))}
                </Flex>
              </Col>
            </Row>
            <Divider />
          </TabPane>

          <TabPane tab='Infographics' key='5'>
            <Row gutter={16}>
              <Col span={12}>
                <h3>Associated Infographics</h3>
                <Spacer height={8} />
                {associatedInfographics?.length ? (
                  associatedInfographics.map((info) => (
                    <Card style={{ width: "100%" }}>
                      <Divider />
                      <p style={{ fontWeight: 600 }}>{info.title}</p>
                      <Button
                        block
                        type='dashed'
                        icon={<MinusCircleOutlined />}
                        onClick={() => handleDissociateInfographic(info._id)}
                      >
                        Dissociate this infographic
                      </Button>
                    </Card>
                  ))
                ) : (
                  <div />
                )}
              </Col>
              <Col span={12}>
                <h3>Add infographics to this article</h3>
                <Spacer height={8} />
                <Input.Search
                  placeholder='Search infographics...'
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchValue({
                      videos: searchValue?.videos || "",
                      infographics: e.target.value,
                    });
                  }}
                />
                {searchValue && <Divider />}
                <Flex flexDirection='column' gap={8}>
                  {innerInfographics?.map((info) => (
                    <Card key={info.id + "--card"} style={{ width: "100%" }}>
                      <b>{info.title}</b>
                      <Spacer height={8} />
                      <Button
                        block
                        icon={<PlusCircleOutlined />}
                        onClick={() => handleAssociateInfographic(info.id)}
                      >
                        Add this infographic
                      </Button>
                    </Card>
                  ))}
                </Flex>
              </Col>
            </Row>
            <Divider />
          </TabPane>

          <TabPane tab='Audio' key='6'>
            <Flex align='center' gap={8}>
              <Button
                disabled={!!article?.speech?.url}
                onClick={handleSynthesize}
                loading={articleSynthesize}
                icon={<SoundOutlined />}
              >
                {articleSynthesize
                  ? "In progress..."
                  : "Get Audio (synthesize)"}
              </Button>
              <Divider type='vertical' />

              <UploadSpeechAudio
                articleId={article?._id}
                callback={(article: IArticle) => {
                  setArticle({
                    ...article,
                    speech: null,
                  } as unknown as IArticle);

                  setTimeout(() => {
                    article &&
                      setArticle({
                        ...article,
                        speech: article.speech,
                      });
                  }, 1000);
                }}
              />
            </Flex>
            <Divider />
            {!!article?.speech?.url && (
              <audio controls>
                <source src={article?.speech?.url} type='audio/mpeg' />
                Your browser does not support the audio element.
              </audio>
            )}
            {article?.speech?.url && (
              <p>
                <b>{"Audio name: "}</b>
                {article?.speech?.path}
              </p>
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default function ArticleEditionWithContext() {
  return (
    <ArticleEditionProvider>
      <ArticleEdition />
    </ArticleEditionProvider>
  );
}
