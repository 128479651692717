/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from "@ant-design/icons";
import { Form, FormInstance, notification } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import DragImageUploader from "~/components/shared/DragImageUploader";
import {
  deleteArticleImage,
  updateArticleImages,
  uploadArticleImages,
} from "~/services";
import { MAX_UPLOAD_IMAGE } from "~/utils/constants";
import { useArticleEditionContext } from "~/utils/hooks";

type InnerProps = {
  form: FormInstance;
};

export type ArticleEditionImagesHandle = {
  triggerFinish: (autoSave: boolean) => void;
};

const ArticleEditionImages = forwardRef<ArticleEditionImagesHandle, InnerProps>(
  ({ form }, ref) => {
    const [deleteFigures, setDeleteFigures] = useState<string[]>([]);

    const { article: innerArticle, setArticle } = useArticleEditionContext();

    // Gère les images relatif à l'article qu'on soit en mode création ou édition...
    async function onFinishForm(autoSave: boolean) {
      if (autoSave) return; // Prevent autoSave from updating images

      if (!autoSave)
        notification.info({
          key: "updateImageProgress",
          icon: <LoadingOutlined />,
          message: "Update of images in progress",
          description: "Please do not refresh the application",
          placement: "bottomRight",
          duration: 0,
        });

      let newArticle = innerArticle;

      try {
        if (!innerArticle) throw new Error("Article not found");

        // Upload images
        const files = form
          .getFieldValue("figures")
          .filter((file: any) => !!file.originFileObj)
          .filter(
            (file: any) =>
              !innerArticle?.images?.some((img) => img.path === file.name)
          );

        const deleteImageIds = innerArticle?.images
          .filter((image) => deleteFigures.includes(image.path))
          .map((image) => image._id);

        if (deleteImageIds) {
          for await (const imageId of deleteImageIds) {
            await deleteArticleImage(innerArticle._id, imageId);
          }

          setDeleteFigures([]);
        }

        if (files.length > 0)
          newArticle = await uploadArticleImages(
            innerArticle._id,
            files.map((file: { originFileObj: File & { uid: string } }) => {
              const { uid, type, lastModified } = file.originFileObj;
              const name = uid;
              const content = file.originFileObj;
              return new File([content], name, { type, lastModified });
            })
          );

        // To prevent name with special chars to break the worflow (thank you Jothini for help ^_^)

        newArticle = await updateArticleImages(
          innerArticle._id,
          form.getFieldValue("figures").map((file: any) => ({
            id: file.originFileObj
              ? newArticle?.images.find(
                  (img) => img.path.split("/")[2]?.slice(0, -5) === file.uid
                )?._id
              : file.uid,
            legend: file.legend,
            order: file.order,
          }))
        );

        setArticle(newArticle); // Update article in context

        if (!autoSave) {
          notification.close("updateImageProgress");
          notification.success({
            message: "Images updated",
            description: "Images have been updated successfully",
            placement: "bottomRight",
          });
        }
      } catch (error) {
        notification.close("updateImageProgress");
        notification.error({
          message: "Error on updating images",
          description: "Legend and position of images have not been updated",
          placement: "bottomRight",
        });
      } finally {
        // reset form values
        form.setFieldsValue({
          figures: newArticle?.images.map((image) => ({
            uid: image._id,
            name: image.path,
            status: "done",
            url: image.url,
            order: image.order,
            legend: image.legend,
          })),
        });
      }
    }

    useImperativeHandle(ref, () => ({
      triggerFinish: (autoSave: boolean) => onFinishForm(autoSave),
    }));

    if (!form) return null;

    return (
      <Form.Item name='figures'>
        <DragImageUploader
          name='file'
          form={form}
          maxCount={MAX_UPLOAD_IMAGE}
          deleteFile={(imagePath) =>
            setDeleteFigures([...deleteFigures, imagePath])
          }
        />
      </Form.Item>
    );
  }
);

export default ArticleEditionImages;
