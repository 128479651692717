import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Row,
  Spin,
  Tabs,
  Input,
} from "antd";
import { red } from "@ant-design/colors";
import {
  getPlaylist,
  removePlaylistArticle,
  addPlaylistArticle,
  searchArticlesV2,
  deleteContentFromPlaylist,
  searchVideos,
  addContentToPlaylist,
  searchNectar,
} from "../../../services";
import { useLocation, useParams } from "react-router-dom";
import RoomsAssociator from "../../../components/shared/RoomsAssociator";
import { AxiosError } from "axios";
import { FiltersDrawerValuesModel } from "../../../components/shared/FiltersDrawer";
import PlaylistEditionContentForm from "./PlaylistEditionContentForm";
import LoadingLayout from "~/components/shared/LoadingLayout";
import EditionPageHeader, {
  PageHeaderSetContent,
} from "~/components/EditionPageHeader.tsx";
import { IPlaylist } from "~/model/playlist.model";
import { ContentFormatsEnum, SupportedLanguage } from "~/model/enum";
import { IContent } from "~/model/content.model";
import { IArticle } from "~/model/article.model";
import { ITag } from "~/model/tag.model";

export const PlaylistEdition: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const playlistId = params.id;
  const search = new URLSearchParams(location.search);
  const [innerPlaylist, setInnerPlaylist] = useState<
    IPlaylist & { meta?: IPlaylist["meta"] }
  >();
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    Object.values(SupportedLanguage).includes(
      search.get("language") as SupportedLanguage
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );

  const [playlistSaving, setPlaylistSaving] = useState<boolean>(false);
  const [innerSearchList, setInnerSearchList] = useState<
    IContent[] | IArticle[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [activePane, setActivePane] = useState<string>("0");

  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const fetchPlaylist = async () => {
    if (playlistId && !innerPlaylist) {
      const playlist = await getPlaylist(playlistId);

      setInnerPlaylist(playlist);
      setLanguage(playlist.language);
    }
  };

  const fetchArticles = async (limit?: number, offset?: number) => {
    const query = {
      limit: limit || 10,
      offset: offset || 0,
      language,
    };

    let articleList;

    if (searchValue) {
      articleList = await searchArticlesV2({
        ...query,
        status: "published",
        search: searchValue,
      });

      if (articleList.hits.hits) {
        setInnerSearchList(
          articleList.hits.hits.map(
            (article: { _source: { core: IArticle } }) => article._source.core
          )
        );
      }
    }
  };

  const fetchVideos = async (limit?: number, offset?: number) => {
    const query = {
      limit: limit || 10,
      offset: offset || 0,
      status: "published" as FiltersDrawerValuesModel["status"],
      language,
    };

    let videoList;

    if (searchValue) {
      videoList = await searchVideos(searchValue, query);

      if (videoList.hits) {
        setInnerSearchList(
          videoList.hits.map(
            (video: { _source: { view: IContent; id: string } }) => ({
              ...video._source.view,
              id: video._source.id,
            })
          )
        );
      }
    }
  };

  const fetchNectars = async () => {
    if (!searchValue) return;

    const { docs: nectarList } = await searchNectar(searchValue);

    if (nectarList) setInnerSearchList(nectarList);
    else setInnerSearchList([]);
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Playlist saved",
      description: "The playlist was succesfully saved.",
    });
  };

  const openNotificationError = (description: string) => {
    notification["error"]({
      message: "An error occurred",
      description,
    });
  };

  const handleAddContent = async (contentId: string) => {
    if (!playlistId)
      return openNotificationError(
        "The playlist must be created before adding an article."
      );

    setPlaylistSaving(true);
    try {
      const newPlaylist =
        activePane === ContentFormatsEnum.ARTICLE
          ? await addPlaylistArticle(playlistId, contentId)
          : await addContentToPlaylist({ contentId, playlistId });

      if (innerPlaylist && newPlaylist) {
        setInnerPlaylist({
          ...newPlaylist,
          medical_specialties: (newPlaylist.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (newPlaylist.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
      }
      setPlaylistSaving(false);
      openNotificationSuccess();
    } catch (error) {
      openNotificationError(
        (error as AxiosError<{ message: string }>)?.response?.data?.message ||
          "An error occurred"
      );
      setPlaylistSaving(false);
    }
  };

  const handleDeleteContent = async (contentId: string) => {
    if (!playlistId)
      return openNotificationError(
        "The playlist must be created before removing an article."
      );

    try {
      setPlaylistSaving(true);
      const newPlaylist =
        activePane === ContentFormatsEnum.ARTICLE
          ? await removePlaylistArticle(playlistId, contentId)
          : await deleteContentFromPlaylist({
              contentId,
              playlistId,
            });

      if (newPlaylist) {
        setInnerPlaylist({
          ...newPlaylist,
          medical_specialties: (newPlaylist.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (newPlaylist.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
      }
      setPlaylistSaving(false);
      openNotificationSuccess();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.error({
        message: "An error occurred",
        description: error.response.data.message,
      });
      setPlaylistSaving(false);
    }
  };

  useEffect(() => {
    fetchPlaylist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId]);

  useEffect(() => {
    if (!searchValue) setInnerSearchList([]);
    else if (activePane === ContentFormatsEnum.ARTICLE) fetchArticles();
    else if (activePane === ContentFormatsEnum.VIDEO) fetchVideos();
    else fetchNectars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (
      activePane === ContentFormatsEnum.ARTICLE ||
      activePane === ContentFormatsEnum.VIDEO
    ) {
      setSearchValue("");
      setInnerSearchList([]);
    }
  }, [activePane]);

  const contentTabPanes = [
    {
      title: "Article",
      type: ContentFormatsEnum.ARTICLE,
      contentList: innerPlaylist?.playlist,
    },
    {
      title: "Video",
      type: ContentFormatsEnum.VIDEO,
      contentList: innerPlaylist?.playlistContent?.filter(
        (el) => el.content_format === ContentFormatsEnum.VIDEO
      ),
    },
    {
      title: "Nectar",
      type: ContentFormatsEnum.NECTAR,
      contentList: innerPlaylist?.playlistContent?.filter(
        (el) => el.content_format === ContentFormatsEnum.NECTAR
      ),
    },
  ];

  if (!innerPlaylist && playlistId) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <EditionPageHeader
        form={form}
        format={ContentFormatsEnum.PLAYLIST}
        content={innerPlaylist}
        setContent={setInnerPlaylist as PageHeaderSetContent}
      />

      <div className='site-layout-content'>
        <Spin spinning={playlistSaving} tip='Playlist saving...'>
          <Tabs
            defaultActiveKey={"1"}
            onChange={(activeKey) => setActivePane(activeKey)}
          >
            <TabPane tab='Details' key='1'>
              <PlaylistEditionContentForm
                form={form}
                playlist={innerPlaylist}
                setPlaylist={setInnerPlaylist}
              />
            </TabPane>

            <TabPane tab='Rooms' key={"2"}>
              {playlistId && innerPlaylist && (
                <RoomsAssociator
                  contentId={playlistId}
                  language={innerPlaylist.language}
                  type={"playlist"}
                  isPublic={innerPlaylist?.isPublic}
                  ownerId={innerPlaylist?.owner?._id}
                  form={form}
                />
              )}
            </TabPane>

            {contentTabPanes.map((tabPane) => (
              <TabPane tab={tabPane.title} key={tabPane.type}>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "20px 0",
                  }}
                >
                  <Col span={11}>
                    <h3 style={{ marginBottom: "20px" }}>
                      {`Current ${tabPane.title}s`}
                    </h3>
                    {tabPane.contentList?.map(
                      (content: IArticle | IContent) => (
                        <Card
                          key={content.slug + "current-content"}
                          style={{ marginBottom: "20px" }}
                        >
                          <p>{content.title}</p>
                          <Button
                            style={{
                              color: "white",
                              backgroundColor: red.primary,
                              float: "right",
                            }}
                            htmlType='button'
                            onClick={() =>
                              handleDeleteContent(
                                activePane === ContentFormatsEnum.ARTICLE
                                  ? content.slug
                                  : content._id
                              )
                            }
                          >
                            Remove
                          </Button>
                        </Card>
                      )
                    )}
                  </Col>
                  <Col span={11}>
                    <h3 style={{ marginBottom: "20px" }}>
                      {`${tabPane.title}s list`}
                    </h3>
                    <Input.Search
                      placeholder={`Search ${tabPane.title}`}
                      allowClear
                      style={{
                        marginBottom: "20px",
                      }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onSearch={(value) => setSearchValue(value)}
                    />
                    {innerSearchList?.map((content) => (
                      <Card
                        key={content.title + "search-content"}
                        style={{ marginBottom: "20px" }}
                      >
                        <p>{content.title}</p>
                        <Button
                          type='primary'
                          htmlType='button'
                          className='info-form-button'
                          style={{ float: "right" }}
                          onClick={() =>
                            handleAddContent(
                              activePane === ContentFormatsEnum.ARTICLE
                                ? content.slug
                                : content.id
                            )
                          }
                        >
                          Add new content
                        </Button>
                      </Card>
                    ))}
                  </Col>
                </Row>
              </TabPane>
            ))}
          </Tabs>
        </Spin>
      </div>
    </div>
  );
};
