import { createContext, useState } from "react";
import { IArticle } from "~/model/article.model";

type ArticleEditionContextType = {
  article: IArticle | null;
  setArticle: (article: IArticle | null) => void;
  // translatedArticles: ArticleTranslationResponse | null;
  // setTranslatedArticles: (translations: ArticleTranslationResponse | null) => void;
};

export const ArticleEditionContext = createContext<ArticleEditionContextType>({
  article: null,
  setArticle: () => {},
  // translatedArticles: null,
  // setTranslatedArticles: () => {},
});

export const ArticleEditionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [article, setArticle] = useState<IArticle | null>(null);
  // const [translatedArticles, setTranslatedArticles] = useState<ArticleTranslationResponse | null>(null);

  return (
    <ArticleEditionContext.Provider value={{ article, setArticle }}>
      {children}
    </ArticleEditionContext.Provider>
  );
};
