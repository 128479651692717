export const userMapping: { [key: string]: string } = {
  email: "Email",
  firstname: "Firstname",
  lastname: "Lastname",
  birthdate: "Birthdate",
  country: "Country",
  profession: "Profession",
  main_specialty: "Main specialty",
  preferredFormats: "Preferred formats",
  journal: "Journal",
  origin: "Origin",
  device: "Device",
};
