import {
  ApiOutlined,
  BookOutlined,
  ExperimentOutlined,
  MobileOutlined,
  SolutionOutlined,
  TagsOutlined,
  TranslationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import { useContext } from "react";
import CustomColumnsPlot from "~/components/monitoring/CustomColumnsPlot";
import MetricsCard from "~/components/MetricsCard";
import TopContentCard from "~/components/monitoring/TopContentCard";
import { Spacer } from "~/components/shared/global";
import LoadingLayout from "~/components/shared/LoadingLayout";
import { GlobalContext } from "~/context/global.context";

const PerformanceMetricsUsers = () => {
  const { monitoring } = useContext(GlobalContext);

  if (!monitoring) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <h1>{"Performance Metrics: Registered Users"}</h1>
      <Divider />
      <Row gutter={{ lg: 24, md: 8 }}>
        <Col span={8}>
          <MetricsCard
            title='Total of Registered Users'
            icon={<UserOutlined />}
            value={2874}
          />
        </Col>
        <Col span={8}>
          <MetricsCard
            title='Total of first Login'
            icon={<ApiOutlined />}
            value={2874}
          />
        </Col>
        <Col span={8}>
          <MetricsCard
            title='Monthly Active Users'
            icon={<MobileOutlined />}
            value={2874}
          />
        </Col>
      </Row>
      <Spacer />
      <CustomColumnsPlot
        icon={<TranslationOutlined />}
        title='Registered Users by Country'
        color='#CE0868'
        link='usersByCountry'
        data={monitoring.users.usersByCountry
          .map((item) => ({
            label: item.country || "Unknown",
            value: item.users,
          }))
          .slice(0, 10)}
      />
      <Spacer />
      <CustomColumnsPlot
        icon={<SolutionOutlined />}
        title='Registered Users by Profession'
        color='#FFAA00'
        link='usersByProfession'
        data={monitoring.users.usersByProfession
          .map((item) => ({
            label: item.profession?.translations?.en || "Unknown",
            value: item.users,
          }))
          .slice(0, 20)}
      />
      <Spacer />
      <CustomColumnsPlot
        icon={<TagsOutlined />}
        title='Registered Users by Main Specialty'
        color='#FF8800'
        link='usersByMainSpecialty'
        data={monitoring.users.usersByMainSpecialty
          .map((item) => ({
            label: item.specialty?.translations?.en || "Unknown",
            value: item.users,
          }))
          .slice(0, 10)}
      />
      <Spacer />
      <Row gutter={24}>
        <Col span={12}>
          <TopContentCard
            icon={<BookOutlined />}
            title='Top 5 favorite journals'
            color='#D6DF1F'
            data={monitoring.users.topJournals
              .map((item) => ({
                label: item.journal?.name || "Unknown",
                value: `${item.followers || 0} follows`,
              }))
              .slice(0, 5)}
          />
        </Col>
        <Col span={12}>
          <TopContentCard
            icon={<ExperimentOutlined />}
            title='Top 5 preferred formats'
            color='#FFAA00'
            data={monitoring.users.preferredFormats
              .map((item) => ({
                label: item.format || "Unknown",
                value: `${item.users || 0} users`,
              }))
              .slice(0, 5)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PerformanceMetricsUsers;
