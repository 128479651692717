import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Row,
  Segmented,
  Upload,
  message,
  notification,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoRequest, postVideoRequest } from "../../../../services";
import { useContext, useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ApiVideoPlayer from "@api.video/react-player";
import styled from "styled-components";
import { languageLabel } from "~/utils/helpers";
import LoadingLayout from "../../../../components/shared/LoadingLayout";
import { Flex, Spacer } from "../../../../components/shared/global";
import { AxiosError } from "axios";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import {
  VideoRequestInformationsContext,
  VideoRequestInformationsProvider,
} from "~/context/video-request.context";
import { SegmentedValue } from "antd/lib/segmented";
import VideoRequestReviewHeader from "./VideoRequestReviewHeader";
import VideoRequestInformations from "./VideoRequestInformations";
import VideoRequestReviewComments from "./VideoRequestReviewComments";
import { VideoRequestStatus } from "~/model/enum";

const VideoRequestReview = () => {
  const { id: requestId } = useParams();
  const navigate = useNavigate();
  const { video, request, setRequest, setVideo } = useContext(
    VideoRequestInformationsContext
  );
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoTimer, setVideoTimer] = useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  const fetchRequest = async () => {
    setRequest(null);
    setVideo(null);

    if (!requestId) return;
    const res = await getVideoRequest(requestId);

    setRequest(res);
    setVideo(res.videos[0]);
  };

  const handleSaveReview = async () => {
    if (!requestId || !request || !video) {
      navigate(-1);
      return console.error("Missing data");
    }

    setIsSaving(true);

    try {
      if (!videoFile) throw Error("No video file to upload");

      const formValues = form.getFieldsValue();
      await postVideoRequest({
        ...formValues,
        requestId,
        language: video.language,
        file: videoFile,
      });

      fetchRequest();

      message.success("Video uploaded successfully, review saved!");
      setIsSaving(false);
      setVideoFile(null);
    } catch (error) {
      notification.error({
        message: "An error occured",
        description:
          (error as AxiosError<{ message: string }>)?.response?.data?.message ||
          "An error occured while saving the review",
      });
      setIsSaving(false);
      return error;
    }
  };

  function handleUploadChange({ file }: UploadChangeParam<UploadFile>) {
    if (file.status === "done") {
      setVideoFile(file.originFileObj as File);
    } else if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    } else if (file.status === "removed") {
      setVideoFile(null);
    }
  }

  function handleChangeVideoLanguage(language: SegmentedValue) {
    const newVideo = request?.videos.find((v) => v.language === language);
    if (newVideo) setVideo(newVideo);
    setVideoFile(null);
  }

  if (!request || !video) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <Flex align='start' justify='space-between'>
        <Button
          type='link'
          style={{ padding: 0 }}
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {"Back"}
        </Button>
        <VideoRequestReviewHeader />
      </Flex>
      <Divider />
      <Flex align='center' justify='space-between'>
        <h1
          style={{
            fontWeight: 800,
            fontSize: "30px",
            margin: 0,
            width: "100%",
          }}
        >
          {request.name}
        </h1>

        <Segmented
          options={request.videos.map((video) => ({
            label: `${languageLabel[video.language]}`,
            value: video.language,
          }))}
          onChange={handleChangeVideoLanguage}
        />
      </Flex>

      <Spacer />

      <Row gutter={24}>
        <Col span={14}>
          {video.videoId ? (
            <ApiVideoPlayer
              video={{
                id: video.videoId,
              }}
              style={{
                width: "100%",
                height: 500,
              }}
              onTimeUpdate={(timer) => setVideoTimer(timer)}
            />
          ) : (
            <Alert
              message='No video available'
              description='Please upload a video to review this request'
            />
          )}
          <Spacer />

          <VideoRequestInformations
            formInstance={form}
            hasFile={!!videoFile}
            loading={isSaving}
          />

          <Spacer height={8} />

          <StyledUpload
            accept='.mp4'
            maxCount={1}
            customRequest={(payload) => {
              if (payload.onSuccess) payload.onSuccess("ok");
            }}
            onChange={handleUploadChange}
            style={{ width: "100%" }}
          >
            {!videoFile && (
              <Button
                block
                htmlType='button'
                icon={<UploadOutlined />}
                disabled={request?.status !== VideoRequestStatus.WAITING}
              >
                {"Upload a new video"}
              </Button>
            )}
          </StyledUpload>
          <Spacer height={8} />
          {!!videoFile && (
            <Button
              block
              onClick={handleSaveReview}
              type={"primary"}
              icon={<SaveOutlined />}
              disabled={!videoFile}
              loading={isSaving}
            >
              {"Save video"}
            </Button>
          )}
        </Col>
        <Col span={10}>
          <VideoRequestReviewComments videoTimer={videoTimer} />
        </Col>
      </Row>
    </div>
  );
};

const VideoRequestReviewWithContext = () => {
  return (
    <VideoRequestInformationsProvider>
      <VideoRequestReview />
    </VideoRequestInformationsProvider>
  );
};

export default VideoRequestReviewWithContext;

const StyledUpload = styled(Upload)`
  div.ant-upload {
    width: 100%;
  }
`;
