import React, { useContext, useEffect, useState } from "react";
import { Button, Form, notification, Layout, Row, Spin, Col } from "antd";

import { red } from "@ant-design/colors";
import { LeftOutlined } from "@ant-design/icons";
import {
  getCongress,
  createCongress,
  deleteCongress,
  updateCongress,
  uploadCongressImage,
} from "../services";
import { ICreateCongressDto } from "../model/dto//congress-dto";
import { createCongressMapping } from "../config/createCongressMapping";
import { FormMapping } from "./shared/FormMapping";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ImageUploader from "./shared/ImageUploader";
import { GlobalContext, IGlobalContext } from "../context/global.context";
import { IMapping } from "../config";
import { FETCH_ORGANISATIONS } from "../store/types";
import { fetchData } from "../store/actions";
import { useDispatch } from "react-redux";
import { ICongress } from "~/model/congress.mode";

export const CongressEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const congressId = params.id;
  const dispatch = useDispatch();
  const { organisationList } = useContext(GlobalContext) as IGlobalContext;
  const [congressSaving, setCongressSaving] = useState<boolean>(false);
  const [innerCongress, setInnerCongress] = useState<ICongress>();
  const [congressMapping, setCongressMapping] = useState<{
    [key: string]: IMapping;
  } | null>(null);

  const [form] = Form.useForm();

  const fetchCongress = async () => {
    if (congressId && !innerCongress) {
      const congress = await getCongress(congressId);
      setInnerCongress(congress);
    }
  };

  useEffect(() => {
    fetchCongress();
    if (!organisationList.length) dispatch(fetchData(FETCH_ORGANISATIONS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!congressMapping && organisationList.length)
      setCongressMapping(createCongressMapping(organisationList));
  }, [organisationList, congressMapping]);

  const handleReturn = () => {
    navigate(`/reference-data/congress/`);
  };

  const openNotificationSuccess = (entity: string) => {
    notification["success"]({
      message: `${entity} saved`,
      description: `The ${entity.toLowerCase()} was succesfully saved.`,
    });
  };

  const openNotificationDeletion = (entity: string) => {
    notification["success"]({
      message: `${entity} deleted`,
      description: `The ${entity.toLowerCase()} was succesfully deleted.`,
    });
  };

  const handleImageUpload = async (file: File) => {
    setCongressSaving(true);
    if (innerCongress) {
      const infographic = await uploadCongressImage(innerCongress._id, file);
      if (infographic) {
        setInnerCongress({
          ...innerCongress,
          image: infographic.image,
        });
        setCongressSaving(false);
      }
    } else {
      setCongressSaving(false);
    }
  };

  const handleImageDeletion = async () => {
    setCongressSaving(true);
    if (congressId) {
      const congress = await updateCongress(congressId, {
        ...innerCongress,
        organisations: innerCongress?.organisations.map((org) => org._id) || [],
        image: null,
      });
      if (congress) {
        setInnerCongress(congress);
      }
    }
    setCongressSaving(false);
  };

  const handleEdition = async (values: ICreateCongressDto) => {
    setCongressSaving(true);

    const data = {
      ...values,
      organisations: organisationList
        .filter((organisation) =>
          values.organisations.includes(organisation.name)
        )
        .map((organisation) => organisation._id),
    };

    console.log(data);

    if (congressId) {
      const updatedCongress = await updateCongress(congressId, data);
      setInnerCongress(updatedCongress);
    } else {
      const createdCongress = await createCongress(data);
      if (createdCongress) {
        navigate(`/reference-data/congress/${createdCongress._id}`);
      }
    }
    setCongressSaving(false);
    openNotificationSuccess("Congress");
  };

  const handleDeletion = async () => {
    if (congressId) {
      const deletedCongress = await deleteCongress(congressId);
      openNotificationDeletion("Congress");
      if (deletedCongress) {
        navigate("/reference-data/congress/");
      }
    }
  };

  if (!congressMapping || !organisationList) return null;

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Row>
          <div>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
          </div>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
            {congressId ? "Edit congress" : "Create congress"}
          </h1>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={congressSaving} tip='Congress saving...'>
            <Form
              form={form}
              name='congress_edition'
              layout='vertical'
              fields={
                innerCongress &&
                Object.keys(innerCongress).map((key: string) => ({
                  name: [key],
                  value:
                    key === "organisations"
                      ? innerCongress.organisations.map((org) => org.name) || []
                      : innerCongress[key as keyof ICreateCongressDto],
                }))
              }
              onFinish={handleEdition}
            >
              <Row
                style={{
                  paddingBottom: "20px",
                  borderBottom: "1px solid #d9d9d9",
                }}
                justify='space-between'
                gutter={20}
              >
                <Col span={12}>
                  {Object.keys(congressMapping).map((key: string) => (
                    <FormMapping
                      key={key}
                      formMapping={congressMapping}
                      id={key}
                    />
                  ))}
                </Col>
                {congressId && innerCongress ? (
                  <Col span={12}>
                    <div style={{ marginBottom: "16px" }} />
                    <ImageUploader
                      images={innerCongress?.image ? [innerCongress.image] : []}
                      deleteFile={() => handleImageDeletion()}
                      inputLegendImage={() => {
                        return;
                      }}
                      onChange={(file) => handleImageUpload(file)}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row justify='end'>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='congress-form-button'
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    {congressId ? "Save congress" : "Create congress"}
                  </Button>
                  {congressId ? (
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: red.primary,
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                    >
                      Delete congress
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
