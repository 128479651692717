import { IContent } from "./content.model";
import { SupportedLanguage } from "./enum";
import { IImage } from "./image.model";
import { IJournal } from "./journal.model";
import { IOrganisation } from "./organisation.model";
import { ITag } from "./tag.model";
import { IVideo } from "./video.model";

export interface IArticle {
  _id: string;
  id: string;
  abstract_provided: boolean;
  associatedContent: IContent[];
  article_type: ArticleTypeEnum;
  authors: string[];
  background: string;
  citation_count: number;
  comments: string[];
  conclusion: string[];
  disclosure: string;
  doi: string;
  edito: string;
  formatting_level: "high" | "med" | "low";
  images: IImage[];
  impact_factor: number;
  journal: IJournal;
  keywords: string[];
  legend: string;
  legends: string[];
  language: SupportedLanguage;
  limitations: string;
  meta: {
    creationDate: Date;
    lastModified: Date;
    status: "draft" | "published";
  };
  medical_specialties: string[] | ITag[];
  methodology: string;
  metrics: {
    saveds: number;
    favorites: number;
    likes: number;
    shares: number;
    views: number;
    sourceViews: number;
  };
  title: string;
  objectives: string[];
  owner?: IOrganisation;
  primary_author: string;
  publication_date: Date;
  results: string[];
  slug: string;
  tags: string[] | ITag[];
  speech?: {
    path: string;
    url: string;
  };
  video?: IVideo;
  isPublic: boolean;
}

export enum ArticleTypeEnum {
  CaseReport = "Case Report",
  ClinicalStudy = "Clinical study",
  Commentary = "Commentary",
  Guidelines = "Guidelines",
  LetterEditorial = "Letter/Editorial",
  MetaAnalysis = "Meta-analysis",
  OriginalResearch = "Original research",
  Perspective = "Perspective",
  Review = "Review",
  ShortCommunication = "Short communication",
  StudyProtocol = "Study Protocol",
  SystematicReviewAndMetaAnalysis = "Systematic review and meta-analysis",
  TechnicalReport = "Technical report",
}

export type TranslateArticleErrorType = {
  status: string;
  message: string;
  translated_payload: IArticle;
};

export type TranslationsType = {
  [key in SupportedLanguage]: string;
};

export type ArticleTranslationResponse = {
  slug: string;
  language: SupportedLanguage;
  _id: string;
}[];
