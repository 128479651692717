/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from "antd";
import {
  getMonitoringArticles,
  getMonitoringInfographics,
  getMonitoringNectars,
  getMonitoringPlaylists,
  getMonitoringUsers,
  getMonitoringVideos,
  getMonitoringContent,
  getNectarHighlights,
  getRoomsList,
  listCompanies,
  listJournals,
  listOrganisations,
  listTags,
} from "../services";
import {
  FETCH_DATA_SUCCESS,
  FETCH_DATA_ERROR,
  FETCH_JOURNALS,
  FETCH_TAGS,
  FETCH_ORGANISATIONS,
  FETCH_COMPANIES,
  FETCH_HIGHLIGHT_NECTARS,
  FETCH_ROOMS,
  FETCH_MONITORING,
} from "./types";

export const fetchDataSuccess = (
  key: string,
  data: any
): FetchDataSuccessAction => {
  return {
    type: FETCH_DATA_SUCCESS,
    key,
    data,
  };
};

export const fetchDataError = (error: any): FetchDataErrorAction => {
  return {
    type: FETCH_DATA_ERROR,
    error,
  };
};

export const fetchData = (type: string, args?: any): any => {
  switch (type) {
    case FETCH_COMPANIES:
      return async (dispatch: any) => {
        const companyList = await listCompanies();
        return dispatch(fetchDataSuccess("companyList", companyList));
      };
    case FETCH_JOURNALS:
      return async (dispatch: any) => {
        const journalList = await listJournals();
        return dispatch(fetchDataSuccess("journalList", journalList));
      };
    case FETCH_TAGS:
      return async (dispatch: any) => {
        const tagList = await listTags();
        return dispatch(fetchDataSuccess("tagList", tagList));
      };
    case FETCH_ORGANISATIONS:
      return async (dispatch: any) => {
        const { docs: organisationList } = await listOrganisations();
        return dispatch(fetchDataSuccess("organisationList", organisationList));
      };

    case FETCH_HIGHLIGHT_NECTARS:
      return async (dispatch: any) => {
        const highlights = await getNectarHighlights();

        return dispatch(fetchDataSuccess("highlightNectars", highlights));
      };

    case FETCH_ROOMS:
      return async (dispatch: any) => {
        const rooms = await getRoomsList();
        return dispatch(fetchDataSuccess("roomList", rooms));
      };

    case FETCH_MONITORING:
      return async (dispatch: any) => {
        let contents;
        let users;
        let articles;
        let videos;
        let nectars;
        let playlists;
        let infographics;

        try {
          contents = await getMonitoringContent(args);
        } catch (error) {
          notification.error({ message: "Error fetching contents" });
          contents = null;
        }

        try {
          users = await getMonitoringUsers(args);
        } catch (error) {
          notification.error({ message: "Error fetching users" });
          users = null;
        }

        try {
          articles = await getMonitoringArticles(args);
        } catch (error) {
          notification.error({ message: "Error fetching articles" });
          articles = null;
        }

        try {
          videos = await getMonitoringVideos(args);
        } catch (error) {
          notification.error({ message: "Error fetching videos" });
          videos = null;
        }

        try {
          nectars = await getMonitoringNectars(args);
        } catch (error) {
          notification.error({ message: "Error fetching nectars" });
          nectars = null;
        }

        try {
          playlists = await getMonitoringPlaylists(args);
        } catch (error) {
          notification.error({ message: "Error fetching playlists" });
          playlists = null;
        }

        try {
          infographics = await getMonitoringInfographics(args);
        } catch (error) {
          notification.error({ message: "Error fetching infographics" });
          infographics = null;
        }

        const monitoring = {
          contents,
          users,
          articles,
          videos,
          nectars,
          playlists,
          infographics,
        };

        return dispatch(fetchDataSuccess("monitoring", monitoring));
      };

    default:
      return;
  }
};

interface FetchDataSuccessAction {
  type: typeof FETCH_DATA_SUCCESS;
  key: string;
  data: any;
}

interface FetchDataErrorAction {
  type: typeof FETCH_DATA_ERROR;
  error: any;
}

export type ActionTypes = FetchDataSuccessAction | FetchDataErrorAction;
