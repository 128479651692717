import { GlobalOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  List,
  notification,
  Popover,
  PopoverProps,
} from "antd";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  ArticleTranslationResponse,
  IArticle,
  TranslateArticleErrorType,
} from "~/model/article.model";
import { SupportedLanguage } from "~/model/enum";
import { translateArticleById } from "~/services";
import { fetchData } from "~/store/actions";
import { FETCH_ROOMS } from "~/store/types";
import { getRequestErrorMessage, languageOptions } from "~/utils/helpers";

type ArticleEditionTranslateProps = {
  article: IArticle | null;
  translatedArticles: ArticleTranslationResponse | null;
  setArticle: (article: IArticle | null) => void;
  setTranslatedArticles: (articles: ArticleTranslationResponse) => void;
};

const MyPopover = ({ className, ...props }: PopoverProps) => (
  <Popover {...props} overlayClassName={className} />
);

export default function ArticleEditionTranslate({
  article,
  translatedArticles,
  setTranslatedArticles,
}: ArticleEditionTranslateProps) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<
    SupportedLanguage[]
  >([]);
  const dispatch = useDispatch();

  const languagesToTranslate = languageOptions.filter(
    (lang) =>
      lang.value !== article?.language &&
      !translatedArticles?.find((art) => art.language === lang.value)
  );

  async function handleTranslateArticle(language: SupportedLanguage) {
    if (!article) return;

    try {
      if (!article.journal)
        throw Error(
          "Journal not found, please update the article with a journal (save before translating again)"
        );

      const translatedArticle = await translateArticleById(
        language,
        article._id
      );

      if (
        (translatedArticle as TranslateArticleErrorType)?.status === "error"
      ) {
        const articleTitle = (translatedArticle as TranslateArticleErrorType)
          .translated_payload.title;

        if (articleTitle)
          return notification.warning({
            placement: "bottomRight",
            message: "An article may already exists with this title",
            duration: 0,
            description: (
              <div>
                <small>Title: {articleTitle}</small>
                <p>
                  Please check the DOI of this article and make sure it is the
                  same as the original article.
                </p>
                {/* TODO: Add a button to navigate to the article with '_id' */}
                {/* <Button
                  style={{ padding: 0 }}
                  icon={<ArrowRightOutlined />}
                  type='link'
                  onClick={() => {
                    setArticle(null);
                    navigate(
                      `/content-management/article/${slugify(articleTitle, {
                        remove: /[*+~.()'"!:@]/g,
                        lower: true,
                        strict: true,
                      })}`,
                      {
                        replace: true,
                        state: {
                          translatedArticles,
                        },
                      }
                    );
                  }}
                >
                  {"Go to article"}
                </Button> */}
              </div>
            ),
          });

        throw Error((translatedArticle as TranslateArticleErrorType).message);
      }

      const _translatedArticle = translatedArticle as IArticle;

      return {
        slug: _translatedArticle.slug,
        language,
        _id: _translatedArticle._id,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleChangeCheckbox(checked: boolean, lang: SupportedLanguage) {
    if (checked) {
      setSelectedLanguages([...selectedLanguages, lang]);
    } else {
      setSelectedLanguages(selectedLanguages.filter((l) => l !== lang));
    }
  }

  const handleTriggerTranslations = useCallback(
    async () => {
      setIsLoading(true);
      setOpen(false);
      setSelectedLanguages([]);

      try {
        if (!article) throw Error("Article not found");
        if (!translatedArticles)
          throw Error("Translated articles not loaded yet");

        const newTranslations: ArticleTranslationResponse = [];

        for (const lang of selectedLanguages)
          if (lang !== article.language) {
            const translated = await handleTranslateArticle(lang);

            if (translated) newTranslations.push(translated);
          }

        setTranslatedArticles([...translatedArticles, ...newTranslations]);

        // Fetch rooms to update the list after translation
        dispatch(fetchData(FETCH_ROOMS));

        notification.success({
          placement: "bottomRight",
          message: "Articles translated successfully!",
          description: "The articles have been translated successfully.",
        });
      } catch (error) {
        notification.error({
          placement: "bottomRight",
          message: "An error occurred while translating the article.",
          description: getRequestErrorMessage(error),
        });
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [article, translatedArticles, selectedLanguages]
  );

  return (
    <StyledPopover
      content={
        <List
          size='small'
          footer={
            <Button size='small' block onClick={handleTriggerTranslations}>
              Confirm
            </Button>
          }
        >
          {languagesToTranslate.map((lang) => (
            <List.Item
              key={lang.value}
              style={{ padding: "8px 0", width: "100%" }}
            >
              <Checkbox
                checked={selectedLanguages.includes(lang.value)}
                onChange={(e) =>
                  handleChangeCheckbox(e.target.checked, lang.value)
                }
              >
                {lang.label}
              </Checkbox>
            </List.Item>
          ))}
        </List>
      }
      title='Select languages'
      trigger='click'
      open={open}
      onOpenChange={(newOpen) => setOpen(newOpen)}
    >
      <Button
        loading={isLoading || !translatedArticles}
        disabled={
          isLoading || !translatedArticles || languagesToTranslate.length === 0
        }
        icon={<GlobalOutlined />}
      >
        {"Translate article"}
      </Button>
    </StyledPopover>
  );
}

const StyledPopover = styled(MyPopover)`
  .ant-popover-inner-content {
    padding: 0px 16px;
    width: 100%;
  }
`;
