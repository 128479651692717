import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Image, Upload, Button, Input } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IImage } from "~/model/image.model";

interface IImageUploader {
  images?: IImage[];
  name?: string;
  deleteFile?: (name: string) => void;
  inputLegendImage: ((legend: string, imageName: number) => void) | null;
  onChange: (file: File) => void;
}

const ImageUploader: React.FC<IImageUploader> = ({
  images,
  name,
  deleteFile,
  inputLegendImage,
  onChange,
}) => {
  const uploaderRef = useRef(null);
  const [fileList, setFileList] = useState<UploadFile[]>(
    images
      ? images.map((image) => ({
          uid: image._id,
          name: image.path,
          status: "done",
          url: image.url,
        }))
      : []
  );
  const [inputValues, setInputValues] = useState<
    { legend: string | undefined; imageName: string }[]
  >(
    images
      ? images.map((image) => ({
          legend: image.legend,
          imageName: image.path,
        }))
      : []
  );

  const handleChange: UploadProps["onChange"] = ({ file }) => {
    onChange && onChange(file.originFileObj as File);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Uploader</div>
    </div>
  );

  useEffect(() => {
    if (images !== undefined) {
      setFileList(
        images.map((image) => ({
          uid: image._id,
          name: image.path,
          status: "done",
          url: image.url,
        }))
      );
      setInputValues(
        images.map((image) => ({
          legend: image.legend,
          imageName: image.path,
        }))
      );
    }
  }, [images]);

  return (
    <div>
      <StyledUpload
        ref={uploaderRef}
        name={name}
        accept='.jpg, .jpeg, .png'
        listType='picture-card'
        fileList={fileList}
        onChange={handleChange}
        customRequest={(payload) => {
          if (payload.onSuccess) payload.onSuccess("ok");
        }}
        itemRender={(_originNode, file, currFileList) => (
          <div className='upload-render'>
            {deleteFile && (
              <Button
                size='small'
                shape='circle'
                className='delete-button'
                icon={<CloseOutlined />}
                onClick={() => {
                  setFileList(currFileList.filter((el) => el !== file));
                  deleteFile(file.name);
                }}
              />
            )}
            <Image
              width={300}
              height={150}
              src={
                file.url
                  ? file.url
                  : file.originFileObj
                  ? URL.createObjectURL(file.originFileObj)
                  : file.thumbUrl
              }
              alt={file.name}
              onClick={() => console.log(file)}
            />

            {!!inputLegendImage && (
              <Input
                placeholder={"Legend image"}
                style={{ width: 300 }}
                value={
                  inputValues.find((el) => el.imageName === file.name)?.legend
                }
                onChange={(e) => {
                  inputLegendImage(e.target.value, currFileList.indexOf(file));
                  setInputValues((prev) => {
                    const newInputValues = [...prev];
                    const index = newInputValues.findIndex(
                      (el) => el.imageName === file.name
                    );
                    newInputValues[index] = {
                      legend: e.target.value,
                      imageName: file.name,
                    };
                    return newInputValues;
                  });
                }}
              />
            )}
          </div>
        )}
      >
        {uploadButton}
      </StyledUpload>
    </div>
  );
};

export default ImageUploader;

const StyledUpload = styled(Upload)`
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 0px;
    margin-bottom: 48px;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    width: 300px;
    height: 150px;
  }

  .upload-render {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .delete-button {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 10;
    }

    img {
      object-fit: cover;
    }
  }
`;
