import { useContext, useEffect, useState } from "react";
import { Card, Checkbox, Modal, Select, Table, Tag, notification } from "antd";
import { getAdminsPermissionsList, updateAdminPermissions } from "~/services";
import { ColumnsType } from "antd/lib/table";
import { Flex } from "./shared/global";
import { capitalize } from "lodash";
import { GlobalContext } from "~/context/global.context";
import { IUserAdmin } from "~/model/user.model";
import {
  IUserRole,
  OrganisationUid,
  PermissionEnum,
  PermissionLabel,
} from "~/model/enum";

const RolePermissionList = () => {
  const { user, organisationList } = useContext(GlobalContext);
  const [admins, setAdmins] = useState<IUserAdmin[] | null>(null);
  const [selectedUser, setSelectedUser] = useState<IUserAdmin | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState<
    string | undefined
  >(organisationList.find((el) => el.uid === OrganisationUid.JUISCI)?._id);
  const [fetchQuery, setFetchQuery] = useState({
    offset: 0,
    limit: 100,
    total: -1,
  });

  const juisciOrg = organisationList.find(
    (el) => el.uid === OrganisationUid.JUISCI
  );

  const fetchRolesList = async () => {
    if (!selectedOrganisation) return;

    const { docs, meta } = await getAdminsPermissionsList({
      offset: 0,
      limit: 100,
      organisation: selectedOrganisation,
    });

    setAdmins(docs);
    setFetchQuery({ ...fetchQuery, total: meta.total });
  };

  const handleEditPermissions = (
    label: PermissionLabel,
    newPermissions: PermissionEnum[]
  ) => {
    if (!selectedUser) return;

    const updatedPermissions = [
      ...selectedUser.adminPermissions.filter(
        (permission) => permission.label !== label
      ),
      {
        label,
        permissions: newPermissions,
      },
    ];

    const updatedUser: IUserAdmin = {
      ...selectedUser,
      adminPermissions: updatedPermissions,
    };

    setSelectedUser(updatedUser);
  };

  const handleSubmitEdition = async () => {
    if (!selectedUser || !admins) return;

    const formattedPermissions = selectedUser.adminPermissions.map(
      (permission) => ({
        key: permission.label + permission.permissions.join(""),
        label: permission.label,
        permissions: permission.permissions,
      })
    );

    const res = await updateAdminPermissions({
      user: selectedUser._id,
      permissions: formattedPermissions,
    });

    notification.success({
      message: "Permissions updated",
      description: "Permissions have been updated successfully",
    });

    setAdmins([
      ...admins
        .map((admin) => (admin._id === res._id ? res : admin))
        .map((el) => ({ ...el, key: el._id })),
    ]);
    setSelectedUser(null);
  };

  const formatPermissions = (
    adminPermissions: IUserAdmin["adminPermissions"],
    currentUser: IUserAdmin
  ) => {
    const formattedPermissions = adminPermissions.map((permission) => {
      const isJuisci = !currentUser.organisations.length;

      if (!isJuisci && permission.label === PermissionLabel.Referential)
        return null;
      if (!isJuisci && permission.label === PermissionLabel.Sources)
        return null;

      const label = capitalize(permission.label);
      const formattedPerms = permission.permissions
        .map((perm: PermissionEnum) => {
          switch (perm) {
            case PermissionEnum.Read:
              return "R";
            case PermissionEnum.Write:
              return "W";
            case PermissionEnum.Publish:
              return "P";
            case PermissionEnum.Delete:
              return "D";
            default:
              return perm;
          }
        })
        .join("/");

      return `${label} (${formattedPerms})\n`;
    });

    return formattedPermissions;
  };

  useEffect(() => {
    if (user && !user.roles.includes(IUserRole.SUPER_ADMIN))
      window.location.href = "/content-management/article";
    else fetchRolesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRolesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!selectedOrganisation) setSelectedOrganisation(juisciOrg?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationList]);

  const columns: ColumnsType<IUserAdmin> = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => <a>{email}</a>,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (roles: string[]) =>
        roles
          .sort()
          .filter((el) => el !== "juisci")
          .map((role, index) =>
            role === "super_admin" ? (
              <Tag key={role + "-columns-" + index} color='purple'>
                {"Super Admin"}
              </Tag>
            ) : (
              <Tag key={role + "-columns-" + index}>{capitalize(role)}</Tag>
            )
          ),
    },
    {
      title: "Permissions",
      dataIndex: "adminPermissions",
      key: "adminPermissions",
      render: (adminPermissions, userAdmin) => (
        <div
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {formatPermissions(adminPermissions, userAdmin)}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, user) => (
        <Flex gap={16}>
          <a onClick={() => setSelectedUser(user)}>Edit</a>
        </Flex>
      ),
    },
  ];

  return (
    <div className='basic-layout'>
      <Flex align='center' justify='space-between'>
        <h1>Roles & Permissions</h1>
        <Select
          style={{ width: 200 }}
          placeholder='Organisation'
          loading={!selectedOrganisation}
          value={selectedOrganisation}
          onChange={(org) => setSelectedOrganisation(org)}
          options={[
            {
              label: "Internal",
              options: [
                {
                  value: juisciOrg?._id,
                  label: juisciOrg?.name,
                },
              ],
            },
            {
              label: "Client",
              options: [
                ...organisationList
                  .filter((org) => org.uid !== OrganisationUid.JUISCI)
                  .map((org) => ({
                    value: org?._id,
                    label: org?.name,
                  })),
              ],
            },
          ]}
        />
      </Flex>
      <Card>
        <Table
          loading={!admins || !selectedOrganisation}
          columns={columns}
          dataSource={admins?.map((admin) => ({ ...admin, key: admin._id }))}
          pagination={{ pageSize: 50 }}
        />
      </Card>

      <Modal
        open={!!selectedUser}
        title={<div>Edit Permissions</div>}
        okText='Confirm'
        onOk={handleSubmitEdition}
        onCancel={() => setSelectedUser(null)}
        okButtonProps={{ disabled: !selectedUser }}
      >
        {Object.values(PermissionLabel)
          .filter((label) =>
            selectedUser?.organisations.some(
              (org) => org.uid !== OrganisationUid.JUISCI
            )
              ? label !== PermissionLabel.Referential &&
                label !== PermissionLabel.Sources
              : true
          )
          .map((label) => (
            <Flex
              key={label}
              align='center'
              justify='space-between'
              style={{ marginBottom: 16 }}
            >
              <div style={{ fontWeight: 500, fontSize: 16 }}>
                {`${capitalize(label)}`}
              </div>
              <Checkbox.Group
                options={Object.values(PermissionEnum).map((key) => ({
                  key,
                  label: capitalize(key),
                  value: key,
                }))}
                value={
                  selectedUser
                    ? selectedUser.adminPermissions.find(
                        (el) => el.label === label
                      )?.permissions
                    : []
                }
                onChange={(values) =>
                  handleEditPermissions(label, values as PermissionEnum[])
                }
              />
            </Flex>
          ))}
      </Modal>
    </div>
  );
};

export default RolePermissionList;
