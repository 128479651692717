import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DragInputList } from "~/components/shared/DragInputList";
import { GlobalContext } from "~/context/global.context";
import { createArticle, updateArticle } from "~/services";
import {
  getRequestErrorMessage,
  languageOptions,
  pasteAndClearLineBreaks,
  searchFilterOptions,
} from "~/utils/helpers";
import ArticleEditionImages from "./ArticleEditionImages";
import { triggerAutosaveMessage } from "~/utils/ui/messages";
import SelectTagsField from "~/components/SelectTagsField";
import { ArticleTypeEnum } from "~/model/article.model";
import { useArticleEditionContext } from "~/utils/hooks";

type InnerProps = {
  form: FormInstance; // Form instance is set by parent component to prevent values reset on re-render
  section: "card" | "publication";
};

const TIMEOUTAUTO_SAVE = 5000;

const ArticleEditionContentForm = ({ form, section }: InnerProps) => {
  const navigate = useNavigate();

  const { id: articleId } = useParams<{ id: string }>();

  const imageUploaderRef = useRef<{
    triggerFinish: (autoSave: boolean) => void;
  }>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { article, setArticle } = useArticleEditionContext();
  const { tagList, journalList } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  function handleKeywordsSplit() {
    const keywords = form.getFieldsValue()["keywords"];
    const newKeywords = keywords
      .join(",")
      .split(/[,;. -]/)
      .map((k: string) => k.trim());

    form.setFieldsValue({
      ...form.getFieldsValue(),
      keywords: Array.from(new Set(newKeywords)),
    });
  }

  function handleAuthorSplit() {
    const authors = form.getFieldsValue()["authors"];
    const newAuthors = authors
      .join(",")
      .replaceAll("* ", "")
      .split(/[,;]/)
      .map((k: string) => k.trim())
      .filter(
        (k: string) => k.length > 0 && k != "MD" && k != "PhD" && k != "MSc"
      );

    form.setFieldValue("authors", newAuthors);
  }

  async function handleCreateArticle() {
    setIsLoading(true);
    try {
      const values = {
        ...form.getFieldsValue(true),
        figures: undefined,
        images: undefined,
      };

      const createdArticle = await createArticle(values);

      if (createdArticle) {
        if (imageUploaderRef.current)
          imageUploaderRef.current.triggerFinish(false);

        navigate(`/content-management/article/${createdArticle._id}`, {
          replace: true,
        });
      }
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Article not created",
        description: getRequestErrorMessage(error),
        placement: "bottomRight",
        duration: 0,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSaveArticle(autoSave = false) {
    if (!articleId || !article) return;

    if (!autoSave) setIsLoading(true);

    const values = {
      ...form.getFieldsValue(true),
      figures: undefined,
      images: undefined,
      ownerId: form.getFieldValue("owner"),
    };

    try {
      if (!autoSave)
        notification.info({
          key: "saveArticle",
          icon: <LoadingOutlined />,
          message: "Article is saving...",
          placement: "bottomRight",
        });

      const updatedArticle = await updateArticle(articleId, values);

      if (imageUploaderRef.current)
        imageUploaderRef.current.triggerFinish(autoSave);

      setArticle({ ...updatedArticle, images: article.images });

      if (autoSave) {
        triggerAutosaveMessage();
      } else {
        notification.close("saveArticle");
        notification.success({
          message: "Article saved",
          description: `Article ${updatedArticle.slug} has been saved successfully`,
          placement: "bottomRight",
          duration: 2,
        });
      }

      clearTimeout(timeoutRef.current);
    } catch (error) {
      if (autoSave) {
        triggerAutosaveMessage(error);
      }

      notification.destroy();
      notification.error({
        message: "Article not saved",
        description: getRequestErrorMessage(error),
        placement: "bottomRight",
        duration: 0,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleFinish() {
    if (!articleId) {
      handleCreateArticle();
    } else {
      handleSaveArticle();
    }
  }

  function handleFinishFailed() {
    form.getFieldsError().forEach((error) => {
      if (error.errors.length > 0) message.error(error.errors[0]);
    });
  }

  async function triggerAutoSave() {
    if (!articleId || !article || article?.meta?.status == "published")
      return setIsLoading(false);

    handleSaveArticle(true);
  }

  function handleChangeFields() {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      triggerAutoSave();
    }, TIMEOUTAUTO_SAVE);
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId]);

  // Prevent double save with autosave
  useEffect(() => {
    if (!isLoading && timeoutRef.current) clearTimeout(timeoutRef.current);
  }, [isLoading]);

  const cardContent = (
    <>
      <Form.Item name='language' label='Language'>
        <Select placeholder='Language' options={languageOptions} />
      </Form.Item>
      <Form.Item name='publication_date' label='Publication date'>
        <DatePicker
          format='DD/MM/YYYY'
          style={{ width: "100%" }}
          placeholder='Publication date'
          disabledDate={(current) => current && current > moment().endOf("day")}
        />
      </Form.Item>
      <Form.Item name='medical_specialties' label='Medical specialties'>
        <Select
          mode='multiple'
          placeholder='Medical specialties'
          filterOption={searchFilterOptions}
          options={tagList
            ?.filter((tag) => !Object.keys(tag).includes("parent"))
            .map((tag) => ({
              label: tag?.translations?.en,
              value: tag._id,
            }))}
        />
      </Form.Item>
      <Form.Item name='tags' label='Tags'>
        <SelectTagsField form={form} />
      </Form.Item>
      <Form.Item name='doi' label='DOI'>
        <Input placeholder='DOI' />
      </Form.Item>
      <Form.Item name='title' label='Title'>
        <Input placeholder='Title' />
      </Form.Item>
      <DragInputList
        name='conclusion'
        label='Conclusion'
        form={form}
        type='textarea'
      />
      <Form.Item
        name='journal'
        label='Journal'
        rules={[{ required: true, message: "Please select a journal" }]}
      >
        <Select
          placeholder='Journal'
          showSearch
          allowClear
          filterOption={searchFilterOptions}
          options={journalList.map((journal) => ({
            value: journal._id,
            label: journal.name,
          }))}
        />
      </Form.Item>
      <Form.Item name='primary_author' label='Primary author'>
        <Input placeholder='Primary author' />
      </Form.Item>
      <Row gutter={8}>
        <Col flex='auto'>
          <DragInputList
            name='authors'
            form={form}
            type='input'
            label='Authors'
          />
        </Col>
        <Col>
          <Button onClick={handleAuthorSplit}>{"Split authors"}</Button>
        </Col>
      </Row>
    </>
  );

  const publicationContent = (
    <>
      <Form.Item name='edito' label='Edito'>
        <Input placeholder='Edito' />
      </Form.Item>
      <Form.Item name='externalLink' label='External link'>
        <Input placeholder='https://example.com' />
      </Form.Item>
      <Form.Item name='background' label='Background'>
        <Input.TextArea
          placeholder='Background'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>

      <DragInputList
        name='objectives'
        label='Objectives'
        form={form}
        type='textarea'
      />

      <Form.Item name='methodology' label='Methodology'>
        <Input.TextArea
          placeholder='Methodology'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>

      <DragInputList
        name='results'
        form={form}
        label='Results'
        type='textarea'
      />

      <Form.Item name='limitations' label='Limitations'>
        <Input.TextArea
          placeholder='Limitations'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>
      <Form.Item name='disclosure' label='Disclosure'>
        <Input.TextArea
          placeholder='Disclosure'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>
      <Form.Item name='article_type' label='Article type'>
        <Select
          placeholder='Article type'
          options={Object.values(ArticleTypeEnum).map((value) => ({
            label: value,
            value,
          }))}
        />
      </Form.Item>
      <Form.Item label='Keywords'>
        <Row gutter={8} align='middle'>
          <Col flex='auto'>
            <Form.Item name='keywords' noStyle>
              <Select
                mode='tags'
                placeholder='Keywords'
                tokenSeparators={[",", ";", ".", "•"]}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={handleKeywordsSplit}>{"Split Tags"}</Button>
          </Col>
        </Row>
      </Form.Item>
    </>
  );

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout='vertical'
        name='articleEditionContent'
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        onValuesChange={handleChangeFields}
      >
        {section === "publication" && (
          <ArticleEditionImages ref={imageUploaderRef} form={form} />
        )}
        {section === "card" ? cardContent : publicationContent}
      </Form>
    </Spin>
  );
};

export default ArticleEditionContentForm;
