import { ICompany } from "~/model/company.model";
import { ActionTypes } from "./actions";
import { IJournal } from "~/model/journal.model";
import { ITag } from "~/model/tag.model";
import { IOrganisation } from "~/model/organisation.model";
import { INectarHighlight } from "~/model/nectar.model";
import { IRoom } from "~/model/room.model";
import { MonitoringReducerModel } from "~/model/monitoring.model";

export interface IReduxStore {
  companyList: ICompany[];
  journalList: IJournal[];
  tagList: ITag[];
  organisationList: IOrganisation[];
  highlightNectars: INectarHighlight[] | null;
  roomList: IRoom[] | null;
  monitoring: MonitoringReducerModel | null;
}

const initialState: IReduxStore = {
  companyList: [],
  journalList: [],
  tagList: [],
  organisationList: [],
  highlightNectars: null,
  roomList: null,
  monitoring: null,
};

export const dataReducer = (
  state = initialState,
  action: ActionTypes
): IReduxStore => {
  switch (action.type) {
    case "FETCH_DATA_SUCCESS":
      return {
        ...state,
        [action.key]: action.data,
      };
    default:
      return state;
  }
};
