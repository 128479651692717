import { IMapping } from ".";
import { europePMCJournalList } from "./europePMCJournalList";

export const createCrawlingMapping: { [key: string]: IMapping } = {
  title: {
    label: "Title",
    type: "text",
    placeholder: "Crawling batch title",
    required: true,
  },
  keywords: {
    label: "Keywords",
    type: "text",
    placeholder: 'Keywords separated with semicolon ";"',
    required: true,
  },
  journals: {
    label: "Journals",
    type: "multiple",
    enum: Object.values(europePMCJournalList),
    required: false,
  },
  limit: {
    label: "Maximum number of articles",
    type: "text",
    required: false,
  },
  authors: {
    label: "Authors",
    type: "text",
    placeholder: 'Authors separated with semicolon ";"',
    required: false,
  },
  titles: {
    label: "Article title",
    type: "text",
    placeholder: 'Titles separated with semicolon ";"',
    required: false,
  },
  publicationTypes: {
    label: "Publication types",
    type: "text",
    placeholder: 'Publication types separated with semicolon ";"',
    required: false,
  },
  custom: {
    label: "Custom query",
    type: "text",
    placeholder: "Custom Europe PMC query",
    required: false,
  },
};
