import { useContext } from "react";
import { Modal, notification, Select } from "antd";
import { GlobalContext } from "~/context/global.context";
import { deleteUserOrganisation, updateUserOrganisation } from "~/services";
import { IUser } from "~/model/user.model";

type UseOrganisationAssociatorProps = {
  user: IUser;
  revalidate: () => void;
};
const UserOrganisationAssociator = ({
  user,
  revalidate,
}: UseOrganisationAssociatorProps) => {
  const { organisationList } = useContext(GlobalContext);

  const handleUpdateUser = async (organisationId: string) => {
    Modal.confirm({
      title: "Are you sure?",
      content: "This will update the user's organisation",
      onOk: async () => {
        try {
          if (!organisationId) await deleteUserOrganisation(user._id);
          else
            await updateUserOrganisation({
              userId: user._id,
              organisationId,
            });
          notification.success({
            message: "User updated",
            description: `User ${user.email} has been updated`,
          });
          revalidate();
        } catch (error) {
          notification.error({
            message: "An error has occured",
            description: (error as Error)?.message || "Please try again later",
          });

          throw error;
        }
      },
    });
  };

  return (
    <Select
      allowClear
      placeholder='Select organisation'
      defaultValue={user.organisations?.[0]?._id}
      options={organisationList.map((org) => ({
        label: org.name,
        value: org._id,
      }))}
      onChange={handleUpdateUser}
      style={{ width: "100%" }}
    />
  );
};

export default UserOrganisationAssociator;
