import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Tabs,
  notification,
} from "antd";
import { useContext, useEffect } from "react";
import { GlobalContext } from "~/context/global.context";

import {
  createAppAnnouncement,
  getAdminsPermissionsList,
  updateAdminPermissions,
} from "~/services";
import { languageLabel } from "~/utils/helpers";
import { Spacer } from "./shared/global";
import { SendOutlined } from "@ant-design/icons";
import {
  PermissionEnum,
  PermissionLabel,
  SupportedLanguage,
} from "~/model/enum";

const AnnouncementEdition = () => {
  const { organisationList } = useContext(GlobalContext);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchAdmins = async () => {
      const res = await getAdminsPermissionsList({
        limit: 10,
        offset: 0,
      });
      console.log(res);
    };

    fetchAdmins();
  }, []);

  const handleUpdatePermissions = async () => {
    const body = {
      user: "655cb5d9fa86607138fba555",
      permissions: [
        {
          label: PermissionLabel.Content,
          permissions: [
            PermissionEnum.Write,
            PermissionEnum.Read,
            PermissionEnum.Publish,
          ],
        },
      ],
    };

    const res = await updateAdminPermissions(body);

    console.log("permissions updated", res);
  };

  const handleFinish = async () => {
    const contents: {
      title: string;
      content: string;
      cta: string;
      language: SupportedLanguage;
    }[] = [];
    const inputData = form.getFieldsValue();

    Object.values(SupportedLanguage).forEach((language) => {
      const title = inputData[`title-${language}`];
      const content = inputData[`content-${language}`];
      const cta = inputData[`cta-${language}`];

      if (title && content) {
        contents.push({
          title,
          content,
          cta,
          language,
        });
      }
    });

    await createAppAnnouncement({
      contents,
      organisation: inputData["organisation"],
    });

    form.resetFields();

    notification.success({
      message: "✅ Announcement created successfully",
    });
  };

  return (
    <div className='basic-layout'>
      <h1>Publish an in-app announcement</h1>
      <Spacer />
      <Card>
        <Button style={{ display: "none" }} onClick={handleUpdatePermissions}>
          Test Permissions
        </Button>
        <Form layout='vertical' form={form} onFinish={handleFinish}>
          <Form.Item label='Organisation' name='organisation'>
            <Select
              placeholder='Organisation'
              options={organisationList.map((org) => ({
                label: org.name,
                value: org._id,
              }))}
              allowClear
            />
          </Form.Item>

          <Tabs
            items={Object.values(SupportedLanguage).map((lng) => ({
              key: lng,
              label: languageLabel[lng],
              children: (
                <div>
                  <Form.Item label='Title' name={"title-" + lng}>
                    <Input placeholder='Title' />
                  </Form.Item>
                  <Form.Item label='Content' name={"content-" + lng}>
                    <Input.TextArea
                      placeholder='Your message here...'
                      autoSize={{ minRows: 3, maxRows: 9 }}
                    />
                  </Form.Item>
                  <Form.Item label='CTA' name={"cta-" + lng}>
                    <Input placeholder='CTA' />
                  </Form.Item>
                </div>
              ),
            }))}
          />
          <Divider />
          <Form.Item name='submit'>
            <Button
              type='primary'
              htmlType='submit'
              style={{ width: 200 }}
              icon={<SendOutlined />}
            >
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AnnouncementEdition;
