import {
  FileExclamationOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { Flex } from "~/components/shared/global";

const items = [
  {
    label: (
      <NavLink to={"/analytics/performance-metrics/users"}>
        <Flex align='center' gap={8}>
          <UserOutlined />
          {"Registered Users"}
        </Flex>
      </NavLink>
    ),
    key: "tab-users",
  },
  {
    label: (
      <NavLink to={"/analytics/performance-metrics/contents"}>
        <Flex align='center' gap={8}>
          <TableOutlined />
          {"All Contents"}
        </Flex>
      </NavLink>
    ),
    key: "tab-contents",
  },
  {
    label: (
      <NavLink to={"/analytics/performance-metrics/types"}>
        <Flex align='center' gap={8}>
          <FileExclamationOutlined />
          {"Content Types"}
        </Flex>
      </NavLink>
    ),
    key: "tab-types",
    link: "/analytics/performance-metrics/types",
  },
];

const PerformanceMenu = () => {
  return (
    <Menu
      mode='horizontal'
      items={items}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        padding: "8px 0",
      }}
      defaultActiveFirst
    />
  );
};

export default PerformanceMenu;
