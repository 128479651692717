import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Link } from "react-router-dom";
import { IArticle } from "~/model/article.model";
import { IOrganisation } from "~/model/organisation.model";

export default function columnsArticles(
  onDelete: (article: IArticle) => void
): ColumnsType<IArticle> {
  return [
    {
      title: "",
      key: "actions",
      width: 50,
      render: (article) => (
        <Button
          shape='circle'
          size='small'
          type='primary'
          danger
          onClick={() => onDelete(article)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string) => (
        <div style={{ color: "#000", fontWeight: 600, fontSize: 13 }}>
          {title}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: ["meta", "status"],
      key: "status",
      render: (status: string) =>
        status === "published" ? (
          <Tag color='green'>Published</Tag>
        ) : (
          <Tag color='red'>Draft</Tag>
        ),
      width: 100,
    },
    {
      title: "Publication date",
      dataIndex: "publication_date",
      key: "publication_date",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
      width: 200,
    },
    {
      title: "Access",
      dataIndex: "isPublic",
      key: "isPublic",
      width: 80,
      render: (isPublic: boolean) =>
        isPublic ? (
          <Tag color='green'>{"✅ Public"}</Tag>
        ) : (
          <Tag color='purple'>{"🔐 Private"}</Tag>
        ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 150,
      render: (owner: IOrganisation) => {
        if (!owner) return <span>{"N/A"}</span>;

        return (
          <Link to={`/sensitive-data/organisations/${owner?.uid}`}>
            {owner.name}
          </Link>
        );
      },
    },
  ];
}
