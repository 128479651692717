import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext, IGlobalContext } from "~/context/global.context";
import {
  Button,
  Divider,
  Layout,
  Modal,
  notification,
  Pagination,
  Radio,
  Row,
  Select,
  Spin,
  Table,
} from "antd";

import {
  getArticleCsv,
  listArticles,
  searchArticlesV2,
  translateArticleById,
  translateArticles,
} from "~/services";
import {
  BarChartOutlined,
  LoadingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { TablePaginationConfig } from "antd/lib/table";
import axios from "axios";
import FiltersDrawer, {
  FiltersDrawerValuesModel,
} from "~/components/shared/FiltersDrawer";
import { Flex } from "~/components/shared/global";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useCheckFiltersSaved } from "~/utils/hooks";
import { languageOptions } from "~/utils/helpers";
import { columns, columnsMetrics } from "./columns";
import { IArticle, TranslateArticleErrorType } from "~/model/article.model";
import { ISorter, QueryContentParamsModel } from "~/model/query.model";
import { SupportedLanguage } from "~/model/enum";

const DEFAULT_PAGE_SIZE = 30;

export const ArticleList: React.FC = () => {
  const { tagList, organisationList, roomList } = useContext(
    GlobalContext
  ) as IGlobalContext;

  const [innerArticleList, setInnerArticleList] = useState<IArticle[]>([]);
  const [metricsMode, setMetricsMode] = useState<boolean>(false);

  const [filters, setFilters] = useState<FiltersDrawerValuesModel>();
  const [sorter, setSorter] = useState<ISorter>();

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedArticles, setSelectedArticles] = useState<string[]>([]);
  const hasSavedFilters = useCheckFiltersSaved();

  const fetchArticles = async (limit?: number, offset?: number) => {
    const search = new URLSearchParams(location.search);

    if (!filters && !!search.get("language")) return;

    // To prevent fetching articles when the page is fetching with saved filters
    if (!filters && hasSavedFilters) return;

    try {
      const query: QueryContentParamsModel = {
        ...filters,
        limit: limit || DEFAULT_PAGE_SIZE,
        offset: offset || 0,
        ...sorter,
      };

      // TODO: get saved filters from localStorage

      let articleList;
      setIsLoading(true);

      if (filters?.search) {
        // Search API expects uid, not _id
        const medical_specialties = filters.medicalSpecialties
          ? tagList
              .filter((tag) => filters.medicalSpecialties?.includes(tag._id))
              .map((tag) => tag.uid)
          : undefined;

        articleList = await searchArticlesV2({
          ...query,
          language: filters.languages,
          medical_specialties,
        });

        if (articleList.hits.hits) {
          const resArticles = articleList.hits.hits.map((article) => ({
            ...article._source.core,
            ...article._source.filters,
            owner: organisationList.find(
              (o) =>
                o._id === (article._source.filters.owner as unknown as string)
            ),
            _id: article._source.id,
          })) as IArticle[];

          setInnerArticleList(resArticles);
          handlePaginationChange(1);
        }
      } else {
        if (query.status === "all") {
          delete query["status"];
        }

        articleList = await listArticles({ ...query, language: undefined });

        if (articleList) {
          setInnerArticleList(articleList.docs);
          setCount(articleList.meta.total);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Couldn't search articles.", error);
      if (axios.isAxiosError(error))
        notification.error({
          message: "An error occurred",
          description: error.response?.data?.message,
        });
      setIsLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    const articleCsv = await getArticleCsv(selectedArticles);
    const blobConfig = new Blob([articleCsv], {
      type: "text/csv;charset=utf-8",
    });
    const blobUrl = URL.createObjectURL(blobConfig);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = "articles.csv";

    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  };

  const handleSelectArticles = (selectedRowKeys: React.Key[]) => {
    const keys = selectedRowKeys as string[];

    if (selectedArticles.length && keys.length === 0) setSelectedArticles([]);
    else setSelectedArticles(keys);
  };

  const handleFiltersChange = (values: FiltersDrawerValuesModel) => {
    setFilters(values);

    handlePaginationChange(1);
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchArticles(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
    }
    setPage(page);
  };

  const openNotificationSuccess = (language: SupportedLanguage) => {
    notification["success"]({
      message: "Translation task sent for " + language.toLocaleUpperCase(),
      description: "The translation task was succesfully sent to the server.",
    });
  };

  const handleTranslation = async () => {
    let selectedLanguagesTranslation: SupportedLanguage[] = [];

    if (selectedArticles.length === 0) {
      translateArticles(SupportedLanguage.FR).then(() => {
        openNotificationSuccess(SupportedLanguage.FR);
      });
      return;
    }

    Modal.confirm({
      icon: null,
      title: "Please select the languages you want to translate the articles",
      content: (
        <div>
          <Select
            allowClear
            mode='multiple'
            placeholder='Select languages'
            style={{ width: "100%" }}
            options={languageOptions}
            onChange={(value) => {
              selectedLanguagesTranslation = value;
            }}
          />
        </div>
      ),
      onCancel() {},
      onOk: async () => {
        Modal.destroyAll(); // Background tasks

        let counter = 0;

        notification.info({
          key: "loader-notification",
          icon: <LoadingOutlined />,
          message: "Translation in progress...",
          description:
            "The translation task has been sent to the server. Please wait",
          duration: 0,
        });

        for (const language of selectedLanguagesTranslation) {
          for (const articleId of selectedArticles) {
            const slug = innerArticleList.find(
              (article) => article._id === articleId
            )?.slug;

            if (slug)
              translateArticleById(language, articleId)
                .then((translatedArticle) => {
                  if (
                    (translatedArticle as TranslateArticleErrorType)?.status ===
                    "error"
                  ) {
                    const articleTitle = (
                      translatedArticle as TranslateArticleErrorType
                    ).translated_payload.title;

                    notification.error({
                      message: "Error",
                      description:
                        "This article has already been translated in " +
                        language +
                        " language: " +
                        articleTitle,
                    });
                  }

                  counter++;
                })
                .catch((error) => {
                  if (axios.isAxiosError(error))
                    notification.error({
                      message: `Translation in ${language} failed`,
                      description:
                        error.response?.data?.message ||
                        "An error has occurred while translating the article.",
                    });

                  counter++;
                });
          }
        }

        while (
          counter <
          selectedLanguagesTranslation.length * selectedArticles.length
        ) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        notification.close("loader-notification");
        openNotificationSuccess("selected languages" as SupportedLanguage);
      },
    });
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IArticle> | SorterResult<IArticle>[]
  ) => {
    const _sorter = sorter as SorterResult<IArticle>;
    const sortOrder = _sorter.order === "ascend" ? "asc" : "desc";

    if (_sorter.column?.title === "Creation date")
      setSorter({ sortBy: "creationDate", sortOrder });

    if (_sorter.column?.title === "Publication date")
      setSorter({ sortBy: "publicationDate", sortOrder });
  };

  useEffect(() => {
    fetchArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sorter]);

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
            Articles
          </h1>
          <div>
            <Radio.Group
              onChange={() => setMetricsMode(!metricsMode)}
              value={metricsMode}
              style={{ marginBottom: 8 }}
            >
              <Radio.Button value={true}>
                <Flex align='center' gap={8}>
                  <BarChartOutlined />
                  {"Metrics"}
                </Flex>
              </Radio.Button>
              <Radio.Button value={false}>
                <Flex align='center' gap={8}>
                  <UnorderedListOutlined />
                  {"Management"}
                </Flex>
              </Radio.Button>
            </Radio.Group>

            <Divider type='vertical' />
            <Button onClick={handleTranslation} style={{ marginRight: 10 }}>
              {selectedArticles.length > 0
                ? `Translate articles (${selectedArticles.length || 0})`
                : "Translate all articles (FR)"}
            </Button>
            <Button onClick={handleDownloadCsv}>Download as CSV</Button>
            <Link to={"/content-management/article/create/"}>
              <Button type='primary' style={{ marginLeft: 10 }}>
                New article
              </Button>
            </Link>
          </div>
        </Row>
        <FiltersDrawer onChange={handleFiltersChange} />

        <div style={{ height: 16 }} />
        <Spin spinning={isLoading} tip='Loading...'>
          <Table
            sticky
            size='small'
            columns={metricsMode ? columnsMetrics : columns}
            pagination={false}
            rowSelection={{
              onChange: handleSelectArticles,
              selectedRowKeys: selectedArticles,
            }}
            dataSource={innerArticleList.map((article) => ({
              ...article,
              rooms: roomList?.filter((room) =>
                (room.articles as unknown as string).includes(article._id)
              ),
              key: article._id,
            }))}
            scroll={{
              x: "max-content",
              y: "calc(100vh - 300px)",
            }}
            onChange={handleTableChange}
          />
        </Spin>
        <Pagination
          style={{ marginTop: "10px" }}
          onChange={handlePaginationChange}
          total={count}
          current={page}
          defaultCurrent={1}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </Layout.Content>
    </Layout>
  );
};
