export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR";

export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const FETCH_JOURNALS = "FETCH_JOURNALS";
export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_ORGANISATIONS = "FETCH_ORGANISATIONS";
export const FETCH_HIGHLIGHT_NECTARS = "FETCH_HIGHLIGHT_NECTARS";
export const FETCH_ROOMS = "FETCH_ROOMS";

// monitoring
export const FETCH_MONITORING = "FETCH_MONITORING";
