import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Input, message, Modal, Row } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Flex } from "~/components/shared/global";
import { IPlaylist } from "~/model/playlist.model";
import {
  deletePlaylistFromRoom,
  postPlaylistToRoom,
  searchPlaylists,
} from "~/services";

const RoomEditionPlaylists = (props: { playlists: IPlaylist[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [innerPlaylists, setInnerPlaylists] = useState<IPlaylist[]>(
    props.playlists
  );
  const [searchResults, setSearchResults] = useState<IPlaylist[]>([]);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const localSearchPlaylists = async () => {
      if (!searchValue.length) return setSearchResults([]);

      const res = await searchPlaylists(searchValue);

      if (res.hits.hits) {
        setSearchResults(
          res.hits.hits
            .map(
              (playlist: {
                _source: { core: IPlaylist; filters: IPlaylist; id: string };
              }) => ({
                ...playlist._source.core,
                id: playlist._source.id,
                _id: playlist._source.id,
              })
            )
            .filter((playlist: IPlaylist) => {
              return !innerPlaylists.find(
                (innerPlaylist) => innerPlaylist._id === playlist._id
              );
            })
        );
      }
    };

    localSearchPlaylists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleAddPlaylist = async (playlist: IPlaylist) => {
    try {
      if (!params.id) throw new Error("No room id provided");

      await postPlaylistToRoom(params.id, playlist.id);

      setInnerPlaylists([playlist, ...innerPlaylists]);
      message.success("Playlist added to the room successfully!");
    } catch (error) {
      message.error("An error occured while adding the playlist to the room");
      throw error;
    }
  };

  const handleDeletePlaylist = async (playlistId: string) => {
    const callback = async () => {
      try {
        if (!params.id) throw new Error("No room id provided");

        await deletePlaylistFromRoom(params.id, playlistId);

        setInnerPlaylists(
          innerPlaylists.filter((playlist) => playlist._id !== playlistId)
        );
        message.success(
          "Playlist has been successfully removed from the room!"
        );
      } catch (error) {
        message.error(
          "An error occured while removing the playlist from the room"
        );
        throw error;
      }
    };

    Modal.confirm({
      title: "Are you sure you want to remove this playlist from the room?",
      content: "This action is irreversible",
      onOk: callback,
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns: ColumnsType<IPlaylist> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: () => (
        <Input.Search
          allowClear
          placeholder='Search Playlists'
          onSearch={(val) => setSearchFilter(val)}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#ffc408" : undefined }} />
      ),
      render: (_text: string, playlist: IPlaylist) => (
        <Link to={`/content-management/playlist/${playlist._id}`}>
          {playlist.title}
        </Link>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 81,
      render: (_text: string, playlist: IPlaylist) => (
        <Button
          danger
          shape={"circle"}
          icon={<CloseOutlined />}
          onClick={() => handleDeletePlaylist(playlist._id)}
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Table
          bordered
          columns={columns}
          dataSource={innerPlaylists
            .map((playlist) => ({
              ...playlist,
              key: playlist._id,
            }))
            .filter((playlist) => {
              if (!searchFilter) return true;
              return playlist.title
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            })}
        />
      </Col>
      <Col span={8}>
        <Input.Search
          placeholder='Add playlists'
          allowClear
          onSearch={(val) => setSearchValue(val)}
        />
        <Divider />
        {searchResults.length ? (
          <Flex
            gap={8}
            flexDirection='column'
            style={{ height: 800, overflowY: "scroll", overflowX: "hidden" }}
          >
            {searchResults.map((playlist) => (
              <Card key={`${playlist._id}-search`}>
                <p>{playlist.title}</p>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddPlaylist(playlist)}
                >
                  Add this playlist
                </Button>
              </Card>
            ))}
          </Flex>
        ) : null}
      </Col>
    </Row>
  );
};

export default RoomEditionPlaylists;
