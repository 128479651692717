// TODO: Remove this page
import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Modal,
  Table,
  notification,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext, useState } from "react";
import { GlobalContext } from "~/context/global.context";
import { Flex } from "./shared/global";
import { updateOrganisationLanguages } from "~/services";
import axios from "axios";
import { fetchData } from "~/store/actions";
import { FETCH_ORGANISATIONS } from "~/store/types";
import { useDispatch } from "react-redux";
import { languageLabel } from "~/utils/helpers";
import { SupportedLanguage } from "~/model/enum";
import { IOrganisation } from "~/model/organisation.model";

const OrganisationLanguages = () => {
  const dispatch = useDispatch();
  const { organisationList } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [selectedOrganisation, setSelectedOrganisation] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    const values = form.getFieldsValue();

    setIsSubmitting(true);

    const allowedLanguages = Object.keys(values).filter(
      (key) => values[key]
    ) as SupportedLanguage[];

    const currentLanguages =
      organisationList.find((o) => o._id === selectedOrganisation)
        ?.organisationSettings.allowedLanguages || [];

    try {
      await updateOrganisationLanguages(selectedOrganisation, {
        allowedLanguages: [...allowedLanguages, ...currentLanguages],
      });
      notification.success({
        message: "Organisation features updated successfully",
      });
      dispatch(fetchData(FETCH_ORGANISATIONS));
    } catch (error) {
      if (axios.isAxiosError(error))
        notification.error({
          message: "An error occurred while updating organisation features",
          description: error?.response?.data.message,
        });
    }

    setIsSubmitting(false);
    form.resetFields();
    setSelectedOrganisation("");
  };

  const columns: ColumnsType<IOrganisation> = [
    {
      title: "Organisation",
      dataIndex: "name",
      key: "name",
    },
    ...Object.keys(SupportedLanguage).map((lang) => ({
      title:
        languageLabel[
          SupportedLanguage[lang as keyof typeof SupportedLanguage]
        ],
      dataIndex: lang,
      key: lang,
      width: 75,
      align: "center" as unknown as "center",
      render: (_: unknown, org: IOrganisation) => (
        <Checkbox
          style={{ pointerEvents: "none" }}
          checked={org.organisationSettings.allowedLanguages?.includes(
            lang.toLowerCase() as SupportedLanguage
          )}
        />
      ),
    })),
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_: unknown, org: IOrganisation) => (
        <Button
          type='ghost'
          icon={<EditOutlined />}
          onClick={() => setSelectedOrganisation(org._id)}
          style={{ width: "100%" }}
        >
          {"Edit access"}
        </Button>
      ),
    },
  ];

  return (
    <div className='basic-layout'>
      <h1>Organisation Languages</h1>
      <Table
        bordered
        columns={columns}
        dataSource={organisationList.map((org) => ({
          ...org,
          key: org._id,
        }))}
        pagination={{ pageSize: 50 }}
      />
      <Modal
        title={
          "Edit access for " +
          organisationList.find((o) => o._id === selectedOrganisation)?.name
        }
        open={!!selectedOrganisation}
        onCancel={() => setSelectedOrganisation("")}
        footer={null}
        destroyOnClose
      >
        <Form form={form} onFinish={handleSubmit}>
          <Flex justify='space-between' align='center'>
            {Object.values(SupportedLanguage).map((lang) => (
              <Form.Item name={lang.toLowerCase()}>
                <Checkbox
                  defaultChecked={
                    organisationList
                      .find((o) => o._id === selectedOrganisation)
                      ?.organisationSettings.allowedLanguages.includes(
                        lang as SupportedLanguage
                      ) || false
                  }
                  onChange={(e) => {
                    form.setFieldsValue({
                      [lang.toLowerCase()]: e.target.checked,
                    });
                  }}
                >
                  {languageLabel[lang]}
                </Checkbox>
              </Form.Item>
            ))}
          </Flex>
          <Divider />
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              block
              loading={isSubmitting}
            >
              {"Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OrganisationLanguages;
