import { EnterOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import { capitalize } from "lodash";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import SelectTagsField from "~/components/SelectTagsField";
import { Flex } from "~/components/shared/global";
import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from "~/config/dropzone";
import { GlobalContext } from "~/context/global.context";
import { ICongress } from "~/model/congress.mode";
import { SupportedLanguage, SupportedPublisherEnum } from "~/model/enum";
import { ITag } from "~/model/tag.model";
import { IUser } from "~/model/user.model";
import { IVideo } from "~/model/video.model";
import {
  createVideo,
  listCongress,
  searchUsers,
  updateVideo,
  uploaAPIVideo,
} from "~/services";
import {
  getRequestErrorMessage,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import { triggerAutosaveMessage } from "~/utils/ui/messages";

type InnerProps = {
  video: IVideo | null;
  form: FormInstance;
};

const TIMEOUT_AUTOSAVE = 5000;

export default function VideoEditionContentForm({ video, form }: InnerProps) {
  const navigate = useNavigate();
  const { companyList, journalList, tagList } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchUserList, setSearchUserList] = useState<IUser[]>([]);
  const [congressList, setCongressList] = useState<ICongress[] | null>(null);

  // Ref timeout autosave
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Use Watch values
  const watchLanguage: SupportedLanguage = Form.useWatch("language", form);
  const watchPublisher = Form.useWatch("publisher", form);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "video/*": [] },
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const initialValues = {
    ...video,
    publication_date: video?.publication_date
      ? moment(video.publication_date)
      : undefined,
    company: video?.company?._id,
    journal: video?.journal?._id,
    congress: video?.congress?._id,
    medical_specialties: video?.medical_specialties?.map(
      (spe) => (spe as ITag)._id
    ),
    tags: video?.tags?.map((tag) => (tag as ITag)._id),
    owner: video?.owner?._id,
  };

  async function handleSearchUsers(value: string) {
    const users = await searchUsers(value, { limit: 10 });
    setSearchUserList(users);
  }

  async function handleUploadApiVideo(id: string) {
    if (!acceptedFiles.length) return;

    await uploaAPIVideo(id, acceptedFiles[0]);
  }

  async function handleFinish() {
    const publisherNotEmpty =
      watchPublisher && form.getFieldValue(watchPublisher)?.length;

    if (!publisherNotEmpty) {
      return notification.error({
        message: "Validation Error",
        description: "The publisher field is required.",
        placement: "bottomRight",
      });
    }

    setIsLoading(true);

    try {
      const values = {
        ...form.getFieldsValue(true),
        apiVideo: undefined,
        sourceURL: form.getFieldValue("sourceURL")?.length
          ? form.getFieldValue("sourceURL")
          : undefined,
        externalLink: form.getFieldValue("externalLink")?.length
          ? form.getFieldValue("externalLink")
          : undefined,
        embedURL: form.getFieldValue("embedURL")?.length
          ? form.getFieldValue("embedURL")
          : undefined,
        publication_date: form.getFieldValue("publication_date").toISOString(),
      };

      if (video) {
        await updateVideo(video._id, values);
        await handleUploadApiVideo(video._id);
        notification.success({
          message: "Video updated",
          description: "The video was successfully updated.",
          placement: "bottomRight",
        });
      } else {
        const newVideo = await createVideo(values);

        if (!newVideo) {
          throw new Error("The video could not be created.");
        }

        await handleUploadApiVideo(newVideo._id);

        notification.success({
          message: "Video created",
          description: "The video was successfully created.",
          placement: "bottomRight",
        });

        // Redirect to the new video with SLUG
        navigate(`/content-management/video/${newVideo.slug}`, {
          replace: true,
        });
      }
    } catch (error) {
      notification.error({
        message: "An error has occurred",
        description: getRequestErrorMessage(error),
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleFinishFailed() {
    const errorFields = form.getFieldsError();

    errorFields.forEach((error) => {
      notification.error({
        message: "Validation Error",
        description: error.errors[0],
        placement: "bottomRight",
      });
    });
  }

  function renderPublisherInput() {
    let options;

    switch (watchPublisher) {
      case "congress":
        options = congressList?.map((congress) => ({
          label: congress.name,
          value: congress._id,
        }));
        break;

      case "company":
        options = companyList
          .filter((company) =>
            watchLanguage ? company?.language === watchLanguage : true
          )
          .map((company) => ({
            label: `${company.name} (${company.language})`,
            value: company._id,
          }));
        break;

      case "journal":
        options = journalList.map((journal) => ({
          label: journal.name,
          value: journal._id,
        }));
        break;

      case "user":
        options = searchUserList.map((user) => ({
          label: user.fullname,
          value: user._id,
        }));
        break;

      default:
        break;
    }

    const isLoading = !options || !congressList || !companyList || !journalList;

    return (
      <Select
        style={{ width: "100%" }}
        options={options}
        loading={isLoading}
        disabled={isLoading}
        showSearch={watchPublisher === "user"}
        autoClearSearchValue={watchPublisher === "user"}
        onSearch={watchPublisher === "user" ? handleSearchUsers : undefined}
        filterOption={watchPublisher === "user" ? false : undefined}
        placeholder={`Select ${watchPublisher}`}
      />
    );
  }

  async function handleAutoSave() {
    const publisherNotEmpty =
      watchPublisher && form.getFieldValue(watchPublisher)?.length;

    if (!video || video.meta.status !== "draft" || !publisherNotEmpty) return;

    try {
      const values = {
        ...form.getFieldsValue(),
        apiVideo: undefined,
        sourceURL: form.getFieldValue("sourceURL")?.length
          ? form.getFieldValue("sourceURL")
          : undefined,
        externalLink: form.getFieldValue("externalLink")?.length
          ? form.getFieldValue("externalLink")
          : undefined,
        embedURL: form.getFieldValue("embedURL")?.length
          ? form.getFieldValue("embedURL")
          : undefined,
        publication_date: form.getFieldValue("publication_date").toISOString(),
      };

      await updateVideo(video._id, values);
      await handleUploadApiVideo(video._id);

      triggerAutosaveMessage();
    } catch (error) {
      triggerAutosaveMessage(error);
    }
  }

  async function handleFieldsChange() {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      timeoutRef.current = null;
      await handleAutoSave();
    }, TIMEOUT_AUTOSAVE);
  }

  async function getCongressList() {
    setCongressList(null);

    const query = {
      limit: 20,
      offset: 0,
      language: watchLanguage || SupportedLanguage.EN,
    };
    const { docs: congressList } = await listCongress(query);
    setCongressList(congressList);
  }

  async function handleChangeLanguage() {
    form.setFieldsValue({ [watchPublisher]: undefined });

    getCongressList();
  }

  // Prevent double save with autosave
  useEffect(() => {
    if (!isLoading && timeoutRef.current) clearTimeout(timeoutRef.current);
  }, [isLoading]);

  useEffect(() => {
    getCongressList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        onFieldsChange={handleFieldsChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name='language' label='Language' required>
              <Select
                placeholder='Select a language'
                options={languageOptions}
                onChange={handleChangeLanguage}
              />
            </Form.Item>
            <Form.Item name='title' label='Title'>
              <Input placeholder='Title' />
            </Form.Item>

            <Form.Item
              name='publication_date'
              label='Publication date'
              required
            >
              <DatePicker
                style={{ width: "75%" }}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
              />
            </Form.Item>

            <Form.Item name='embedURL' label='Embed URL'>
              <Input placeholder='Embed URL' />
            </Form.Item>

            <Form.Item name='videoFormat' label='Video format' required>
              <Select
                placeholder='Select a video format'
                options={[
                  { label: "📑 Article", value: "article" },
                  { label: "📲 Story", value: "story" },
                ]}
              />
            </Form.Item>

            <Form.Item name='publisher' label='Publisher' required>
              <Select
                placeholder='Select a publisher'
                onChange={() => {
                  form.setFieldsValue({ [watchPublisher]: undefined });
                  setSearchUserList([]);
                }}
                options={
                  Object.values(SupportedPublisherEnum).map((value) => ({
                    label: capitalize(value),
                    value,
                  })) || []
                }
              />
            </Form.Item>

            {watchPublisher && (
              <Flex
                gap={16}
                align='start'
                style={{ width: "100%", paddingLeft: 16 }}
              >
                <EnterOutlined
                  style={{ transform: "scaleX(-1)", fontSize: 24 }}
                />
                <Form.Item
                  name={watchPublisher}
                  style={{ width: "100%" }}
                  required
                >
                  {renderPublisherInput()}
                </Form.Item>
              </Flex>
            )}

            <Form.Item
              name='medical_specialties'
              label='Medical specialties'
              required
            >
              <Select
                mode='tags'
                placeholder='Select medical specialties'
                disabled={!tagList.length}
                loading={!tagList.length}
                options={tagList
                  .filter((tag) => !tag.parent)
                  .map((tag) => ({
                    label: tag.translations.en,
                    value: tag._id,
                  }))}
                filterOption={searchFilterOptions}
              />
            </Form.Item>

            <Form.Item name='tags' label='Tags'>
              <SelectTagsField form={form} />
            </Form.Item>

            <Form.Item name='keywords' label='Keywords'>
              <Select
                mode='tags'
                placeholder='Select keywords'
                filterOption={false}
                notFoundContent={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='externalLink' label='External link'>
              <Input placeholder='https://example.com' />
            </Form.Item>
            <Form.Item
              name='sourceURL'
              label='Source URL'
              tooltip='The full-video version link shared via an external link.'
            >
              <Input placeholder='Source URL' />
            </Form.Item>
            <Form.Item label='API Video' name='apiVideo'>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  {...getRootProps({
                    className: "dropzone",
                    maxFiles: 1,
                  })}
                >
                  <input {...getInputProps({ style })} />
                </div>
                {video?.apiVideo?.videoId ? (
                  <img
                    key={video?.apiVideo.videoId}
                    style={{ maxHeight: "72px", marginLeft: "10px" }}
                    src={video?.apiVideo.thumbnail}
                    alt={video?.apiVideo.thumbnail}
                  />
                ) : null}
              </div>
            </Form.Item>

            <Form.Item
              key='transcription'
              label='Transcription'
              name='transcription'
            >
              <Input.TextArea
                placeholder='Transcription of the video'
                rows={20}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
