import { ICrawlingItem } from "../crawler.model";
import { SupportedLanguage } from "../enum";

export interface ICreateCrawlingDto {
  title: string;
  sources: CrawlingSourceEnum[];
  keywords: string[];
  journals: string[];
  authors: string[];
  period?: Date[];
  from: Date;
  to: Date;
  titles: string[];
  publicationTypes: string[];
  languages: SupportedLanguage[];
  custom_query: string;
  nb_articles?: number;
}

export interface IUpdateCrawlingDto {
  results: ICrawlingItem[];
}

export enum CrawlingSourceEnum {
  EuropePmc = "EuropePmc",
  PubMed = "PubMed",
  SemanticScholar = "SemanticScholar",
}
