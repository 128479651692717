import React from "react";

import { Form, Input, Button, Select } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { pasteAndClearLineBreaks } from "~/utils/helpers";

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { offset: 0 },
    sm: { offset: 0 },
  },
};

interface IMultipleInputProps {
  id: string;
  label: string;
  type:
    | "text"
    | "textarea"
    | "number"
    | "date"
    | "select"
    | "multiple"
    | "search"
    | "multipleTag";
  selectOptions: string[];
  required: boolean;
}

export const MultipleInput: React.FC<IMultipleInputProps> = ({
  id,
  label,
  type,
  selectOptions,
  required,
}) => {
  return (
    <>
      <Form.List name={id} initialValue={[""]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...formItemLayoutWithOutLabel}
                label={index === 0 ? label : ""}
                required={required}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  key={field.key}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required,
                      whitespace: true,
                      message: `Please input ${label} or delete this field.`,
                    },
                  ]}
                  noStyle
                >
                  {type === "select" ? (
                    <Select style={{ width: "80%", marginRight: "20px" }}>
                      {selectOptions.map((option: string) => (
                        <Select.Option key={id} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : type === "textarea" ? (
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder={`${label} item`}
                      style={{ width: "80%", marginRight: "20px" }}
                      onPaste={pasteAndClearLineBreaks}
                    />
                  ) : (
                    <Input
                      placeholder={`${label} item`}
                      style={{ width: "80%", marginRight: "20px" }}
                    />
                  )}
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className='dynamic-delete-button'
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
