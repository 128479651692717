import {
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Select,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { DebounceSelect } from "~/components/shared/DebounceSelect";
import { GlobalContext } from "~/context/global.context";
import {
  getRequestErrorMessage,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import columnsArticles from "./columns";
import {
  createNectar,
  getArticleById,
  searchArticlesV2,
  updateNectar,
} from "~/services";
import { useNavigate, useParams } from "react-router-dom";
import { triggerAutosaveMessage } from "~/utils/ui/messages";
import SelectTagsField from "~/components/SelectTagsField";
import { INectar } from "~/model/nectar.model";
import { ITag } from "~/model/tag.model";
import { IArticle } from "~/model/article.model";

const TIMEOUT_AUTOSAVE = 5000;

type NectarEditionContentFormProps = {
  form: FormInstance;
  nectar: INectar | null;
  setNectar: (nectar: INectar) => void;
};
export default function NectarEditionContentForm({
  form,
  nectar,
  setNectar,
}: NectarEditionContentFormProps) {
  const navigate = useNavigate();
  const { id: nectarId } = useParams<{ id: string }>();

  const { organisationList, tagList } = useContext(GlobalContext);

  // Ref timeout autosave
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // States
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = nectar
    ? {
        ...nectar,
        publication_date: moment(nectar.publication_date),
        owner: nectar?.owner?._id,
        medical_specialties: nectar.medical_specialties.map(
          (specialty) => (specialty as ITag)._id
        ),
        tags: nectar.tags.map((tag) => (tag as ITag)._id),
      }
    : undefined;

  function handleDeleteArticle(article: IArticle) {
    if (!nectar) return;

    const associatedArticles = nectar?.associatedArticles?.filter(
      (el) => el._id !== article._id
    );
    setNectar({ ...nectar, associatedArticles });
  }

  async function handleSearchArticles(searchValue: string) {
    const { hits } = await searchArticlesV2({
      language: nectarId ? nectar?.language : form.getFieldValue("language"),
      search: searchValue,
    });
    const articles = hits.hits
      .map((hit) => hit._source)
      .map((article) => ({ ...article.core, _id: article.id }));

    return articles.map((el) => ({ label: el.title, value: el._id }));
  }

  async function handleAddArticle(
    selections: { label: string; value: string }[]
  ) {
    const selection = selections[0];
    const article = await getArticleById(selection.value);

    if (!nectar) {
      setNectar({
        ...form.getFieldsValue(),
        associatedArticles: [article],
      });
    } else {
      if (nectar?.associatedArticles?.find((el) => el._id === article._id))
        return;

      const associatedArticles = nectar?.associatedArticles
        ? [article, ...nectar.associatedArticles]
        : [article];

      setNectar({ ...nectar, associatedArticles });
    }
  }

  async function handleFinish() {
    if (!nectar) return;

    setIsLoading(true);

    const formValues = form.getFieldsValue(true);

    try {
      if (!nectarId) {
        const createdNectar = await createNectar(formValues);

        notification.success({
          message: "Nectar created successfully",
          placement: "bottomRight",
        });

        navigate(`/content-management/nectar/${createdNectar._id}`);
      } else {
        const associatedArticles = nectar?.associatedArticles?.map(
          (article) => article._id
        );

        const payload = {
          ...nectar,
          ...formValues,
          associatedArticles,
        };

        const newNectar = await updateNectar(nectarId, payload);

        setNectar({
          ...nectar,
          isPublic: newNectar.isPublic,
          owner: newNectar.owner,
        });

        notification.success({ message: "Nectar updated successfully" });
      }
    } catch (error) {
      notification.error({
        message: "An error occurred while creating the nectar",
        description: getRequestErrorMessage(error),
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleFinishFailed() {
    const errorFields = form.getFieldsError();

    errorFields.forEach((error) => {
      notification.error({
        message: "Validation Error",
        description: error.errors[0],
        placement: "bottomRight",
      });
    });
  }

  async function handleAutoSave() {
    if (!nectar || nectar.meta.status !== "draft" || !nectarId) return;

    try {
      const associatedArticles = nectar?.associatedArticles?.map(
        (article) => article._id
      );

      const payload = {
        ...nectar,
        ...form.getFieldsValue(),
        associatedArticles,
      };

      const newNectar = await updateNectar(nectarId, payload);

      setNectar({
        ...nectar,
        isPublic: newNectar.isPublic,
        owner: newNectar.owner,
      });

      triggerAutosaveMessage();
    } catch (error) {
      triggerAutosaveMessage(error);
    }
  }

  async function handleFieldsChange() {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      timeoutRef.current = null;
      await handleAutoSave();
    }, TIMEOUT_AUTOSAVE);
  }

  // Prevent double save with autosave
  useEffect(() => {
    if (!isLoading && timeoutRef.current) clearTimeout(timeoutRef.current);
  }, [isLoading]);

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        onFieldsChange={handleFieldsChange}
      >
        <Form.Item name='question' label='Question' required>
          <Input placeholder='Question' />
        </Form.Item>
        <Form.Item name='answer' label='Answer' required>
          <Input.TextArea
            autoSize={{ minRows: 5, maxRows: 15 }}
            placeholder='Answer'
          />
        </Form.Item>
        <Form.Item name='language' label='Language' required>
          <Select
            placeholder='Language'
            onChange={(value) => {
              nectar && setNectar({ ...nectar, language: value });
            }}
            options={languageOptions}
          />
        </Form.Item>
        <Form.Item name='publication_date' label='Publication date' required>
          <DatePicker placeholder='Publication date' />
        </Form.Item>
        <Form.Item name='owner' label='Owner' required>
          <Select
            disabled={organisationList.length === 0}
            placeholder='Owner'
            options={organisationList.map((organisation) => ({
              label: organisation.name,
              value: organisation._id,
            }))}
          />
        </Form.Item>

        <Form.Item
          name='medical_specialties'
          label='Medical specialties'
          required
        >
          <Select
            mode='multiple'
            placeholder='Medical specialties'
            options={tagList
              .filter((tag) => !tag.parent)
              .map((specialty) => ({
                label: specialty.translations["en"],
                value: specialty._id,
              }))}
            filterOption={searchFilterOptions}
          />
        </Form.Item>

        <Form.Item name='tags' label='Tags'>
          <SelectTagsField form={form} />
        </Form.Item>

        <Divider />

        <DebounceSelect
          style={{ width: "100%", marginBottom: 16 }}
          mode='multiple'
          placeholder='Search articles'
          fetchOptions={handleSearchArticles}
          value={[]}
          onChange={(article) => {
            handleAddArticle(
              article as unknown as Array<{
                label: string;
                value: string;
              }>
            );
          }}
        />
        <Table
          scroll={{ x: "max-content" }}
          columns={columnsArticles(handleDeleteArticle)}
          dataSource={nectar?.associatedArticles.map((article) => ({
            ...article,
            key: article._id,
          }))}
        />
      </Form>
    </Spin>
  );
}
