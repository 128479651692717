import { useEffect, useState } from "react";
import { Button, Col, Divider, Layout, Row, Select, Table } from "antd";
import { getVideoRequestList } from "../../../../services";
import {
  ArrowLeftOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import columns from "./columns";
import { IVideoRequest } from "~/model/video.model";
import { VideoRequestStatus } from "~/model/enum";

const VideoRequestList = () => {
  const navigate = useNavigate();
  const [requestsList, setRequestsList] = useState<Array<IVideoRequest> | null>(
    null
  );

  const [fetchStatus, setFetchStatus] = useState<VideoRequestStatus[] | null>(
    null
  );

  const getRequestList = async () => {
    const { docs: requests } = await getVideoRequestList(
      !fetchStatus?.length || !fetchStatus ? undefined : fetchStatus
    );
    setRequestsList(requests);
  };

  useEffect(() => {
    getRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus]);

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <Col>
            <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
              Video Requests
            </h1>
          </Col>
          <Col>
            <Select
              placeholder='Filter by status'
              style={{ width: 300 }}
              mode='multiple'
              onChange={(status) => setFetchStatus(status)}
              options={Object.values(VideoRequestStatus).map((status) => ({
                label: status,
                value: status,
              }))}
            />
            <Divider type='vertical' />
            <Button
              icon={<VideoCameraAddOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video/requests")}
              type={"primary"}
            >
              Requests
            </Button>
            <Button
              icon={<UnorderedListOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video")}
              type={"default"}
            >
              Management
            </Button>
            <Button
              icon={<BarChartOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video?metrics=true")}
              type={"default"}
            >
              Metrics
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={requestsList?.map((request) => ({
            ...request,
            key: request._id,
          }))}
          scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
          footer={() => (
            <Button
              icon={<ArrowLeftOutlined />}
              type='link'
              onClick={() => navigate(-1)}
            >
              {"Back to videos"}
            </Button>
          )}
        />
      </Layout.Content>
    </Layout>
  );
};

export default VideoRequestList;
