import {
  CheckCircleOutlined,
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Modal,
  notification,
  Select,
  Upload,
  UploadFile,
} from "antd";
import { Flex } from "~/components/shared/global";
import { IUser } from "~/model/user.model";
import {
  addUserLemonCertification,
  deleteUser,
  postUserProfilePicture,
  removeUserLemonCertification,
} from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";

type InnerProps = {
  user: IUser;
  fetchUsers: () => void;
};
export default function UsersListActions({ user, fetchUsers }: InnerProps) {
  async function fetchUploadUserProfilePic() {
    const onSubmit = async (values: {
      profilePicture: { file: UploadFile };
    }) => {
      if (!values.profilePicture)
        return notification.error({ message: "No file selected" });

      try {
        const { file } = values.profilePicture;
        notification.info({
          message: "Uploading profile picture...",
          icon: <LoadingOutlined />,
          duration: 0,
        });
        await postUserProfilePicture(user._id, file.originFileObj as File);
        notification.destroy();
        notification.success({
          message: "Profile picture uploaded successfully",
        });
        fetchUsers();
        Modal.destroyAll();
      } catch (error) {
        notification.destroy();

        notification.error({
          message: getRequestErrorMessage(error) || "An error occured",
        });

        return error;
      }
    };

    Modal.info({
      title: "Upload profile picture",
      okText: "Close",
      okButtonProps: { type: "default", block: true },
      icon: null,
      content: (
        <Form onFinish={onSubmit}>
          <Form.Item name='profilePicture' label='Image file'>
            <Upload
              accept='image/*'
              maxCount={1}
              multiple={false}
              customRequest={(payload) => {
                if (payload.onSuccess) payload.onSuccess("ok");
              }}
              action={""}
            >
              <Button htmlType='button' icon={<UploadOutlined />}>
                Upload image file
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button block type='primary' htmlType='submit'>
              Upload
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  }

  async function fetchCertifyUser(user: IUser) {
    if (user.lemonCertified) {
      await removeUserLemonCertification(user._id);
      fetchUsers();
      notification.success({
        message: "❌ User uncertified successfully",
      });
    } else {
      await addUserLemonCertification(user._id);
      fetchUsers();
      notification.success({
        message: "🍋 User certified successfully",
      });
    }
  }

  async function fetchDeleteUser(uid: string) {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      content: "This action is irreversible",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await deleteUser(uid);
        fetchUsers();
      },
    });
  }

  function handleChangeActions(value: string) {
    switch (value) {
      case "certify":
        fetchCertifyUser(user);
        break;
      case "delete":
        fetchDeleteUser(user.uid);
        break;
      case "addProfilePicture":
        fetchUploadUserProfilePic();
        break;
      default:
        break;
    }
  }

  return (
    <Flex justify='center' align='center' gap={8}>
      <Select
        style={{ width: 150 }}
        placeholder='Select action'
        onChange={handleChangeActions}
        value={null}
      >
        <Select.Option value={"certify"}>
          <Flex
            align={"center"}
            gap={8}
            style={{
              color: user.lemonCertified ? "#FF4D4F" : "#FFC81A",
              fontWeight: 600,
            }}
          >
            <CheckCircleOutlined />
            {user.lemonCertified ? "Uncertify" : "Certify"}
          </Flex>
        </Select.Option>
        <Select.Option value={"delete"}>
          <Flex align='center' gap={8}>
            <DeleteOutlined style={{ color: "red" }} />
            <div style={{ color: "red" }}>Delete</div>
          </Flex>
        </Select.Option>
        <Select.Option value={"addProfilePicture"}>
          <Flex align='center' gap={8}>
            <UserAddOutlined />
            <div>Add avatar</div>
          </Flex>
        </Select.Option>
      </Select>
    </Flex>
  );
}
