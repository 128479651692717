import { Button, Card, Col, notification, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { IInvite, IRoom } from "~/model/room.model";
import { createInvite, deleteInvite, listInvites } from "~/services";

const env = import.meta.env.VITE_APP_ENV;

const RoomInviteLinks = ({ room }: { room: IRoom }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [innerInvites, setInnerInvites] = useState<IInvite[] | null>(null);

  const roomId = room.id;
  const organisationId = room.organisation._id;

  useEffect(() => {
    const fetchInvites = async () => {
      if (!organisationId) return;

      try {
        const invites = await listInvites(organisationId);
        setInnerInvites(invites);
      } catch (error) {
        notification.error({
          message: "An error occured while fetching the invites",
          description: "Please try again later",
        });
        throw error;
      }
    };

    fetchInvites();
  }, [organisationId]);

  const handleInviteCreation = async () => {
    if (!roomId) return;

    setLoading(true);

    try {
      const createdInvite = await createInvite(roomId);

      setInnerInvites(
        innerInvites ? [...innerInvites, createdInvite] : [createdInvite]
      );

      notification.success({
        message: "Invite created successfully!",
        description: "The invite has been created and added to the room",
      });
    } catch (error) {
      notification.error({
        message: "An error occured while creating the invite",
        description: "Please try again later",
      });
      throw error;
    }
    setLoading(false);
  };

  const handleInviteDeletion = async (inviteCodeId: string) => {
    if (!organisationId) return;

    setLoading(false);
    try {
      await deleteInvite(inviteCodeId);
      const invites = await listInvites(organisationId);
      setInnerInvites(invites);

      notification.success({
        message: "Invite deleted successfully!",
        description: "The invite has been removed from the room",
      });
    } catch (error) {
      notification.error({
        message: "An error occured while deleting the invite",
        description: "Please try again later",
      });
      throw error;
    }

    setLoading(false);
  };

  const inviteLinks = innerInvites?.filter(
    (invite) => invite.room?.id === roomId
  );

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Card title={"List invite links"}>
          <Row gutter={[16, 16]}>
            {inviteLinks ? (
              inviteLinks.map((invite) => (
                <Col span={24} key={invite._id}>
                  <Card key={invite.code} style={{ marginBottom: "20px" }}>
                    <p>
                      <strong>Code: </strong>
                      <a
                        href={
                          env === "production"
                            ? `https://app.juisci.com/invite/${invite.code}`
                            : `https://dev.juisci.com/invite/${invite.code}`
                        }
                      >
                        {env === "production"
                          ? `https://app.juisci.com/invite/${invite.code}`
                          : `https://dev.juisci.com/invite/${invite.code}`}
                      </a>
                      <br />
                      <strong>Created at: </strong>
                      {new Date(invite.createdAt).toLocaleDateString()}
                    </p>
                    <Button
                      danger
                      type='primary'
                      loading={loading}
                      style={{
                        float: "right",
                      }}
                      onClick={() => handleInviteDeletion(invite._id)}
                    >
                      Remove
                    </Button>
                  </Card>
                </Col>
              ))
            ) : (
              <Spin />
            )}
          </Row>
        </Card>
      </Col>
      <Col span={8}>
        <Card title={"Create Invite"}>
          <Button
            block
            loading={loading}
            type='primary'
            onClick={handleInviteCreation}
            className='invite-form-button'
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            {"Add new invite"}
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default RoomInviteLinks;
