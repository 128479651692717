import React, { useEffect, useState } from "react";
import { Button, Input, Layout, Row, Select, Table, Tag } from "antd";
import { listCompanies } from "../services";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { languageOptions } from "../utils/helpers";
import { SupportedLanguage } from "~/model/enum";
import { ICompany } from "~/model/company.model";
import * as _ from "lodash";

export const CompanyList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const defaultLanguage = Object.values(SupportedLanguage).includes(
    search.get("language") as SupportedLanguage
  )
    ? (search?.get("language") as SupportedLanguage)
    : SupportedLanguage.EN;
  const [innerCompanyList, setInnerCompanyList] = useState<ICompany[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [language, setLanguage] = useState<SupportedLanguage>(defaultLanguage);

  const fetch = async () => {
    const companyList = await listCompanies({ language });
    setInnerCompanyList(companyList);
  };

  const handleLanguageChange = (language: SupportedLanguage) => {
    navigate(`/reference-data/company?language=${language}`);
    setLanguage(language);
  };

  const handleClick = () => {
    navigate(`/reference-data/company/create/?language=${language}`);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const columns = [
    {
      title: "Name",
      dataIndex: "_id",
      key: "_id",
      render: (text: string) => {
        const company = _.find(innerCompanyList, { _id: text });
        if (company) {
          return (
            <Link to={`/reference-data/company/${company._id}`}>
              {company.name}
            </Link>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      width: 100,
      render: (text: string) => {
        const company = _.find(innerCompanyList, { _id: text });
        if (company) {
          return (
            <Tag
              color={company?.meta?.status === "draft" ? "warning" : "success"}
            >
              {company?.meta?.status?.toLocaleUpperCase()}
            </Tag>
          );
        }
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
    },
    {
      title: "Clicks",
      dataIndex: "metrics",
      key: "metrics",
      render: (metrics: ICompany["metrics"]) => {
        return metrics.views;
      },
    },
    {
      title: "Shares",
      dataIndex: "metrics",
      key: "metrics",
      render: (metrics: ICompany["metrics"]) => {
        return metrics.shares;
      },
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row justify='space-between' style={{ margin: "20px 0" }}>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Companies</h1>
          <div>
            <Select
              defaultValue={language}
              style={{ marginRight: 10 }}
              onSelect={handleLanguageChange}
              options={languageOptions}
            />
            <Button type='primary' onClick={handleClick}>
              New company
            </Button>
          </div>
        </Row>

        <Input.Search
          placeholder='Search company'
          allowClear
          onSearch={setSearchValue}
        />
        <div style={{ height: 16 }} />
        <Table
          columns={columns}
          dataSource={innerCompanyList
            .filter((v) =>
              v.name.toLowerCase().includes(searchValue?.toLowerCase() ?? "")
            )
            .map((v) => ({ ...v, key: v._id }))}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          sticky
        />
      </Layout.Content>
    </Layout>
  );
};
