import { CalculatorOutlined } from "@ant-design/icons";
import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Flex } from "~/components/shared/global";
import { GlobalContext } from "~/context/global.context";
import { ContentFormatsEnum } from "~/model/enum";
import { MonitoringReducerModel } from "~/model/monitoring.model";

type InnerProps = {
  contentType: ContentFormatsEnum;
  dataKey: "articles" | "videos" | "nectars" | "playlists" | "infographics";
};

export default function ContentMonitoringTable({
  contentType,
  dataKey,
}: InnerProps) {
  const { monitoring } = useContext(GlobalContext);

  const columns: ColumnsType<
    MonitoringReducerModel[
      | "articles"
      | "videos"
      | "nectars"
      | "playlists"
      | "infographics"]["bySpecialty"][0]
  > = [
    {
      title: "Specialty",
      dataIndex: ["specialty", "translations", "en"],
      key: "specialty",
    },
    {
      title: `Total number of ${contentType}s`,
      dataIndex: dataKey,
      key: "total",
    },
    {
      title: `Last ${contentType} date`,
      dataIndex: "creationDate",
      key: "lastArticleDate",
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: `Last published ${contentType} title`,
      dataIndex: "title",
      key: "title",
      render: (title: string, content) => (
        <Link
          to={`/content-management/${contentType.toLowerCase()}/${
            content.slug
          }`}
        >
          {title}
        </Link>
      ),
    },
  ];
  return (
    <Card
      style={{ borderRadius: 16 }}
      title={
        <Flex align='center' gap={8}>
          <CalculatorOutlined />
          <span>{`Total number of ${contentType}s by main specialty`}</span>
        </Flex>
      }
    >
      <Table
        loading={!monitoring}
        columns={columns}
        dataSource={
          !monitoring
            ? []
            : monitoring[dataKey].bySpecialty.map((data, index) => ({
                key: "monitorign-table-" + data.title + index.toString(),
                ...data,
              }))
        }
        scroll={{ x: "max-content" }}
      />
    </Card>
  );
}
