/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Image,
  Upload,
  Button,
  Input,
  Modal,
  FormInstance,
  Form,
  notification,
} from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { pasteAndClearLineBreaks } from "~/utils/helpers";
import { useArticleEditionContext } from "~/utils/hooks";

interface DragImageUploader extends UploadProps {
  form: FormInstance;
  deleteFile: (name: string) => void;
}

const DragImageUploader: React.FC<DragImageUploader> = ({
  form,
  name,
  deleteFile,
  ...otherProps
}) => {
  const { article } = useArticleEditionContext();
  const images = article?.images.sort((a, b) => a.order - b.order);
  const noticedRef = React.useRef(false);

  const [fileList, setFileList] = useState<UploadFile[]>(
    images?.map((image) => ({
      uid: image._id,
      name: image.path,
      status: "done",
      url: image.url,
      legend: image.legend,
      order: image.order,
    })) || []
  );

  const [textAreaValue, setTextAreaValue] = useState("");
  const [preventPreview, setPreventPreview] = useState(false);
  const [selectedModal, setSelectedModal] = useState<string | null>(null);

  const watchFigures = Form.useWatch("figures", form);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const orderedList = newFileList.map((file, index) => ({
      ...file,
      order: (file as any)?.order || index,
      legend: (file as any)?.legend || undefined,
    }));

    setFileList(orderedList);
  };

  function handleBeforeUpload(
    file: UploadFile<File>,
    fileList: UploadFile<File>[]
  ) {
    if (otherProps.maxCount && fileList.length > otherProps.maxCount) {
      const index = fileList.indexOf(file);

      if (!noticedRef.current) {
        notification.error({
          message: "Max number of images reached",
          description: `You can only upload ${otherProps.maxCount} images, the rest will be ignored.`,
        });
        noticedRef.current = true;
      }

      if (index === fileList.length - 1) noticedRef.current = false;

      return false;
    }

    return true;
  }

  const onDragChange = (
    _sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    if (sourceIndex === targetIndex) return;

    setPreventPreview(true);
    const nextState = swap(fileList, sourceIndex, targetIndex);
    setFileList(nextState.map((file, index) => ({ ...file, order: index })));

    setTimeout(() => {
      setPreventPreview(false);
    }, 100);
  };

  const handleDelete = (file: UploadFile<any>) => {
    const newFileList = fileList.filter((el) => el !== file);
    setFileList(newFileList);

    if (file.url) deleteFile(file.name);
  };

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    file: UploadFile<any>
  ) => {
    const innerFile = { ...file, legend: e.target.value };

    const updatedList = fileList.map((el) =>
      el.uid === file.uid ? innerFile : el
    );

    setFileList(updatedList);
    setTextAreaValue(e.target.value);
  };

  useEffect(() => {
    form.setFieldValue("figures", fileList);
  }, [form, fileList]);

  // To update fileList when form is updated
  useEffect(() => {
    if (watchFigures && watchFigures.length > 0) {
      setFileList(watchFigures);
    }
  }, [watchFigures]);

  const uploadButton = (
    <div style={{ padding: 48 }}>
      <PlusOutlined />
      <div>{"Upload"}</div>
    </div>
  );

  return (
    <>
      <GridContextProvider onChange={onDragChange}>
        <StyledWrapper>
          <GridDropZone
            id='items'
            boxesPerRow={4}
            rowHeight={216}
            style={{
              height: 216 * Math.ceil(fileList.length / 4),
            }}
            disableDrag={!!selectedModal}
          >
            {fileList
              .sort((a, b) => (a as any).order - (b as any).order)
              .map((file) => (
                <GridItem key={file.uid}>
                  <div className='grid-item'>
                    <div className='upload-render'>
                      <Button
                        size='small'
                        shape='circle'
                        className='delete-button'
                        icon={<CloseOutlined />}
                        onClick={() => handleDelete(file)}
                      />
                      <Image
                        width='100%'
                        height={150}
                        preview={!preventPreview}
                        src={
                          file.url
                            ? file.url
                            : file.originFileObj
                            ? URL.createObjectURL(file.originFileObj)
                            : file.thumbUrl
                        }
                        alt={file.name}
                      />

                      <Modal
                        title='Add a legend'
                        open={selectedModal === file.name}
                        onOk={() => setSelectedModal(null)}
                        onCancel={() => setSelectedModal(null)}
                        destroyOnClose
                      >
                        <Input.TextArea
                          placeholder={"Legend image"}
                          style={{ width: "100%" }}
                          onPaste={pasteAndClearLineBreaks}
                          autoSize={{ minRows: 5, maxRows: 20 }}
                          value={textAreaValue}
                          onChange={(e) => handleChangeInput(e, file)}
                        />
                      </Modal>

                      <Button
                        type='link'
                        onClick={() => {
                          setSelectedModal(file.name);
                          setTextAreaValue((file as any).legend || "");
                        }}
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          width: "100%",
                          display: "block",
                          marginTop: 8,
                          padding: 0,
                        }}
                      >
                        {(file as any).legend || "Add a legend"}
                      </Button>
                    </div>
                  </div>
                </GridItem>
              ))}
          </GridDropZone>
        </StyledWrapper>
      </GridContextProvider>
      <StyledUpload
        showUploadList={false}
        name={name}
        multiple={true}
        accept='.jpg, .jpeg, .png'
        listType='picture-card'
        fileList={fileList}
        maxCount={fileList.length + (otherProps.maxCount || 0)}
        beforeUpload={handleBeforeUpload}
        onChange={handleChange}
        customRequest={(payload) => {
          if (payload.onSuccess) payload.onSuccess("ok");
        }}
      >
        {uploadButton}
      </StyledUpload>
    </>
  );
};

export default DragImageUploader;

const StyledUpload = styled(Upload)`
  display: block;
  box-sizing: border-box;
  height: 100%;
  width: 50%;
  margin: 16px auto;

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 75px;
  }
`;

const StyledWrapper = styled.div`
  .grid-item {
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  .upload-render {
    position: relative;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    .delete-button {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 10;
    }

    img {
      object-fit: cover;
    }
  }
`;
