import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps, message } from "antd";
import { IArticle } from "~/model/article.model";
import { postArticleAudioSpeech } from "~/services";

const UploadSpeechAudio = ({
  articleId,
  callback,
}: {
  articleId?: string;
  callback?: (article: IArticle) => void;
}) => {
  const handleUploadAudio: UploadProps["onChange"] = async (info) => {
    if (!articleId) return message.error("Article ID is missing.");

    if (
      info.file.status === "uploading" &&
      info.file.type !== "audio/mpeg" &&
      info.file.type !== "audio/wav"
    )
      return message.error("Only .mp3 and .wav files are allowed.");

    if (info.file.status === "error")
      return message.error(`${info.file.name} file upload failed.`);

    if (info.file.status === "done") {
      message.loading("Uploading audio file...");
      const updatedArticle = await postArticleAudioSpeech(
        articleId,
        info.file.originFileObj as File
      );
      callback && callback(updatedArticle);
      message.destroy();
      message.success("Audio file uploaded successfully!");
    }
  };

  return (
    <Upload
      name='file'
      accept='.mp3, .wav'
      showUploadList={false}
      customRequest={(payload) => {
        if (payload.onSuccess) payload.onSuccess("ok");
      }}
      onChange={handleUploadAudio}
    >
      <Button
        loading={!articleId}
        disabled={!articleId}
        icon={<UploadOutlined />}
      >
        Upload Speech Audio
      </Button>
    </Upload>
  );
};

export default UploadSpeechAudio;
