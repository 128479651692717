import { FormInstance, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditionPageHeader, {
  PageHeaderSetContent,
} from "~/components/EditionPageHeader.tsx";
import { getTranslatedArticleFromDoi } from "~/services";
import ArticleEditionTranslate from "./ArticleEditionTranslate";
import { ContentFormatsEnum } from "~/model/enum";
import { ArticleTranslationResponse } from "~/model/article.model";
import { useArticleEditionContext } from "~/utils/hooks";

type ArticleEditionHeaderProps = {
  form: FormInstance;
};
export default function ArticleEditionHeader({
  form,
}: ArticleEditionHeaderProps) {
  const navigate = useNavigate();
  const { id: articleId } = useParams();
  const { article, setArticle } = useArticleEditionContext();
  const [translatedArticles, setTranslatedArticles] =
    useState<ArticleTranslationResponse | null>(null);

  useEffect(() => {
    async function getTranslations() {
      if (!article) return;

      const translations = await getTranslatedArticleFromDoi(article.doi);
      if (translations) setTranslatedArticles(translations);
    }

    getTranslations();
  }, [article]);

  const isCreationMode = !articleId && !article;

  if (!article && !isCreationMode) return null;

  return (
    <EditionPageHeader
      format={ContentFormatsEnum.ARTICLE}
      form={form}
      content={article || undefined}
      setContent={setArticle as PageHeaderSetContent}
    >
      {!isCreationMode && (
        <ArticleEditionTranslate
          article={article}
          setArticle={setArticle}
          translatedArticles={translatedArticles}
          setTranslatedArticles={setTranslatedArticles}
        />
      )}

      {!isCreationMode && article && (
        <Select
          style={{ width: 120, marginLeft: 10 }}
          placeholder='Translated version'
          defaultValue={articleId}
          disabled={!translatedArticles}
          loading={!translatedArticles}
          options={translatedArticles?.map((art) => ({
            label: `Version ${art.language.toUpperCase()}`,
            value: art._id,
            disabled: art._id === article._id,
          }))}
          onChange={(value) => {
            setArticle(null);
            navigate(`/content-management/article/${value}`, {
              replace: true,
              state: {
                translatedArticles,
              },
            });
          }}
        />
      )}
    </EditionPageHeader>
  );
}
