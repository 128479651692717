import { Select, Tabs } from "antd";
import { CONTENT_TYPES_TABS } from "../performance/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Spacer } from "~/components/shared/global";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "~/context/global.context";
import { searchFilterOptions } from "~/utils/helpers";
import { useDispatch } from "react-redux";
import { fetchData, fetchDataSuccess } from "~/store/actions";
import { FETCH_MONITORING } from "~/store/types";
import {
  MonitoringContext,
  MonitoringContextModel,
} from "~/context/monitoring.context";

const ContentMonitoringTabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { organisationList, tagList, monitoring } = useContext(GlobalContext);
  const { setFilters } = useContext(MonitoringContext);

  const searchParams = new URLSearchParams(location.search);

  const [fetchParams, setFetchParams] = useState<
    MonitoringContextModel["filters"]
  >({
    medicalSpecialties: searchParams.get("medicalSpecialties") || undefined,
    organisations: searchParams.get("organisations") || undefined,
  });

  useEffect(() => {
    dispatch(dispatch(fetchDataSuccess("monitoring", null)));
    dispatch(fetchData(FETCH_MONITORING, fetchParams));

    if (setFilters) setFilters(fetchParams);

    const filteredParams = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(fetchParams).filter(([_key, value]) => value !== undefined)
    );

    const searchParams = new URLSearchParams(filteredParams);

    navigate(`${location.pathname.split("/")[3]}?${searchParams}`, {
      replace: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParams]);

  return (
    <div className='basic-layout' style={{ paddingBottom: 0 }}>
      <h1 onClick={() => console.log(fetchParams)}>{"Content Monitoring"}</h1>
      <Flex
        align='start'
        justify='space-between'
        style={{
          backgroundColor: "#fff",
          padding: "12px 24px 0",
          borderRadius: 16,
          marginTop: 24,
        }}
      >
        <Tabs
          defaultActiveKey={location.pathname.split("/")[3]}
          onChange={(activeKey) => {
            navigate(activeKey);
          }}
          items={CONTENT_TYPES_TABS.map((tab) => ({
            key: tab.key,
            tabKey: tab.type,
            label: tab.title,
          }))}
        />
        <Spacer />
        <Flex align='center' gap={8}>
          <Select
            allowClear
            showSearch
            loading={!organisationList.length}
            disabled={!organisationList.length || !monitoring}
            placeholder='Select Organisation'
            style={{ width: 200, marginTop: 8 }}
            options={organisationList.map((org) => ({
              label: org.name,
              value: org._id,
            }))}
            filterOption={searchFilterOptions}
            defaultValue={fetchParams.organisations}
            onChange={(value) =>
              setFetchParams({ ...fetchParams, organisations: value })
            }
          />
          <Select
            allowClear
            showSearch
            placeholder='Select specialties'
            loading={!organisationList.length}
            disabled={!organisationList.length || !monitoring}
            style={{ width: 200, marginTop: 8 }}
            defaultValue={fetchParams.medicalSpecialties}
            options={tagList
              .filter((tag) => !tag.parent)
              .map((org) => ({
                label: org.translations.en,
                value: org._id,
              }))}
            filterOption={searchFilterOptions}
            onChange={(value) =>
              setFetchParams({ ...fetchParams, medicalSpecialties: value })
            }
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default ContentMonitoringTabs;
