import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import frLocale from "i18n-iso-countries/langs/fr.json";

countries.registerLocale(enLocale);
countries.registerLocale(frLocale);

export const POPULAR_COUNTRY_CODES = [
  "FR",
  "GB",
  "US",
  "DE",
  "DZ",
  "ES",
  "MA",
  "BE",
  "SN",
  "CA",
  "CI",
  "NG",
  "CH",
  "SG",
  "ZA",
  "IN",
  "PK",
  "TN",
  "NO",
  "NL",
  "AU",
];

export const countryList = countries.getNames("en", { select: "official" });
