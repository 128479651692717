import { CalendarOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import moment from "moment";
import MetricCard from "~/components/monitoring/MetricCard";
import ContentMonitoringTable from "../../components/monitoring/ContentMonitoringTable";
import ContentMonitoringVersus from "~/components/monitoring/ContentMonitoringVersus";
import { useContext } from "react";
import { GlobalContext } from "~/context/global.context";
import {
  ContentMonitoringCardLabel,
  ContentMonitoringDateCardLabel,
} from "~/components/shared/global";
import { ContentFormatsEnum } from "~/model/enum";

export default function ContentMonitoringInfographics() {
  const { monitoring } = useContext(GlobalContext);

  return (
    <div className='basic-layout' style={{ paddingTop: 0 }}>
      <Divider />
      <Row gutter={24}>
        <Col span={12}>
          <MetricCard
            icon={<PlayCircleOutlined />}
            backgroundColor='#ffc408'
            title={monitoring?.infographics?.total || "..."}
            size='large'
            label={ContentMonitoringCardLabel(ContentFormatsEnum.INFOGRAPHIC)}
          />
        </Col>
        <Col span={12}>
          <MetricCard
            icon={<CalendarOutlined />}
            backgroundColor='#EEF822'
            label={ContentMonitoringDateCardLabel(
              ContentFormatsEnum.INFOGRAPHIC
            )}
            title={moment().format("DD MMM YYYY")}
            subtitle='Today'
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={6}>
          <MetricCard
            title={monitoring?.infographics?.byStatus?.drafts || 0}
            subtitle='Draft'
          />
        </Col>
        <Col span={6}>
          <MetricCard
            title={monitoring?.infographics?.byStatus?.published || 0}
            subtitle='Published'
          />
        </Col>
        <Col span={6}>
          <MetricCard title={-1} subtitle='by Juisci' />
        </Col>
        <Col span={6}>
          <MetricCard title={-1} subtitle='by Clients' />
        </Col>
      </Row>
      <Divider />
      <ContentMonitoringTable
        dataKey='infographics'
        contentType={ContentFormatsEnum.NECTAR}
      />
      <Divider />
      <ContentMonitoringVersus
        contentType={ContentFormatsEnum.NECTAR}
        dataKey='infographics'
      />
    </div>
  );
}
