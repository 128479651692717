// Enums

export enum SupportedLanguage {
  EN = "en",
  FR = "fr",
  IT = "it",
  ES = "es",
  DE = "de",
  PT = "pt",
  AR = "ar",
}

export enum SupportedPublisherEnum {
  COMPANY = "company",
  CONGRESS = "congress",
  JOURNAL = "journal",
  USER = "user",
}

export enum PermissionLabel {
  Sources = "sources",
  Content = "content",
  Referential = "referential",
  SensitiveData = "sensitive data",
}

export enum PermissionEnum {
  Read = "read",
  Write = "write",
  Publish = "publish",
  Delete = "delete",
}

export enum OrganisationUid {
  JUISCI = "juisci",
  BIOCODEX = "biocodex-rd",
  SFRO = "sfro",
  SFR = "sfr",
  ROYAL_CANIN = "royal-canin",
  SPILF = "spilf",
  MGEN = "mgen",
  SANOFI = "sanofi-bodypain-app-staging",
  ESR = "esr",
  DEMO = "dashboard-demo",
  OREAL = "loreal-dermato-portal-staging",
  TAKEDA = "takeda-lymphoma-app",
  UCB = "ucb-rare-diseases",
}

export enum FeatureEnum {
  JuisciGPT = "JuisciGPT",
  Videos = "Videos",
  Infographics = "Infographics",
  Users = "Users",
  Analytics = "Analytics",
  QrCodes = "QrCodes",
  Playlists = "Playlists",
  Nectars = "Nectars",
}

export enum RoomType {
  Default = "Default",
  ExternalApi = "ExternalApi",
  Audio = "Audio",
}

export enum IUserRole {
  ADMIN = "admin",
  USER = "user",
  ORGANISATION = "organisation",
  SUPER_ADMIN = "super_admin",
}

export enum VideoFormatEnum {
  ARTICLE = "article",
  STORY = "story",
}

export enum VideoRequestStatus {
  WAITING = "Waiting",
  REJECTED = "Rejected",
  DONE = "Done",
  CANCELED = "Canceled",
  WAITING_VALIDATION = "WaitingValidation",
}

export enum FeedbackStatusEnum {
  NEW = "New",
  DONE = "Done",
  CANCEL = "Cancel",
}

export enum ContentFormatsEnum {
  ARTICLE = "Article",
  VIDEO = "Video",
  INFOGRAPHIC = "Infographic",
  NECTAR = "Nectar",
  PLAYLIST = "Playlist",
}
