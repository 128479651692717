import { Layout, Spin } from "antd";

const LoadingLayout = () => {
  return (
    <Layout>
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
          height: "100dvh",
          position: "relative",
        }}
      >
        <Spin
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          size='large'
        />
      </Layout.Content>
    </Layout>
  );
};

export default LoadingLayout;
