import { Card, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import moment from "moment";
import { Flex } from "~/components/shared/global";

type DataType = {
  id: string;
  title: string;
  contentType: string;
  creationDate: Date;
  link: string;
  owner?: string;
};

type InnerProps = {
  title: string;
  icon: React.ReactNode;
  data: DataType[];
};

const columns: ColumnsType<DataType> = [
  {
    title: "Type",
    key: "contentType",
    dataIndex: "contentType",
    render: (val: string) => <Tag>{val.toLocaleUpperCase()}</Tag>,
    width: 100,
  },
  {
    title: "Creation Date",
    key: "creationDate",
    dataIndex: "creationDate",
    render: (val: Date) => moment(val).format("MM/DD/YYYY"),
  },
  {
    title: "Title",
    key: "title",
    dataIndex: "title",
    render: (val: string, record: DataType) => (
      <Link to={record.link}>{val}</Link>
    ),
  },
  {
    title: "Owner",
    key: "owner",
    dataIndex: "owner",
    render: (val: string) => val || "N/A",
  },
];

const PerformanceTop10Table = ({ title, icon, data }: InnerProps) => {
  return (
    <Card
      title={
        <Flex align='center' gap={8}>
          {icon}
          <span>{title}</span>
        </Flex>
      }
    >
      <Table
        columns={columns}
        dataSource={data.map((data) => ({ key: data.id, ...data }))}
      />
    </Card>
  );
};

export default PerformanceTop10Table;
