import {
  LeftOutlined,
  SolutionOutlined,
  TagsOutlined,
  TranslationOutlined,
} from "@ant-design/icons";
import { Button, Card, Table } from "antd";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Spacer } from "~/components/shared/global";
import { GlobalContext } from "~/context/global.context";

import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(enLocale);

const PerformanceMetricsUsersDetail = () => {
  const navigate = useNavigate();
  const params = useParams<{
    type: "usersByCountry" | "usersByProfession" | "usersByMainSpecialty";
  }>();
  const { monitoring } = useContext(GlobalContext);

  const renderTitle = () => {
    let title = "";
    let icon = null;

    switch (params.type) {
      case "usersByCountry":
        title = "Registered Users by Country";
        icon = <TranslationOutlined />;
        break;

      case "usersByProfession":
        title = "Registered Users by Profession";
        icon = <SolutionOutlined />;
        break;

      case "usersByMainSpecialty":
        title = "Registered Users by Main Specialty";
        icon = <TagsOutlined />;
        break;

      default:
        break;
    }

    return (
      <Flex gap={8} align='center'>
        {icon}
        <span>{title}</span>
      </Flex>
    );
  };

  const getDataSource = () => {
    if (!monitoring) return [];

    switch (params.type) {
      case "usersByCountry":
        return monitoring.users.usersByCountry.map((item) => ({
          key: item.country,
          label: item.country
            ? countries.getName(item.country, "en")
            : "Unknown",
          users: item.users || 0,
        }));

      case "usersByProfession":
        return monitoring.users.usersByProfession.map((item) => ({
          key: item.profession?._id,
          label: item.profession?.translations?.en || "Unknown",
          users: item.users || 0,
        }));

      case "usersByMainSpecialty":
        return monitoring.users.usersByMainSpecialty.map((item) => ({
          key: item.specialty?._id,
          label: item.specialty?.translations?.en || "Unknown",
          users: item.users || 0,
        }));

      default:
        break;
    }
  };

  const label =
    params.type === "usersByCountry"
      ? "Country"
      : params.type === "usersByProfession"
      ? "Profession"
      : "Main Specialty";

  if (!monitoring || !params?.type) return null;

  return (
    <div className='basic-layout'>
      <Button
        type='link'
        icon={<LeftOutlined />}
        style={{ padding: 0 }}
        onClick={() => navigate(-1)}
      >
        {"Back"}
      </Button>
      <Spacer />
      <Card title={renderTitle()}>
        <Table
          size='small'
          columns={[
            {
              title: label,
              dataIndex: "label",
              key: "label",
            },
            {
              title: "Users",
              dataIndex: "users",
              key: "users",
            },
          ]}
          dataSource={getDataSource()}
        />
      </Card>
    </div>
  );
};

export default PerformanceMetricsUsersDetail;
