import { useContext, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Spin,
  Tabs,
} from "antd";
import { GlobalContext } from "~/context/global.context";
import { languageOptions, searchFilterOptions } from "~/utils/helpers";
import { sendNotification } from "~/services";
import { Flex } from "~/components/shared/global";
import { NotificationOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SupportedLanguage } from "~/model/enum";

const NotificationEdition = () => {
  const { roomList } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [notificationSending, setNotificationSending] =
    useState<boolean>(false);

  const [globalNotification, setGlobalNotification] = useState<boolean>(false);

  const handleFinish = async (values: {
    url: string;
    room?: string;
    contents?: {
      [key in SupportedLanguage]: {
        title: string;
        text: string;
      };
    };
    title?: string;
    body?: string;
  }) => {
    try {
      setNotificationSending(true);

      const contents = globalNotification
        ? undefined
        : languageOptions
            .map((language) => ({
              title: values.contents?.[language.value]?.title || "",
              text: values.contents?.[language.value]?.text || "",
              language: language.value,
            }))
            .filter((content) => content.title || content.text);

      await sendNotification({
        ...values,
        contents,
      });

      form.resetFields();
      setNotificationSending(false);

      notification.success({
        message: "Notification sent",
        description: "The notification was sent successfully",
      });

      navigate("/sensitive-data/notifications");
    } catch (error) {
      setNotificationSending(false);

      notification.error({
        message: "Error",
        description:
          (error as Error)?.message ||
          "An error occurred while sending the notification",
      });
    }
  };

  const onCheckChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;

    setGlobalNotification(checked);

    if (checked) {
      form.setFieldValue("room", undefined);
      form.setFieldValue("contents", undefined);
    } else {
      form.setFieldValue("title", undefined);
      form.setFieldValue("body", undefined);
    }
  };

  return (
    <div className='basic-layout'>
      <Flex align='center' justify='space-between'>
        <h1
          style={{ margin: 0 }}
          onClick={() => console.log("form", form.getFieldsValue())}
        >
          Create a notification
        </h1>
        <Button
          type='primary'
          icon={<NotificationOutlined />}
          onClick={() => form.submit()}
          disabled={notificationSending}
          loading={notificationSending}
        >
          {"Send notification"}
        </Button>
      </Flex>
      <Divider />
      <Spin spinning={notificationSending}>
        <Card>
          <Form
            form={form}
            layout='vertical'
            name='send_notification'
            onFinish={handleFinish}
          >
            <Form.Item key='room' name='room' label='Room'>
              <Select
                allowClear
                showSearch
                placeholder='Select a room'
                filterOption={searchFilterOptions}
                disabled={!roomList?.length || globalNotification}
                loading={!roomList?.length}
                options={roomList?.map((room) => ({
                  label: room.name,
                  value: room.id,
                }))}
              />
            </Form.Item>
            <Form.Item key='url' label='URL Link' name='url' required>
              <Input placeholder='URL' />
            </Form.Item>
            <Divider orientation='left'>
              <Checkbox onChange={onCheckChange}>
                {"Send a global notification"}
              </Checkbox>
            </Divider>
            {globalNotification ? (
              <>
                <Form.Item label='Title' name='title' required>
                  <Input placeholder='Title' />
                </Form.Item>
                <Form.Item label='Notification content' name='body' required>
                  <Input.TextArea placeholder='Global notification content' />
                </Form.Item>
              </>
            ) : (
              <Tabs>
                {languageOptions.map((language) => (
                  <Tabs.TabPane key={language.value} tab={language.label}>
                    <Form.Item
                      key={["contents", language.value, "title"].toString()}
                      label='Title'
                      name={["contents", language.value, "title"]}
                      required
                    >
                      <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item
                      key={["contents", language.value, "text"].toString()}
                      label='Notification content'
                      name={["contents", language.value, "text"]}
                      required
                    >
                      <Input.TextArea placeholder='Notification content' />
                    </Form.Item>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            )}
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default NotificationEdition;
