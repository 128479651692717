import React, { createContext } from "react";

export interface MonitoringContextModel {
  filters: {
    medicalSpecialties?: string;
    organisations?: string;
  };
  setFilters?: React.Dispatch<
    React.SetStateAction<MonitoringContextModel["filters"]>
  >;
}

const defaultMonitoringContext: MonitoringContextModel = {
  filters: {
    medicalSpecialties: undefined,
    organisations: undefined,
  },
};

export const MonitoringContext = createContext<MonitoringContextModel>(
  defaultMonitoringContext
);

export function MonitoringProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filters, setFilters] = React.useState<
    MonitoringContextModel["filters"]
  >(defaultMonitoringContext.filters);

  return (
    <MonitoringContext.Provider value={{ filters, setFilters }}>
      {children}
    </MonitoringContext.Provider>
  );
}
