import { useLocation } from "react-router-dom";
import { pathToSlug } from "./helpers";
import { LocalStorageKeys } from "./constants";
import { useContext } from "react";
import { ArticleEditionContext } from "~/context/article.context";

export function useCheckFiltersSaved() {
  const location = useLocation();
  const saves = JSON.parse(
    localStorage.getItem(LocalStorageKeys.FILTERS_DRAWER) || "{}"
  );
  const slugPath = pathToSlug(location.pathname);

  return !!saves[slugPath];
}

export const useArticleEditionContext = () => {
  const context = useContext(ArticleEditionContext);
  if (context === undefined) {
    throw new Error(
      "useArticleEditionContext must be used within a ArticleEditionProvider"
    );
  }
  return context;
};
